import React from 'react';

import { K6_FREE_INCLUDED, LIMIT_TITLE } from '../consts/usage';
import { formatUsageValue, handleCompactFormat } from '../utils/usageUtils';
import { Metric } from './Metric';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { UsageProps } from 'feature/usage/types/UsageData';

export function K6Usage({ usage, isFree, billingDashboard }: UsageProps): JSX.Element {
  return (
    <div data-testid={DataTestId.K6_USAGE_METRIC_ID}>
      <Metric
        id="metric_k6"
        label={isFree ? `${LIMIT_TITLE}: ${handleCompactFormat(K6_FREE_INCLUDED)} VUh` : ''}
        link={billingDashboard?.url}
        value={formatUsageValue(usage, MetricUnit.K6_TESTS, isFree as boolean)}
        unit={'k6 tests'}
      ></Metric>
    </div>
  );
}

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { appCreateApi } from '../../../app/context';
import { Dashboard, DashboardResponse } from '../types/Dashboard';

export const BillingDashboardApi = appCreateApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  reducerPath: 'billing-dashboard',
  endpoints: (build) => ({
    getBillingDashboard: build.query<DashboardResponse, void>({
      query: () => `api/search?type=dash-db&tag=billing`,
      transformResponse: (response: Dashboard[]) =>
        response?.find((dashboard) => {
          const uri = decodeURIComponent(dashboard.uri ?? '');
          return uri.indexOf('billing-usage') !== -1 || uri.indexOf('grafana-cloud-billing/usage') !== -1;
        }),
    }),
  }),
});

export const { useGetBillingDashboardQuery } = BillingDashboardApi;

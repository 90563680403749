import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getImportDataSourceConfigStyles = (theme: GrafanaTheme2) => ({
  container: css`
    top: 0;
    width: 100%;
    overflow-y: auto;
  `,
  logoContainer: css`
    border: 1px solid ${theme.colors.border.strong};
    height: 46px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 16px;
    position: relative;
    background-color: ${theme.components.input.background};
  `,
  logoIcon: css`
    width: 32px;
    height: 32px;
    margin-right: ${theme.spacing(1)};
  `,
  wrapper: css`
    margin-top: 32px;
    max-width: 700px;
    width: 100%;
    text-align: left;

    h3 {
      line-height: 2.5rem;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  `,
  selectContent: css`
    margin-top: ${theme.spacing(3)};
  `,
  boldfaceText: css`
    font-weight: 700;
    margin-left: ${theme.spacing(1)};
  `,
  headerWrapper: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 60px;
  `,
  buttonsWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing(4)};
  `,
  continueButton: css`
    width: 338px;
    height: 48px;
    display: flex;
    justify-content: center;
  `,
  dbConfigContainer: css`
    justify-content: center;
    text-align: left;
  `,
  alert: css`
    margin-top: ${theme.spacing(2)};
  `,
});

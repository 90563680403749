import { cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import React, { useContext, useEffect } from 'react';

import { AppContext } from 'App';
import { getPageStyles } from 'feature/common/components/Page.styles';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { OnboardingCard } from 'feature/onboarding/components/OnboardingCard';
import { OnboardingImageCard } from 'feature/onboarding/components/OnboardingCard/OnboardingImageCard';
import { OnboardingLinksCard } from 'feature/onboarding/components/OnboardingCard/OnboardingLinksCard';
import { getOnboardingScreenStyles } from 'feature/onboarding/components/OnboardingScreen/OnboardingScreen.styles';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import {
  ControlOnboardingStep,
  OnboardingCardData,
  OnboardingGeneralCardData,
  OnboardingImageCardData,
  OnboardingLinksCardData,
  OnboardingStepData,
  SimplifiedOnboardingStep,
} from 'feature/onboarding/types/OnboardingCard';
import grafana from 'img/grafana-icon.svg';
import { trackPage } from '@grafana/cloud-ab-testing';
import { getTestGroupBasedOnFlowEvent } from 'feature/onboarding/utils/TestGroup';

interface OnboardingScreenProps {
  step: ControlOnboardingStep | SimplifiedOnboardingStep;
  stepData: OnboardingStepData;
  eventName?: FlowEventName;
}

export const OnboardingScreen: React.FC<OnboardingScreenProps> = ({
  step,
  stepData,
  eventName = FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
}) => {
  const pageStyles = useStyles2(getPageStyles);
  const styles = useStyles2((theme) => getOnboardingScreenStyles(theme, 'onboarding'));
  const { orgId } = useContext(AppContext);

  useEffect(() => {
    document.title = 'Get started - Grafana';
    trackPage({
      adobeTarget: {
        viewName: `onboarding-flow/${step}`,
      },
    });

    return trackAction(eventName, `${step}-screen-${getTestGroupBasedOnFlowEvent(eventName)}`, ActionType.visit, orgId);
  }, [step, orgId, eventName]);

  function onComponentClick(key: string): void {
    trackAction(eventName, key, ActionType.click, orgId);
  }

  function renderCard(card: OnboardingCardData) {
    if (!!card) {
      switch (card.type) {
        case 'general':
          return <OnboardingCard card={card as OnboardingGeneralCardData} key={card.key} />;
        case 'image':
          return <OnboardingImageCard card={card as OnboardingImageCardData} key={card.key} />;
        case 'links':
          return <OnboardingLinksCard card={card as OnboardingLinksCardData} key={card.key} />;
      }
    }
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={cx(pageStyles.wrapper, styles.wrapper)}>
        {!!stepData && (
          <>
            <div className={styles.buttonsWrapper}>
              <a
                className={styles.buttonsLink}
                href={stepData.buttons.back.link}
                onClick={() => onComponentClick(stepData.buttons.back.key)}
              >
                {stepData.buttons.back.text}
              </a>
              <button
                className={styles.buttonsLink}
                onClick={() => {
                  onComponentClick(stepData.buttons.skip.key);
                  window.location.href = stepData.buttons.skip.link;
                }}
              >
                {stepData.buttons.skip.text}
              </button>
            </div>
            <div className={styles.headerWrapper}>
              {stepData.step === 'start' && <img className={styles.icon} alt="Grafana" src={grafana} />}
              <div className={styles.textWrapper}>
                <h1 className={styles.header}>{stepData.header}</h1>
                {!!stepData.preamble && <p className={styles.preamble}>{stepData.preamble}</p>}
              </div>
            </div>
            <div className={styles.cardWrapper(stepData.columns)}>
              {stepData.cards.map((card: OnboardingCardData) => renderCard(card))}
            </div>
            {stepData.buttons.more && (
              <div className={styles.moreBtnWrapper}>
                <a
                  className={styles.buttonsLink}
                  href={stepData.buttons.more.link}
                  onClick={() => onComponentClick(stepData.buttons.more!.key)}
                >
                  {stepData.buttons.more.text}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

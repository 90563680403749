import { isCloudRBACRolesEnabled } from './featureToggles';
import { CurrentUser, Org, ROLE, User } from '../types/User';
import { ContextServType, ContextUser } from 'feature/common/types/ContextServType';

const getContextSrv: () => ContextServType | null | undefined = () => require('grafana/app/core/core').contextSrv;

export const isUserAdmin = (): boolean => {
  return getContextSrv()?.hasRole?.('Admin') === true;
};

export const isUserEditor = (): boolean => {
  return getContextSrv()?.hasRole?.('Editor') === true;
};

export const isUserViewer = (): boolean => {
  return getContextSrv()?.hasRole?.('Viewer') === true;
};

export const isSignedIn = (): boolean => {
  return getContextSrv()?.isSignedIn === true;
};

export const getContextUser = (): ContextUser | undefined => {
  return getContextSrv()?.user;
};

export const isAdmin = (user: CurrentUser | undefined, orgs: Org[] | undefined) => {
  if (!!user && !!orgs) {
    const { orgId } = user;
    const currentOrg = orgs.find((org) => org.orgId === orgId);

    return currentOrg?.role === ROLE.ADMIN;
  }
  return false;
};

export const getOrgRole = (user: CurrentUser | undefined, orgs: Org[] | undefined): ROLE | undefined => {
  if (!!user && !!orgs) {
    const { orgId } = user;
    const currentOrg = orgs.find((org) => org.orgId === orgId);

    return currentOrg?.role as ROLE;
  }
  return undefined;
};

export function excludeLocalAdmin(users: User[]): User[] {
  return users.filter((user) => user.email !== 'admin@localhost');
}

export enum Action {
  SupportTicketsRead = 'cloud.support-tickets:read',
  SupportTicketsWrite = 'cloud.support-tickets:write',
}

// if "cloudRBACRoles" feature toggle is enabled action will be used
// to evaluate if a user has access. Otherwise fallback will be used.
export function hasAccess(action: Action, fallback: ROLE): boolean {
  if (!isCloudRBACRolesEnabled()) {
    if (fallback === ROLE.ANY) {
      return true;
    }
    return getContextSrv()?.hasRole?.(fallback) === true;
  }
  return getContextSrv()?.hasPermission?.(action) === true;
}

// if "cloudRBACRoles" feature toggle is enabled actions will be used
// to evaluate if a user has access. Otherwise fallback will be used.
export function hasAnyAccess(fallback: ROLE, ...actions: Action[]): boolean {
  return actions.findIndex((v) => hasAccess(v, fallback)) !== -1;
}

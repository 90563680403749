import {
  DatasourceConfigType,
  DatasourceConfigVersion,
  Datasources,
} from 'feature/datasource-config/types/DatasourceConfig';

import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { GettingStartedPath } from 'feature/onboarding/types/GettingStartedCard';

export const datasourceConfigData: { [key: string]: DatasourceConfigType } = {
  influxdb: {
    datasource: 'influxdb',
    name: 'InfluxDB',
    htmlPrefix: 'influxdb-config',
    header: 'Configure your InfluxDB connection',
    preamble: 'Dashboard, query and alert on data from an InfluxDB compatible database',
    form: [],
    buttons: {
      skip: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SKIP_CONFIG_INFLUXDB,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SELECT_AND_SETTINGS_CONFIG_INFLUXDB,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SAVE_CONFIG_INFLUXDB,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
  },
  graphite: {
    datasource: 'graphite',
    name: 'Graphite',
    htmlPrefix: 'graphite-config',
    header: 'Configure your Graphite connection',
    preamble: 'Dashboard, query and alert on data from a Graphite compatible database',
    form: [],
    buttons: {
      skip: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SKIP_CONFIG_GRAPHITE,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SELECT_AND_SETTINGS_CONFIG_GRAPHITE,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SAVE_CONFIG_GRAPHITE,
      },
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.SELECT_AND_BACK_CONFIG_GRAPHITE,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
  },
  postgres: {
    datasource: 'postgres',
    name: 'PostgreSQL',
    htmlPrefix: 'postgresql-config',
    header: 'Configure your PostgreSQL connection',
    preamble: 'Dashboard, query and alert on data from a PostgreSQL compatible database',
    form: [],
    buttons: {
      skip: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SKIP_CONFIG_POSTGRESQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SELECT_AND_SETTINGS_CONFIG_POSTGRESQL,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SAVE_CONFIG_POSTGRESQL,
      },
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.SELECT_AND_BACK_CONFIG_POSTGRESQL,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
  },
  mysql: {
    datasource: 'mysql',
    name: 'MySQL',
    htmlPrefix: 'mysql-config',
    header: 'Configure your MySQL connection',
    preamble: 'Dashboard, query and alert on data from a MySQL compatible database',
    form: [],
    buttons: {
      skip: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SKIP_CONFIG_MYSQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SELECT_AND_SETTINGS_CONFIG_MYSQL,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SAVE_CONFIG_MYSQL,
      },
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.SELECT_AND_BACK_CONFIG_MYSQL,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
  },
  prometheus: {
    datasource: 'prometheus',
    name: 'Prometheus',
    htmlPrefix: 'prometheus-config',
    header: 'Configure your Prometheus connection',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SKIP_CONFIG_PROMETHEUS,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SELECT_AND_SETTINGS_CONFIG_PROMETHEUS,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.SELECT_AND_SAVE_CONFIG_PROMETHEUS,
      },
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.SELECT_AND_BACK_CONFIG_PROMETHEUS,
      },
    },
    successMessage: 'Success! Your data is connected.',
  },
};

const datasourceConfigSetupWizardData: { [key: string]: DatasourceConfigType } = {
  influxdb: {
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    datasource: 'influxdb',
    name: 'InfluxDB',
    htmlPrefix: 'influxdb-config',
    header: '2. Configure data - InfluxDB',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SKIP_CONFIG_INFLUXDB,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SETTINGS_CONFIG_INFLUXDB,
      },
      save: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SAVE_CONFIG_INFLUXDB,
      },
    },
  },
  graphite: {
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    datasource: 'graphite',
    name: 'Graphite',
    htmlPrefix: 'graphite-config',
    header: '2. Configure data - Graphite',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SKIP_CONFIG_GRAPHITE,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SETTINGS_CONFIG_GRAPHITE,
      },
      save: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SAVE_CONFIG_GRAPHITE,
      },
    },
  },
  postgres: {
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    datasource: 'postgres',
    name: 'PostgreSQL',
    htmlPrefix: 'postgresql-config',
    header: '2. Configure data - PostgreSQL',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SKIP_CONFIG_POSTGRESQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SETTINGS_CONFIG_POSTGRESQL,
      },
      save: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SAVE_CONFIG_POSTGRESQL,
      },
    },
  },
  mysql: {
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    datasource: 'mysql',
    name: 'MySQL',
    htmlPrefix: 'mysql-config',
    header: '2. Configure data - MySQL',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SKIP_CONFIG_MYSQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SETTINGS_CONFIG_MYSQL,
      },
      save: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SAVE_CONFIG_MYSQL,
      },
    },
  },
  prometheus: {
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    datasource: 'prometheus',
    name: 'Prometheus',
    htmlPrefix: 'prometheus-config',
    header: '2. Configure data - Prometheus',
    preamble: '',
    form: [],
    buttons: {
      skip: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SKIP_CONFIG_PROMETHEUS,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.SETTINGS_CONFIG_PROMETHEUS,
      },
      save: {
        text: 'Save and continue',
        link: '',
        key: ComponentKey.SAVE_CONFIG_PROMETHEUS,
      },
    },
  },
};

const datasourceImportDashboardData: { [key: string]: DatasourceConfigType } = {
  influxdb: {
    datasource: 'influxdb',
    name: 'InfluxDB',
    htmlPrefix: 'influxdb-config',
    header: '',
    preamble: '',
    form: [],
    buttons: {
      save: {
        text: 'Save and continue',
        link: 'a/cloud-home-app/import-dashboard/configure-data/:dashboardId/:datasource',
        key: ComponentKey.ConfigureDataImportDashboardSuccess,
      },
    },
    successMessage: "Connected! Now it's time to configure the dashboard name and folder.",
  },
};

const datasourceConfigGettingStartedData: { [key: string]: DatasourceConfigType } = {
  influxdb: {
    datasource: 'influxdb',
    name: 'InfluxDB',
    htmlPrefix: 'influxdb-config',
    header: 'Configure your InfluxDB connection',
    preamble: 'Dashboard, query and alert on data from an InfluxDB compatible database',
    form: [],
    buttons: {
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.GettingStartedSettingsConfigInfluxDB,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.GettingStartedSaveConfigInfluxDB,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
    connectionId: 'datasource-influxdb',
  },
  graphite: {
    datasource: 'graphite',
    name: 'Graphite',
    htmlPrefix: 'graphite-config',
    header: 'Configure your Graphite connection',
    preamble: 'Dashboard, query and alert on data from a Graphite compatible database',
    form: [],
    buttons: {
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.GettingStartedBackConfigGraphite,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.GettingStartedSettingsConfigGraphite,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.GettingStartedSaveConfigGraphite,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
    connectionId: 'graphite-group',
  },
  postgres: {
    datasource: 'postgres',
    name: 'PostgreSQL',
    htmlPrefix: 'postgresql-config',
    header: 'Configure your PostgreSQL connection',
    preamble: 'Dashboard, query and alert on data from a PostgreSQL compatible database',
    form: [],
    buttons: {
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.GettingStartedBackConfigPostgreSQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.GettingStartedSettingsConfigPostgreSQL,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.GettingStartedSaveConfigPostgreSQL,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
    connectionId: 'postgres-group',
  },
  mysql: {
    datasource: 'mysql',
    name: 'MySQL',
    htmlPrefix: 'mysql-config',
    header: 'Configure your MySQL connection',
    preamble: 'Dashboard, query and alert on data from a MySQL compatible database',
    form: [],
    buttons: {
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.GettingStartedBackConfigMySQL,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.GettingStartedSettingsConfigMySQL,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.GettingStartedSaveConfigMySQL,
      },
    },
    successMessage: "Connected! Now it's time to build some panels for your first dashboard.",
    connectionId: 'mysql-group',
  },
  prometheus: {
    datasource: 'prometheus',
    name: 'Prometheus',
    htmlPrefix: 'prometheus-config',
    header: 'Configure your Prometheus connection',
    preamble: '',
    form: [],
    buttons: {
      back: {
        text: 'Back',
        link: '',
        key: ComponentKey.GettingStartedBackConfigPrometheus,
      },
      settings: {
        text: 'advanced settings page.',
        link: '',
        key: ComponentKey.GettingStartedSettingsConfigPrometheus,
      },
      save: {
        text: 'Connect data',
        link: '',
        key: ComponentKey.GettingStartedSaveConfigPrometheus,
      },
    },
    successMessage: 'Success! Your data is connected.',
    connectionId: 'prometheus-group',
  },
};

const gettingStartedDatasourceSteps = [
  ...new Set(Object.values(datasourceConfigGettingStartedData).map((obj) => obj.datasource)),
];

export const gettingStartedDatasourcePaths: GettingStartedPath[] = gettingStartedDatasourceSteps.map((datasource) => ({
  step: datasource,
  path: `datasource-${datasource}`,
}));

export const getDatasourceConfigData = (datasource: Datasources, source: DatasourceConfigVersion = 'setup-wizard') => {
  switch (source) {
    case 'select-and-create-dashboard':
      return datasourceConfigData[datasource];

    case 'import-dashboard':
      return datasourceImportDashboardData[datasource];

    case 'getting-started':
      return {
        ...datasourceConfigGettingStartedData[datasource],
        header: `3. ${datasourceConfigGettingStartedData[datasource].header}`,
      };

    case 'prometheus':
      return { ...datasourceConfigData[datasource], header: `3. ${datasourceConfigData[datasource].header}` };

    default: // 'setup-wizard'
      return datasourceConfigSetupWizardData[datasource];
  }
};

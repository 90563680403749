import React, { FC, useContext } from 'react';

import { LoadingPlaceholder, Tooltip, useStyles2 } from '@grafana/ui';
import { QueryError } from 'feature/common/types/QueryError';
import { getStyles } from 'feature/usage/components/refresh-design/Metric.styles';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { handleKeyDown } from 'feature/common/utils/aria';
import { AppContext } from 'App';
import { CircleColor } from 'feature/usage/types/CircleColor';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { MetricTitle } from 'feature/usage/components/refresh-design/MetricTitle';
import { MetricTooltip } from 'feature/usage/components/refresh-design/MetricTooltip';

interface Props {
  id: string;
  label: string;
  value: string;
  limit?: string;
  tooltipText?: string;
  linkText?: string;
  link?: string;
  isFree?: boolean;
  error?: QueryError;
  isLoading: boolean;
  circleColor?: CircleColor;
  isCircleHidden?: boolean;
}

export const Metric: FC<Props> = ({
  id,
  label,
  value,
  limit,
  tooltipText,
  linkText,
  link,
  isFree = false,
  isLoading,
  error,
  circleColor,
}) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  function onComponentClick(): void {
    if (linkText) {
      const trackId = ComponentKey.Usage.TooltipLink(
        id.replaceAll('_', '-'),
        linkText.toLowerCase().replace(' →', '').replaceAll(' ', '-')
      );
      trackAction(FlowEventName.HOME_PAGE_EVENT, trackId, ActionType.click, orgId);
    }
  }

  const renderTitle = () => {
    if (!!tooltipText && !!link && !!linkText) {
      return (
        <Tooltip
          interactive={true}
          content={<MetricTooltip title={tooltipText} link={{ text: linkText, url: link }} />}
        >
          <div className={styles.tooltipContent}>
            <MetricTitle label={label} circleColor={circleColor} />
          </div>
        </Tooltip>
      );
    } else {
      return <MetricTitle label={label} circleColor={circleColor} />;
    }
  };

  return (
    <div
      className={styles.insights}
      onClick={onComponentClick}
      onKeyDown={handleKeyDown(onComponentClick)}
      tabIndex={0}
      role="button"
    >
      {isLoading ? (
        <LoadingPlaceholder text="Loading ..." data-testid={DataTestId.METRIC_LOADING_ID} />
      ) : (
        <>
          <div className={styles.section}>{renderTitle()}</div>
          {error === undefined ? (
            <div className={styles.text} data-testid={DataTestId.METRIC_VALUE_ID}>
              {value}
            </div>
          ) : (
            <div className={styles.text} data-testid={DataTestId.METRIC_ERROR_ID}>
              Error
            </div>
          )}
          {isFree && !!limit && (
            <div data-testid={DataTestId.METRIC_LIMIT_ID} className={styles.includedUsageLabel}>
              Included: {limit}
            </div>
          )}
        </>
      )}
    </div>
  );
};

import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { appCreateApi } from '../../../app/context';
import { CurrentUser, Org, User } from '../types/User';
import { excludeLocalAdmin } from '../utils/UserUtils';

export const UsersApi = appCreateApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'api/' }),
  reducerPath: 'users',
  endpoints: (build) => ({
    getUsers: build.query<User[], void>({
      query: () => 'org/users',
      transformResponse: (users: User[]) => {
        return excludeLocalAdmin(users);
      },
    }),
    getCurrentUser: build.query<CurrentUser, void>({
      query: () => 'user',
    }),
    getCurrentUserOrgs: build.query<Org[], void>({
      query: () => 'user/orgs',
    }),
  }),
});

export const { useGetUsersQuery, useGetCurrentUserQuery, useGetCurrentUserOrgsQuery } = UsersApi;

import React from 'react';
import { Icon, IconName, PopoverContent, Tooltip, useStyles2 } from '@grafana/ui';
import { OnboardingCardData, OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';
import { isOnboardingCustomIconName } from 'feature/onboarding/utils/OnboardingCard';
import { isWizardCustomIconName } from 'feature/onboarding/utils/SetupWizard';
import { IconTypes } from 'feature/onboarding/types/IconTypes';
import { CustomOnboardingIcon } from './CustomOnboardingIcon';
import { getOnboardingCardStyles } from './OnboardingCard.styles';
import { OnboardingIcon } from './OnboardingIcon';

export function OnboardingCardIconsAndTitle({
  card,
  isInModal,
}: {
  card: OnboardingCardData;
  isInModal?: boolean;
}): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  return (
    <div className={card.style === 'row-flex' ? styles.headerWrapper(card.titleAlign) : styles.headerColumnWrapper}>
      {card.selected && (
        <input aria-label="option" type="radio" readOnly checked={card.selected} className={'selected'} />
      )}
      {!!card.icons?.length && (
        <div className={styles.iconsWrapper}>
          {card.icons.map((icon) => {
            if (isOnboardingCustomIconName(icon) || isWizardCustomIconName(icon as unknown as IconTypes)) {
              return <CustomOnboardingIcon key={icon} icon={icon as unknown as OnboardingCustomIconName} />;
            } else {
              return <OnboardingIcon key={icon} icon={icon as IconName} />;
            }
          })}
        </div>
      )}
      {!card.titleInfo && <h2 className={styles.title(card.titleAlign, isInModal)}>{card.title}</h2>}
      {card.titleInfo && (
        <h2 className={styles.title(card.titleAlign, isInModal)}>
          <div className={styles.titleWithInfo}>
            {typeof card.title === 'string' ? <span>{card.title}</span> : card.title}
            <Tooltip content={card.titleInfo.body as PopoverContent} theme="info" placement="bottom">
              <Icon name={card.titleInfo.icon ?? 'question-circle'} className={styles.infoIcon} />
            </Tooltip>
          </div>
        </h2>
      )}
    </div>
  );
}

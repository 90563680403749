import { useStyles2 } from '@grafana/ui';
import React, { useContext } from 'react';

import { AppContext } from 'App';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { OnboardingCard } from 'feature/onboarding/components/OnboardingCard';
import { OnboardingImageCard } from 'feature/onboarding/components/OnboardingCard/OnboardingImageCard';
import { OnboardingLinksCard } from 'feature/onboarding/components/OnboardingCard/OnboardingLinksCard';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import {
  OnboardingCardData,
  OnboardingGeneralCardData,
  OnboardingImageCardData,
  OnboardingLinksCardData,
} from 'feature/onboarding/types/OnboardingCard';
import grafana from 'img/grafana-icon.svg';
import { GettingStartedStep } from 'feature/onboarding/types/GettingStartedCard';
import { getGettingStartedStepData } from 'feature/onboarding/data/GettingStartedCard.data';
import { getGettingStartedStyles } from 'feature/onboarding/components/GettingStarted/GettingStarted.styles';
import { OnboardingVersion } from 'feature/onboarding/types/OnboardingVersion';

interface GettingStartedProps {
  step: GettingStartedStep;
  version: OnboardingVersion;
}

export const GettingStarted: React.FC<GettingStartedProps> = ({ step, version }) => {
  const styles = useStyles2((theme) => getGettingStartedStyles(theme, version));
  const { orgId } = useContext(AppContext);

  const trackHasEnteredFromOnboardingFlow = () => {
    trackAction(
      FlowEventName.DOCS_PLUGIN_EVENT,
      ComponentKey.TutorialPluginEnteredFromOnboardingFlow,
      ActionType.click,
      orgId
    );
  };

  const stepData = getGettingStartedStepData(step);

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.GETTING_STARTED_EVENT_NAME, key, ActionType.click, orgId);
  }

  function renderCard(card: OnboardingCardData) {
    if (!!card) {
      switch (card.type) {
        case 'general':
          return <OnboardingCard card={card as OnboardingGeneralCardData} key={card.key} />;
        case 'image':
          return <OnboardingImageCard card={card as OnboardingImageCardData} key={card.key} />;
        case 'links':
          return <OnboardingLinksCard card={card as OnboardingLinksCardData} key={card.key} />;
      }
    }
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <>
        {version === 'onboarding' && (!!stepData.buttons.back.link || !!stepData.buttons.skip.link) && (
          <div className={styles.buttonsWrapper}>
            {!!stepData.buttons.back.link && (
              <a
                className={styles.buttonsLink}
                href={stepData.buttons.back.link}
                onClick={() => onComponentClick(stepData.buttons.back.key)}
              >
                {stepData.buttons.back.text}
              </a>
            )}
            {!!stepData.buttons.skip.link && (
              <a
                className={styles.buttonsLink}
                href={stepData.buttons.skip.link}
                onClick={() => onComponentClick(stepData.buttons.skip.key)}
              >
                {stepData.buttons.skip.text}
              </a>
            )}
          </div>
        )}
        {version === 'onboarding' && !!stepData.header && (
          <div className={styles.headerWrapper}>
            {stepData.step === 'start' && <img className={styles.icon} alt="Grafana" src={grafana} />}
            <div className={styles.textWrapper}>
              <h1 className={styles.header}>{stepData.header}</h1>
              {!!stepData.preamble && <p className={styles.preamble}>{stepData.preamble}</p>}
            </div>
          </div>
        )}
        <div className={styles.cardWrapper(stepData.columns)}>{stepData.cards.map((card) => renderCard(card))}</div>
        {stepData.buttons.more && (
          <div className={styles.moreBtnWrapper}>
            <a
              className={styles.buttonsLink}
              href={stepData.buttons.more.link}
              onClick={() => onComponentClick(stepData.buttons.more!.key)}
            >
              {stepData.buttons.more.text}
            </a>
          </div>
        )}
        {stepData.step === 'explore' && (
          <div className={styles.viewMoreLink}>
            <a href="/a/cloud-home-app/docs-plugin" onClick={trackHasEnteredFromOnboardingFlow}>
              See more Grafana Cloud Tutorials →
            </a>
          </div>
        )}
      </>
    </div>
  );
};

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getImportDashboardStyles = (theme: GrafanaTheme2) => ({
  container: css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,
  wrapper: (includeTop: boolean) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    h3 {
      line-height: 2.5rem;
    }
    margin-top: ${includeTop ? theme.spacing(3) : 0};
    overflow-y: auto;
    padding-left: 80px;
    padding-right: 60px;
  `,
  contentOuter: css`
    label: box;
    margin: ${theme.spacing(1)};
    overflow-y: auto;
    flex: 1;
    display: flex;
  `,
  wizardBody: (totalSteps: number) => css`
    padding-left: ${totalSteps > 0 ? '60px' : 0};

    @media screen and (max-width: 670px) {
      padding-left: 0;
    }
  `,
  errorContainer: css`
    padding: 4rem;
  `,
});

import React, { useLayoutEffect } from 'react';

import { PluginPage } from '@grafana/runtime';
import { NavModelItem, PageLayoutType } from '@grafana/data';
import { SelectAndCreateDashboard } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectAndCreateDashboard';
import { appPaths } from 'feature/common/consts/appPaths';
import { CREATE_DASHBOARD_HEADER, START_HEADER, TELL_US_WHAT_TO_CONNECT } from 'feature/onboarding/consts/consts';
import { closeNavIfOpened } from 'feature/onboarding/utils/sectionNavUtils';

export const GettingStartedCreateDashboardPage: React.FC = () => {
  const pageNav: NavModelItem = {
    text: CREATE_DASHBOARD_HEADER,
    subTitle: TELL_US_WHAT_TO_CONNECT,
    parentItem: {
      text: START_HEADER,
      url: appPaths.gettingStarted,
    },
  };

  useLayoutEffect(() => closeNavIfOpened());

  return (
    <PluginPage layout={PageLayoutType?.Standard} pageNav={pageNav}>
      <SelectAndCreateDashboard version={'get-started'} />
    </PluginPage>
  );
};

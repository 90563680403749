import { ADOBE_TARGET_AVAILABLE_DATA_PROVIDERS, getData, initialize } from '@grafana/cloud-ab-testing';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appCreateApi } from 'app/context';
import { QueryError } from 'feature/common/types/QueryError';
import { ABTestingActivity, ABTestingData, ABTestingPayload } from 'feature/common/types/ABTestingData';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { getContextUser } from 'feature/common/utils/UserUtils';

const parseError = (err: any) => {
  if (!err) {
    return 'Error while loading the AB Testing data';
  } else if (typeof err === 'object') {
    return err.error?.message || err.error || JSON.stringify(err);
  } else {
    return err;
  }
};

// we need to initialize the AB testing library before using it in the hook to
// prevent flickering
initialize({
  adobeTarget: {
    predefinedDataProviders: [ADOBE_TARGET_AVAILABLE_DATA_PROVIDERS.GRAFANA],
    scriptUrl: '/public/plugins/cloud-home-app/static/at-2.10.1.js',
    secureOnly: true,
  },
});

const getEventNameFromActivity = (activity: string): FlowEventName => {
  if (activity === ABTestingActivity.HOME) {
    return FlowEventName.HOME_PAGE_EVENT;
  }
  if (activity.indexOf(ABTestingActivity.GET_STARTED) >= 0) {
    return FlowEventName.GETTING_STARTED_EVENT_NAME;
  }
  return FlowEventName.ONBOARDING_FLOW_EVENT_NAME;
};

export const ABTestingApi = appCreateApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'api/' }),
  reducerPath: 'abTesting',
  endpoints: (build) => ({
    getABTesting: build.query<ABTestingData | null, ABTestingPayload>({
      async queryFn({ mbox, orgId, activity, params }) {
        try {
          // eslint-disable-next-line @typescript-eslint/array-type
          const data = await getData<Array<{ content: ABTestingData[] }>>({
            adobeTarget: {
              mbox,
              params,
            },
          });

          const content = data?.[0]?.content[0] || null;
          if (content) {
            (window as any).rudderanalytics?.track('experiment_viewed', {
              distinct_id: getContextUser()?.email ?? '',
              experiment_id: content?.experimentId,
              experiment_version: content?.version,
              experiment_group: content?.experimentData?.testGroup,
            });
          }

          return {
            data: content,
          };
        } catch (err: any) {
          const message = parseError(err);

          trackAction(getEventNameFromActivity(activity), ComponentKey.LOAD_AB_TESTING_ERROR, ActionType.load, orgId, {
            message: JSON.stringify(err),
          });

          return { error: { data: { message }, status: 500 } as QueryError };
        }
      },
    }),
  }),
});

export const { useGetABTestingQuery } = ABTestingApi;

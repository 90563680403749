import React, { useContext } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';

import { config, PanelRenderer } from '@grafana/runtime';
import { DataTestId } from '../../common/types/test-id.enum';
import { getPanelStyles } from 'feature/Panel.styles';
import { EMPTY_PANEL_DATA } from '../../common/consts/panels';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

export const AlertsPanel = () => {
  const styles = useStyles2(getPanelStyles);
  const { orgId } = useContext(AppContext);

  return (
    <div data-testid={DataTestId.ALERTS_CONTAINER_ID} className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Recent Alerts</div>
        <LinkButton
          target="_blank"
          variant="primary"
          fill="text"
          href={config.unifiedAlertingEnabled ? '/alerting/list' : '/a/grafana-alerting-ui-app/'}
          onClick={() =>
            trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.AlertsAllLink, ActionType.click, orgId)
          }
        >
          {'All alerts →'}
        </LinkButton>
      </div>
      <div className={styles.contentWrapper}>
        <PanelRenderer title="" width={1000} height={1000} pluginId="alertlist" data={EMPTY_PANEL_DATA} />
      </div>
    </div>
  );
};

import React, { FC } from 'react';

import { Bill } from './Bill';
import { Billing } from './Billing';
import { BriefCase } from './BriefCase';
import { Community } from './Community';
import { Documentation } from './Documentation';
import { QuickStarts } from './QuickStarts';
import { Shield } from './Shield';
import { Support } from './Support';
import { Users } from './Users';
import { Star } from './Star';
import { Home } from './Home';

import { IconTypes } from './types';

interface IconProps {
  logo: IconTypes;
  className?: string;
}

export const Icon: FC<IconProps> = ({ logo, className }) => {
  switch (logo) {
    case IconTypes.BRIEFCASE:
      return <BriefCase />;
    case IconTypes.SHIELD:
      return <Shield />;
    case IconTypes.USERS:
      return <Users />;
    case IconTypes.BILL:
      return <Bill />;
    case IconTypes.BILLING:
      return <Billing />;
    case IconTypes.COMMUNITY:
      return <Community className={className || ''} />;
    case IconTypes.DOCUMENTATION:
      return <Documentation className={className || ''} />;
    case IconTypes.SUPPORT:
      return <Support className={className || ''} />;
    case IconTypes.QUICKSTARTS:
      return <QuickStarts className={className || ''} />;
    case IconTypes.STAR:
      return <Star className={className || ''} />;
    case IconTypes.HOME:
      return <Home />;
    default:
      return null;
  }
};

import { Badge, Button, Card, Icon, IconName, LinkButton, PopoverContent, Tooltip, useStyles2 } from '@grafana/ui';
import { OnboardingCustomIconName, OnboardingGeneralCardData } from 'feature/onboarding/types/OnboardingCard';
import React, { useContext } from 'react';
import { getOnboardingCardStyles } from './OnboardingCard.styles';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { OnboardingCardIconsAndTitle } from 'feature/onboarding/components/OnboardingCard/OnboardingCardIconsAndTitle';
import { Toggletip } from 'feature/common/components/Toggletip/Toggletip';
import { handleKeyDown } from 'feature/common/utils/aria';
import { AppContext } from 'App';
import { getTrackOriginFromPath, isOnboardingCustomIconName } from 'feature/onboarding/utils/OnboardingCard';
import { isWizardCustomIconName } from 'feature/onboarding/utils/SetupWizard';
import { CustomOnboardingIcon } from 'feature/onboarding/components/OnboardingCard/CustomOnboardingIcon';
import { OnboardingIcon } from 'feature/onboarding/components/OnboardingCard/OnboardingIcon';
import { IconTypes } from 'feature/onboarding/types/IconTypes';
import { cx } from '@emotion/css';

function OnboardingCardBody({ card }: { card: OnboardingGeneralCardData }): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    const origin = getTrackOriginFromPath();
    trackAction(
      (card.eventName as FlowEventName) ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      key,
      ActionType.click,
      orgId,
      origin ? { origin } : undefined
    );
  }

  return (
    <div className={styles.wrapper(undefined)}>
      <OnboardingCardIconsAndTitle card={card} />
      {card.text && typeof card.text === 'string' && (
        <div
          className={cx([styles.text, { [styles.textIconAlign]: card.contentAlignedWithTitle }])}
          dangerouslySetInnerHTML={{ __html: card.text }}
        />
      )}
      {card.text && typeof card.text !== 'string' && (
        <p className={cx([styles.text, { [styles.textIconAlign]: card.contentAlignedWithTitle }])}>{card.text}</p>
      )}
      {card.badge && (
        <div className={cx([styles.badgeWrapper, { [styles.badgeIconAlign]: card.contentAlignedWithTitle }])}>
          {(card.badge.fill === 'solid' || !card.badge.fill) && (
            <Badge className={styles.badge} color="orange" icon={card.badge.icon} text={card.badge.text} />
          )}
          {card.badge.fill === 'text' && !!card.badge.info?.body && (
            <Tooltip content={card.badge.info.body as PopoverContent} theme="info" placement="bottom">
              <Button variant={'primary'} fill={'text'}>
                {card.badge.text}
              </Button>
            </Tooltip>
          )}
          {card.badge.info?.header && (
            <Toggletip
              content={card.badge.info.body as PopoverContent}
              title={card.badge.info.header}
              closeButton={true}
            >
              <button
                className={styles.infoIconCt}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon className={styles.infoIcon} name={card.badge.info.icon ?? 'question-circle'} />
              </button>
            </Toggletip>
          )}
        </div>
      )}
      {!!card.buttons?.length && (
        <div className={styles.buttonsWrapper}>
          {card.buttons?.map((btn) => (
            <LinkButton
              type="button"
              variant={btn.variant}
              fill="solid"
              href={btn.link}
              key={btn.key}
              onClick={(e) => onComponentClick(btn.key)}
            >
              {btn.text}
            </LinkButton>
          ))}
        </div>
      )}
    </div>
  );
}

export function OnboardingCard({ card }: { card: OnboardingGeneralCardData }): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    const origin = getTrackOriginFromPath();
    trackAction(
      (card.eventName as FlowEventName) ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      key,
      ActionType.click,
      orgId,
      origin ? { origin } : undefined
    );
  }

  function onCardClick(): void {
    onComponentClick(card.key);
    if (card.link.callback) {
      card.link.callback();
    }
    if (!!card.link.url) {
      if (!!card.link.target) {
        window.open(card.link.url, card.link.target);
      } else {
        window.location.href = card.link.url;
      }
    }
  }

  const getContentWrapper = (children: any, tabIndex: number) => (
    <div onClick={onCardClick} onKeyDown={handleKeyDown(onCardClick)} tabIndex={tabIndex} role="button">
      {children}
    </div>
  );

  if (card.design !== 'card') {
    return (
      <div
        className={card.selected ? styles.cardSelected : ''}
        onClick={onCardClick}
        onKeyDown={handleKeyDown(onCardClick)}
        tabIndex={0}
        role="button"
      >
        <OnboardingCardBody card={card} />
      </div>
    );
  } else {
    return (
      <Card key={card.key} isSelected={card.selected} onClick={onCardClick}>
        <Card.Heading>{typeof card.title === 'string' ? <span>{card.title}</span> : card.title}</Card.Heading>
        <Card.Description>
          {card.text &&
            typeof card.text === 'string' &&
            getContentWrapper(<div className={styles.text} dangerouslySetInnerHTML={{ __html: card.text }} />, 0)}
          {card.text &&
            typeof card.text !== 'string' &&
            getContentWrapper(<p className={styles.text}>{card.text}</p>, 1)}
        </Card.Description>
        {card.icons?.length === 1 && (
          <Card.Figure className={styles.figure}>
            {card.icons.map((icon) => {
              if (isOnboardingCustomIconName(icon) || isWizardCustomIconName(icon as unknown as IconTypes)) {
                return getContentWrapper(
                  <CustomOnboardingIcon
                    key={icon}
                    icon={icon as unknown as OnboardingCustomIconName}
                    position={'left'}
                  />,
                  2
                );
              } else {
                return getContentWrapper(<OnboardingIcon key={icon} icon={icon as IconName} position={'left'} />, 3);
              }
            })}
          </Card.Figure>
        )}
        {card.badge && (
          <Card.Actions className={styles.cardButtons}>
            <div className={styles.badgeWrapper}>
              {(card.badge.fill === 'solid' || !card.badge.fill) && (
                <Badge className={styles.badge} color="orange" icon={card.badge.icon} text={card.badge.text} />
              )}
              {card.badge.fill === 'text' && !!card.badge.info?.body && (
                <Tooltip
                  content={card.badge.info.body as PopoverContent}
                  theme="info"
                  placement="bottom"
                  interactive={true}
                >
                  <Button variant={'primary'} fill={'text'}>
                    {card.badge.text}
                  </Button>
                </Tooltip>
              )}
              {card.badge.info?.header && (
                <Toggletip
                  content={card.badge.info.body as PopoverContent}
                  title={card.badge.info.header}
                  closeButton={true}
                >
                  <button className={styles.infoIconCt} type="button" onClick={(e) => e.preventDefault()}>
                    <Icon className={styles.infoIcon} name={card.badge.info.icon ?? 'question-circle'} />
                  </button>
                </Toggletip>
              )}
            </div>
          </Card.Actions>
        )}
      </Card>
    );
  }
}

import React from 'react';
import { LIMIT_TITLE, PROFILES_FREE_INCLUDED } from '../consts/usage';
import { formatUsageValue, handleCompactFormat } from '../utils/usageUtils';
import { Metric } from './Metric';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { UsageProps } from 'feature/usage/types/UsageData';

export function ProfilesUsage({ usage, isFree, billingDashboard }: UsageProps): JSX.Element {
  return (
    <div data-testid={DataTestId.PROFILES_USAGE_METRIC_ID}>
      <Metric
        id="metric_profiles"
        label={isFree ? `${LIMIT_TITLE}: ${handleCompactFormat(PROFILES_FREE_INCLUDED)} GB` : ''}
        link={billingDashboard?.url}
        value={formatUsageValue(usage, MetricUnit.PROFILES, isFree as boolean)}
        unit={'Profiles'}
      />
    </div>
  );
}

import React from 'react';

import { Badge, useStyles2 } from '@grafana/ui';
import { DataTestId } from '../../common/types/test-id.enum';
import { getStyles } from './PlanBadge.styles';
import { getTrialBadgeClassName } from 'feature/home/utils/planUtils';
import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';

export const PlanBadge = () => {
  const styles = useStyles2(getStyles);
  const {
    data: { pricingPlan, orgPlan, trialEnd },
    isSuccess,
  } = useOrgInfoData();

  return (
    <div data-testid={DataTestId.PLAN_CONTAINER_ID} className={styles.container}>
      {/* Color prop is mandatory in Badge Component */}

      {!!pricingPlan && (
        <Badge data-testid={DataTestId.PLAN_BADGE_ID} text={pricingPlan} color="blue" className={styles.planBadge} />
      )}
      {isSuccess && !!pricingPlan && orgPlan === Tiers.Trial && !!trialEnd && trialEnd > 0 && (
        <Badge
          data-testid={DataTestId.PLAN_TRIAL_ID}
          icon="calendar-alt"
          color="blue"
          className={`${styles.trialBadge} ${styles.trialBadge}${getTrialBadgeClassName(trialEnd)}`}
          text={
            <div className={styles.trialDays}>
              <span data-testid={DataTestId.PLAN_TRIAL_TOTAL_DAYS}>{trialEnd}</span>
              <span data-testid={DataTestId.PLAN_TRIAL_DAYS_LEFT} className={styles.trialText}>{`day${
                trialEnd > 1 || trialEnd < 1 ? 's' : ''
              } left`}</span>
            </div>
          }
        />
      )}
    </div>
  );
};

import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';
import { getStatus } from 'feature/usage/utils/usageUtils';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { UsageState } from 'feature/usage/types/UsageState';

export const useUsageStatus = (metricUnit: MetricUnit): UsageState => {
  const { data: orgInfoData, isLoading, error: error } = useOrgInfoData();

  const isFree = orgInfoData?.orgPlan === Tiers.Free;
  const usage = orgInfoData?.usage?.data[metricUnit] ?? 0;
  const limit = orgInfoData?.usage?.limit[metricUnit] ?? 0;

  // display list of active users on the usage bar but calculate status based on total number of users in the org
  const status = getStatus(usage, limit, metricUnit, isFree, orgInfoData?.orgSlug, error);

  return {
    isLoading,
    error,
    usage,
    limit,
    isFree,
    status,
  };
};

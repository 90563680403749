import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStepsListStyles = (theme: GrafanaTheme2) => ({
  contentInner: css`
    label: vertical;
    // width: 30%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing(1)};
  `,
  pairBox: css`
    label: beside;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding-left: 24px;
  `,
  stepsBox: css`
    label: steps;
    border-right: 1px solid ${theme.colors.border.medium};
    max-width: 271px;
    min-width: 200px;
    margin-top: 20px;
    position: relative;
    border-left: 1px solid ${theme.colors.border.weak};
    padding: 0;
    ol {
      list-style: none;
      position: relative;
      margin: 0;
      left: -13px;
      counter-reset: stepcounter;
      padding-left: 32px;
    }
    ol li {
      counter-increment: stepcounter;
      position: relative;

      h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
      }

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    ol li:before {
      content: counter(stepcounter);
      font-weight: bold;
      border-radius: 100%;
      position: absolute;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      top: -1px;
      padding: 4px;
      left: -32px;
    }
  `,
  stepItem: (active: boolean) => css`
    color: ${active ? theme.colors.text.primary : theme.colors.text.disabled};
  `,
});

import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';
import {
  CUSTOM_CONNECTION_DESCRIPTIONS,
  CUSTOM_CONNECTION_ICON,
  CUSTOM_CONNECTION_TITLES,
} from 'feature/common/consts/consts';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { getCustomDataSourceConfigTypes } from 'feature/datasource-config/utils/datasourceConfigUtils';
import { DatasourceResponse } from 'feature/common/types/Datasources';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { externalPaths } from 'feature/common/consts/appPaths';
import { OnboardingCardData, OnboardingGeneralCardData } from 'feature/onboarding/types/OnboardingCard';
import { interpolateString } from 'feature/common/utils/misc';
import { goToCloudPlatformConnection, isCloudPlatformOption } from 'feature/onboarding/utils/cloudPlatformUtils';
import { redirectToHref } from 'feature/onboarding/utils/sectionNavUtils';
import { getEventNameFromPath } from './OnboardingCard';

export const getCardTitleFromConnection = (connection: DataToConnect): string => {
  if (!connection) {
    return '';
  }
  if (CUSTOM_CONNECTION_TITLES[connection.id]) {
    return CUSTOM_CONNECTION_TITLES[connection.id];
  } else if (CUSTOM_CONNECTION_TITLES[connection.type]) {
    return interpolateString(CUSTOM_CONNECTION_TITLES[connection.type], { connection_name: connection.name });
  } else {
    return connection.name;
  }
};

export const getCardDescriptionFromConnection = (connection: DataToConnect): string => {
  if (!connection) {
    return '';
  }

  if (CUSTOM_CONNECTION_DESCRIPTIONS[connection.id]) {
    return CUSTOM_CONNECTION_DESCRIPTIONS[connection.id];
  } else if (CUSTOM_CONNECTION_DESCRIPTIONS[connection.type]) {
    return interpolateString(CUSTOM_CONNECTION_DESCRIPTIONS[connection.type], { connection_name: connection.name });
  } else {
    return connection.overview ?? '';
  }
};

export function getCardFromConnection(connection: DataToConnect, onCardSelected: Function): OnboardingGeneralCardData {
  const card: OnboardingCardData = {
    title: getCardTitleFromConnection(connection),
    text: getCardDescriptionFromConnection(connection),
    icons: [],
    link: {
      key: `${connection.trackId}-link`,
      url: '',
      callback: () => onCardSelected(connection.id),
    },
    key: `${connection.trackId}`,
    style: 'column-flex',
    type: 'general',
    titleAlign: 'left',
    eventName: getEventNameFromPath(),
  };
  const icon = CUSTOM_CONNECTION_ICON[connection.type];
  if (icon) {
    card.icons = [icon];
  }

  return card;
}

export function getConnectionCards(
  connectionSelected: DataToConnect | undefined,
  optionSelected: string,
  onCardSelected: Function
): OnboardingCardData[] {
  if (connectionSelected) {
    if (connectionSelected.children && connectionSelected.children.length > 0) {
      const cards: OnboardingCardData[] = [];
      connectionSelected.children.forEach((connection) => {
        cards.push({
          ...getCardFromConnection(connection, onCardSelected),
          selected: optionSelected === connection.id,
        });
      });
      return cards;
    } else {
      return [
        {
          ...getCardFromConnection(connectionSelected, onCardSelected),
          selected: optionSelected === connectionSelected.id,
        },
      ];
    }
  }
  return [];
}

export const getCustomDatasourceFromConnectionSelected = (connectionId: string): Datasources | null => {
  const dataSourceId = (connectionId ?? '').replace('datasource-', '');
  if (getCustomDataSourceConfigTypes().includes(dataSourceId)) {
    return dataSourceId;
  }
  return null;
};

export const isDatasourceWithCustomPage = (
  connectionSelected: DataToConnect | undefined,
  optionSelected: string
): boolean => {
  if (connectionSelected && optionSelected) {
    const currentConnection = connectionSelected.children?.length
      ? connectionSelected.children.find((child) => child.id === optionSelected)
      : connectionSelected;

    const datasourceId = getCustomDatasourceFromConnectionSelected(optionSelected);
    return currentConnection?.type === DataToConnectType.DataSource && Boolean(datasourceId);
  }
  return false;
};

export function goToNewDashboard(orgId: number | undefined, dataSourceSaved: DatasourceResponse | undefined) {
  trackAction(getEventNameFromPath(), ComponentKey.GoToCreateDashboardPage, ActionType.click, orgId, {
    origin: 'create-dashboard',
  });

  if (!!dataSourceSaved) {
    redirectToHref(`/dashboard/new-with-ds/${dataSourceSaved.datasource.uid}`);
  } else {
    redirectToHref('/dashboard/new');
  }
}

function goToConnections(
  orgId: number | undefined,
  connectionSelected: DataToConnect | undefined,
  optionSelected: string
) {
  if (connectionSelected) {
    const hasChildren = connectionSelected.children && connectionSelected.children.length > 0;
    const currentConnection = hasChildren
      ? (connectionSelected.children ?? []).find((child) => child.id === optionSelected)
      : connectionSelected;
    if (currentConnection) {
      trackAction(getEventNameFromPath(), ComponentKey.GoToConnectionPage, ActionType.click, orgId, {
        origin: 'create-dashboard',
      });
      redirectToHref(currentConnection.externalUrl ?? externalPaths.integrationsAndConnections);
    }
  }
}

export function onContinue(
  connectionSelected: DataToConnect | undefined,
  optionSelected: string,
  cloudPlatformOptionSelected: string,
  orgId: number,
  dataSourceSaved: DatasourceResponse | undefined
) {
  if (isDatasourceWithCustomPage(connectionSelected, optionSelected)) {
    goToNewDashboard(orgId, dataSourceSaved);
  } else if (isCloudPlatformOption(optionSelected)) {
    goToCloudPlatformConnection(orgId, optionSelected, cloudPlatformOptionSelected);
  } else {
    goToConnections(orgId, connectionSelected, optionSelected);
  }
}

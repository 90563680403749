import { useGetHostedDataDetailsQuery, useGetOrgDataQuery } from 'feature/common/api/GrafanaComApi';
import { isFree } from 'feature/common/utils/orgInfoUtils';
import { QueryError } from 'feature/common/types/QueryError';

export const useOrgFree = (): {
  data: boolean | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  error?: QueryError;
} => {
  const {
    data: hostedData,
    isLoading: isHostedDataLoading,
    error: hostedDataError,
    isSuccess: isHostedDataSuccess,
  } = useGetHostedDataDetailsQuery();

  const {
    data: orgData,
    isLoading: isOrgDataLoading,
    error: orgDataError,
    isSuccess: isOrgDataSuccess,
  } = useGetOrgDataQuery(hostedData?.orgSlug ?? '', { skip: !hostedData });

  return {
    data: !!orgData ? isFree(orgData) : undefined,
    isLoading: isHostedDataLoading || isOrgDataLoading,
    isSuccess: isHostedDataSuccess && isOrgDataSuccess,
    error: (hostedDataError || orgDataError) as QueryError,
  };
};

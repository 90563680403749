import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'feature/common/consts/styles';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    align-items: center;
  `,
  planBadge: css`
    color: ${theme.colors.text.secondary} !important;
    border-color: ${theme.colors.text.secondary} !important;
    background-color: transparent !important;
    margin-right: 16px;
    width: fit-content;
  `,
  trialBadge: css`
    max-width: 7rem;
    width: fit-content;

    &-green {
      color: ${theme.colors.success.main} !important;
      border-color: ${theme.colors.success.main} !important;
      background-color: rgba(27, 133, 94, 0.15) !important;
    }

    &-yellow {
      color: ${theme.colors.warning.main} !important;
      border-color: ${theme.colors.warning.main} !important;
      background-color: rgba(245, 183, 61, 0.18) !important;
    }

    &-red {
      color: ${theme.colors.error.main} !important;
      border-color: ${theme.colors.error.main} !important;
      background-color: rgba(209, 14, 92, 0.15) !important;
    }
  `,
  trialText: css`
    margin-left: 2px;
    display: block;
    @media (max-width: ${breakpoints.titleText}px) {
      display: none;
    }
  `,
  trialDays: css`
    display: flex;
  `,
});

import { QueryError } from 'feature/common/types/QueryError';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { useGetHostedDataDetailsQuery } from 'feature/common/api/GrafanaComApi';
import { useGetActiveDatasourcesQuery, useGetGrafanaDatasourcesQuery } from 'feature/common/api/PluginsApi';
import { useGetIntegrationsQuery } from 'feature/common/api/IntegrationsApi';
import { transformHostedDataList } from 'feature/common/utils/HostedDataUtils';
import { useTheme2 } from '@grafana/ui';
import { cloudAppPlugins, cloudPlatformDataSources } from 'feature/common/consts/localSources';

export function useConnectToData(): {
  data: DataToConnect[] | undefined;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: QueryError | undefined;
} {
  const theme = useTheme2();
  const {
    data: hostedData,
    isSuccess: isHostedDataSuccess,
    isLoading: isHostedDataLoading,
    isError: isHostedDataError,
    error: hostedDataError,
  } = useGetHostedDataDetailsQuery();

  const {
    data: datasources,
    isSuccess: isDatasourcesSuccess,
    isLoading: isDatasourcesLoading,
    isError: isDatasourcesError,
    error: datasourcesError,
  } = useGetActiveDatasourcesQuery();

  const stackId = hostedData?.id;
  const {
    data: integrations,
    isSuccess: isIntegrationsSuccess,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationsError,
    error: integrationError,
  } = useGetIntegrationsQuery(
    {
      stackId: !!stackId ? `${stackId}` : '',
      logoTheme: theme.isDark ? 'dark' : 'light',
    },
    { skip: !stackId }
  );

  const {
    data: grafanaDatasourcesData,
    isSuccess: isGrafanaDatasourcesSuccess,
    isLoading: isGrafanaDatasourcesLoading,
    isError: isGrafanaDatasourcesError,
    error: grafanaDatasourcesError,
  } = useGetGrafanaDatasourcesQuery();

  const isSuccess = isHostedDataSuccess && isDatasourcesSuccess && isIntegrationsSuccess && isGrafanaDatasourcesSuccess;
  const hostedDataList = transformHostedDataList(hostedData);
  let grafanaDataSources: DataToConnect[] = [];
  if (grafanaDatasourcesData && grafanaDatasourcesData.length > 0 && datasources && datasources.length > 0) {
    grafanaDataSources = grafanaDatasourcesData.filter(
      (grafanaDatasource) => !datasources.some((datasource) => datasource.id === grafanaDatasource.id)
    );
  }

  let data: DataToConnect[] = [];
  if (isSuccess) {
    data = (datasources ?? [])
      .concat(integrations || [])
      .concat(hostedDataList)
      .concat(grafanaDataSources)
      .concat(cloudPlatformDataSources)
      .concat(cloudAppPlugins);
  }

  return {
    data,
    isLoading: isHostedDataLoading || isDatasourcesLoading || isIntegrationsLoading || isGrafanaDatasourcesLoading,
    isSuccess,
    isError: isHostedDataError || isDatasourcesError || isIntegrationsError || isGrafanaDatasourcesError,
    error: (hostedDataError || datasourcesError || integrationError || grafanaDatasourcesError) as QueryError,
  };
}

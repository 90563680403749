import React, { useContext, useEffect } from 'react';
import { trackPage } from '@grafana/cloud-ab-testing';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { css, cx } from '@emotion/css';
import { Icon, useStyles2 } from '@grafana/ui';
import { getPageStyles } from 'feature/common/components/Page.styles';
import { getOnboardingScreenStyles } from 'feature/onboarding/components/OnboardingScreen/OnboardingScreen.styles';
import { OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';
import { GrafanaTheme2 } from '@grafana/data';
import { CustomOnboardingIcon } from 'feature/onboarding/components/OnboardingCard/CustomOnboardingIcon';
import { PrometheusOnboardingControl } from 'feature/onboarding/components/PrometheusOnboarding/PrometheusOnboardingControl';
import { appPaths } from 'feature/common/consts/appPaths';

const getPrometheusStyles = (theme: GrafanaTheme2) => ({
  backLink: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(1)};

    img,
    h3 {
      margin-bottom: 0;
    }
  `,
});

export const PrometheusOnboardingScreen = () => {
  const pageStyles = useStyles2(getPageStyles);
  const styles = useStyles2((theme) => getOnboardingScreenStyles(theme, 'onboarding'));
  const promStyles = useStyles2(getPrometheusStyles);
  const { orgId } = useContext(AppContext);

  useEffect(() => {
    trackPage({
      adobeTarget: {
        viewName: `onboarding-flow/prometheus`,
      },
    });

    return trackAction(FlowEventName.ONBOARDING_FLOW_EVENT_NAME, `prometheus-screen`, ActionType.visit, orgId);
  }, [orgId]);

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.ONBOARDING_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }

  return (
    <div className={styles.container}>
      <div className={cx(pageStyles.wrapper, styles.wrapper)}>
        <div className={styles.buttonsWrapper}>
          <a
            className={promStyles.backLink}
            href={appPaths.onboarding}
            onClick={() => onComponentClick('back-prometheus')}
          >
            <Icon name={'arrow-left'} size={'xxl'} />
            <CustomOnboardingIcon icon={'prometheus-color' as unknown as OnboardingCustomIconName} />
            <h3>Connect your Prometheus data</h3>
          </a>
        </div>
        <PrometheusOnboardingControl />
      </div>
    </div>
  );
};

import { isCloudRBACRolesEnabled } from './featureToggles';

export const supportLink = () => {
  if (isCloudRBACRolesEnabled()) {
    // Backend for grafana to gcom authentication is provided by grafana-auth-app.
    return '/api/plugins/grafana-auth-app/resources/gcom/profile/org#support';
  }

  return 'https://grafana.com/profile/org/support';
};

import { appPaths, externalPaths } from 'feature/common/consts/appPaths';
import { CUSTOM_CONNECTION_DESCRIPTIONS, CUSTOM_CONNECTION_TITLES } from 'feature/common/consts/consts';
import { OnboardingPath, ControlOnboardingStep, OnboardingStepData } from 'feature/onboarding/types/OnboardingCard';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { SetupImageName } from 'feature/onboarding/types/SetupWizard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import {
  TELL_US_WHAT_TO_CONNECT,
  START_HEADER,
  getSelectAndCreateDashboardTitle,
} from 'feature/onboarding/consts/consts';
import { TestGroup } from '../types/TestGroup';
import { Icon } from '@grafana/ui';
import React from 'react';

const controlOnboardingData: { [key: string]: OnboardingStepData } = {
  //Routing Screen
  start: {
    step: 'start',
    header: START_HEADER,
    preamble: '',
    cards: [
      {
        icons: ['create-dashboard-large'],
        title: 'Create a dashboard',
        text: 'Visualize and/or monitor data',
        link: {
          key: 'select-and-create-dashboard-link',
          url: `${appPaths.onboarding}/select-and-create-dashboard`,
        },
        key: 'select-and-create-dashboard',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['scale-centralize-large'],
        title: 'Hosted Telemetry Data',
        text: 'A fully-managed service for better storage, management, and scaling',
        link: {
          key: 'hosted-data-link',
          url: `${appPaths.onboarding}/hosted-data`,
        },
        key: 'hosted-data',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['prometheus-color'],
        title: 'Prometheus',
        text: 'Connect Prometheus data to Grafana Cloud',
        link: {
          key: 'prometheus-link',
          url: `${appPaths.onboarding}/prometheus`,
        },
        key: 'prometheus',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['kube-color'],
        title: 'Monitor Kubernetes',
        text: 'Visibility into health and performance of your clusters',
        link: {
          key: 'monitor-kubernetes-link',
          url: externalPaths.kubernetesCluster,
        },
        key: 'kubernetes',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['aws', 'gcp', 'azure'],
        title: 'Connect to your cloud services',
        text: 'Monitor and/or visualize your AWS, GCP or Azure Cloud Platform',
        link: {
          key: 'cloud-services-link',
          url: `${appPaths.onboarding}/cloud-services`,
        },
        key: 'cloud-services',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['k6-color', 'synthetic-monitoring'],
        title: 'Testing & synthetics',
        text: 'Optimize performance with k6 and Synthetic Monitoring insights',
        link: {
          key: 'testing-link',
          url: `/testing-and-synthetics`,
        },
        key: 'performance-testing',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['rocket-orange'],
        title: 'Quickstart',
        text: 'Simplest & fastest way to try out Grafana Cloud with your data',
        link: {
          key: 'quickstart-link',
          url: `${appPaths.onboarding}/quickstart`,
        },
        key: 'quickstart',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['search-large'],
        title: 'Explore & learn',
        text: 'See what you can do with Grafana Cloud and learn more',
        link: {
          key: 'explore-and-learn-link',
          url: `${appPaths.onboarding}/explore`,
        },
        key: 'explore-and-learn',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      // {
      //   icons: ['search-large'],
      //   title: 'Explore Demo Dashboards',
      //   text: 'Use real data to explore and play with interactive dashboards',
      //   link: {
      //     key: 'explore-demo-dashboards-link',
      //     url: `${appPaths.onboarding}/explore-demo-dashboards`,
      //   },
      //   key: 'explore-demo-dashboards',
      //   style: 'column-flex',
      //   type: 'general',
      //   eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      // },
    ],
    buttons: {
      skip: {
        text: "I'm already familiar with Grafana. Skip setup →",
        link: externalPaths.integrationsAndConnections,
        key: 'skip-start',
      },
      back: {
        text: '',
        link: '',
        key: '',
      },
    },
  },

  //Within "Ship data to Grafana Cloud's backend" Card
  'hosted-data': {
    step: 'hosted-data',
    header: 'Ship your existing data',
    preamble: 'Get a centralized, high-performance, long-term data store for your observability data',
    cards: [
      {
        icons: ['prometheus-color'],
        title: 'Hosted Prometheus metrics',
        text: 'Configure your Prometheus instances to ship your metrics data to Grafana Cloud',
        link: {
          key: 'hosted-prometheus-link',
          url: `${externalPaths.integrationsAndConnections}/hmInstancePromId?from=onboarding`,
        },
        key: 'hosted-data-prometheus',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['graphite-color'],
        title: 'Hosted Graphite metrics',
        text: ' Ship Graphite metrics to Grafana Cloud with carbon-relay-ng',
        link: {
          key: 'hosted-graphite-link',
          url: `${externalPaths.integrationsAndConnections}/hmInstanceGraphiteId`,
        },
        key: 'hosted-data-graphite',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['loki-color'],
        title: 'Hosted logs',
        text: 'Ship logs to Grafana Cloud from a standalone host or Kubernetes cluster',
        link: {
          key: 'hosted-logs-link',
          url: `${externalPaths.integrationsAndConnections}/hlInstanceId`,
        },
        key: 'hosted-data-loki',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['tempo-color'],
        title: 'Hosted traces',
        text: 'Instrument your code and deploy to start shipping traces',
        link: {
          key: 'hosted-traces-link',
          url: `${externalPaths.integrationsAndConnections}/htInstanceId`,
        },
        key: 'hosted-data-tempo',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['http-metrics'],
        title: 'HTTP Metrics',
        text: 'Ship metrics directly from your application code over HTTP for storage in Grafana Cloud',
        link: {
          key: 'http-metrics-link',
          url: `${externalPaths.integrationsAndConnections}/http-metrics`,
        },
        key: 'hosted-data-http-metrics',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['open-telemetry'],
        title: 'OpenTelemetry (OTLP)',
        text: 'Send all OpenTelemetry data including traces, metrics, and logs, to Grafana Cloud',
        link: {
          key: 'open-telemetry-link',
          url: `${externalPaths.integrationsAndConnections}/open-telemetry`,
        },
        key: 'hosted-data-open-telemetry',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['pyroscope'],
        title: 'Hosted profiles',
        text: 'Hosted continuous profiling tool for better application performance',
        link: {
          key: 'pyroscope-link',
          url: `${externalPaths.integrationsAndConnections}/hpInstanceId`,
        },
        key: 'hosted-data-pyroscope',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['faro'],
        title: 'Frontend o11y',
        text: 'Hosted service for real user monitoring to gain precise, end-to-end user insights',
        link: {
          key: 'frontend-observability-app-link',
          url: externalPaths.faro,
        },
        key: 'hosted-data-frontend-observability-app',
        style: 'row-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/start`,
        key: 'back-hosted-data',
      },
    },
  },

  //Within "Connect to your cloud services" Card
  'cloud-services': {
    step: 'cloud-services',
    header: 'Connect to your cloud services',
    preamble: 'Which Cloud Service do you want to connect with to get started?',
    cards: [
      {
        icons: ['aws'],
        title: 'Amazon Web Services',
        text: 'Monitor and/or visualize your AWS services',
        link: {
          key: 'aws-link',
          url: `${appPaths.onboarding}/custom-aws-options`,
        },
        key: 'cloud-services-aws-options',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['gcp'],
        title: 'Google Cloud Platform',
        text: 'Monitor and/or visualize your GCP services',
        link: {
          key: 'gcp-link',
          url: `${appPaths.onboarding}/custom-gcp-options`,
        },
        key: 'cloud-services-gcp-options',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: ['azure'],
        title: 'Microsoft Azure',
        text: 'Monitor and/or visualize your Azure services',
        link: {
          key: 'azure-link',
          url: `${appPaths.onboarding}/custom-azure-options`,
        },
        key: 'cloud-services-azure-options',
        style: 'column-flex',
        type: 'general',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: appPaths.onboarding,
        key: 'back-cloud-services',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Amazon Web Services" Card
  'custom-aws-options': {
    step: 'custom-aws-options',
    header: 'How do you want to connect to Amazon Web Services?',
    preamble: getSelectAndCreateDashboardTitle('AWS'),
    columns: 2,
    cards: [
      {
        icons: ['bolt'],
        title: CUSTOM_CONNECTION_TITLES['aws'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['aws'],
        link: {
          key: 'aws-monitor-link',
          url: `/a/grafana-csp-app/aws/configuration`,
        },
        key: 'aws-monitor-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['database'],
        title: CUSTOM_CONNECTION_TITLES['datasource-aws'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['datasource-aws'],
        link: {
          key: 'aws-visualize-link',
          url: `${appPaths.onboarding}/custom-aws-data`,
        },
        key: 'aws-visualize-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/cloud-services`,
        key: 'back-aws-options',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Amazon Web Services" Card -> "Create dashboards and queries from your AWS Services" Card
  'custom-aws-data': {
    step: 'custom-aws-data',
    header: 'Visualize & Explore your AWS services',
    preamble: TELL_US_WHAT_TO_CONNECT,
    cards: [
      {
        icons: ['cloudwatch'],
        title: 'Amazon CloudWatch',
        text: 'Create dashboards and/or query metrics and logs straight from the AWS CloudWatch API',
        link: {
          key: 'aws-data-cloudwatch-link',
          url: `${externalPaths.connectionDatasources}/cloudwatch/`,
        },
        key: 'aws-data-cloudwatch-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['aws-athena'],
        title: 'Amazon Athena',
        text: 'Create dashboards and/or query data straight from AWS Athena data metrics',
        link: {
          key: 'aws-data-athena-link',
          url: `${externalPaths.connectionDatasources}/grafana-athena-datasource/`,
        },
        key: 'aws-data-athena-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['aws-redshift'],
        title: 'Amazon Redshift',
        text: 'Create dashboards and/or query data straight from AWSRedshift service',
        link: {
          key: 'aws-data-redshift-link',
          url: `${externalPaths.connectionDatasources}/grafana-redshift-datasource/`,
        },
        key: 'aws-data-redshift-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['aws-timestream'],
        title: 'Amazon Timestream',
        text: 'Create dashboards and/or query data straight from AWS Timestream service',
        link: {
          key: 'aws-data-timestream-link',
          url: `${externalPaths.connectionDatasources}/grafana-timestream-datasource/`,
        },
        key: 'aws-data-timestream-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['aws-x-ray'],
        title: 'Amazon X-Ray',
        text: 'Build dashboards and/or use Explore with AWS X-Ray to look at traces, analytics, or insights',
        link: {
          key: 'aws-data-xray-link',
          url: `${externalPaths.connectionDatasources}/grafana-x-ray-datasource/`,
        },
        key: 'aws-data-xray-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['aws-sitewise'],
        title: 'Amazon IoT SiteWise',
        text: 'Create dashboards and/or query data straight from AWS IoT SiteWise service',
        link: {
          key: 'aws-data-sitewise-link',
          url: `${externalPaths.connectionDatasources}/grafana-iot-sitewise-datasource/`,
        },
        key: 'aws-data-sitewise-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/custom-aws-options`,
        key: 'back-aws-data',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Google Cloud Platform" Card
  'custom-gcp-options': {
    step: 'custom-gcp-options',
    header: 'How do you want to connect to Google Cloud Platform?',
    preamble: getSelectAndCreateDashboardTitle('GCP'),
    columns: 2,
    cards: [
      {
        icons: ['bolt'],
        title: CUSTOM_CONNECTION_TITLES['gcp'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['gcp'],
        link: {
          key: 'gcp-monitor-link',
          url: `/a/grafana-csp-app/gcp/configuration`,
        },
        key: 'gcp-monitor-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['database'],
        title: CUSTOM_CONNECTION_TITLES['datasource-gcp'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['datasource-gcp'],
        link: {
          key: 'gcp-visualize-link',
          url: `${appPaths.onboarding}/custom-gcp-data`,
        },
        key: 'gcp-visualize-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/cloud-services`,
        key: 'back-gcp-options',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Google Cloud Platform" Card -> "Create dashboards and queries from your GCP Projects" Card
  'custom-gcp-data': {
    step: 'custom-gcp-data',
    header: 'Visualize & Explore your GCP services',
    preamble: TELL_US_WHAT_TO_CONNECT,
    cards: [
      {
        icons: ['gcp-logging'],
        title: 'Google Cloud Logging',
        text: 'Query and visualize your Google Cloud logs in Grafana',
        link: {
          key: 'gcp-data-googlecloudlogging-link',
          url: `${externalPaths.connectionDatasources}/googlecloud-logging-datasource/`,
        },
        key: 'gcp-data-googlecloudlogging-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['gcp-monitoring'],
        title: 'Google Cloud Monitoring',
        text: 'Build dashboards for your Cloud Monitoring metrics',
        link: {
          key: 'gcp-data-stackdriver-link',
          url: `${externalPaths.connectionDatasources}/stackdriver/`,
        },
        key: 'gcp-data-monitoring-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/custom-gcp-options`,
        key: 'back-gcp-data',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Microsoft Azure" Card
  'custom-azure-options': {
    step: 'custom-azure-options',
    header: 'How do you want to connect to Microsoft Azure?',
    preamble: getSelectAndCreateDashboardTitle('Azure'),
    columns: 2,
    cards: [
      {
        icons: ['bolt'],
        title: CUSTOM_CONNECTION_TITLES['azure'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['azure'],
        link: {
          key: 'azure-monitor-link',
          url: `/a/grafana-csp-app/azure/configuration`,
        },
        key: 'azure-monitor-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['database'],
        title: CUSTOM_CONNECTION_TITLES['datasource-azure'],
        text: CUSTOM_CONNECTION_DESCRIPTIONS['datasource-azure'],
        link: {
          key: 'azure-visualize-link',
          url: `${appPaths.onboarding}/custom-azure-data`,
        },
        key: 'azure-visualize-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/cloud-services`,
        key: 'back-azure-options',
      },
    },
  },
  //Within "Connect to your cloud services" Card -> "Microsoft Azure" Card -> "Create dashboards and queries from your Azure Projects" Card
  'custom-azure-data': {
    step: 'custom-azure-data',
    header: 'Azure Services',
    preamble: TELL_US_WHAT_TO_CONNECT,
    cards: [
      {
        icons: ['azure-data-explorer'],
        title: 'Azure Data Explorer Datasource',
        text: 'Build dashboards for your data analytics using azure data explorer',
        link: {
          key: 'azure-data-explorer-link',
          url: `${externalPaths.connectionDatasources}/grafana-azure-data-explorer-datasource/`,
        },
        key: 'azure-data-explorer-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['azure-devops'],
        title: 'Azure Devops',
        text: 'Query and visualize Azure DevOps data from within Grafana',
        link: {
          key: 'azure-devops-link',
          url: `${externalPaths.connectionDatasources}/grafana-azuredevops-datasource/`,
        },
        key: 'azure-data-devops-option',
        style: 'column-flex',
        type: 'general',
      },
      {
        icons: ['azure-monitor'],
        title: 'Azure Monitor',
        text: 'Build dashboards for your Azure Monitor metrics',
        link: {
          key: 'azure-data-monitor-link',
          url: `${externalPaths.connectionDatasources}/grafana-azure-monitor-datasource/`,
        },
        key: 'azure-data-monitor-option',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/custom-azure-options`,
        key: 'back-azure-data',
      },
    },
  },
  //Within the "Quickstart" Card
  quickstart: {
    step: 'quickstart',
    header: 'Quickstart Options',
    preamble: `Select fast, easily configurable connections that provide instant data access.`,
    columns: 3,
    cards: [
      {
        icons: ['linux'],
        title: 'Linux',
        text: '',
        link: {
          key: 'quickstart-linux-link',
          url: `${externalPaths.integrationsAndConnections}/linux-node`,
        },
        key: 'linux-quickstart',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: ['macos'],
        title: 'MacOS',
        text: '',
        link: {
          key: 'quickstart-macos-link',
          url: `${externalPaths.integrationsAndConnections}/macos-node`,
        },
        key: 'macos-quickstart',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: ['windows'],
        title: 'Windows',
        text: '',
        link: {
          key: 'quickstart-windows-link',
          url: `${externalPaths.integrationsAndConnections}/windows-exporter`,
        },
        key: 'windows-quickstart',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: ['csv'],
        title: 'CSV',
        text: '',
        link: {
          key: 'quickstart-csv-link',
          url: `${externalPaths.connectionDatasources}/marcusolsson-csv-datasource/`,
        },
        key: 'csv-quickstart',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: ['raspberry-pi'],
        title: 'Raspberry PI',
        text: '',
        link: {
          key: 'quickstart-raspberry-pi-node-link',
          url: `${externalPaths.integrationsAndConnections}/raspberry-pi-node`,
        },
        key: 'raspberry-pi-quickstart',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: ['synthetic-monitoring'],
        title: 'Monitor a URL (Synthetics)',
        text: '',
        link: {
          key: 'quickstart-synthetics-link',
          url: `${externalPaths.syntheticMonitoringApp}/home`,
        },
        key: 'performance-testing',
        style: 'row-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: '',
        link: '',
        key: '',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/start`,
        key: 'back-quickstart',
      },
    },
  },
  explore: {
    step: 'explore',
    header: 'Explore & Learn',
    preamble:
      'Check out a demo dashboard or use a getting started guide to learn what you can build in Grafana Cloud. All of the following dashboards use Big Query',
    cards: [
      {
        icons: [],
        image: SetupImageName.QUICKPIZZA_SRE_DEMODASHBOARD,
        title: 'Demo Data Dashboards',
        titleAlign: 'center',
        link: {
          key: ComponentKey.DemoDashboardsPage,
          url: '/a/grafana-demodashboards-app',
          target: '_blank',
        },
        key: 'demo-dashboards-link',
        style: 'row-flex',
        type: 'image',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: [],
        image: SetupImageName.TIME_SERIES_VIZ_DASH,
        title: 'Explore Sandbox Accounts',
        titleAlign: 'center',
        link: {
          key: ComponentKey.SandboxAccountsPage,
          target: '_blank',
          url: 'https://play.grafana.org/',
        },
        key: 'sandbox-accounts-link',
        style: 'row-flex',
        type: 'image',
        eventName: FlowEventName.HOME_PAGE_EVENT,
      },
      {
        icons: [],
        image: SetupImageName.SINGLE_LINUX_NODE,
        title: 'Grafana Cloud Tutorials',
        titleAlign: 'center',
        link: {
          key: ComponentKey.TutorialPluginEnteredFromOnboardingFlow,
          url: '/a/grafana-selfservetutorials-app',
          target: '_blank',
        },
        key: 'tutorials-link',
        style: 'row-flex',
        type: 'image',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
      {
        icons: [],
        title: 'Learn how to:',
        titleAlign: 'left',
        key: 'learn-how-to-links',
        style: 'row-flex',
        type: 'links',
        links: [
          {
            key: 'explore-how-to-connect-your-data',
            url: 'https://grafana.com/docs/grafana-cloud/data-configuration/get-started-data/',
            target: '_blank',
            text: (
              <span>
                Connect your data <Icon name="arrow-right" />
              </span>
            ),
          },
          {
            key: 'explore-how-to-create-an-alert',
            url: 'https://grafana.com/docs/grafana-cloud/alerting/',
            target: '_blank',
            text: (
              <span>
                Create an alert <Icon name="arrow-right" />
              </span>
            ),
          },
          {
            key: 'explore-how-to-find-use-dashboards',
            url: 'https://grafana.com/docs/grafana-cloud/fundamentals/find-and-use-dashboards/',
            target: '_blank',
            text: (
              <span>
                Find and use dashboards <Icon name="arrow-right" />
              </span>
            ),
          },
        ],
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      },
    ],
    buttons: {
      skip: {
        text: 'Skip',
        link: externalPaths.integrationsAndConnections,
        key: 'skip-explore-and-learn',
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/start`,
        key: 'back-explore-and-learn',
      },
    },
  },
  //Within the "Explore Demo Dashboards" Card
  // 'explore-demo-dashboards': {
  //   step: 'explore-demo-dashboards',
  //   header: 'Explore Demo Dashboards',
  //   preamble: 'Explore a demo dashboard to discover what you can create in Grafana Cloud.',
  //   columns: 3,
  //   cards: [
  //     {
  //       icons: [],
  //       image: SetupImageName.QUICKPIZZA_SRE_DEMODASHBOARD,
  //       title: '🍕 QuickPizza SRE Demo Dashboard',
  //       titleAlign: 'center',
  //       link: {
  //         key: ComponentKey.QuickPizzaDemoDashboardsLink,
  //         url: '/a/grafana-demodashboards-app',
  //       },
  //       key: ComponentKey.QuickPizzaDemoDashboards,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //     {
  //       icons: [],
  //       image: SetupImageName.WEATHER_DEMODASHBOARD,
  //       title: '⛅️ Weather Demo Dashboard',
  //       titleAlign: 'center',
  //       link: {
  //         key: ComponentKey.WeatherDemoDashboardsLink,
  //         url: '/a/grafana-demodashboards-app',
  //       },
  //       key: ComponentKey.WeatherDemoDashboards,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //     {
  //       icons: [],
  //       image: SetupImageName.KUBERNETES_MONITORING,
  //       title: '☸️ Kubernetes Monitoring',
  //       titleAlign: 'center',
  //       text: '',
  //       link: {
  //         key: ComponentKey.KubernetesDemoDashboardsPageLink,
  //         target: '_blank',
  //         url: `${externalPaths.monitorKubernetes}?var-datasource=grafanacloud-demoinfra-prom&var-loki=grafanacloud-demoinfra-logs&from=now-1h&to=now`,
  //       },
  //       key: ComponentKey.KubernetesDemoDashboardsPage,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //     {
  //       icons: [],
  //       image: SetupImageName.APPLICATION_OBSERVABILITY,
  //       title: '🖥 Application Observability',
  //       titleAlign: 'center',
  //       link: {
  //         key: ComponentKey.ExploreApplicationObservabilityDashboardLink,
  //         target: '_blank',
  //         url: `${externalPaths.applicationObservability}?var-tempo=grafanacloud-demoinfra-traces&instrumentedFilter=all&var-prometheus=grafanacloud-demoinfra-prom&var-loki=grafanacloud-demoinfra-logs&var-pyroscope=grafanacloud-profiles&var-environmentValue=$__all`,
  //       },
  //       key: ComponentKey.ExploreApplicationObservabilityDashboard,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //     {
  //       icons: [],
  //       image: SetupImageName.TIME_SERIES_VIZ_DASH,
  //       title: '📈 Time series visualization',
  //       titleAlign: 'center',
  //       link: {
  //         key: ComponentKey.ExploreTimeSeriesVizDashboardFromHomeLink,
  //         target: '_blank',
  //         url: `${externalPaths.grafanaPlay}000000016/1-time-series-graphs?orgId=1`,
  //       },
  //       key: ComponentKey.ExploreTimeSeriesVizDashboardFromHome,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //     {
  //       icons: [],
  //       image: SetupImageName.CITI_BIKES_NYC_DASH,
  //       title: '🚲 Citi Bikes in NYC',
  //       titleAlign: 'center',
  //       text: '',
  //       link: {
  //         key: ComponentKey.CitiBikesNYCFromHomeLink,
  //         target: '_blank',
  //         url: `${externalPaths.grafanaPlay}play-bigquery-1/citibike-example-overview?orgId=1`,
  //       },
  //       key: ComponentKey.CitiBikesNYCFromHome,
  //       style: 'row-flex',
  //       type: 'image',
  //       eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
  //     },
  //   ],
  //   buttons: {
  //     skip: {
  //       text: '',
  //       link: '',
  //       key: '',
  //     },
  //     back: {
  //       text: 'Back',
  //       link: `${appPaths.onboarding}/start`,
  //       key: 'back-demo-dashboards',
  //     },
  //   },
  // },
};

/**
 * These standard onboarding experiment functions are used to populate data. At the end of each
 * experiment, these functions should remain, as there will be future experiments that require them.
 */
const stepsOnExperiments: string[] = [];

export const controlOnboardingSteps = [...new Set(Object.values(controlOnboardingData).map((obj) => obj.step))];

export const controlOnboardingPaths: OnboardingPath[] = controlOnboardingSteps.map((step) => ({
  step,
  path: `${step}`,
}));

export const getControlOnboardingStepData = (step: ControlOnboardingStep): OnboardingStepData => {
  const stepId = stepsOnExperiments.includes(step) ? `${step}-${TestGroup.CONTROL}` : step;
  return controlOnboardingData[stepId];
};

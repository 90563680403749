import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getLogsCardStyles = (theme: GrafanaTheme2) => ({
  wrapper: (isSelected: boolean) => css`
    display: flex;
    flex-flow: column;
    gap: '10px';
    background-color: ${isSelected
      ? theme.colors.emphasize(theme.colors.background.secondary, 0.06)
      : theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    border-radius: ${theme.shape.radius.default};
    border: ${isSelected
      ? `2px solid ${theme.colors.primary.border};`
      : `2px solid ${theme.colors.background.secondary}`};
    box-sizing: border-box;
    width: auto;
    position: relative;

    &:hover {
      background: ${theme.colors.emphasize(theme.colors.background.secondary, 0.08)};
      border-color: ${theme.colors.emphasize(theme.colors.background.secondary, 0.08)};
      cursor: pointer;
      z-index: 1;
    }
  `,
  body: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 100%;

    @media (width <=450px) {
      display: flex;
      flex-direction: column;
    }
  `,
  bodyWrapper: (isSelected: boolean) => css`
    ${isSelected ? 'display: none;' : 'display: flex;'}
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    background-image: linear-gradient(to left, rgba(245, 95, 62, 0.3), rgba(255, 136, 51, 0.3));
    height: 100%;
    border-radius: ${theme.shape.radius.default};
  `,
  title: css`
    align-items: center;
    gap: ${theme.spacing(1)};
    flex: 1;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 0;
    vertical-align: top;
    font-size: 18px;
    @media (width <= 478px) {
      line-height: 22px;
      font-size: 16px;
    }

    h4 {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 30px;
      color: ${theme.colors.text.secondary};
      @media (width <= 478px) {
        line-height: 17px;
      }
    }

    img {
      margin-right: 10px;
      width: 25px;
    }

    @media (width <=450px) {
      h2 {
        line-height: 24px;
      }
      h4 {
        line-height: 16px;
      }
    }
  `,
  block: (frame: boolean, width: string) => css`
    ${frame && `outline: solid 1px ${theme.colors.secondary.contrastText};`}
    border-radius: 5px;
    ${frame && 'padding: 10px 10px;'}
    width: ${width};
    height: fit-content;
    ${frame ? 'font-size: 12px;' : 'font-size: 11px;'}
    margin: 3px;
    text-align: center;

    img {
      display: flex;
      margin: auto;
      max-width: 100%;
    }

    @media (width <=450px) {
      width: 100%;
    }
  `,
});

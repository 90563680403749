import { Badge, useStyles2 } from '@grafana/ui';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { getTrialBadgeClassName } from 'feature/home/utils/planUtils';
import React from 'react';
import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';
import { getAccountStyles } from 'feature/home/components/refresh-design/Account.styles';
import { HomeCustomLink } from 'feature/home/components/refresh-design/HomeCustomLink';
import { getSubscriptionLink } from 'feature/home/components/refresh-design/helpers';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { cx } from '@emotion/css';
import { isUserAdmin } from 'feature/common/utils/UserUtils';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';

export const AccountPlanCard = () => {
  const styles = useStyles2(getAccountStyles);
  const isAdmin = isUserAdmin();
  const {
    data: { pricingPlan, orgPlan, trialEnd, orgSlug },
    isLoading,
    isSuccess,
  } = useOrgInfoData();
  const isTrialPlan = orgPlan === Tiers.Trial;
  const subscriptionLink = getSubscriptionLink(orgSlug);
  const trialClassName = getTrialBadgeClassName(trialEnd);

  return (
    <div className={styles.card} data-testid={DataTestId.ACCOUNT_PLAN_SECTION}>
      <div data-testid={DataTestId.ACCOUNT_PLAN_TITLE} className={styles.primaryText}>
        Account plan
      </div>
      <div className={styles.planWrapper}>
        {isLoading && <LoadingIndicator />}
        {isSuccess && (
          <>
            <div data-testid={DataTestId.ACCOUNT_PLAN_VALUE} className={styles.standOutText}>
              {pricingPlan}
            </div>
            {Boolean(pricingPlan) && isTrialPlan && !!trialEnd && trialEnd > 0 && (
              <Badge
                data-testid={DataTestId.ACCOUNT_PLAN_TRIAL_BADGE}
                color="blue"
                className={styles.trialBadge}
                text={
                  <div className={styles.trialDays}>
                    <div
                      data-testid={DataTestId.ACCOUNT_PLAN_TRIAL_CIRCLE}
                      className={cx(styles.circle, trialClassName)}
                    />
                    <span>{trialEnd}</span>
                    <span className={styles.trialText}>{`day${trialEnd > 1 || trialEnd < 1 ? 's' : ''} left`}</span>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
      {isAdmin && (
        <HomeCustomLink
          id={'manage-subscription'}
          text="Manage subscription"
          href={subscriptionLink}
          trackId={ComponentKey.Accounts.ManageSubscription}
        />
      )}
    </div>
  );
};

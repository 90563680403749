import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'feature/common/consts/styles';
export const getStyles = (theme: GrafanaTheme2) => ({
  addDataWrapper: css`
    display: flex;
    @media (max-width: ${breakpoints.addDataButton}px) {
      display: none;
    }
  `,
  addDataLink: css`
    align-self: flex-end;
  `,
  header: css`
    font-weight: 300;
    line-height: 58px;
    display: flex;
    font-size: 40px;
    margin-bottom: 20px;
  `,
  welcomeText: css`
    margin-right: 6px;
    @media (max-width: ${breakpoints.titleText}px) {
      display: none;
    }
  `,
  headerWrapper: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(4)};
    width: 100%;
  `,
  cloudText: css`
    font-weight: ${theme.typography.fontWeightMedium};
    @media (max-width: ${breakpoints.titleText}px) {
      position: absolute;
      top: 16px;
      left: 48px;
      font-weight: 500;
      line-height: 24px;
      font-size: 20px;
    }
  `,
  welcome: css`
    display: flex;
    flex-direction: column;
  `,
  planRow: css`
    display: flex;
    line-height: 24px;
    margin-left: 15px;
    margin-top: -30px;
    align-items: center;
    @media (max-width: ${breakpoints.titleText}px) {
      position: absolute;
      margin-top: -42px;
      right: 0px;
    }
  `,
});

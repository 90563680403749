import { ButtonVariant, IconName } from '@grafana/ui';
import { IndividualStep } from 'feature/onboarding/types/CardData';
import { HTMLAttributeAnchorTarget } from 'react';
import { SetupImageName } from 'feature/onboarding/types/SetupWizard';
import { controlOnboardingSteps } from '../data/ControlOnboarding.data';
import { simplifiedOnboardingSteps } from '../data/SimplifiedOnboarding.data';

export type ControlOnboardingStep = (typeof controlOnboardingSteps)[number];

export type SimplifiedOnboardingStep = (typeof simplifiedOnboardingSteps)[number];

export type OnboardingPath = { step: string; path: string };

export enum OnboardingCustomIconName {
  'prometheus',
  'graphite',
  'loki',
  'tempo',
  'prometheus-color',
  'graphite-color',
  'loki-color',
  'tempo-color',
  'kube-color',
  'kubernetes-logo-small',
  'macos',
  'macos-color',
  'windows',
  'windows-color',
  'linux',
  'linux-color',
  'synthetic-monitoring',
  'sample-data',
  'raspberry-pi',
  'raspberry-pi-color',
  'k6-color',
  'http-metrics',
  'open-telemetry',
  'aws',
  'gcp',
  'azure',
  'aws-athena',
  'aws-redshift',
  'aws-timestream',
  'aws-x-ray',
  'aws-sitewise',
  'big-query',
  'gcp-logs',
  'gcp-metrics',
  'gcp-logging',
  'gcp-monitoring',
  'gcp-analytics',
  'gcp-calendar',
  'googlesheets',
  'azure-metrics',
  'azure-event-hubs',
  'azure-data-explorer',
  'azure-devops',
  'azure-monitor',
  'pyroscope',
  'observability',
  'faro',
  'grafana-agent',
  'testing',
  'demo-dash-purple',
  'json-api',
  'csv',
  'create-dashboard-large',
  'scale-centralize-large',
  'search-large',
  'rocket-orange',
  'rocket',
  'o11y',
}

export enum OnboardingImageName {
  'template-dashboard',
  'custom-dashboard',
}

export type IconTypes = IconName | OnboardingCustomIconName | string;

export interface OnboardingStepData {
  step: string;
  steps?: IndividualStep[];
  header: string;
  preamble: string;
  cards: OnboardingCardData[];
  buttons: {
    skip: OnboardingCardButton;
    back: OnboardingCardButton;
    more?: OnboardingCardButton;
  };
  columns?: number;
}
export type OnboardingCardData = OnboardingGeneralCardData | OnboardingImageCardData | OnboardingLinksCardData;

interface OnboardingCardCommon {
  key: string;
  title: JSX.Element | string;
  titleAlign?: 'left' | 'center' | 'space-between';
  titleInfo?: {
    body?: JSX.Element | string;
    icon?: IconName;
  };
  icons: IconTypes[];
  eventName?: string;
  style: 'row-flex' | 'column-flex';
  type: 'general' | 'image' | 'links';
  selected?: boolean;
}

type ButtonFill = 'solid' | 'text';

export interface OnboardingGeneralCardData extends OnboardingCardCommon {
  link: OnboardingCardLink;
  text: JSX.Element | string;
  contentAlignedWithTitle?: boolean;
  badge?: {
    icon?: IconName;
    text: string;
    info?: {
      header?: JSX.Element | string;
      body?: JSX.Element | string;
      footer?: JSX.Element | string;
      icon?: IconName;
    };
    fill?: ButtonFill;
  };
  buttons?: OnboardingCardButton[];
  selected?: boolean;
  design?: 'card' | 'div';
}

export interface OnboardingImageCardData extends OnboardingCardCommon {
  link: OnboardingCardLink;
  image: SetupImageName;
}

export interface OnboardingLinksCardData extends OnboardingCardCommon {
  links: OnboardingCardLink[];
}

export interface OnboardingCardLink {
  key: string;
  text?: JSX.Element | string;
  url: string;
  target?: HTMLAttributeAnchorTarget;
  callback?: () => void;
}

export interface OnboardingCardButton {
  key: string;
  text: string;
  link: string;
  variant?: ButtonVariant;
  preamble?: string;
}

import { getConnectionUrl } from 'feature/common/utils/misc';

const basePath = '/a/cloud-home-app';
const onboarding = `${basePath}/onboarding-flow`;

export const appPaths = {
  home: basePath,
  gettingStarted: `${basePath}/getting-started`,
  onboarding,
  setupWizard: `${basePath}/setup-wizard`,
  importDashboard: `${basePath}/import-dashboard`,
  logsOnboarding: `${onboarding}/logs-onboarding`,
  docsPlugin: '/a/grafana-selfservetutorials-app',
  linuxNode: `/a/grafana-selfservetutorials-app/linux-node`,
  selfHostedMetrics: `/a/grafana-selfservetutorials-app/self-hosted-metrics`,
  multipleLinuxHosts: `/a/grafana-selfservetutorials-app/monitor-multiple-linux-hosts`,
};

export const externalPaths = {
  integrationsAndConnections: getConnectionUrl(),
  connectionDatasources: '/connections/datasources',
  syntheticMonitoringChecks: '/a/grafana-synthetic-monitoring-app/checks/new',
  syntheticMonitoringApp: '/a/grafana-synthetic-monitoring-app',
  monitorKubernetes: '/a/grafana-k8s-app/home',
  kubernetesCluster: '/a/grafana-k8s-app/configuration/cluster-config',
  k6App: `/a/k6-app/initialize`,
  k6Projects: `/a/k6-app/projects`,
  pyroscope: 'a/grafana-pyroscope-app/tag-explorer',
  faro: 'a/grafana-kowalski-app/apps',
  applicationO11y: '/a/grafana-app-observability-app/initialize',
  dashboards: '/dashboards',
  newAlertRules: '/alerting/new/alerting?returnTo=/alerting/list',
  alertRulesList: '/alerting/list',
  firingAlertRules: '/alerting/list?search=state:firing',
  irmActiveUsers: '/alerting/notifications/receivers/new?alertmanager=grafana&query=',
  profiles: '/a/grafana-pyroscope-app',
  billingDashboard: '/d/i933Yr2nk/billing-usage?orgId=1',
  feO11Y: 'a/grafana-kowalski-app/apps',
  onCall: '/a/grafana-oncall-app',
  incidents: '/a/grafana-incident-app',
  alerts: '/alerting',
  demoDashboards: '/a/grafana-demodashboards-app',
  applicationObservability: '/a/grafana-app-observability-app/services',
  grafanaPlay: 'https://play.grafana.org/d/',
};

import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { AlertLine } from 'feature/common/components/AlertLine/AlertLine';
import { DashboardStarredEmpty } from 'feature/home/components/refresh-design/DashboardSection/DashboardStarredEmpty';
import { DashboardStarredItem } from 'feature/home/components/refresh-design/DashboardSection/DashboardStarredItem';
import { Dashboard } from 'feature/common/types/Dashboard';
import { QueryError } from 'feature/common/types/QueryError';
import { useDeleteStarredDashboardMutation, useGetStarredDashboardsQuery } from 'feature/common/api/DashboardsApi';

const getStyles = () => ({
  container: css`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  `,
  icon: css`
    display: inline-flex;
    vertical-align: text-top;
  `,
  list: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  `,
  item: css`
    position: relative;
    list-style: none;
    line-height: 1.5714285714;
    width: 100%;
  `,
});

export const DashboardStarredItems = () => {
  const styles = useStyles2(getStyles);

  const { data, isLoading, error, isSuccess } = useGetStarredDashboardsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteStarredDashboard, { error: errorDelete, isLoading: isLoadingDelete }] =
    useDeleteStarredDashboardMutation();

  if (isLoading || !!error || (isSuccess && data?.length === 0)) {
    return (
      <div className={styles.container}>
        {isLoading && <LoadingIndicator />}
        {error && (
          <AlertLine status="error">{'There was an error while trying to load the starred dashboards'}</AlertLine>
        )}
        {!isLoading && !error && isSuccess && data?.length === 0 && <DashboardStarredEmpty />}
      </div>
    );
  } else {
    return (
      <ul className={styles.list}>
        {data
          ?.filter((item) => !!item)
          .map((item, i) => (
            <li className={styles.item} key={'dashboard-' + i}>
              <DashboardStarredItem
                item={item as Dashboard}
                onDelete={() => deleteStarredDashboard((item as Dashboard).uid)}
                error={errorDelete as QueryError}
                loading={isLoadingDelete}
              />
            </li>
          ))}
      </ul>
    );
  }
};

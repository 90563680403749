import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getSelectConnectionInputStyles = (theme: GrafanaTheme2) => ({
  searchContainer: css`
    padding: ${theme.spacing(3)};
  `,
  input: css`
    height: ${theme.spacing(6)};
  `,
});

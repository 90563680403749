import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useStyles2 } from '@grafana/ui';
import { OnboardingCard } from 'feature/onboarding/components/OnboardingCard';
import { getSetupWizardBodyStyles } from 'feature/onboarding/components/SetupWizard/SetupWizardBody/SetupWizardBody.styles';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { SetupWizardStep } from 'feature/onboarding/types/SetupWizard';
import { getWizardStepData } from 'feature/onboarding/data/SetupWizard.data';
import {
  OnboardingCardData,
  OnboardingGeneralCardData,
  OnboardingImageCardData,
  OnboardingLinksCardData,
} from 'feature/onboarding/types/OnboardingCard';
import { OnboardingImageCard } from 'feature/onboarding/components/OnboardingCard/OnboardingImageCard';
import { OnboardingLinksCard } from 'feature/onboarding/components/OnboardingCard/OnboardingLinksCard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { TestGroup } from 'feature/onboarding/types/TestGroup';

interface SetupWizardBodyProps {
  step: SetupWizardStep;
  testGroup?: TestGroup;
}

export const SetupWizardBody: React.FC<SetupWizardBodyProps> = ({ step, testGroup }) => {
  const styles = useStyles2(getSetupWizardBodyStyles);
  const { orgId } = useContext(AppContext);
  const wizardBodyRef = useRef(null);
  const [cardsToDisplay, setCardsToDisplay] = useState(0);

  const stepData = getWizardStepData(step, testGroup);

  function onComponentClick(key: string): void {
    if (stepData) {
      trackAction(stepData.eventName ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME, key, ActionType.click, orgId);
    }
  }

  function renderCard(card: OnboardingCardData) {
    if (!!card) {
      switch (card.type) {
        case 'general':
          return <OnboardingCard card={card as OnboardingGeneralCardData} key={card.key} />;
        case 'image':
          return <OnboardingImageCard card={card as OnboardingImageCardData} key={card.key} />;
        case 'links':
          return <OnboardingLinksCard card={card as OnboardingLinksCardData} key={card.key} />;
      }
    }
    return null;
  }

  const handleResize = useCallback(() => {
    if (wizardBodyRef?.current && stepData?.cards?.length) {
      const totalCards = stepData.cards.length;
      const stepsWidth = 311;
      const marginLeft = 60;
      const minCardWidth = 247;
      const gap = 16;

      const width = stepData?.steps?.length > 0 ? window.innerWidth - stepsWidth - marginLeft : window.innerWidth;
      const maxWidthForOne = minCardWidth * 2 + gap * 1;
      const maxWidthForTwo = minCardWidth * 3 + gap * 2;
      const maxWidthForThree = minCardWidth * 4 + gap * 3;

      let cardsToDisplayByRow = 0;
      if (width > 0 && width <= maxWidthForOne) {
        cardsToDisplayByRow = 1;
      }
      if (width > maxWidthForOne && width <= maxWidthForTwo) {
        cardsToDisplayByRow = 2;
      }
      if (width > maxWidthForTwo && width <= maxWidthForThree) {
        cardsToDisplayByRow = 3;
      }
      if (width > maxWidthForThree) {
        cardsToDisplayByRow = 4;
      }
      if (totalCards > 0 && totalCards < cardsToDisplayByRow) {
        cardsToDisplayByRow = totalCards;
      }
      setCardsToDisplay(cardsToDisplayByRow);
    }
  }, [stepData]);

  useEffect(() => {
    if (wizardBodyRef?.current) {
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [wizardBodyRef, handleResize]);

  useLayoutEffect(() => handleResize(), [handleResize]);

  if (!!stepData) {
    return (
      <div className={stepData.steps.length > 0 ? styles.contentWrapper : ''}>
        {(!!stepData.header || !!stepData.preamble) && (
          <div className={styles.headerWrapper}>
            <div className={styles.textWrapper}>
              <h3 className={styles.header}>{stepData.header}</h3>
              {stepData.preamble && <p className={styles.preamble}>{stepData.preamble}</p>}
            </div>
          </div>
        )}
        <div
          ref={wizardBodyRef}
          className={styles.cardWrapper}
          style={{
            gridTemplateColumns: stepData.cards.length > 0 ? `repeat(${cardsToDisplay}, 1fr)` : undefined,
          }}
        >
          {stepData.cards.map((card) => renderCard(card))}
        </div>
        {!!stepData.buttons.more && (
          <div className={styles.moreBtnWrapper}>
            {!!stepData.buttons.more.preamble && (
              <span className={styles.moreText}>{stepData.buttons.more.preamble}</span>
            )}
            {!!stepData.buttons.more.link && stepData.buttons.more.key && (
              <a
                className={styles.moreLink}
                href={stepData.buttons.more.link}
                onClick={() => onComponentClick(stepData.buttons.more?.key as string)}
              >
                {stepData.buttons.more.text}
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
  return null;
};

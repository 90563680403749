import { css } from '@emotion/css';

export const getStyles = () => {
  return {
    indicator: css`
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      div {
        font-size: 48px;
      }
    `,
  };
};

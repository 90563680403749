import React, { useContext, useLayoutEffect, useState } from 'react';

import { PluginPage } from '@grafana/runtime';
import { GrafanaTheme2, PageLayoutType } from '@grafana/data';
import { Icon, LinkButton, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { CloudServiceCardData, CloudServicesStep } from 'feature/onboarding/types/CloudServicesCard';
import { getCloudServicesStepData } from 'feature/onboarding/data/CloudServices.data';
import { CloudServiceCard } from 'feature/onboarding/components/GettingStarted/CloudServiceCard';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { closeNavIfOpened } from 'feature/onboarding/utils/sectionNavUtils';

const getStyles = (theme: GrafanaTheme2) => ({
  cardWrapper: (columns?: number) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;
    margin-top: ${theme.spacing(3)};
    gap: ${theme.spacing(2)};
    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 540px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 540px) and (max-width: 820px) {
      grid-template-columns: repeat(${(columns && columns > 2) || !columns ? 2 : columns}, 1fr);
    }
    @media (min-width: 820px) and (max-width: 1095px) {
      grid-template-columns: repeat(${(columns && columns > 3) || !columns ? 3 : columns}, 1fr);
    }
    @media (min-width: 1095px) {
      grid-template-columns: repeat(${(columns && columns > 4) || !columns ? 4 : columns}, 1fr);
    }
  `,
  buttonsWrapper: css`
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    padding-right: 10px;
  `,
  link: css`
    padding: 0;

    &:hover {
      background: transparent;
    }
  `,
});

export const GettingStartedCloudServicesPage: React.FC = () => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);
  const [option, setOption] = useState<CloudServicesStep>('cloud-services');
  const data = getCloudServicesStepData(option);

  useLayoutEffect(() => closeNavIfOpened());

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.GETTING_STARTED_EVENT_NAME, key, ActionType.click, orgId);
  }

  function onBack() {
    if (!!data.buttons?.back) {
      onComponentClick(data.buttons.back.key);
      if (!!data.buttons.back.goToOption) {
        setOption(data.buttons.back.goToOption);
      }
    }
  }

  function onCardSelected(card: CloudServiceCardData) {
    if (!!card.link.goToOption) {
      setOption(card.link.goToOption);
    }
  }

  return (
    <PluginPage layout={PageLayoutType?.Standard} pageNav={data.breadcrumbs}>
      {!!data.buttons?.back && (
        <div className={styles.buttonsWrapper}>
          <LinkButton
            type="button"
            variant={data.buttons.back.variant ?? 'secondary'}
            fill="text"
            href={data.buttons.back.link}
            key={data.buttons.back.key}
            onClick={onBack}
            className={styles.link}
          >
            {!!data.buttons.back.icon && <Icon name={data.buttons.back.icon} />}
            {data.buttons.back.text}
          </LinkButton>
        </div>
      )}
      <div className={styles.cardWrapper(data.columns)}>
        {data.cards.map((card) => (
          <CloudServiceCard card={card} key={card.key} onCardSelected={() => onCardSelected(card)} />
        ))}
      </div>
    </PluginPage>
  );
};

import React, { FC } from 'react';

export const Star: FC<{ className: string }> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M22 9.67C21.9368 9.48709 21.822 9.32643 21.6693 9.2075C21.5167 9.08858 21.3328 9.0165 21.14 9L15.45 8.17L12.9 3C12.8181 2.83093 12.6903 2.68834 12.5311 2.58857C12.3719 2.48881 12.1878 2.43589 12 2.43589C11.8121 2.43589 11.6281 2.48881 11.4689 2.58857C11.3097 2.68834 11.1819 2.83093 11.1 3L8.54999 8.16L2.85999 9C2.67492 9.02631 2.50092 9.10396 2.35774 9.22416C2.21457 9.34435 2.10795 9.50227 2.04999 9.68C1.99694 9.85367 1.99218 10.0385 2.03622 10.2147C2.08026 10.3909 2.17145 10.5517 2.29999 10.68L6.42999 14.68L5.42999 20.36C5.39429 20.5475 5.41299 20.7413 5.48386 20.9185C5.55474 21.0956 5.67485 21.2489 5.82999 21.36C5.98121 21.4681 6.15957 21.5319 6.34504 21.5443C6.53051 21.5567 6.71576 21.5171 6.87999 21.43L12 18.76L17.1 21.44C17.2403 21.5192 17.3989 21.5605 17.56 21.56C17.7718 21.5608 17.9784 21.4942 18.15 21.37C18.3051 21.2589 18.4252 21.1056 18.4961 20.9285C18.567 20.7513 18.5857 20.5575 18.55 20.37L17.55 14.69L21.68 10.69C21.8244 10.5677 21.9311 10.4069 21.9877 10.2263C22.0444 10.0458 22.0486 9.85287 22 9.67ZM13 12C12.8827 12.1134 9.08915 12.1994 12.58 13.0692C9.02003 11.0092 8.98559 11.4902 12.58 13.0692L12.1035 13.2092L13 12C13.1639 12 12.8553 11.923 13 12C12.8361 12 11.9925 9.64904 12.1035 13.2092L13 12L12.2071 13.9184C15.6671 11.8584 13.0505 12.1551 13 12C13.1173 12.1134 14.5 11.5 13 12C15.0915 14.6203 15.54 14.44 13 12C14.78 14.99 15.0915 14.6203 13 12C10.8085 14.6103 13.072 12.1468 13 12C10.8085 14.6103 11.16 15.7879 12.9 12.9289L13 12Z"
      fill="currentColor"
    />
  </svg>
);

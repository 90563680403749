import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams

import { trackPage } from '@grafana/cloud-ab-testing';
import { useStyles2 } from '@grafana/ui';
import { DataSourceInstanceSettings, PageLayoutType } from '@grafana/data';

import { PluginPage } from 'feature/common/components/PluginPage';
import { lsGet, lsSave } from 'feature/common/utils/misc';
import { getImportDashboardStepData } from 'feature/onboarding/data/ImportDashboard.data';
import { ImportDashboardStep } from 'feature/onboarding/types/ImportDashboard';
import { getImportDashboardStyles } from 'feature/onboarding/components/ImportDashboard/ImportDashboardPage.styles';
import ImportDashboardStepsList from 'feature/onboarding/components/ImportDashboard/ImportDashboardStepsList';
import { ImportDataSourceConfig } from 'feature/onboarding/components/ImportDashboard/ImportDataSourceConfig';
import { ImportDashboardForm } from 'feature/onboarding/components/ImportDashboard/ImportDashboardForm';

interface ImportDashboardProps {
  step: ImportDashboardStep;
}

export const ImportDashboardPage: React.FC<ImportDashboardProps> = ({ step }) => {
  const styles = useStyles2(getImportDashboardStyles);
  const [dataSource, setDataSource] = useState<DataSourceInstanceSettings>();
  const stepData = getImportDashboardStepData(step);

  const { dashboardId, datasource } = useParams<{ dashboardId: string; datasource: string }>();

  useEffect(() => {
    if (!!stepData) {
      trackPage({
        adobeTarget: {
          viewName: stepData.route,
        },
      });
    }
  }, [stepData]);

  useEffect(() => {
    if (!!datasource && !!dataSource && Object.keys(dataSource).length > 0) {
      lsSave('import-dashboard-saved-data', dataSource);
    } else if (!!lsGet('import-dashboard-saved-data')) {
      setDataSource(lsGet('import-dashboard-saved-data'));
    }
  }, [dataSource, datasource]);

  return (
    <PluginPage layout={PageLayoutType?.Standard} pageNav={{ text: 'Import Dashboard Wizard' }}>
      <div className={styles.container}>
        {!!stepData && (
          <>
            <div className={stepData.steps.length > 0 ? styles.contentOuter : styles.wrapper(!!stepData.header)}>
              {stepData.steps.length > 0 && <ImportDashboardStepsList data={stepData.steps} />}
              <div className={styles.wizardBody(stepData.steps.length)}>
                {stepData.step === 'configure-data' ? (
                  <ImportDataSourceConfig dashboardId={dashboardId} setDataSource={setDataSource} />
                ) : (
                  <ImportDashboardForm dashboardId={dashboardId} selectedDataSource={dataSource} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PluginPage>
  );
};

import { useGetCurrentUserOrgsQuery, useGetCurrentUserQuery } from 'feature/common/api/UsersApi';
import { QueryError } from 'feature/common/types/QueryError';
import { CurrentUserData } from 'feature/common/types/User';
import { getOrgRole, isAdmin } from 'feature/common/utils/UserUtils';

export function useCurrentUser(): CurrentUserData {
  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
    error: currentUserError,
    isSuccess: isCurrentUserSuccess,
  } = useGetCurrentUserQuery();

  const {
    data: currentUserOrgs,
    isLoading: isCurrentUserOrgsLoading,
    isError: isCurrentUserOrgsError,
    error: currentUserOrgsError,
    isSuccess: isCurrentUserOrgsSuccess,
  } = useGetCurrentUserOrgsQuery();

  return {
    isLoading: isCurrentUserLoading || isCurrentUserOrgsLoading,
    currentUser,
    isSuccess: isCurrentUserSuccess && isCurrentUserOrgsSuccess,
    error: (currentUserError || currentUserOrgsError) as QueryError,
    isError: isCurrentUserError || isCurrentUserOrgsError,
    isAdmin: !!currentUser && !!currentUserOrgs ? isAdmin(currentUser, currentUserOrgs) : undefined,
    orgRole: !!currentUser && !!currentUserOrgs ? getOrgRole(currentUser, currentUserOrgs) : undefined,
  };
}

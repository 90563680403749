import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    promptWrapper: css`
      min-width: 132px;
      text-align: right;
      display: flex;
      flex-direction: column;
      @media (max-width: 860px) {
        width: 100%;
        text-align: center;
      }
    `,
    limitText: css`
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      margin-top: 2px;
      @media (max-width: 860px) {
        margin-bottom: ${theme.spacing(2)};
      }
    `,
    upgradeButtonWrapper: css`
      @media (max-width: 860px) {
        order: 2;
        width: 100%;
      }
      a {
        @media (max-width: 860px) {
          justify-content: center;
          width: 100%;
        }
      }
    `,
  };
};

import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { SetupImageName } from 'feature/onboarding/types/SetupWizard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

export const demoDashboardData = [
  {
    icons: [],
    image: SetupImageName.QUICKPIZZA_SRE_DEMODASHBOARD,
    title: 'QuickPizza SRE Demo Dashboard',
    titleAlign: 'center',
    link: {
      key: ComponentKey.QuickPizzaDemoDashboards,
      url: '/a/grafana-demodashboards-app',
      target: '_blank',
    },
    key: ComponentKey.QuickPizzaDemoDashboards,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    image: SetupImageName.WEATHER_DEMODASHBOARD,
    title: 'Weather Demo Dashboard',
    titleAlign: 'center',
    link: {
      key: ComponentKey.WeatherDemoDashboards,
      url: '/a/grafana-demodashboards-app',
      target: '_blank',
    },
    key: ComponentKey.WeatherDemoDashboards,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    image: SetupImageName.CITI_BIKES_NYC_DASH,
    title: 'Citi Bikes in NYC',
    titleAlign: 'center',
    text: '',
    link: {
      key: ComponentKey.CitiBikesNYCFromHome,
      target: '_blank',
      url: 'https://play.grafana.org/d/play-bigquery-1/citibike-example-overview?orgId=1',
    },
    key: ComponentKey.CitiBikesNYCFromHome,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    image: SetupImageName.TIME_SERIES_VIZ_DASH,
    title: 'Time series visualization',
    titleAlign: 'center',
    link: {
      key: ComponentKey.ExploreTimeSeriesVizDashboardFromHome,
      target: '_blank',
      url: 'https://play.grafana.org/d/000000016/1-time-series-graphs?orgId=1',
    },
    key: ComponentKey.ExploreTimeSeriesVizDashboardFromHome,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
];

import React, { FC, useContext } from 'react';

import { Icon, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import { QueryError } from 'feature/common/types/QueryError';
import { LinkOrSpan } from 'feature/usage/components/LinkOrSpan';
import { getStyles } from './Metric.styles';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { handleKeyDown } from 'feature/common/utils/aria';
import { AppContext } from 'App';

interface Props {
  id: string;
  label: string;
  value: string;
  unit?: string;
  link?: string;
  error?: QueryError;
  isLoading?: boolean;
}

export const Metric: FC<Props> = ({ id, label, value, unit, link, isLoading, error }) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  function onComponentClick(): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, `usage_${id}_link`, ActionType.click, orgId);
  }

  return (
    <div
      className={styles.wrapper}
      onClick={onComponentClick}
      onKeyDown={handleKeyDown(onComponentClick)}
      tabIndex={0}
      role="button"
    >
      {isLoading ? (
        <LoadingPlaceholder text="Loading ..." data-testid={DataTestId.METRIC_LOADING_ID} />
      ) : (
        <>
          {error === undefined ? (
            <LinkOrSpan to={link} className={styles.value}>
              <span className={styles.valueText} data-testid={DataTestId.METRIC_VALUE_ID}>
                {value}
              </span>
              {unit != null && (
                <span className={styles.unitText} data-testid={DataTestId.METRIC_UNIT_ID}>{` ${unit}`}</span>
              )}
            </LinkOrSpan>
          ) : (
            <span className={styles.valueText} title={error.data?.message} data-testid={DataTestId.METRIC_ERROR_ID}>
              Error
              <Icon name="info-circle" className={styles.errorIcon}></Icon>
            </span>
          )}
          <LinkOrSpan to={link} className={styles.label}>
            <span data-testid={DataTestId.METRIC_LABEL_ID}>{label}</span>
          </LinkOrSpan>
        </>
      )}
    </div>
  );
};

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    link: css`
      color: ${theme.colors.primary.text};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    section: css`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: baseline;
      cursor: default;
    `,
    insights: css`
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 1.5rem;
    `,
    text: css`
      font-size: 22px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: ${theme.colors.text.maxContrast};
      cursor: default;
    `,
    insightsWrapper: css`
      display: flex;
      flex-direction: row;
      justify-content: start;
    `,
    insightsGroupWrapper: css`
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-right: 8rem;
    `,
    includedUsageLabel: css`
      color: ${theme.colors.text.secondary};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
      cursor: default;
    `,
    tooltipContent: css`
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
    `,
  };
};

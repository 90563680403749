import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';
import { SelectableValue } from '@grafana/data';

type DatasourceAccess = 'proxy' | 'server';

export type DatasourceType = 'influxdb' | 'postgres' | 'graphite' | 'mysql' | 'prometheus';

export interface DatasourceResponse {
  datasource: {
    access: DatasourceAccess;
    basicAuth: boolean;
    basicAuthUser: string;
    database: string;
    id: number;
    isDefault: boolean;
    jsonData: {};
    name: string;
    orgId: number;
    readOnly: boolean;
    secureJsonFields: {};
    type: DatasourceType;
    typeLogoUrl: string;
    uid: string;
    url: string;
    user: string;
    version: number;
    withCredentials: boolean;
  };
  id: number;
  message: string;
  name: string;
}

export interface JsonDataTypes {
  graphiteVersion?: string;
  postgresVersion?: number;
  version?: string;
  httpMode?: string;
  organization?: string;
  defaultBucket?: string;
  database?: string;
  // For InfluxQl `database` was deprecated for this prop
  // https://grafana.com/docs/grafana/latest/datasources/influxdb/#provision-the-data-source
  // So we'll just update the output via settings for now.
  dbName?: string;
  httpHeaderName1?: string;
  oauthPassThru?: boolean;
  httpMethod?: string;
  sigV4Auth?: boolean;
}

export enum AuthMethod {
  NoAuth = 'NoAuth',
  BasicAuth = 'BasicAuth',
  OAuthForward = 'OAuthForward',
  CrossSiteCredentials = 'CrossSiteCredentials',
}

export const AuthMethodOptions: Record<AuthMethod, SelectableValue<AuthMethod>> = {
  [AuthMethod.BasicAuth]: {
    label: 'Basic authentication',
    value: AuthMethod.BasicAuth,
    description: 'Authenticate with your data source username and password',
  },
  [AuthMethod.CrossSiteCredentials]: {
    label: 'Enable cross-site access control requests',
    value: AuthMethod.CrossSiteCredentials,
    description:
      'Allow cross-site Access-Control requests with your existing credentials and cookies. This enables the server to authenticate the user and perform authorized requests on their behalf on other domains.',
  },
  [AuthMethod.OAuthForward]: {
    label: 'Forward OAuth Identity',
    value: AuthMethod.OAuthForward,
    description:
      'Forward the OAuth access token (and if available: the OIDC ID token) of the user querying to the data source',
  },
  [AuthMethod.NoAuth]: {
    label: 'No Authentication',
    value: AuthMethod.NoAuth,
    description: 'Data source is available without authentication',
  },
};

export interface DatasourceSettings {
  id: number;
  uid: string;
  orgId: number;
  name: string;
  type: DatasourceType;
  typeLogoUrl: string;
  access: DatasourceAccess;
  url: string;
  user?: string;
  database?: string;
  basicAuth: boolean;
  basicAuthUser?: string;
  withCredentials: boolean;
  isDefault: boolean;
  jsonData: JsonDataTypes;
  secureJsonData?: {
    basicAuthPassword?: string;
    password?: string;
    token?: string;
    httpHeaderValue1?: string;
  };
  secureJsonFields: {};
  version: number;
  readOnly: boolean;
  accessControl: {
    'alert.instances.external:read': boolean;
    'alert.instances.external:write': boolean;
    'alert.notifications.external:read': boolean;
    'alert.notifications.external:write': boolean;
    'alert.rules.external:read': boolean;
    'alert.rules.external:write': boolean;
    'datasources.caching:read': boolean;
    'datasources.caching:write': boolean;
    'datasources.id:read': boolean;
    'datasources.permissions:read': boolean;
    'datasources.permissions:write': boolean;
    'datasources:delete': boolean;
    'datasources:query': boolean;
    'datasources:read': boolean;
    'datasources:write': boolean;
  };
}
export interface DataSourceDTO {
  id: number;
  uid: string;
  name: string;
  url: string;
  database: string;
  user: string;
  password?: string;
  version?: string;
  type: DatasourceType;
  organization?: string;
  token?: string;
  defaultBucket?: string;
  httpHeaderName1?: string;
  httpHeaderValue1?: string;
  authMethod?: AuthMethod;
}

export type DatasourcePlugin = {
  category: string;
  defaultNavUrl: string;
  enabled: boolean;
  hasUpdate: boolean;
  id: string;
  info: {
    description: string;
    logos: {
      small: string;
    };
  };
  latestVersion: string;
  name: string;
  pinned: false;
  signature: string;
  signatureOrg: string;
  signatureType: string;
  state: string;
  type: string;
};

enum DatasourcePlan {
  Enterprise = 'enterprise',
  Cloud = 'cloud',
  OSS = 'oss',
  Active = 'active',
}

export type GrafanaDatasource = {
  name: string;
  description: string;
  downloadSlug: string;
  status: DatasourcePlan;
};

export const CONNECTION_DATASOURCES: { [key: string]: DataToConnect } = {
  influxdb: {
    externalUrl: '/connections/datasources/influxdb/',
    id: 'datasource-influxdb',
    logo_url: 'public/app/plugins/datasource/influxdb/img/influxdb_logo.svg',
    name: 'InfluxDB',
    overview: 'Open source time series database',
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'influxdb-datasource-connection',
  },
  mysql: {
    externalUrl: '/connections/datasources/mysql/',
    id: 'datasource-mysql',
    logo_url: 'public/app/plugins/datasource/mysql/img/mysql_logo.svg',
    name: 'MySQL',
    overview: 'Data source for MySQL databases',
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'mysql-datasource-connection',
  },
  postgres: {
    externalUrl: '/connections/datasources/postgres/',
    id: 'datasource-postgres',
    logo_url: 'public/app/plugins/datasource/postgres/img/postgresql_logo.svg',
    name: 'PostgreSQL',
    overview: 'Data source for PostgreSQL and compatible databases',
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'postgres-datasource-connection',
  },
  graphite: {
    externalUrl: '/connections/datasources/graphite/',
    id: 'datasource-graphite',
    logo_url: 'public/app/plugins/datasource/graphite/img/graphite_logo.png',
    name: 'Graphite',
    overview: 'Open source time series database',
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'graphite-datasource-connection',
  },
};

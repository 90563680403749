import {
  DashboardSectionType,
  DashboardTab,
} from 'feature/home/components/refresh-design/DashboardSection/DashboardSection.types';

export const DASHBOARD_TABS = [
  { id: DashboardSectionType.MOST_RECENT, label: 'Most recent', active: true },
  { id: DashboardSectionType.STARRED, label: 'Starred', active: false },
  { id: DashboardSectionType.EXPLORE, label: 'Explore', active: false },
];

export const getDashboardsTabs = (totalRecentDashboards = 0): DashboardTab[] => {
  return DASHBOARD_TABS.map((tab) => {
    if (tab.id === DashboardSectionType.MOST_RECENT && totalRecentDashboards > 0) {
      return { ...tab, active: true };
    }
    if (tab.id === DashboardSectionType.EXPLORE && totalRecentDashboards === 0) {
      return { ...tab, active: true };
    }
    return { ...tab, active: false };
  });
};

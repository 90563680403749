import { BillingData } from '../types/Billing';
import { CloudOrgData } from 'feature/common/types/CloudOrgData';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { User } from '../types/User';
import * as baseApi from 'feature/common/api/baseApi';
import { FREE_INSTANCE, FREE_SUBSCRIPTION } from 'test/mocks/gcomMocks';
import { getUsageDataFromOrg } from 'feature/usage/utils/usageUtils';
import { MetricUnit } from 'feature/usage/types/MetricUnit';

export function getBillingData(orgData: CloudOrgData, users: User[] = []): BillingData {
  const usage = getUsageDataFromOrg(orgData);
  return {
    logs: usage?.data[MetricUnit.LOGS] ?? 0,
    metrics: usage?.data[MetricUnit.METRICS] ?? 0,
    traces: usage?.data[MetricUnit.TRACES] ?? 0,
    users: users.filter((user) => user.email !== 'admin@localhost').length,
    k6Metrics: usage?.data[MetricUnit.K6_TESTS] ?? 0,
    feO11Y: usage?.data[MetricUnit.FEO11Y] ?? 0,
    appO11Y: usage?.data[MetricUnit.APPO11Y] ?? 0,
    sm: usage?.data[MetricUnit.SYNTHETIC_MONITORING] ?? 0,
  };
}

export function getOrgData(slug: string): Promise<CloudOrgData> {
  if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
    return Promise.resolve(FREE_SUBSCRIPTION as unknown as CloudOrgData);
  } else {
    return baseApi.apiGet<CloudOrgData>(`api/plugin-proxy/${baseApi.PLUGIN_ID}/grafanacom-api/orgs/${slug}`);
  }
}

export function getOrgUsers(): Promise<User[]> {
  return baseApi.apiGet<[User]>('/api/org/users');
}

export function getHostedDataDetails(): Promise<HostedDataDetails> {
  if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
    return Promise.resolve(FREE_INSTANCE as unknown as HostedDataDetails);
  } else {
    return baseApi.apiGet<HostedDataDetails>(`api/plugin-proxy/${baseApi.PLUGIN_ID}/grafanacom-api/instances`);
  }
}

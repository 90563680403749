import { OnboardingCardButton } from './OnboardingCard';

export type LogsOnboardingData = {
  header: string;
  preamble: string;
  cards: LogsCardData[];
  buttons: {
    skip: OnboardingCardButton;
    back: OnboardingCardButton;
  };
  columns?: number;
};

export type LogsCardData = {
  title: string;
  text?: string;
  blocks?: BlocksData[];
  key: GrafanaCloudKeys | ExistingSourceKeys | LogsKeys;
  icon?: SVGAElement;
  redirectUrl?: string;
};

export type BlocksData = {
  title: string;
  width: string;
  key: string;
  frame: boolean;
  image: SVGAElement;
  topImage?: SVGAElement;
};

export enum LogsKeys {
  existingSource = 'logs-connect-existing-source',
  grafanaCloud = 'logs-connect-grafana-cloud',
}

export enum platformKey {
  // grafana-cloud instructions in cloud-home-app
  CLOUDFLARE = 'cloudflare',
  HEROKU = 'heroku',
  KAFKA = 'kafka',

  // datasource instructions in cloud-home-app
  FLUENT_D = 'fluent-d',
  FLUENT_BIT = 'fluent-bit',
  PROMTAIL = 'promtail',

  // instructions that are already in connections
  GCP = 'gcp-logs',
  FIREHOSE = 'grafana-aws-app',
  AZURE = 'azure-event-hubs',
  KUBERNETES = 'grafana-k8s-app',
  DOCKER = 'docker',
  LINUX = 'linux-node',
  WINDOWS = 'windows-exporter',
  LOGS_HTTP = 'logs-http',

  LOKI = 'loki',
  ELASTIC = 'elasticsearch',
  CLOUDWATCH = 'cloudwatch',
  ORACLE = 'oci-logs-datasource',
  GCP_DATASOURCE = 'googlecloud-logging-datasource',
  ALLOY = 'alloy',
  OTEL = 'collector-open-telemetry',
  SUMO_LOGIC = 'grafana-sumologic-datasource',
  SPLUNK = 'grafana-splunk-datasource',
  DATA_DOG = 'grafana-datadog-datasource',
  NEW_RELIC = 'grafana-newrelic-datasource',
}

export enum instructionsKey {
  GRAFANA_CLOUD_CLOUDFLARE = `grafana-cloud/${platformKey.CLOUDFLARE}`,
  GRAFANA_CLOUD_HEROKU = `grafana-cloud/${platformKey.HEROKU}`,
  GRAFANA_CLOUD_KAFKA = `grafana-cloud/${platformKey.KAFKA}`,

  DATA_SOURCE_FLUENT_BIT = `data-source/${platformKey.FLUENT_BIT}`,
  DATA_SOURCE_FLUENT_D = `data-source/${platformKey.FLUENT_D}`,
  DATA_SOURCE_PROMTAIL = `data-source/${platformKey.PROMTAIL}`,
}

export enum GrafanaCloudKeys {
  grafanaCloudGcp = `${LogsKeys.grafanaCloud}-gcp`,
  grafanaCloudFireHose = `${LogsKeys.grafanaCloud}-firehose`,
  grafanaCloudAzure = `${LogsKeys.grafanaCloud}-azure`,
  grafanaCloudHeroku = `${LogsKeys.grafanaCloud}-heroku`,
  grafanaCloudCloudflare = `${LogsKeys.grafanaCloud}-cloudflare`,
  grafanaCloudK8 = `${LogsKeys.grafanaCloud}-k8`,
  grafanaCloudDocker = `${LogsKeys.grafanaCloud}-docker`,
  grafanaCloudLinux = `${LogsKeys.grafanaCloud}-linux`,
  grafanaCloudWindows = `${LogsKeys.grafanaCloud}-windows`,
  grafanaCloudKafka = `${LogsKeys.grafanaCloud}-kafka`,
  grafanaCloudHttpLogs = `${LogsKeys.grafanaCloud}-httplogs`,
}

export enum ExistingSourceKeys {
  existingSourceLoki = `${LogsKeys.existingSource}-${platformKey.LOKI}`,
  existingSourceElastic = `${LogsKeys.existingSource}-${platformKey.ELASTIC}`,
  existingSourceCloudwatch = `${LogsKeys.existingSource}-${platformKey.CLOUDWATCH}`,
  existingSourceOracle = `${LogsKeys.existingSource}-${platformKey.ORACLE}`,
  existingSourceGcl = `${LogsKeys.existingSource}-${platformKey.GCP_DATASOURCE}`,
  existingSourceAlloy = `${LogsKeys.existingSource}-${platformKey.ALLOY}`,
  existingSourceOtel = `${LogsKeys.existingSource}-${platformKey.OTEL}`,
  existingSourcefluentd = `${LogsKeys.existingSource}-${platformKey.FLUENT_D}`,
  existingSourcefluentbit = `${LogsKeys.existingSource}-${platformKey.FLUENT_BIT}`,
  existingSourcepromtail = `${LogsKeys.existingSource}-${platformKey.PROMTAIL}`,
  existingSourcesumologic = `${LogsKeys.existingSource}-${platformKey.SUMO_LOGIC}`,
  existingSourceSplunk = `${LogsKeys.existingSource}-${platformKey.SPLUNK}`,
  existingSourceDatadog = `${LogsKeys.existingSource}-${platformKey.DATA_DOG}`,
  existingSourceNewrelic = `${LogsKeys.existingSource}-${platformKey.NEW_RELIC}`,
}

export type DataConnectionData = { [key in LogsKeys]: { [key: string]: LogsCardData[] } };

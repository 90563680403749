import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

enum CloudHomeFeatureToggle {
  newCloudHomeRedesign = 'newCloudHomeRedesign',
}

type CloudHomeFeatureToggles = FeatureToggles & {
  [key in CloudHomeFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: CloudHomeFeatureToggle): boolean {
  return (config.featureToggles as CloudHomeFeatureToggles)[featureName] ?? false;
}

export function isNewCloudHomeRedesignEnabled(): boolean {
  return isFeatureToggleEnabled(CloudHomeFeatureToggle.newCloudHomeRedesign);
}

export function isCloudRBACRolesEnabled(): boolean {
  return config.featureToggles.cloudRBACRoles ?? false;
}

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getHeaderGetStartedStyles = (theme: GrafanaTheme2) => {
  return {
    headerWrapper: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    `,
    headerLogo: css`
      filter: brightness(0) invert(1);
      width: 110px;
    `,
    getStartedText: css`
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    getStartedBtn: css`
      margin-left: 10px;
    `,
  };
};

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Tab, TabContent, TabsBar, useStyles2 } from '@grafana/ui';

import { getDashboardSectionStyles } from 'feature/home/components/refresh-design/DashboardSection/DashboardSection.styles';
import { PanelRenderer } from '@grafana/runtime';
import { EMPTY_PANEL_DATA } from 'feature/common/consts/panels';
import { DashboardMostRecentEmpty } from 'feature/home/components/refresh-design/DashboardSection/DashboardMostRecentEmpty';
import { DashboardStarredItems } from 'feature/home/components/refresh-design/DashboardSection/DashboardStarredItems';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { ExploreDashboards } from 'feature/home/components/refresh-design/ExploreDashboards';
import {
  DASHBOARD_TABS,
  getDashboardsTabs,
} from 'feature/home/components/refresh-design/DashboardSection/DashboardSection.utils';
import { DashboardTab } from 'feature/home/components/refresh-design/DashboardSection/DashboardSection.types';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

export const DashboardSection = () => {
  const styles = useStyles2(getDashboardSectionStyles);
  const panelRendererRef = useRef<HTMLDivElement>(null);
  const { orgId } = useContext(AppContext);

  const [tabsState, setTabsState] = useState<DashboardTab[]>(DASHBOARD_TABS);
  const [dashboardLen, setDashboardLen] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const checkMostRecentDashboards = useCallback(() => {
    if (panelRendererRef.current) {
      const list = panelRendererRef.current.querySelector('ul');
      if (list) {
        const items = list.querySelectorAll('li');
        if (dashboardLen !== items.length) {
          setDashboardLen(items.length);
        }
      }
    }
  }, [dashboardLen]);

  useEffect(() => {
    // wait until panel finish rendering
    const timeout = setTimeout(() => checkMostRecentDashboards(), 500);

    return () => clearTimeout(timeout);
  }, [tabsState, checkMostRecentDashboards]);

  useEffect(() => {
    if (loading && dashboardLen !== undefined) {
      const updatedTabs = getDashboardsTabs(dashboardLen);
      setTabsState(updatedTabs);
      setLoading(false);

      const tabId = (updatedTabs.find((tab) => tab.active) ?? updatedTabs[0]).id;
      trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.Dashboards.DashboardTab(tabId), ActionType.click, orgId);
    }
  }, [dashboardLen, loading, orgId]);

  function onTabSelected(tab: DashboardTab, index: number) {
    setTabsState(tabsState.map((tab, idx) => ({ ...tab, active: idx === index })));
    setDashboardLen(undefined);
    trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.Dashboards.DashboardTab(tab.id), ActionType.click, orgId);
  }

  const renderMostRecentContent = () => {
    if (dashboardLen === 0) {
      return <DashboardMostRecentEmpty />;
    } else {
      return (
        <div className={styles.dashboardPanel} ref={panelRendererRef}>
          <PanelRenderer
            title=""
            width={1000}
            height={1000}
            pluginId="dashlist"
            data={EMPTY_PANEL_DATA}
            options={{
              maxItems: 20,
              showHeadings: false,
              showRecentlyViewed: true,
              showSearch: false,
              showStarred: false,
            }}
          />
        </div>
      );
    }
  };

  return (
    <div data-testid={DataTestId.DASHBOARDS_CONTAINER_ID}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerTitle}>Dashboards</div>
      </div>
      <TabsBar>
        {tabsState.map((tab, index) => (
          <Tab key={index} label={tab.label} active={tab.active} onChangeTab={() => onTabSelected(tab, index)} />
        ))}
      </TabsBar>
      {tabsState[0].active && <TabContent className={styles.tabsContent}>{renderMostRecentContent()}</TabContent>}
      {tabsState[1].active && (
        <TabContent className={styles.tabsContent}>
          <div className={styles.dashboardPanel}>
            <DashboardStarredItems />
          </div>
        </TabContent>
      )}
      {tabsState[2].active && (
        <TabContent className={styles.tabsContent}>
          <ExploreDashboards />
        </TabContent>
      )}
    </div>
  );
};

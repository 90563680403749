import { useStyles2 } from '@grafana/ui';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { getTrackOriginFromPath } from 'feature/onboarding/utils/OnboardingCard';
import {
  BlocksData,
  ExistingSourceKeys,
  GrafanaCloudKeys,
  LogsCardData,
  LogsKeys,
} from 'feature/onboarding/types/LogsOnboardingTypes';
import { getLogsCardStyles } from './LogsCard.styles';

function LogCardBody({ block }: { block: BlocksData }): JSX.Element {
  const styles = useStyles2((theme) => getLogsCardStyles(theme));
  return (
    <div className={styles.block(block.frame, block.width)}>
      {block.topImage && <img src={block.topImage as unknown as string} alt="" />}
      {block.title}
      <img src={block.image as unknown as string} alt="" />
    </div>
  );
}

export function LogsCard<T extends LogsKeys | GrafanaCloudKeys | ExistingSourceKeys | undefined>({
  card,
  selectedCard,
  setSelectedCard,
}: {
  card: LogsCardData;
  selectedCard: T;
  setSelectedCard: (key: T) => void;
}): JSX.Element {
  const styles = useStyles2((theme) => getLogsCardStyles(theme));
  const { orgId } = useContext(AppContext);
  const navigate = useNavigate();
  function onCardClick(): void {
    setSelectedCard(card.key as T);

    if (card.redirectUrl) {
      navigate(card.redirectUrl);
    }

    const origin = getTrackOriginFromPath();
    trackAction(
      FlowEventName.LOGS_ONBOARDING_EVENT_NAME as FlowEventName,
      card.key,
      ActionType.click,
      orgId,
      origin ? { origin } : undefined
    );
  }
  return (
    <div
      className={styles.wrapper(card.key === selectedCard)}
      onClick={onCardClick}
      onKeyDown={onCardClick}
      tabIndex={0}
      // not ideal to turn a div into a button. temp solution. do not do in the future
      role="button"
    >
      <h2 className={styles.title}>
        {card.icon && <img src={card.icon as unknown as string} alt={card.title} />}
        {card.title}
        <h4>{card.text}</h4>
      </h2>
      {card.blocks && (
        <div className={styles.bodyWrapper(!!selectedCard)}>
          <div className={styles.body}>
            {card.blocks.map((block) => (
              <LogCardBody block={block} key={block.key} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

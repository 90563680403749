import { configureStore } from '@reduxjs/toolkit';
import { UsageApi } from 'feature/usage/api/UsageApi';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux';
import { ABTestingApi } from 'feature/common/api/ABTestingApi';
import { BillingDashboardApi } from 'feature/common/api/BillingDashboardApi';
import { UsersApi } from 'feature/common/api/UsersApi';
import { DashboardsApi } from 'feature/common/api/DashboardsApi';
import { DataSourcesApi } from 'feature/common/api/DataSourcesApi';
import { GrafanaComApi } from 'feature/common/api/GrafanaComApi';
import { PluginsApi } from 'feature/common/api/PluginsApi';
import { IntegrationsApi } from 'feature/common/api/IntegrationsApi';
import { appContext } from './context';

export const store = configureStore({
  reducer: {
    [UsageApi.reducerPath]: UsageApi.reducer,
    [BillingDashboardApi.reducerPath]: BillingDashboardApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [ABTestingApi.reducerPath]: ABTestingApi.reducer,
    [DashboardsApi.reducerPath]: DashboardsApi.reducer,
    [DataSourcesApi.reducerPath]: DataSourcesApi.reducer,
    [GrafanaComApi.reducerPath]: GrafanaComApi.reducer,
    [PluginsApi.reducerPath]: PluginsApi.reducer,
    [IntegrationsApi.reducerPath]: IntegrationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(UsageApi.middleware)
      .concat(ABTestingApi.middleware)
      .concat(BillingDashboardApi.middleware)
      .concat(UsersApi.middleware)
      .concat(DashboardsApi.middleware)
      .concat(DataSourcesApi.middleware)
      .concat(GrafanaComApi.middleware)
      .concat(PluginsApi.middleware)
      .concat(IntegrationsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = createDispatchHook<AppState>(appContext);
export const useAppSelector: TypedUseSelectorHook<AppState> = createSelectorHook(appContext);

import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, useStyles2 } from '@grafana/ui';
import { DataSourceDTO, DatasourceType } from 'feature/common/types/Datasources';
import { useUpdateDatasourceMutation } from 'feature/common/api/DataSourcesApi';
import { getDBConfigFormStyles } from 'feature/datasource-config/components/DBConfigForm.styles';
import { DatasourceConfigErrors } from 'feature/datasource-config/components/DatasourceConfigErrors';
import { DBConfigFooter } from 'feature/datasource-config/components/DBConfigFooter/DBConfigFooter';
import { AlertLine } from 'feature/common/components/AlertLine/AlertLine';
import { useUpdateDatasourceConfig } from 'feature/datasource-config/hooks/useUpdateDatasourceConfig';
import { useErrorDatasourceConfig } from 'feature/datasource-config/hooks/useErrorDatasourceConfig';
import { QueryError } from 'feature/common/types/QueryError';
import {
  getDefaultValuesForDBConfig,
  registerOtherFields,
  updateDatasourceConfig,
} from 'feature/datasource-config/utils/datasourceConfigUtils';
import { DatasourcesProps } from 'feature/datasource-config/types/DatasourceConfig';
import DatasourceFormGroups from 'feature/datasource-config/components/DatasourceFormGroups';
import { AppContext } from 'App';

export const DatasourceConfigForm: React.FC<DatasourcesProps> = ({ name, uid, id, data, onSaved, version }) => {
  const styles = useStyles2(getDBConfigFormStyles);
  const [update, { data: updatedDatasource, error, isLoading, isSuccess }] = useUpdateDatasourceMutation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [firstUpdatedCompleted, setFirstUpdatedCompleted] = useState<boolean>(false);
  const { orgId } = useContext(AppContext);

  const defaultValues: DataSourceDTO = getDefaultValuesForDBConfig(data.datasource as DatasourceType, id, uid, name);

  useEffect(() => setFirstUpdatedCompleted(true), []);
  useUpdateDatasourceConfig({ isSuccess, updatedDatasource, onSaved });
  useErrorDatasourceConfig(error as QueryError, setErrorMessage);

  // Product wants the system errors (e.g. DS with name already exists) to disappear when user starts
  // typing again and only reappear on submit. But since we're using react-hook-form's Form component
  // but not destructuring the formMethods from the RHF hook, I've created this artificial clearErrors
  // rather than using RHF's clearErrors, as one would normally
  const clearErrors = () => {
    setErrorMessage('');
  };

  return (
    <Form<DataSourceDTO>
      onSubmit={(ds) => updateDatasourceConfig(ds, orgId, update, data.buttons.save.key)}
      defaultValues={defaultValues}
      onChange={clearErrors}
      className={version === 'getting-started' ? styles.formFullWidth : undefined}
    >
      {(formApi) => {
        if (!firstUpdatedCompleted) {
          registerOtherFields(data.datasource, formApi);
        }

        return (
          <>
            <DatasourceFormGroups
              datasource={data.datasource}
              defaultVersion={defaultValues.version}
              formApi={formApi}
            />
            <DBConfigFooter
              uid={uid}
              orgId={orgId}
              datasource={data.datasource}
              requiredFields={true}
              version={version}
            />
            {errorMessage && (
              <div className={styles.formGroup}>
                {
                  <DatasourceConfigErrors
                    uid={uid}
                    errorMessages={[errorMessage]}
                    title="DS creation failed"
                    onRemoveFn={() => setErrorMessage('')}
                  />
                }
              </div>
            )}
            <Button type="submit" disabled={formApi.formState.isSubmitting || isLoading || isSuccess}>
              {isLoading ? 'Saving...' : data?.buttons.save.text ?? 'Save'}
            </Button>
            {isSuccess && data?.successMessage && (
              <AlertLine status="success" className={styles.alertSuccess}>
                {data.successMessage}
              </AlertLine>
            )}
          </>
        );
      }}
    </Form>
  );
};

import React, { useContext } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';
import { useGetBillingDashboardQuery } from 'feature/common/api/BillingDashboardApi';
import { Icon, IconTypes } from '../../common/components/icons';
import { DataTestId } from '../../common/types/test-id.enum';
import { getStyles } from './AccountManagement.styles';
import { DashboardResponse } from 'feature/common/types/Dashboard';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { getGrafanaDomain } from 'feature/home/components/refresh-design/helpers';

const orgLink = `${getGrafanaDomain()}/profile/org?src=hg_home`;

const getLinks = (billingDashboard: DashboardResponse): AccountManagementLink[] => {
  const domain = getGrafanaDomain();

  return [
    {
      id: 'stacks',
      text: 'Stacks',
      icon: IconTypes.BRIEFCASE,
      href: orgLink,
      target: '_blank',
      visible: true,
    },
    {
      id: 'user',
      text: 'Users',
      icon: IconTypes.USERS,
      href: `${domain}/profile/org/members?src=hg_home`,
      target: '_blank',
      visible: true,
    },
    {
      id: 'access-policies',
      text: 'Access Policies',
      icon: IconTypes.SHIELD,
      href: `${domain}/profile/org/access-policies`,
      target: '_blank',
      visible: true,
    },
    {
      id: 'billing-dashboard',
      text: 'Billing/Usage dashboard',
      href: billingDashboard?.url || '',
      icon: IconTypes.BILLING,
      target: '_self',
      visible: !!billingDashboard,
    },
    {
      id: 'subscription',
      text: 'Manage subscription',
      icon: IconTypes.BILL,
      href: `${domain}/profile/org/subscription`,
      target: '_blank',
      visible: true,
    },
  ];
};

interface AccountManagementLink {
  id: string;
  text: string;
  icon: IconTypes;
  href: string;
  target: React.HTMLAttributeAnchorTarget;
  visible: boolean;
}

export const AccountManagement = () => {
  const styles = useStyles2(getStyles);
  const { data: billingDashboard } = useGetBillingDashboardQuery();
  const { orgId } = useContext(AppContext);

  function onComponentClick(componentKey: string): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, componentKey, ActionType.click, orgId);
  }

  return (
    <div data-testid={DataTestId.ACCOUNT_MANAGEMENT_CONTAINER_ID}>
      <ul data-testid={DataTestId.ACCOUNT_MANAGEMENT_LIST_ID} className={styles.links}>
        {getLinks(billingDashboard)
          .filter((l) => l.visible && !!l.href)
          .map((link) => (
            <li key={link.text}>
              <LinkButton
                size={'md'}
                className={styles.link}
                target={link.target}
                fill="text"
                href={link.href}
                onClick={() => onComponentClick(`account_management_${link.id}_link`.replace('-', '_'))}
              >
                {link.icon && <Icon logo={link.icon} />}
                <span className={styles.linkText}>{link.text}</span>
              </LinkButton>
            </li>
          ))}
      </ul>
    </div>
  );
};

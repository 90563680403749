import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getHomepageContentStyles = (theme: GrafanaTheme2) => {
  return {
    box: css`
      display: flex;
      flex-direction: column;
      background-color: ${theme.colors.background.canvas};
      border-radius: 12px;
      padding: 2rem;
      height: 100%;
    `,
    body: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(3)};
    `,
    usageContainer: css`
      margin-top: ${theme.spacing(1)};
    `,
  };
};

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getAccountStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${theme.spacing(2)};

      @media (min-width: 768px) and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    `,
    headerTitle: css`
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 1rem;
      color: ${theme.colors.text.maxContrast};
    `,
    card: css`
      width: 100%;
      background-color: ${theme.colors.background.primary};
      border-radius: 4px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      color: ${theme.colors.text.primary};
      gap: ${theme.spacing(1)};
    `,
    docsRows: css`
      display: flex;
      flex-direction: column;
    `,
    usersRow: css`
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(4)};
    `,
    userColumn: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    link: css`
      color: ${theme.colors.primary.text};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    resourcesLinks: css`
      color: ${theme.colors.text.maxContrast};
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.0015em;

      :hover {
        text-decoration: underline;
      }
    `,
    standOutText: css`
      font-size: 22px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.colors.text.maxContrast};
    `,
    primaryText: css`
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    secondaryText: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
      color: ${theme.colors.text.secondary};
      margin-left: 10px;
      align-self: self-end;
    `,
    orgUsersWrapper: css`
      display: flex;
      justify-content: start;
    `,
    planBadge: css`
      color: ${theme.colors.text.secondary};
      border-color: ${theme.colors.text.secondary};
      background-color: transparent;
      margin-right: 16px;
      width: fit-content;
    `,
    trialBadge: css`
      max-width: 7rem;
      width: fit-content;
      border-color: ${theme.colors.text.secondary};
      background-color: transparent;
      color: ${theme.colors.text.secondary};
      margin-left: 10px;
    `,
    circle: css`
      border-radius: 50%;
      width: 8px;
      height: 8px;
      align-self: center;
      margin: 2px 4px 2px 3px;

      &.grey {
        background-color: ${theme.colors.border.medium};
      }

      &.green {
        background-color: ${theme.colors.success.text};
      }

      &.yellow {
        background-color: ${theme.colors.warning.text};
      }

      &.red {
        background-color: ${theme.colors.error.main};
      }
    `,
    trialText: css`
      margin-left: 2px;
      display: block;
    `,
    trialDays: css`
      display: flex;
    `,
    planWrapper: css`
      display: flex;
    `,
  };
};

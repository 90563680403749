import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getOnboardingCardStyles = (theme: GrafanaTheme2, rowStyle: 'row-flex' | 'column-flex') => ({
  wrapper: (image: string | undefined, isInModal?: boolean) => css`
    display: flex;
    flex-flow: column;
    gap: ${image ? '0px' : '10px'};
    background-color: ${theme.colors.background.secondary};
    padding: ${image ? theme.spacing(2) : theme.spacing(3)};
    border: ${theme.isLight ? `1px solid ${theme.colors.border.weak}` : 'unset'};
    border-radius: ${theme.shape.radius.default};
    box-sizing: border-box;
    width: auto;
    position: relative;
    ${isInModal !== undefined && isInModal === true
      ? `width: 240px; height: 187px;`
      : `min-width: 247px;     height: 100%;
    `}
    cursor: ${image !== undefined && 'pointer'};

    &:hover {
      background: ${theme.colors.emphasize(theme.colors.background.secondary, 0.03)};
      cursor: pointer;
      z-index: 1;
    }
  `,
  headerWrapper: (titleAlign: string | undefined = 'space-between') => css`
    display: flex;
    justify-content: ${titleAlign};
    align-items: center;
    position: relative;
    gap: ${theme.spacing(1.5)};

    h2 {
      flex: ${titleAlign === 'center' ? 'unset' : 'flex'};
    }

    div[class$='-Icon'][aria-label='Card Selected'] {
      display: flex;
    }

    input.selected {
      position: absolute;
      top: 0;
      right: 0;
    }
  `,
  headerColumnWrapper: css`
    position: relative;

    div[class$='-Icon'][aria-label='Card Selected'] {
      display: flex;
    }

    input.selected {
      position: absolute;
      right: 0;
    }
  `,
  iconsWrapper: css`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: ${theme.spacing(2.5)};

    img {
      margin-bottom: ${rowStyle === 'row-flex' ? 0 : ''};
    }
  `,
  title: (titleAlign: string | undefined = '', isInModal?: boolean) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    flex: 1;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: ${titleAlign};
    ${isInModal !== undefined && isInModal === true ? `font-size: 14px;` : `font-size: 16px;`}

    h4 {
      margin-bottom: 0;
    }
  `,
  titleWithInfo: css`
    display: inline-block;
    gap: ${theme.spacing(1)};
    width: 100%;
    align-items: center;
  `,
  text: css`
    font-size: ${theme.typography.body.fontSize};
    margin-bottom: 0;
    color: ${theme.colors.text.secondary};

    > ul {
      margin-left: ${theme.spacing(2)};
    }
  `,
  textIconAlign: css`
    padding-left: ${theme.spacing(6)};
  `,
  badgeWrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};

    button {
      padding-left: 0;

      &:hover {
        background-color: transparent;
      }
    }
  `,
  badgeIconAlign: css`
    padding-left: ${theme.spacing(6)};
  `,
  badge: css`
    background-color: transparent;
    color: ${theme.colors.text.primary};
    border: 1px solid ${theme.colors.border.medium};
    border-radius: 2px;
  `,
  infoIconCt: css`
    position: relative;
    margin-left: -13px;
    background-color: transparent;
    border: 0;
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
  `,
  infoIcon: css`
    color: ${theme.colors.text.primary};
    position: relative;
    margin-left: 5px;
  `,
  buttonsWrapper: css`
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    padding-right: 10px;
  `,
  link: css`
    color: ${theme.colors.text.link};
  `,
  linkList: css`
    list-style-type: none;
  `,
  cardSelected: css`
    outline: solid 2px ${theme.colors.primary.border};
    border-radius: 2px;
  `,
  cardSelectedIcon: css`
    color: ${theme.colors.text.link};
    position: absolute;
    right: ${theme.spacing(3)};
    top: ${theme.spacing(3)};
  `,
  figure: css`
    align-self: flex-start !important;

    > div[class$='-Icon'] {
      svg {
        fill: ${theme.v1.palette.orange};
      }
    }

    img {
      margin-bottom: 0;
    }
  `,
  cardButtons: css`
    margin-top: 0;

    button {
      padding-left: 0;
    }
  `,
});

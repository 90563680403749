import React, { FC, useContext } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import kubernetesLogo from 'img/kubernetes-logo.svg';
import onCallLogo from 'img/oncall-logo.svg';
import k6Logo from 'img/k6-app.svg';
import { trackAction, ActionType } from 'feature/common/utils/tracking';

import { getStyles } from './IntegrationCard.styles';
import { AppContext } from 'App';

const images: { [key: string]: JSX.Element } = {
  kubernetesLogo: <img src={kubernetesLogo} alt={'Kubernetes'} />,
  oncallLogo: <img src={onCallLogo} alt={'OnCall'} />,
  k6Logo: <img src={k6Logo} alt={'k6'} />,
};

interface CardButtonProps {
  description: string;
  href: string;
  logo: string;
  title: string;
  btnText: string;
  id?: string;
}

export const IntegrationCard: FC<CardButtonProps> = ({ href, logo, title, description, btnText, id }) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, key, ActionType.click, orgId);
  }

  const cardKey =
    id === 'kubernetes'
      ? ComponentKey.KubernetesFromHomeApp
      : id === 'oncall'
        ? ComponentKey.OncallFromHomeApp
        : ComponentKey.K6FromHomeApp;

  return (
    <div className={styles.card}>
      <div className={styles.iconAndDescrip}>
        <div className={styles.logoWrapper}>{images[logo]}</div>
        <div className={styles.titleWrapper}>
          <div className={styles.header}>{title}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <LinkButton
          className={styles.button}
          target="_blank"
          variant="secondary"
          href={href}
          onClick={() => onComponentClick(cardKey)}
        >
          {btnText}
        </LinkButton>
      </div>
    </div>
  );
};

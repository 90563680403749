import { UsageData, UsageState } from 'feature/usage/types/UsageData';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';
import { useGetUsersQuery } from 'feature/common/api/UsersApi';
import { useGetUsageQuery } from 'feature/usage/api/UsageApi';
import {
  IRM_USAGE,
  K6_USAGE,
  LOGS_USAGE,
  METRICS_USAGE_FREE,
  METRICS_USAGE_PRO,
  PROFILES_USAGE,
  TRACES_USAGE,
  USERS_USAGE,
  FEO11Y_USAGE,
  APPO11Y_USAGE,
  SYNTHETIC_MONITORING_USAGE,
} from 'feature/usage/consts/usage';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { useGetBillingDashboardQuery } from 'feature/common/api/BillingDashboardApi';

export const useUsageData = (): UsageState => {
  const {
    isAdmin,
    isLoading: isLoadingCurrentUser,
    error: errorCurrentUser,
    isSuccess: isSuccessCurrentUser,
    isError: isErrorCurrentUser,
  } = useCurrentUser();

  const {
    data: orgInfoData,
    isLoading: isLoadingOrgInfo,
    error: errorOrgInfo,
    isSuccess: isSuccessOrgInfo,
    isError: isErrorOrgInfo,
  } = useOrgInfoData(isAdmin === false);
  const isFreeTier = isLoadingOrgInfo ? undefined : orgInfoData?.orgPlan === Tiers.Free;

  const { data: billingDashboard, isLoading: isLoadingBillingDashboard } = useGetBillingDashboardQuery();

  const {
    data: usersData,
    isLoading: isTotalUsersLoading,
    error: totalUsersError,
    isSuccess: isSuccessTotalUsers,
    isError: isErrorTotalUsers,
  } = useGetUsersQuery(undefined, { skip: isFreeTier === undefined || isFreeTier === false });

  const {
    data: usersUsageData,
    isLoading: isLoadingUsersUsage,
    error: errorUsersUsage,
    isSuccess: isSuccessUsersUsage,
    isError: isErrorUsersUsage,
  } = useGetUsageQuery(USERS_USAGE, { skip: isFreeTier === undefined || isFreeTier === true });

  const usersUsage = isFreeTier !== undefined ? (isFreeTier === true ? usersData?.length : usersUsageData) : -1;
  const isLoadingUsers =
    isFreeTier !== undefined ? (isFreeTier === true ? isTotalUsersLoading : isLoadingUsersUsage) : false;
  const isSuccessUsers =
    isFreeTier !== undefined ? (isFreeTier === true ? isSuccessTotalUsers : isSuccessUsersUsage) : true;
  const isErrorUsers = isFreeTier !== undefined ? (isFreeTier === true ? isErrorTotalUsers : isErrorUsersUsage) : false;
  const errorUsers = isFreeTier !== undefined ? (isFreeTier === true ? totalUsersError : errorUsersUsage) : undefined;

  const {
    data: metricsFreeData,
    isLoading: isLoadingMetricFree,
    error: errorMetricsFree,
    isSuccess: isSuccessMetricsFree,
    isError: isErrorMetricsFree,
  } = useGetUsageQuery(METRICS_USAGE_FREE, { skip: isFreeTier === undefined || isFreeTier === false });

  const {
    data: metricsProData,
    isLoading: isLoadingMetricPro,
    error: errorMetricsPro,
    isSuccess: isSuccessMetricsPro,
    isError: isErrorMetricsPro,
  } = useGetUsageQuery(METRICS_USAGE_PRO, { skip: isFreeTier === undefined || isFreeTier === true });

  const metricsUsage = isFreeTier !== undefined ? (isFreeTier === true ? metricsFreeData : metricsProData) : -1;
  const isLoadingMetrics =
    isFreeTier !== undefined ? (isFreeTier === true ? isLoadingMetricFree : isLoadingMetricPro) : false;
  const isSuccessMetrics =
    isFreeTier !== undefined ? (isFreeTier === true ? isSuccessMetricsFree : isSuccessMetricsPro) : true;
  const isErrorMetrics =
    isFreeTier !== undefined ? (isFreeTier === true ? isErrorMetricsFree : isErrorMetricsPro) : false;
  const errorMetrics =
    isFreeTier !== undefined ? (isFreeTier === true ? errorMetricsFree : errorMetricsPro) : undefined;

  const {
    data: logsUsage,
    isLoading: isLoadingLogs,
    error: errorLogs,
    isSuccess: isSuccessLogs,
    isError: isErrorLogs,
  } = useGetUsageQuery(LOGS_USAGE);

  const {
    data: tracesUsage,
    isLoading: isLoadingTraces,
    error: errorTraces,
    isSuccess: isSuccessTraces,
    isError: isErrorTraces,
  } = useGetUsageQuery(TRACES_USAGE);

  const {
    data: k6Usage,
    isLoading: isLoadingK6,
    error: errorK6,
    isSuccess: isSuccessK6,
    isError: isErrorK6,
  } = useGetUsageQuery(K6_USAGE);

  const {
    data: profilesUsage,
    isLoading: isLoadingProfiles,
    error: errorProfiles,
    isSuccess: isSuccessProfiles,
    isError: isErrorProfiles,
  } = useGetUsageQuery(PROFILES_USAGE);

  const {
    data: irmUsage,
    isLoading: isLoadingIrm,
    error: errorIrm,
    isSuccess: isSuccessIrm,
    isError: isErrorIrm,
  } = useGetUsageQuery(IRM_USAGE);

  const {
    data: feO11YUsage,
    isLoading: isLoadingFeo11y,
    error: errorFeo11y,
    isSuccess: isSuccessFeo11y,
    isError: isErrorFeo11y,
  } = useGetUsageQuery(FEO11Y_USAGE);

  const {
    data: appO11YUsage,
    isLoading: isLoadingAppO11Y,
    error: errorAppO11Y,
    isSuccess: isSuccessAppO11Y,
    isError: isErrorAppO11Y,
  } = useGetUsageQuery(APPO11Y_USAGE);

  const {
    data: smUsage,
    isLoading: isLoadingSm,
    error: errorSm,
    isSuccess: isSuccessSm,
    isError: isErrorSm,
  } = useGetUsageQuery(SYNTHETIC_MONITORING_USAGE);

  const data: UsageData = {
    [MetricUnit.USERS]: usersUsage,
    [MetricUnit.METRICS]: metricsUsage,
    [MetricUnit.LOGS]: logsUsage,
    [MetricUnit.TRACES]: tracesUsage,
    [MetricUnit.K6_TESTS]: k6Usage,
    [MetricUnit.PROFILES]: profilesUsage,
    [MetricUnit.IRM]: irmUsage,
    [MetricUnit.FEO11Y]: feO11YUsage,
    [MetricUnit.APPO11Y]: appO11YUsage,
    [MetricUnit.SYNTHETIC_MONITORING]: smUsage,
  };

  return {
    isLoading:
      isLoadingBillingDashboard ||
      isLoadingOrgInfo ||
      isLoadingCurrentUser ||
      isLoadingUsers ||
      isLoadingMetrics ||
      isLoadingLogs ||
      isLoadingTraces ||
      isLoadingK6 ||
      isLoadingProfiles ||
      isLoadingIrm ||
      isLoadingFeo11y ||
      isLoadingAppO11Y ||
      isLoadingSm,
    isSuccess:
      isSuccessCurrentUser &&
      isSuccessOrgInfo &&
      isSuccessUsers &&
      isSuccessMetrics &&
      isSuccessLogs &&
      isSuccessTraces &&
      isSuccessK6 &&
      isSuccessProfiles &&
      isSuccessIrm &&
      isSuccessFeo11y &&
      isSuccessAppO11Y &&
      isSuccessSm,
    isError:
      isErrorCurrentUser ||
      isErrorOrgInfo ||
      isErrorUsers ||
      isErrorMetrics ||
      isErrorLogs ||
      isErrorTraces ||
      isErrorK6 ||
      isErrorProfiles ||
      isErrorIrm ||
      isErrorFeo11y ||
      isErrorAppO11Y ||
      isErrorSm,
    data,
    error:
      errorCurrentUser ||
      errorOrgInfo ||
      errorUsers ||
      errorMetrics ||
      errorLogs ||
      errorTraces ||
      errorK6 ||
      errorProfiles ||
      errorIrm ||
      errorFeo11y ||
      errorAppO11Y ||
      errorSm,
    isFree: isFreeTier,
    isAdmin,
    billingDashboard,
  };
};

import { formatStorageCapacity } from 'feature/usage/utils/bytes';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { externalPaths } from 'feature/common/consts/appPaths';
import { UsageInfo, UsageTooltip } from 'feature/usage/types/UsageInfo';
import { CircleColor } from 'feature/usage/types/CircleColor';
import { getMembersLink, getSubscriptionLink } from 'feature/home/components/refresh-design/helpers';
import { QueryError } from 'feature/common/types/QueryError';
import { CloudOrgData } from 'feature/common/types/CloudOrgData';
import { OrgUsageDetails } from 'feature/usage/types/OrgInfoData';
import { isFree } from 'feature/common/utils/orgInfoUtils';
import {
  IRM_FREE_INCLUDED,
  K6_FREE_INCLUDED,
  LOGS_FREE_INCLUDED,
  METRICS_FREE_INCLUDED,
  PROFILES_FREE_INCLUDED,
  TRACES_FREE_INCLUDED,
  USERS_FREE_INCLUDED,
  FEO11Y_FREE_INCLUDED,
  APPO11Y_FREE_INCLUDED,
  SYNTHETIC_MONITORING_FREE_INCLUDED,
} from 'feature/usage/consts/usage';

const displayGbShort = (usage: number): string => {
  return formatStorageCapacity(usage, 'GB').replace('bytes', 'B').replace(' ', '');
};

export function formatUsageValue(usage = 0, unit: MetricUnit, isFree: boolean): string {
  switch (unit) {
    case MetricUnit.LOGS:
    case MetricUnit.TRACES:
    case MetricUnit.PROFILES:
      return isFree ? displayGbShort(usage) : formatStorageCapacity(usage, 'GB');

    case MetricUnit.USERS:
      return usage.toLocaleString();

    case MetricUnit.METRICS:
      return handleCompactFormat(usage);

    case MetricUnit.FEO11Y:
      return `${handleCompactFormat(usage)} sessions`;

    case MetricUnit.APPO11Y:
      return `${handleCompactFormat(usage, MetricUnit.APPO11Y)} host hours`;

    case MetricUnit.K6_TESTS:
      return `${handleCompactFormat(usage)} VUh`;

    case MetricUnit.IRM:
      return handleCompactFormat(usage);

    case MetricUnit.SYNTHETIC_MONITORING:
      return `${handleCompactFormat(usage)}`;

    default:
      return `${usage}`;
  }
}

export function pluralizeWord(count: number, word: string, pluralWord = word + 's'): string {
  return [1, -1].includes(Number(count)) ? word : pluralWord;
}

export function handleCompactFormat(value = 0, unitType?: string): string {
  if (unitType === MetricUnit.APPO11Y) {
    return value >= 10000
      ? `${(value / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}k`
      : value.toLocaleString();
  }

  return value >= 1000
    ? `${
        value % 1000 > 0
          ? (value / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          : (value / 1000).toLocaleString()
      }k`
    : value.toLocaleString();
}

const NO_DATA = 'No data detected';
const LINK_NO_DATA = 'Connect data to get started →';
const UPGRADE_TOOLTIP = 'Nearing limit';
const UPGRADE_LINK = 'Upgrade to get unlimited usage →';

const getCircleColor = (usage = 0, limit = 0, unit: MetricUnit, free = false) => {
  let circleColor = CircleColor.GREEN;

  switch (unit) {
    case MetricUnit.TRACES:
    case MetricUnit.LOGS:
    case MetricUnit.PROFILES:
      if (usage === 0) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage >= limit - 10 && usage <= limit) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.METRICS:
      if (usage < 1) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage >= limit - 1000 && usage <= limit) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.K6_TESTS:
      if (usage === 0) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage >= limit - 100 && usage <= limit) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.IRM:
      if (usage === 0) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage === limit) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.USERS:
      if (usage <= 1) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage === limit) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.FEO11Y:
      if (usage < 1) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage >= limit - 5000) {
        circleColor = CircleColor.RED;
      }
      break;

    case MetricUnit.APPO11Y:
    case MetricUnit.SYNTHETIC_MONITORING:
      if (usage === 0) {
        circleColor = CircleColor.GREY;
      } else if (free && limit > 0 && usage >= limit) {
        circleColor = CircleColor.RED;
      }
      break;

    default:
      if (usage === 0) {
        circleColor = CircleColor.GREY;
      }
  }

  return circleColor;
};

const getRedTooltip = (metricUnit: MetricUnit, free: boolean, orgSlug?: string): UsageTooltip => {
  let tooltipTitle = '';
  let tooltipDescription = '';
  let tooltipUrl = '';

  if (free) {
    if (metricUnit === MetricUnit.USERS || metricUnit === MetricUnit.IRM) {
      tooltipTitle = 'User limit reached';
      tooltipDescription = 'Upgrade to add users →';
    } else if (metricUnit === MetricUnit.SYNTHETIC_MONITORING) {
      tooltipTitle = 'Usage limit reached';
      tooltipDescription = 'Upgrade to get unlimited test executions →';
    } else if (metricUnit === MetricUnit.APPO11Y) {
      tooltipTitle = 'Limit reached';
      tooltipDescription = 'Upgrade to get more host hours →';
    } else {
      tooltipTitle = UPGRADE_TOOLTIP;
      tooltipDescription = UPGRADE_LINK;
    }
    tooltipUrl = getSubscriptionLink(orgSlug);
  }

  return { title: tooltipTitle, description: tooltipDescription, url: tooltipUrl };
};

const getGrayTooltip = (metricUnit: MetricUnit, orgSlug?: string): UsageTooltip => {
  let tooltipTitle = NO_DATA;
  let tooltipDescription = LINK_NO_DATA;
  let tooltipUrl = externalPaths.integrationsAndConnections;

  switch (metricUnit) {
    case MetricUnit.PROFILES:
      tooltipUrl = externalPaths.profiles;
      break;

    case MetricUnit.K6_TESTS:
      tooltipTitle = 'No k6 test runs';
      tooltipDescription = 'Get started with performance testing →';
      tooltipUrl = externalPaths.k6Projects;
      break;

    case MetricUnit.IRM:
      tooltipTitle = 'No IRM users';
      tooltipDescription = 'Get started with IRM →';
      tooltipUrl = externalPaths.irmActiveUsers;
      break;

    case MetricUnit.USERS:
      tooltipTitle = 'No other users';
      tooltipDescription = 'Invite your team to join →';
      tooltipUrl = getMembersLink(orgSlug);
      break;

    case MetricUnit.FEO11Y:
      tooltipTitle = 'No frontend observability sessions';
      tooltipDescription = 'Get started with FE o11y →';
      tooltipUrl = externalPaths.feO11Y;
      break;

    case MetricUnit.APPO11Y:
      tooltipTitle = 'No application observability host hours';
      tooltipDescription = 'Get started with AppO11y →';
      tooltipUrl = externalPaths.applicationObservability;
      break;

    case MetricUnit.SYNTHETIC_MONITORING:
      tooltipTitle = 'No Synthetics test executions';
      tooltipDescription = 'Get started with Synthetic Monitoring →';
      tooltipUrl = externalPaths.syntheticMonitoringApp;
      break;
  }

  return { title: tooltipTitle, description: tooltipDescription, url: tooltipUrl };
};

const getTooltipInfo = (usage = 0, limit = 0, unit: MetricUnit, free: boolean, orgSlug?: string): UsageTooltip => {
  const greenTooltip: UsageTooltip = { title: '', description: '', url: '' };

  switch (unit) {
    case MetricUnit.TRACES:
    case MetricUnit.LOGS:
    case MetricUnit.PROFILES:
      if (usage < 1) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit - 10 && usage <= limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.METRICS:
      if (usage < 1) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit - 1000 && usage <= limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.FEO11Y:
      if (usage < 1) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit - 5000) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.K6_TESTS:
      if (usage === 0) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit - 100 && usage <= limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.APPO11Y:
      if (usage === 0) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.IRM:
      if (usage === 0) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage === limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.USERS:
      if (usage <= 1) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage === limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }

    case MetricUnit.SYNTHETIC_MONITORING:
      if (usage <= 1) {
        return getGrayTooltip(unit, orgSlug);
      } else if (free && limit > 0 && usage >= limit) {
        return getRedTooltip(unit, free, orgSlug);
      } else {
        return greenTooltip;
      }
  }
};

const getTitles = (unit: MetricUnit): string => {
  switch (unit) {
    case MetricUnit.METRICS:
      return 'Metrics';

    case MetricUnit.TRACES:
      return 'Traces';

    case MetricUnit.LOGS:
      return 'Logs';

    case MetricUnit.PROFILES:
      return 'Profiles';

    case MetricUnit.K6_TESTS:
      return 'K6 tests';

    case MetricUnit.IRM:
      return 'IRM active users';

    case MetricUnit.USERS:
      return 'Users';

    case MetricUnit.FEO11Y:
      return 'Frontend Observability';

    case MetricUnit.APPO11Y:
      return 'AppO11y';

    case MetricUnit.SYNTHETIC_MONITORING:
      return 'Synthetics';

    default:
      return '';
  }
};

export function getStatus(
  usage = 0,
  limit = 0,
  unit: MetricUnit,
  free: boolean,
  orgSlug?: string,
  error?: QueryError
): UsageInfo {
  const title = getTitles(unit);

  if (!!error) {
    return { title } as UsageInfo;
  }
  const circleColor = getCircleColor(usage, limit, unit, free);
  const { title: tooltip, description, url } = getTooltipInfo(usage, limit, unit, free, orgSlug);

  return {
    title,
    circleColor,
    link: { title: description, url },
    tooltip,
  };
}

const getUsageLimitOrDefault = (limitName: string, orgData: CloudOrgData, defaultLimit: number): number => {
  // @ts-ignore
  const orgLimit: number = orgData[limitName];

  if (isFree(orgData)) {
    return (orgLimit === 0 && defaultLimit) || orgLimit;
  }
  return orgLimit ?? 0;
};

export const getUsageDataFromOrg = (orgData: CloudOrgData | undefined): OrgUsageDetails | undefined => {
  if (!orgData) {
    return undefined;
  }

  const usageData = {
    [MetricUnit.USERS]: orgData.hgUsage,
    [MetricUnit.METRICS]: orgData.hmUsage,
    [MetricUnit.LOGS]: orgData.hlUsage,
    [MetricUnit.TRACES]: orgData.htUsage,
    [MetricUnit.K6_TESTS]: orgData.k6VuhUsage,
    [MetricUnit.PROFILES]: orgData.hpUsage,
    [MetricUnit.IRM]: orgData.irmUsage,
    [MetricUnit.FEO11Y]: orgData.feO11YUsage,
    [MetricUnit.APPO11Y]: orgData.appO11YUsage,
    [MetricUnit.SYNTHETIC_MONITORING]: orgData.smUsage,
  };
  const usageLimit = {
    [MetricUnit.USERS]: getUsageLimitOrDefault('hgIncludedUsers', orgData, USERS_FREE_INCLUDED),
    [MetricUnit.METRICS]: getUsageLimitOrDefault('hmIncludedSeries', orgData, METRICS_FREE_INCLUDED),
    [MetricUnit.LOGS]: getUsageLimitOrDefault('hlIncludedUsage', orgData, LOGS_FREE_INCLUDED),
    [MetricUnit.TRACES]: getUsageLimitOrDefault('htIncludedUsage', orgData, TRACES_FREE_INCLUDED),
    [MetricUnit.K6_TESTS]: getUsageLimitOrDefault('k6VuhIncludedUsage', orgData, K6_FREE_INCLUDED),
    [MetricUnit.PROFILES]: getUsageLimitOrDefault('hpIncludedUsage', orgData, PROFILES_FREE_INCLUDED),
    [MetricUnit.IRM]: getUsageLimitOrDefault('irmIncludedUsage', orgData, IRM_FREE_INCLUDED),
    [MetricUnit.FEO11Y]: getUsageLimitOrDefault('feO11YIncludedUsage', orgData, FEO11Y_FREE_INCLUDED),
    [MetricUnit.APPO11Y]: getUsageLimitOrDefault('appO11YIncludedUsage', orgData, APPO11Y_FREE_INCLUDED),
    [MetricUnit.SYNTHETIC_MONITORING]: getUsageLimitOrDefault(
      'smIncludedUsage',
      orgData,
      SYNTHETIC_MONITORING_FREE_INCLUDED
    ),
  };
  return { data: usageData, limit: usageLimit } as OrgUsageDetails;
};

import React, { FC } from 'react';

import { useStyles2 } from '@grafana/ui';

import { DataTestId } from 'feature/common/types/test-id.enum';
import { LogsUsage } from './LogsUsage';
import { getStyles } from './MetricsPanel.styles';
import { MetricsUsage } from './MetricsUsage';
import { TracesUsage } from './TracesUsage';
import { UpgradePrompt } from './UpgradePrompt';
import { UsersUsage } from './UsersUsage';
import { K6Usage } from './k6Usage';
import { ProfilesUsage } from 'feature/usage/components/ProfilesUsage';
import { IRMUsage } from 'feature/usage//components/IRMUsage';
import { useUsageData } from 'feature/usage/hooks/useUsageData';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { MetricUnit } from 'feature/usage/types/MetricUnit';

export const MetricsPanel: FC = () => {
  const styles = useStyles2(getStyles);

  const { isSuccess, isError, isLoading, data, isFree, isAdmin, billingDashboard } = useUsageData();

  if (isError) {
    return null;
  } else {
    return (
      <div data-testid={DataTestId.METRICS_CONTAINER_ID}>
        <div id={DataTestId.METRICS_BODY} className={styles.wrapper}>
          {isLoading && (
            <div data-testid={DataTestId.METRIC_LOADING_ID} style={{ width: '100%' }}>
              <LoadingIndicator />
            </div>
          )}
          {isSuccess && isFree !== undefined && (
            <div className={styles.cloudPlan}>
              <div className={styles.titleWrapper}>
                <h3>{isFree ? 'Current' : 'Billable'} usage</h3>
              </div>
            </div>
          )}
          {!isLoading && !!data && isFree !== undefined && (
            <>
              <div className={styles.metrics}>
                <UsersUsage
                  usage={data[MetricUnit.USERS] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <MetricsUsage
                  usage={data[MetricUnit.METRICS] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <LogsUsage
                  usage={data[MetricUnit.LOGS] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <TracesUsage
                  usage={data[MetricUnit.TRACES] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <ProfilesUsage
                  usage={data[MetricUnit.PROFILES] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <K6Usage
                  usage={data[MetricUnit.K6_TESTS] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
                <IRMUsage
                  usage={data[MetricUnit.IRM] as number}
                  isAdmin={isAdmin as boolean}
                  isFree={isFree}
                  billingDashboard={billingDashboard}
                />
              </div>
              <div className={styles.upgradeWrapper}>
                <UpgradePrompt />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { Button, useStyles2 } from '@grafana/ui';

import { AccessPolicyTokenStep } from 'feature/onboarding/components/LogsOnboarding/InstallationStep/AccessPolicyTokenStep';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { getSnippetStyles } from './snippet.styles';

export function FluentDBody({ hostedData }: { hostedData?: HostedDataDetails }): JSX.Element {
  const styles = useStyles2(getSnippetStyles);
  const [token, setToken] = useState('"YOUR_GRAFANA_CLOUD_ACCESS_POLICY_TOKEN"');

  const snippet =
    '\n<match **>\n  @type loki\n  url ' +
    (hostedData?.hlInstanceUrl || 'YOUR_GRAFANA_CLOUD_LOKI_PUSH_ENDPOINT') +
    '\n  username ' +
    (hostedData?.hlInstanceId || 'YOUR_GRAFANA_CLOUD_LOKI_USER_ID') +
    '\n  password ' +
    token +
    "\n  extra_labels {'job':'fluentd'}\n  <buffer>\n    flush_interval 10s\n    flush_at_shutdown true\n  </buffer>\n  buffer_chunk_limit 1m\n</match>";

  const snippetStr = '```bash' + snippet + '\n```';
  return (
    <div className={styles.container}>
      <h3>Step 3: Prepare your configuration file</h3>
      <span>
        To configure the Loki output plugin add `@type loki` as specified in the snippet below and paste it in your
        Fluentd Configuration.{' '}
        <a href="https://grafana.com/docs/loki/latest/send-data/fluentbit/#configuration-examples">Here</a> is an
        example of the full configuration file.
      </span>
      <AccessPolicyTokenStep token={token} setToken={setToken} />
      <br />
      <Markdown>{snippetStr}</Markdown>
      <Button className={styles.button} icon="copy" onClick={() => navigator.clipboard.writeText(snippet)}>
        Copy to clipboard
      </Button>
    </div>
  );
}

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { getGettingStartedStyles } from 'feature/onboarding/components/GettingStarted/GettingStarted.styles';
import { OnboardingVersion } from 'feature/onboarding/types/OnboardingVersion';

export const getOnboardingScreenStyles = (theme: GrafanaTheme2, version: OnboardingVersion) => {
  const getStartedStyles = getGettingStartedStyles(theme, version);

  return {
    ...{
      // hidden navbar and footer hack
      container: css`
        position: fixed;
        left: 0;
        top: 0;
        background: ${theme.colors.background.primary};
        z-index: 1025;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
      `,
    },
    ...getStartedStyles,
  };
};

import { IndividualStep } from 'feature/onboarding/types/CardData';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { OnboardingCardButton, OnboardingCardData } from 'feature/onboarding/types/OnboardingCard';
import { setupSteps } from 'feature/onboarding/data/SetupWizard.data';

export type SetupWizardStep = (typeof setupSteps)[number];

export enum SetupCustomIconName {
  PROMETHEUS = 'prometheus-color',
  GRAPHITE = 'graphite-color',
  LOKI = 'loki-color',
  KUBERNETES = 'kubernetes',
  MACOS = 'macos',
  WINDOWS = 'windows',
  LINUX = 'linux',
  RASPBERRY = 'raspberry-pi',
  DOCKER = 'docker',
  MYSQL = 'mysql',
  MONGODB = 'mongodb',
  INFLUXDB = 'influxdb',
  POSTGRESQL = 'postgresql',
  ELASTICSEARCH = 'elasticsearch',
  CLOUDWATCH = 'cloudwatch',
  SYNTHETIC_MONITORING = 'synthetic-monitoring',
  NODE = 'node',
  KAFKA = 'kafka',
  NOMAD = 'nomad',
  REDIS = 'redis',
}

export enum SetupImageName {
  CLOUDWATCH_DASHBOARD = 'cloudwatch-dashboard',
  DOCKER_DASHBOARD = 'docker-dashboard',
  KUBERNETES_DASHBOARD = 'kubernetes-dashboard',
  LINUX_DASHBOARD = 'linux-dashboard',
  MYSQL_DASHBOARD = 'mysql-dashboard',
  PROMETHEUS_DASHBOARD = 'prometheus-dashboard',
  RASPBERRY_DASHBOARD = 'raspberrypi-dashboard',
  REDIS_DASHBOARD = 'redis-dashboard',
  MACOS_DASHBOARD = 'macos-dashboard',
  NOMAD_DASHBOARD = 'nomad-dashboard',
  KAFKA_DASHBOARD = 'kafka-dashboard',
  NODE_DASHBOARD = 'node-dashboard',
  MONGO_DASHBOARD = 'mongodb-dashboard',
  SINGLE_LINUX_NODE = 'single-linux-node',
  MULTIPLE_LINUX_NODE = 'multiple-linux-node',
  SELF_HOSTED_METRICS = 'self-hosted-metrics',

  CITI_BIKES_NYC_DASH = 'citi-bikes-nyc-dashboard',
  LIFE_EXPECTANCY_DASH = 'life-expectancy-dashboard',
  PROMETHEUS_METRICS_OVERVIEW_DASH = 'prometheus-metrics-overview-dashboard',
  QUICKPIZZA_SRE_DEMODASHBOARD = 'quickpizza-sre-demodashboard',
  WEATHER_DEMODASHBOARD = 'weather-demodashboard',
  TIME_SERIES_VIZ_DASH = 'time-series-viz-dashboard',
  OPEN_ALERT_INCIDENTS_VIOLATIONS_DASH = 'open-alert-incidents-violations',
  KUBERNETES_MONITORING = 'kubernetes-monitoring',
  APPLICATION_OBSERVABILITY = 'application-observability',

  SRE_OVERVIEW_DASH = 'sre-overview-dash',
  WEATHER_DASH = 'weather-dash',
  K8S_MONITORING_DASH = 'k8s-monitoring-dash',
  APP_O11Y_DASH = 'app-o11y-dash',
  LOGS_DASH = 'logs-dash',
  PROFILING_DASH = 'profiling-dash',
}

export interface SetupWizardStepData {
  step: string;
  steps: IndividualStep[];
  header?: string;
  preamble?: string;
  cards: OnboardingCardData[];
  buttons: {
    skip?: OnboardingCardButton;
    back: OnboardingCardButton;
    more?: OnboardingCardButton;
  };
  path?: string;
  route: string;
  eventName: FlowEventName;
  component: JSX.Element | string;
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';
import { isEqual } from 'lodash';
import { Alert, Button, useStyles2 } from '@grafana/ui';

import { AppContext } from 'App';
import { getImportDataSourceConfigStyles } from 'feature/onboarding/components/ImportDashboard/ImportDataSourceConfig.styles';
import { useDashboards } from 'feature/common/hooks/useDashboards';
import { appPaths } from 'feature/common/consts/appPaths';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { CONNECTION_DATASOURCES, DatasourceResponse } from 'feature/common/types/Datasources';
import { DatasourceConfigPage } from 'feature/datasource-config/components/DatasourceConfigPage';
import { waitForElm } from 'feature/common/utils/misc';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import {
  formatErrorInvalidDatasource,
  getDatasourceType,
  isValidDatasourceType,
} from 'feature/onboarding/components/ImportDashboard/importDashboardUtils';

interface ImportDataSourceConfigProps {
  dashboardId?: string;
  setDataSource?: React.Dispatch<React.SetStateAction<any>>;
}

export const ImportDataSourceConfig: React.FC<ImportDataSourceConfigProps> = ({ dashboardId, setDataSource }) => {
  const styles = useStyles2((theme) => getImportDataSourceConfigStyles(theme));
  const { orgId } = useContext(AppContext);
  const { data: dashboardData, isError, isLoading, error, inputs } = useDashboards(dashboardId);
  const [dataSourceSaved, setDataSourceSaved] = useState<DatasourceResponse>();
  const [dataSourceInfo, setDataSourceInfo] = useState<{ type: string; valid: boolean; error?: string }>();
  const customDatasourceContainerRef = useRef<HTMLDivElement>(null);

  const onContinue = () => {
    window.location.href = `${appPaths.importDashboard}/configure-dashboard/${dashboardId}/${dataSourceSaved?.datasource.type}`;
  };

  function onDataSourceSaved(response: DatasourceResponse) {
    // store datasource locally
    setDataSourceSaved(response);

    if (!!setDataSource && !!response?.datasource) {
      setDataSource(response.datasource);
    }
  }

  useEffect(() => {
    trackAction(
      FlowEventName.IMPORT_DASHBOARD_EVENT_NAME,
      ComponentKey.OpenConfigureDataImportDashboard,
      ActionType.click,
      orgId
    );
    waitForElm('[aria-label="Datasource settings page basic settings"]').then(() => {
      customDatasourceContainerRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
    });
  }, [orgId]);

  const validateDataSource = useCallback(() => {
    const valid = isValidDatasourceType(inputs);
    const type = getDatasourceType(inputs);
    const errorMessage = formatErrorInvalidDatasource(valid, type);
    const newDataSourceInfo = { type, valid, error: errorMessage };
    if (!isEqual(newDataSourceInfo, dataSourceInfo)) {
      setDataSourceInfo(newDataSourceInfo);
      if (!valid) {
        trackAction(
          FlowEventName.IMPORT_DASHBOARD_EVENT_NAME,
          ComponentKey.ImportDashboardFlowError,
          ActionType.click,
          orgId,
          { message: errorMessage }
        );
      }
    }
  }, [orgId, dataSourceInfo, inputs]);

  useEffect(() => {
    if (!isLoading) {
      validateDataSource();
    }
  }, [isLoading, validateDataSource]);

  return (
    <div className={styles.container}>
      <div className={cx(styles.wrapper)}>
        <div className={styles.content}>
          <h3 data-testid="import-dash-datasource-config">1. CONFIGURE DATA</h3>
          {isLoading && <LoadingIndicator />}
          {isError && (
            <Alert severity="error" title="" className={styles.alert}>
              {error?.data.message ?? 'Error while loading the dashboard data'}
            </Alert>
          )}
          {!isLoading && !isError && !!dataSourceInfo && !!dashboardData && (
            <>
              <div>
                {`Set up this required data source for community dashboard`}
                <span className={styles.boldfaceText}>{`${dashboardData.name} (ID ${dashboardData.id}).`}</span>
                {!dataSourceInfo.valid && (
                  <span>The data source type needed for this dashboard has been preselected for you</span>
                )}
              </div>
              {!dataSourceInfo.valid && (
                <Alert severity="error" title="" className={styles.alert}>
                  {dataSourceInfo.error}
                </Alert>
              )}
              {dataSourceInfo.valid && (
                <>
                  <div className={styles.selectContent}>
                    <div className={styles.logoContainer}>
                      <img
                        src={CONNECTION_DATASOURCES[dataSourceInfo.type].logo_url}
                        alt={'Logo'}
                        className={styles.logoIcon}
                      />
                      <span>{CONNECTION_DATASOURCES[dataSourceInfo.type].name}</span>
                    </div>
                  </div>
                  <div>
                    <div ref={customDatasourceContainerRef} className={styles.dbConfigContainer}>
                      <DatasourceConfigPage
                        datasource={dataSourceInfo.type}
                        onSaved={onDataSourceSaved}
                        version={'import-dashboard'}
                      />
                    </div>
                    <div className={styles.buttonsWrapper} style={{ marginTop: '32px' }}>
                      <Button
                        className={styles.continueButton}
                        onClick={onContinue}
                        disabled={!dataSourceSaved}
                        variant={'primary'}
                      >
                        Next: Configure dashboard
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

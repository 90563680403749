import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { SetupImageName } from 'feature/onboarding/types/SetupWizard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { appPaths } from 'feature/common/consts/appPaths';

export const cloudDocumentationData = [
  {
    icons: [],
    image: SetupImageName.SINGLE_LINUX_NODE,
    title: 'Monitor a Debian and Ubuntu Linux host with the Linux Node Integration',
    titleAlign: 'center',
    link: {
      key: ComponentKey.ServiceHealthDashboardFromHome,
      url: appPaths.linuxNode,
      target: '_blank',
    },
    key: ComponentKey.ServiceHealthDashboardFromHome,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    image: SetupImageName.MULTIPLE_LINUX_NODE,
    title: 'Monitor multiple Linux hosts with the Linux Node Integration',
    titleAlign: 'center',
    link: {
      key: ComponentKey.WebsitePerformanceDashboardFromHome,
      url: appPaths.multipleLinuxHosts,
      target: '_blank',
    },
    key: ComponentKey.WebsitePerformanceDashboardFromHome,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    image: SetupImageName.SELF_HOSTED_METRICS,
    title: 'Send metrics, logs and traces from self-hosted Prometheus, Loki and Tempo',
    titleAlign: 'center',
    text: '',
    link: {
      key: ComponentKey.PrometheusDashboardFromHome,
      target: '_blank',
      url: appPaths.selfHostedMetrics,
    },
    key: ComponentKey.PrometheusDashboardFromHome,
    style: 'row-flex',
    type: 'image',
    eventName: FlowEventName.HOME_PAGE_EVENT,
  },
  {
    icons: [],
    title: 'Learn how to:',
    titleAlign: 'left',
    key: 'links-dashboard-template',
    style: 'row-flex',
    type: 'links',
    eventName: FlowEventName.HOME_PAGE_EVENT,
    links: [
      {
        key: 'explore-how-to-connect-your-data',
        url: 'https://grafana.com/docs/grafana-cloud/data-configuration/get-started-data/',
        target: '_blank',
        text: 'Connect your data →',
      },
      {
        key: 'explore-how-to-create-an-alert',
        url: 'https://grafana.com/docs/grafana-cloud/alerting/',
        target: '_blank',
        text: 'Create an alert →',
      },
      {
        key: 'explore-how-to-find-use-dashboards',
        url: 'https://grafana.com/docs/grafana-cloud/fundamentals/find-and-use-dashboards/',
        target: '_blank',
        text: 'Find & use dashboards →',
      },
    ],
  },
];

import { useEffect, useState } from 'react';
import { QueryError } from 'feature/common/types/QueryError';

export function useErrorDatasourceConfig(error: QueryError, changeMessage: Function) {
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.data.message);
      changeMessage(error?.data.message);
    }
  }, [error, changeMessage]);

  return errorMessage;
}

import React, { useCallback, useEffect, useRef, useState } from 'react';
import angle from 'img/angle.svg';
import { handleKeyDown } from 'feature/common/utils/aria';
import { useStyles2 } from '@grafana/ui';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { SelectConnectionItem } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectConnectionItem';
import { SelectConnectionInput } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectConnectionInput';
import { onSearchConnection } from 'feature/onboarding/utils/SearchConnection';
import { getSelectConnectionListStyles } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectConnectionList.styles';

interface SelectFromListProps {
  title: string;
  opened: boolean;
  items: DataToConnect[] | undefined;
  loading: boolean;
  message: string;
  itemSelected?: DataToConnect;
  onSelected: (connection: DataToConnect) => void;
}

export function SelectConnectionList({
  title,
  opened,
  items,
  loading,
  message,
  itemSelected,
  onSelected,
}: SelectFromListProps): JSX.Element {
  const styles = useStyles2(getSelectConnectionListStyles);
  const [isSelectOpen, setSelectOpen] = useState<boolean>(opened);
  const [connectionList, setConnectionList] = useState<DataToConnect[]>([]);
  const [connectionSelected, setConnectionSelected] = useState<DataToConnect>();
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const selectorRef = useRef<HTMLDivElement>(null);

  const closeSelector = useCallback(() => {
    setSelectOpen(false);
    setClearSearch(true);
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      setConnectionList(onSearchConnection('', items));
    }
  }, [items]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        selectorRef.current &&
        !selectorRef.current.contains(event.target) &&
        !document.querySelector('[data-testid="toggletip-content"]')?.contains(event.target)
      ) {
        closeSelector();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectorRef, closeSelector]);

  useEffect(() => {
    if (isSelectOpen) {
      const handleKeyDown = (enterKey: KeyboardEvent) => {
        if (enterKey.key === 'Escape') {
          closeSelector();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return;
  }, [isSelectOpen, closeSelector]);

  useEffect(() => {
    if (!!itemSelected) {
      setConnectionSelected(itemSelected);
      closeSelector();
    }
  }, [itemSelected, closeSelector]);

  function onSelectClick() {
    setSelectOpen(!isSelectOpen);
  }

  const onSearchTerm = useCallback(
    (term: string) => {
      setConnectionList(onSearchConnection(term, items));
    },
    [items]
  );

  function onConnectionSelected(item: DataToConnect) {
    setConnectionSelected(item);
    onSelected(item);
    closeSelector();
  }

  return (
    <div className={styles.container} ref={selectorRef}>
      <div
        className={styles.selectContainer}
        onClick={onSelectClick}
        onKeyDown={handleKeyDown(onSelectClick)}
        tabIndex={0}
        role="button"
      >
        {connectionSelected && <img src={connectionSelected.logo_url} alt={'Logo'} className={styles.logoIcon} />}
        <span>{connectionSelected ? connectionSelected.name : title}</span>
        <img src={angle} alt={'Angle Icon'} className={styles.angleIcon} />
      </div>
      {isSelectOpen && (
        <div className={styles.listContainer}>
          <SelectConnectionInput clearSearch={clearSearch} onSearchTerm={onSearchTerm} />
          {message && <span className={styles.message}>{message}</span>}
          {loading && <LoadingIndicator />}
          {connectionList && connectionList.length > 0 && (
            <div className={styles.itemListContainer}>
              <ul className={styles.itemList}>
                {connectionList.map((item) => (
                  <SelectConnectionItem key={'connection-' + item.id} item={item} onSelected={onConnectionSelected} />
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import { QueryState } from 'feature/common/types/QueryState';
import { OrgInfoData } from 'feature/usage/types/OrgInfoData';
import {
  useGetHostedDataDetailsQuery,
  useGetMarketplaceSubscriptionQuery,
  useGetOrgDataQuery,
} from 'feature/common/api/GrafanaComApi';
import { QueryError } from 'feature/common/types/QueryError';
import { getContract, getOrgPlan, getTrialLength } from 'feature/common/utils/orgInfoUtils';
import { getUsageDataFromOrg } from 'feature/usage/utils/usageUtils';

export const useOrgInfoData = (skip?: boolean): QueryState<OrgInfoData> => {
  const {
    data: hostedData,
    isLoading: isHostedDataLoading,
    isError: isHostedDataError,
    error: hostedDataError,
    isSuccess: isHostedDataSuccess,
  } = useGetHostedDataDetailsQuery(undefined, { skip });

  const {
    data: marketplaceSubscription,
    isLoading: isMarketplaceSubscriptionLoading,
    isError: isMarketplaceSubscriptionError,
    error: marketplaceSubscriptionError,
    isSuccess: isMarketplaceSubscriptionSuccess,
  } = useGetMarketplaceSubscriptionQuery(hostedData?.orgSlug ?? '', { skip: !hostedData });

  const {
    data: orgData,
    isLoading: isOrgDataLoading,
    isError: isOrgDataError,
    error: orgDataError,
    isSuccess: isOrgDataSuccess,
  } = useGetOrgDataQuery(hostedData?.orgSlug ?? '', { skip: !hostedData });

  return {
    data: {
      orgSlug: hostedData?.orgSlug ?? '',
      orgPlan: getOrgPlan(orgData, marketplaceSubscription),
      orgContract: getContract(orgData),
      trialEnd: getTrialLength(orgData),
      pricingPlan: orgData?.subscriptions.current.publicName ?? '',
      usage: getUsageDataFromOrg(orgData),
    },
    error: (hostedDataError || marketplaceSubscriptionError || orgDataError) as QueryError,
    isLoading: isHostedDataLoading || isMarketplaceSubscriptionLoading || isOrgDataLoading,
    isSuccess: isHostedDataSuccess && isMarketplaceSubscriptionSuccess && isOrgDataSuccess,
    isError: isHostedDataError || isMarketplaceSubscriptionError || isOrgDataError,
  };
};

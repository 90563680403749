import React, { ReactNode } from 'react';

import { LoadingIndicator } from './LoadingIndicator';

interface LoadManagerProps {
  loading: boolean;
  children: ReactNode;
}

export const LoadManager: React.FC<LoadManagerProps> = ({ loading, children }) => {
  if (loading) {
    return <LoadingIndicator />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

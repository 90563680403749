import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';
import { cloudAppIcons, hostedIcons, customIcon } from 'img';
import { config } from '@grafana/runtime';
import { topPrioritySourceIds } from 'feature/common/consts/topPriorityConnections';
import { HostedData } from 'feature/common/types/HostedData';
import { externalPaths } from 'feature/common/consts/appPaths';
import { JAVA_OPEN_TELEMETRY_ID, OPEN_TELEMETRY_ID } from 'feature/common/consts/consts';

export const cloudAppPlugins: DataToConnect[] = [
  {
    id: 'synthetic-monitoring-app',
    name: 'Synthetic Monitoring',
    logo_url: cloudAppIcons['synthetic-monitoring'],
    overview: ``,
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.syntheticMonitoringApp,
    mostPopular: topPrioritySourceIds.includes('synthetic-monitoring-app'),
    trackId: 'synthetic-monitoring-app-connection',
  },
  {
    id: 'k8s-monitoring-app',
    name: 'Kubernetes Monitoring',
    logo_url: cloudAppIcons['kubernetes'],
    overview: ``,
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.monitorKubernetes,
    mostPopular: topPrioritySourceIds.includes('k8s-monitoring-app'),
    trackId: 'kubernetes-app-connection',
  },
  {
    id: 'k6-app',
    name: 'Grafana Cloud k6',
    logo_url: cloudAppIcons['k6-color'],
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.k6App,
    mostPopular: topPrioritySourceIds.includes('k6-app'),
    trackId: 'k6-app-connection',
  },
  {
    id: 'pyroscope-app',
    name: 'Grafana Cloud Profiles',
    logo_url: cloudAppIcons['pyroscope'],
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.pyroscope,
    mostPopular: topPrioritySourceIds.includes('pyroscope-app'),
    trackId: 'pyroscope-app-connection',
  },
  {
    id: 'frontend-observability-app',
    name: 'Frontend Observability',
    logo_url: cloudAppIcons['faro'],
    overview: ``,
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.faro,
    mostPopular: topPrioritySourceIds.includes('frontend-observability-app'),
    trackId: 'frontend-observability-app-connection',
  },
  {
    id: 'grafana-app-observability-app',
    name: 'Application o11y',
    logo_url: cloudAppIcons['observability'],
    overview: ``,
    type: DataToConnectType.CloudApp,
    externalUrl: externalPaths.applicationO11y,
    mostPopular: topPrioritySourceIds.includes('grafana-app-observability-app'),
    trackId: 'application-observability-app-connection',
  },
  {
    id: 'aws',
    slug: 'aws',
    name: 'AWS',
    logo_url: config.theme2.isDark ? cloudAppIcons['aws-dark'] : cloudAppIcons['aws-light'],
    overview: ``,
    type: DataToConnectType.SaasIntegration,
    mostPopular: topPrioritySourceIds.includes('aws'),
    externalUrl: `${externalPaths.integrationsAndConnections}/aws`,
    trackId: 'aws-connection',
  },
  {
    id: 'gcp',
    slug: 'gcp',
    name: 'GCP',
    logo_url: customIcon['gcp'],
    overview: ``,
    type: DataToConnectType.SaasIntegration,
    mostPopular: false,
    trackId: 'gcp-connection',
  },
  {
    id: 'azure',
    slug: 'azure',
    name: 'Azure',
    logo_url: customIcon['azure'],
    overview: ``,
    type: DataToConnectType.SaasIntegration,
    mostPopular: false,
    trackId: 'azure-connection',
  },
];

export const cloudPlatformDataSources: DataToConnect[] = [
  {
    id: 'datasource-aws',
    name: 'AWS Datasource',
    logo_url: config.theme2.isDark ? cloudAppIcons['aws-dark'] : cloudAppIcons['aws-light'],
    overview: ``,
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'aws-datasource',
  },
  {
    id: 'datasource-gcp',
    name: 'GCP Datasource',
    logo_url: customIcon['gcp'],
    overview: ``,
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'gcp-datasource',
  },
  {
    id: 'datasource-azure',
    name: 'Azure Datasource',
    logo_url: customIcon['azure'],
    overview: ``,
    type: DataToConnectType.DataSource,
    mostPopular: false,
    trackId: 'azure-datasource',
  },
];
const otelDescription = `OpenTelemetry instrumentation is the recommended standard to observe applications with Grafana Cloud.
This integration helps you set up the Grafana Agent to send all OpenTelemetry data: traces, metrics, and logs, to Grafana Cloud.  
Note that it is also possible to send OpenTelemetry signals to Grafana Cloud using the OpenTelemetry Collector as documented 
[here](https://grafana.com/docs/opentelemetry/collector/send-otlp-to-grafana-cloud-databases).  
Moreover, Grafana Cloud will soon expose a native OTLP intake supporting all signal types: traces, metrics, and logs, that will simplify the data collection architecture.`;

export const cloudStackList: DataToConnect[] = [
  {
    id: 'hmInstancePromId',
    name: HostedData.Prometheus,
    logo_url: hostedIcons['prometheus-color'],
    overview: `Your Grafana Cloud stack includes a massively scalable, high-performance, and highly available Prometheus endpoint. Bring together the raw, unsampled metrics for all your applications and infrastructure, spread around the globe, in one place - with 13-months retention (Pro).`,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes('hmInstancePromId'),
    externalUrl: `${externalPaths.integrationsAndConnections}/hmInstancePromId`,
    trackId: 'hosted-prometheus-connection',
  },
  {
    id: 'hlInstanceId',
    name: HostedData.Loki,
    logo_url: hostedIcons['loki-color'],
    overview: `Your Grafana Cloud stack includes a logging service powered by Grafana Loki, our Prometheus-inspired log aggregation system. By using the exact same service discovery as Prometheus, Loki can systematically guarantee your logs have consistent labels with your metrics. This allows you to seamlessly switch between metrics and logs, preserving context and saving time.`,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes('hlInstanceId'),
    externalUrl: `${externalPaths.integrationsAndConnections}/hlInstanceId`,
    trackId: 'hosted-loki-connection',
  },
  {
    id: 'hmInstanceGraphiteId',
    name: HostedData.Graphite,
    logo_url: hostedIcons['graphite-color'],
    overview: `Your Grafana Cloud stack includes a scalable, high performance, and highly available Graphite metrics service.`,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes('hmInstanceGraphiteId'),
    externalUrl: `${externalPaths.integrationsAndConnections}/hmInstanceGraphiteId`,
    trackId: 'hosted-graphite-connection',
  },
  {
    id: 'htInstanceId',
    name: HostedData.Tempo,
    logo_url: hostedIcons['tempo-color'],
    overview: `Powered by Grafana Tempo, Grafana Cloud provides an easy-to-use, highly scalable, and cost-effective distributed tracing system.`,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes('htInstanceId'),
    externalUrl: `${externalPaths.integrationsAndConnections}/htInstanceId`,
    trackId: 'hosted-tempo-connection',
  },
  {
    id: 'hpInstanceId',
    name: 'Hosted profiles',
    logo_url: hostedIcons['pyroscope'],
    overview: `Powered by Grafana Pyroscope, Grafana Cloud provides an easy-to-use, highly scalable, and cost-effective distributed continuous profiling system.`,
    type: DataToConnectType.HostedData,
    search_keywords: ['profiling, profile, pyroscope'],
    mostPopular: topPrioritySourceIds.includes('hpInstanceId'),
    externalUrl: `${externalPaths.integrationsAndConnections}/hpInstanceId`,
    trackId: 'hosted-pyroscope-connection',
  },
  {
    id: 'http-metrics',
    name: HostedData.HTTPMetrics,
    logo_url: hostedIcons['http-metrics'],
    overview:
      'Ship metrics directly from your application code over HTTP for storage in your Grafana Cloud account using Graphite and Prometheus. Featuring the ability to generate an api key and a wide selection of predefined code snippets to plug in and push up.',
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes('http-metrics'),
    externalUrl: `${externalPaths.integrationsAndConnections}/http-metrics`,
    trackId: 'hosted-http-metrics-connection',
  },
  {
    id: OPEN_TELEMETRY_ID,
    name: HostedData.GenericOpenTelemetry,
    logo_url: hostedIcons['open-telemetry'],
    overview: otelDescription,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes(OPEN_TELEMETRY_ID),
    externalUrl: `${externalPaths.integrationsAndConnections}/${OPEN_TELEMETRY_ID}`,
    trackId: 'hosted-open-telemetry-connection',
  },
  {
    id: JAVA_OPEN_TELEMETRY_ID,
    name: HostedData.JavaOpenTelemetry,
    logo_url: hostedIcons['open-telemetry'],
    overview: otelDescription,
    type: DataToConnectType.HostedData,
    mostPopular: topPrioritySourceIds.includes(JAVA_OPEN_TELEMETRY_ID),
    externalUrl: `${externalPaths.integrationsAndConnections}/${JAVA_OPEN_TELEMETRY_ID}`,
    trackId: 'hosted-java-open-telemetry-connection',
  },
  {
    id: 'collector-open-telemetry',
    name: 'OpenTelemetry Collector',
    logo_url: hostedIcons['open-telemetry'],
    type: DataToConnectType.HostedData,
    search_keywords: ['otel'],
    mostPopular: topPrioritySourceIds.includes('collector-open-telemetry'),
    externalUrl: `${externalPaths.integrationsAndConnections}/collector-open-telemetry`,
    trackId: 'hosted-collector-open-telemetry-connection',
  },
];

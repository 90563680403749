import { useStyles2 } from '@grafana/ui';
import React from 'react';
import { DataConnectCardIcon } from 'feature/onboarding/components/PrometheusTooltip/DataConnectCardIcon';
import { getStyles } from 'feature/onboarding/components/PrometheusTooltip/PrometheusTooltip.styles';

export function PrometheusDatasourceTooltip(): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.ct}>
      <b>What you can do</b>
      <div className={styles.icons}>
        <DataConnectCardIcon
          icon={{
            label: 'Collect',
            icon: 'arrow-right',
            size: 'xl',
            disabled: true,
          }}
        />
        <DataConnectCardIcon
          icon={{
            label: 'Store',
            icon: 'database',
            size: 'xl',
            disabled: true,
          }}
        />
        <DataConnectCardIcon
          icon={{
            label: 'Visualize',
            icon: 'apps',
            size: 'xl',
            disabled: false,
          }}
        />
      </div>
      <p>
        <ul>
          <li>Create powerful dashboards for your Prometheus data, without having to move the data around.</li>
          <li>You will need to continue to store Prometheus data locally.</li>
          <li>You will need to manage your Prometheus servers.</li>
        </ul>
      </p>
      <p>
        <b>How to explore your data</b>
      </p>
      <p className={styles.mb0}>
        The data from your Prometheus servers will be available for you to query using Grafana Explore and visualize
        using Grafana dashboards.
      </p>
    </div>
  );
}

import React from 'react';

type MetricTooltipProps = {
  title: string;
  link: {
    text: string;
    url: string;
  };
};

export const MetricTooltip = ({ title, link }: MetricTooltipProps) => {
  return (
    <>
      {title}
      <br />
      <a href={link.url} target={'_blank'} rel="noreferrer">
        {link.text}
      </a>
    </>
  );
};

import { DataToConnectType } from 'feature/common/types/DataToConnect';
import { IconName } from '@grafana/ui';

export const OPEN_TELEMETRY_ID = 'open-telemetry';
export const JAVA_OPEN_TELEMETRY_ID = 'java-open-telemetry';

export const INTERNAL_DATA_SOURCE_IDS = [
  'grafana-alertmanager-datasource',
  'grafana-cardinality-datasource',
  'grafana-ruler-datasource',
  'k6-datasource',
  'synthetic-monitoring-datasource',
];

export const CONNECTION_GROUPS = [
  {
    id: 'aws',
    name: 'AWS',
    children: ['aws', 'datasource-aws'],
  },
  {
    id: 'gcp',
    name: 'GCP',
    children: ['gcp', 'datasource-gcp'],
  },
  {
    id: 'azure',
    name: 'Azure',
    children: ['azure', 'datasource-azure'],
  },
  {
    id: 'prometheus',
    name: 'Prometheus',
    children: ['hmInstancePromId', 'datasource-prometheus'],
  },
  {
    id: 'loki',
    name: 'Grafana Loki',
    children: ['hlInstanceId', 'datasource-loki'],
  },
  {
    id: 'graphite',
    name: 'Graphite',
    children: ['hmInstanceGraphiteId', 'datasource-graphite'],
  },
  {
    id: 'tempo',
    name: 'Tempo',
    children: ['htInstanceId', 'datasource-tempo'],
  },
  {
    id: 'apache-cassandra',
    name: 'Apache Cassandra',
    children: ['apache-cassandra', 'datasource-hadesarchitect-cassandra-datasource'],
  },
  {
    id: 'clickhouse',
    name: 'Clickhouse',
    children: ['clickhouse', 'datasource-grafana-clickhouse-datasource'],
  },
  {
    id: 'consul',
    name: 'Consul',
    children: ['consul', 'datasource-sbueringer-consul-datasource'],
  },
  {
    id: 'discourse',
    name: 'Discourse',
    children: ['discourse', 'datasource-grafana-discourse-datasource'],
  },
  {
    id: 'github',
    name: 'Github',
    children: ['github', 'datasource-grafana-github-datasource'],
  },
  {
    id: 'gitlab',
    name: 'Gitlab',
    children: ['gitlab', 'datasource-grafana-gitlab-datasource'],
  },
  {
    id: 'jira',
    name: 'Jira',
    children: ['jira', 'datasource-grafana-jira-datasource'],
  },
  {
    id: 'kafka',
    name: 'Kafka',
    children: ['kafka', 'datasource-hamedkarbasi93-kafka-datasource'],
  },
  {
    id: 'mongodb',
    name: 'MongoDB',
    children: ['mongodb', 'datasource-grafana-mongodb-datasource'],
  },
  {
    id: 'opensearch',
    name: 'Opensearch',
    children: ['opensearch', 'datasource-grafana-opensearch-datasource'],
  },
  {
    id: 'redis',
    name: 'Redis',
    children: ['redis', 'datasource-redis-datasource'],
  },
  {
    id: 'snowflake',
    name: 'Snowflake',
    children: ['snowflake', 'datasource-grafana-snowflake-datasource'],
  },
  {
    id: 'oracledb',
    name: 'OracleDB',
    children: ['oracledb', 'datasource-grafana-oracle-datasource'],
  },
  {
    id: 'pyroscope',
    name: 'Profiles',
    children: ['hpInstanceId', 'datasource-grafana-pyroscope-datasource'],
  },
];

export const CONNECTIONS_TO_EXCLUDE = [
  'synthetic-monitoring-app',
  'k6-app',
  'pyroscope-app',
  'frontend-observability-app',
  'grafana-app-observability-app',
  'datasource-cloudwatch',
  'datasource-grafana-athena-datasource',
  'datasource-grafana-redshift-datasource',
  'datasource-grafana-timestream-datasource',
  'datasource-grafana-x-ray-datasource',
  'datasource-grafana-iot-sitewise-datasource',
  'datasource-stackdriver',
  'datasource-googlecloud-logging-datasource',
  'gcp-metrics',
  'gcp-logs',
  'azure-metrics',
  'grafana-azure-data-explorer-datasource',
  'datasource-grafana-azure-data-explorer-datasource',
  'azure-event-hubs',
  'datasource-grafana-azuredevops-datasource',
  'datasource-grafana-azure-monitor-datasource',
  'grafana-agent',
  'loki',
  'mimir',
  'datasource-alertmanager',
  'datasource-vertamedia-clickhouse-datasource',
  'datasource-praj-ams-datasource',
  'datasource-thalysantana-appcenter-datasource',
  'datasource-rackerlabs-blueflood-datasource',
  'datasource-yeya24-chaosmesh-datasource',
  'datasource-chaosmeshorg-datasource',
  'datasource-computest-cloudwatchalarm-datasource',
  'datasource-dalmatinerdb-datasource',
  'datasource-andig-darksky-datasource',
  'datasource-devicehive-devicehive-datasource',
  'datasource-abhisant-druid-datasource',
  'datasource-grafana-falconlogscale-datasource',
  'datasource-clarity89-finnhub-datasource',
  'datasource-pgollangi-firestore-datasource',
  'datasource-verticle-flowhook-datasource',
  'datasource-iosb-sensorthings-datasource',
  'datasource-gnocchixyz-gnocchi-datasource',
  'datasource-volkovlabs-grapi-datasource',
  'datasource-mofengfeng-greptimedb-datasource',
  'datasource-groonga-datasource',
  'datasource-svennergr-hackerone-datasource',
  'datasource-hawkular-datasource',
  'datasource-needleinajaystack-haystack-datasource',
  'datasource-udoprog-heroic-datasource',
  'datasource-spotify-heroic-datasource',
  'datasource-ibm-apm-datasource',
  'datasource-simpod-json-datasource',
  'datasource-nagasudhirpulla-api-datasource',
  'datasource-paytm-kapacitor-datasource',
  'datasource-aquaqanalytics-kdbadaptor-datasource',
  'datasource-aquaqanalytics-kdbbackend-datasource',
  'datasource-linksmart-hds-datasource',
  'datasource-linksmart-sensorthings-datasource',
  'datasource-bsull-materialize-datasource',
  'datasource-goshposh-metaqueries-datasource',
  'datasource-meteostat-meteostat-datasource',
  'datasource-monasca-datasource',
  'datasource-monitoringartist-monitoringart-datasource',
  'datasource-radensolutions-netxms-datasource',
  'datasource-ntop-ntopng-datasource',
  'datasource-dvelop-odata-datasource',
  'datasource-fastweb-openfalcon-datasource',
  'datasource-gridprotectionalliance-openhistorian-datasource',
  'datasource-oci-logs-datasource',
  'datasource-parca',
  'datasource-parca-datasource',
  'datasource-parseable-parseable-datasource',
  'datasource-pixie-pixie-datasource',
  'datasource-sni-pnp-datasource',
  'datasource-camptocamp-prometheus-alertmanager-datasource',
  'datasource-quasardb-datasource',
  'datasource-ccin2p3-riemann-datasource',
  'datasource-volkovlabs-rss-datasource',
  'datasource-sidewinder-datasource',
  'datasource-fzakaria-simple-annotations-datasource',
  'datasource-grafana-simple-json-datasource',
  'datasource-skydive-datasource',
  'datasource-pue-solr-datasource',
  'datasource-marcusolsson-static-datasource',
  'datasource-sni-thruk-datasource',
  'datasource-bsull-console-datasource',
  'datasource-natel-usgs-datasource',
  'datasource-ovh-warp10-datasource',
];

export const CUSTOM_CONNECTION_TITLES: { [key: string]: string } = {
  [DataToConnectType.HostedData]: `Send {{connection_name}} to Grafana Cloud for a fully-managed service`,
  [DataToConnectType.DataSource]: `Visualize {{connection_name}} data in Grafana Cloud`,
  [DataToConnectType.AgentIntegration]: `Monitor the health and performance of {{connection_name}}`,
  'confluent-cloud': `Monitor the health and performance of your Confluent Cloud instance`,
  'k8s-monitoring-app': 'Start monitoring your Kubernetes cluster',
  aws: 'Centralize your observability data into a single backend',
  'datasource-aws': 'Create dashboards and queries from your AWS Services',
  gcp: 'Centralize your observability data into a single backend',
  'datasource-gcp': 'Create dashboards and queries from your GCP Projects',
  azure: 'Centralize your observability data into a single backend',
  'datasource-azure': 'Create dashboards and queries from your Azure Projects',
  'http-metrics': 'Send metrics via HTTP to Grafana Cloud',
  github: 'Collect, store and visualize Github stats in Grafana Cloud',
};

export const CUSTOM_CONNECTION_DESCRIPTIONS: { [key: string]: string } = {
  [DataToConnectType.HostedData]: `<ul>
                <li>Use an agent to collect, store and visualize {{connection_name}} in Grafana Cloud.</li>
                <li>No need to manage or scale your {{connection_name}} yourself.</li>
              </ul>`,
  [DataToConnectType.DataSource]: `<ul>
                <li>Retrieve and visualize data directly from {{connection_name}}.</li>
                <li>Create dashboards, queries, and alerts without collecting or storing data in Grafana Cloud.</li>
              </ul>`,
  [DataToConnectType.AgentIntegration]: `<ul>
                <li>Collect and store health and performance data from {{connection_name}}.</li>
                <li>Includes pre-built dashboards, alerts, and recording rules to help you monitor relevant metrics.</li>
              </ul>`,
  'confluent-cloud':
    '<ul><li>The Confluent Cloud integration allows you to scrape Confluent metrics without installing the Grafana agent.</li><li>You can create and configure multiple scrape jobs to organize your resources. </li><li>Create dashboards, queries and alerts using the metrics collected.</li></ul>',
  'k8s-monitoring-app':
    '<ul><li>Use an agent to collect and store K8s metrics and logs in Grafana Cloud.</li><li>Pre-configured dashboards and alerts to monitor your clusters and their workloads.</li><li>Flexible views to navigate between clusters and pods, as well as exploring node resource usage and efficiency.</li></ul>',
  aws: '<ul><li>Use Prometheus to query across all of your observability data.</li><li>Perform cross-region monitoring in AWS with a single query.</li><li>Data is ingested and stored in Grafana Cloud, alongside your other mission critical data.</li></ul>',
  'datasource-aws':
    '<ul><li>Query your AWS services directly in real-time, using native AWS query editors.</li><li>Use Grafana data sources to build visualizations and alerts.</li><li>Data is read-only and not stored in Grafana Cloud.</li></ul>',
  gcp: '<ul><li>Send metrics and logs from your GCP projects to Grafana Cloud.</li><li>Includes pre-built dashboards and alerts to help monitor and visualize GCP data.</li><li>Data is ingested and stored in Grafana Cloud, alongside your other observability data.</li></ul>',
  'datasource-gcp':
    '<ul><li>Query your GCP projects directly in real-time, using native GCP query editors.</li><li>Use Grafana data sources to build visualizations and alerts.</li><li>Data is read-only and not stored in Grafana Cloud.</li></ul>',
  azure:
    '<ul><li>Send metrics and logs from your Azure projects to Grafana Cloud.</li><li>Includes pre-built dashboards and alerts to help monitor and visualize Azure data.</li><li>Data is ingested and stored in Grafana Cloud, alongside your other observability data.</li></ul>',
  'datasource-azure':
    '<ul><li>Query your Azure projects directly in real-time, using native Azure query editors.</li><li>Use Grafana data sources to build visualizations and alerts.</li><li>Data is read-only and not stored in Grafana Cloud.</li></ul>',
  hmInstancePromId:
    '<ul><li>Use an agent to collect, store and visualize Hosted Prometheus metrics in Grafana Cloud.</li><li>No need to manage or scale your own local Prometheus instance.</li></ul>',
  htInstanceId:
    '<ul><li>Use an agent to collect, store and visualize Hosted Traces in Grafana Cloud.</li><li>No need to manage or scale your own local Tempo instance.</li></ul>',
  hlInstanceId:
    '<ul><li>Use an agent to collect, store and visualize Hosted Logs in Grafana Cloud.</li><li>No need to manage or scale your own local Loki instance.</li></ul>',
  'datasource-prometheus':
    '<ul><li>Retrieve and visualize data directly from an existing Prometheus instance.</li><li>Create dashboards, queries, and alerts without collecting or storing data in Grafana Cloud.</li></ul>',
  'datasource-loki':
    '<ul><li>Retrieve and visualize data directly from an existing Loki instance.</li><li>Create dashboards, queries, and alerts without collecting or storing data in Grafana Cloud.</li></ul>',
  'datasource-tempo':
    '<ul><li>Retrieve and visualize data directly from an existing Tempo instance.</li><li>Create dashboards, queries, and alerts without collecting or storing data in Grafana Cloud.</li></ul>',
  'http-metrics':
    '<ul><li>Push metrics to the Grafana Cloud backend in Prometheus or Graphite format.</li><li>No need to manage or scale your own Prometheus or Graphite instance.</li></ul>',
  github:
    '<ul><li>Track insights related to issues, commits, PRs over time.</li><li>Includes pre-built dashboards so you don’t need to start from scratch.</li></ul>',
};

export const CUSTOM_CONNECTION_REDIRECT: { [key: string]: string } = {};

export const CUSTOM_CONNECTION_ICON: { [key: string]: IconName } = {
  [DataToConnectType.HostedData]: 'cloud-upload',
  [DataToConnectType.DataSource]: 'database',
  [DataToConnectType.AgentIntegration]: 'heart-rate',
  [DataToConnectType.CloudApp]: 'heart-rate',
  [DataToConnectType.SaasIntegration]: 'heart-rate',
  [DataToConnectType.Alpha]: 'heart-rate',
};

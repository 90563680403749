import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { OnboardingVersion } from 'feature/onboarding/types/OnboardingVersion';

export const getGettingStartedStyles = (theme: GrafanaTheme2, version: OnboardingVersion) => ({
  wrapper: css`
    max-width: 1300px;
    width: 100%;

    ${version === 'onboarding' ? 'display: flex;flex-direction: column;margin: auto;' : ''}
    h3 {
      line-height: 2.5rem;
    }
    margin-bottom: ${theme.spacing(3)};
  `,
  buttonsWrapper: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 50px 0;
  `,
  buttonsLink: css`
    color: ${theme.colors.text.primary};
    background-color: transparent;
    border: none;
    font-size: ${theme.typography.bodySmall.fontSize};

    :hover {
      opacity: 0.65;
    }
  `,
  buttonsText: css`
    color: ${theme.colors.text.primary};
    font-size: ${theme.typography.bodySmall.fontSize};
  `,
  headerWrapper: css`
    display: flex;
  `,
  icon: css`
    margin-right: 10px;
    width: 25px;
    height: 25px;
  `,
  textWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      margin-bottom: 0;
    }
  `,
  header: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  preamble: css`
    font-size: ${theme.typography.body.fontSize};
    color: ${theme.colors.text.primary};
  `,
  cardWrapper: (columns?: number) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;
    margin-top: ${theme.spacing(3)};
    gap: ${theme.spacing(2)};
    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 540px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 540px) and (max-width: 820px) {
      grid-template-columns: repeat(${(columns && columns > 2) || !columns ? 2 : columns}, 1fr);
    }
    @media (min-width: 820px) and (max-width: 1095px) {
      grid-template-columns: repeat(${(columns && columns > 3) || !columns ? 3 : columns}, 1fr);
    }
    @media (min-width: 1095px) {
      grid-template-columns: repeat(${(columns && columns > 4) || !columns ? 4 : columns}, 1fr);
    }
  `,
  moreBtnWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0 50px 0;
  `,
  bottomWrapper: css`
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  viewMoreLink: css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};

    a {
      color: ${theme.colors.text.link};
    }
  `,
});

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
export const getStyles = (theme: GrafanaTheme2) => {
  return {
    card: css`
      align-items: flex-start;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${theme.components.panel.background};
      border: 1px solid ${theme.components.panel.borderColor};
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: ${theme.shadows.z1};
      color: ${theme.colors.text};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: ${theme.spacing(4)} 0;
      padding: ${theme.spacing(4)};
      width: 100%;
    `,
    iconAndDescrip: css`
      display: flex;
      flex-direction: row;
    `,
    description: css`
      line-height: 1.5rem;
      font-weight: 400;

      @media (max-width: 1000px) {
        display: none;
      }
    `,
    header: css`
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${theme.isDark ? theme.v1.palette.white : theme.v1.palette.gray15};
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 12px;
      @media (max-width: 1000px) {
        font-size: 1.4rem;
      }
    `,
    logoWrapper: css`
      height: 100%;
      margin-right: 12px;
      img {
        width: 32px;
      }

      @media (max-width: 1000px) {
        align-items: baseline;
      }
    `,
    titleWrapper: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    `,
    buttonWrapper: css`
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @media (max-width: 1000px) {
        justify-content: center;
      }
    `,
    button: css`
      span::after {
        content: ' →';
        white-space: pre;
      }

      @media (max-width: 1000px) {
        text-align: center;
        span::after {
          content: '';
          white-space: pre;
        }
      }
    `,
  };
};

import { lsGet, lsRemove, lsSave } from 'feature/common/utils/misc';

export const closeNavIfOpened = () => {
  // save left nav current status and close it
  const navExpanded = lsGet('grafana.sectionNav.expanded');
  if (navExpanded) {
    lsSave('currentNavExpanded', 'true');
    lsSave('grafana.sectionNav.expanded', 'false');
  }
};

export const rollbackNavExpanded = () => {
  const currentNavExpanded = lsGet('currentNavExpanded');
  if (currentNavExpanded) {
    lsRemove('currentNavExpanded');
    lsSave('grafana.sectionNav.expanded', 'true');
  }
};

export const redirectToHref = (url: string) => {
  rollbackNavExpanded();
  window.location.href = url;
};

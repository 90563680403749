import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: ${theme.spacing(4)} 0px;
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
    `,
    integrationsList: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
    `,
    item: css`
      display: flex;
      width: calc((100% - 64px) / 3);
    `,
    link: css`
      text-decoration: none;
    `,
    title: css`
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    `,
  };
};

import React from 'react';
import { getControlOnboardingStepData } from 'feature/onboarding/data/ControlOnboarding.data';
import { ControlOnboardingStep } from 'feature/onboarding/types/OnboardingCard';
import { OnboardingScreen } from '../OnboardingScreen';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

interface ControlOnboardingScreenProps {
  step: ControlOnboardingStep;
}

export const ControlOnboardingScreen: React.FC<ControlOnboardingScreenProps> = ({ step }) => {
  const stepData = getControlOnboardingStepData(step);
  return (
    <OnboardingScreen step={step} stepData={stepData} eventName={FlowEventName.SIMPLIFIED_ONBOARDING_FLOW_EVENT_NAME} />
  );
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { getSelectAndCreateDashboardTitle } from 'feature/onboarding/consts/consts';
import {
  getConnectionCards,
  getCustomDatasourceFromConnectionSelected,
  isDatasourceWithCustomPage,
  onContinue,
} from 'feature/onboarding/utils/selectAndCreateDashboardUtils';
import { OnboardingGeneralCardData } from 'feature/onboarding/types/OnboardingCard';
import { OnboardingCard } from 'feature/onboarding/components/OnboardingCard';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { DatasourceConfigPage } from 'feature/datasource-config/components/DatasourceConfigPage';
import { DatasourceResponse } from 'feature/common/types/Datasources';
import { AppContext } from 'App';
// @ts-ignore
import prometheusStarterDashboard from '../../../../../dashboards/prometheus-starter.json';
import { useInstallDashboardMutation } from 'feature/common/api/DashboardsApi';
import { useGetHostedDataDetailsQuery } from 'feature/common/api/GrafanaComApi';
import { QueryError } from 'feature/common/types/QueryError';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { getEventNameFromPath } from 'feature/onboarding/utils/OnboardingCard';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { PROMETHEUS_CONNECTION } from 'feature/onboarding/data/Prometheus.data';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  cardWrapper: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    gap: ${theme.spacing(2)};

    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    text-align: left;
  `,
  dbConfigContainer: css`
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-top: ${theme.spacing(3)};
  `,
  buttonsWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing(3)};
  `,
  continueButton: css`
    width: 338px;
    height: 48px;
    display: flex;
    justify-content: center;
  `,
});

export const PrometheusOnboardingControl = () => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);
  const [optionSelected, setOptionSelected] = useState<string>('');
  const customDatasourceContainerRef = useRef<HTMLDivElement>(null);
  const [dataSourceSaved, setDataSourceSaved] = useState<DatasourceResponse>();
  const [installDashboard, { data, isLoading, error }] = useInstallDashboardMutation();
  const { data: hostedData, isLoading: isHostedDataLoading } = useGetHostedDataDetailsQuery();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const cards = getConnectionCards(PROMETHEUS_CONNECTION, optionSelected, onCardSelected);

  function onCardSelected(option: string) {
    setOptionSelected(option);
  }

  useEffect(() => {
    if (!!data) {
      window.location.href = data.url;
    }
  }, [data]);

  useEffect(() => {
    if (!!error) {
      setShowAlert(true);
    }
  }, [error]);

  function renderSecondLevelOptions() {
    if (isDatasourceWithCustomPage(PROMETHEUS_CONNECTION, optionSelected)) {
      const customDatasourceId = getCustomDatasourceFromConnectionSelected(optionSelected) as Datasources;
      return (
        <div ref={customDatasourceContainerRef} className={styles.dbConfigContainer}>
          <DatasourceConfigPage
            version={'select-and-create-dashboard'}
            datasource={customDatasourceId}
            onSaved={setDataSourceSaved}
            textAlign={'left'}
          />
        </div>
      );
    }
    return null;
  }

  const hasCustomDataSourcePage = isDatasourceWithCustomPage(PROMETHEUS_CONNECTION, optionSelected);

  function onNext() {
    if (hasCustomDataSourcePage) {
      if (!!dataSourceSaved) {
        installDashboard({
          slugUrl: hostedData?.url ?? '',
          dashboard: prometheusStarterDashboard,
          message: 'creating prometheus starter dashboard from Cloud Home plugin',
          folderTitle: 'Prometheus Onboarding',
          overwrite: true,
          dataSource: { uid: dataSourceSaved.datasource.uid, name: dataSourceSaved.datasource.name },
        });
      } else {
        window.location.href = `/dashboard/new`;
      }
      trackAction(
        getEventNameFromPath(FlowEventName.GETTING_STARTED_EVENT_NAME, FlowEventName.ONBOARDING_FLOW_EVENT_NAME),
        `${ComponentKey.Onboarding.OpenStarterDashboardButton}`,
        ActionType.click,
        orgId
      );
    } else {
      onContinue(PROMETHEUS_CONNECTION, optionSelected, '', orgId, dataSourceSaved);
    }
  }

  return (
    <div className={styles.wrapper}>
      <h3>{getSelectAndCreateDashboardTitle('Prometheus')}</h3>
      <div className={styles.cardWrapper}>
        {cards.map((card) => (
          <OnboardingCard card={card as OnboardingGeneralCardData} key={card.key} />
        ))}
      </div>
      {renderSecondLevelOptions()}
      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.continueButton}
          disabled={
            !Boolean(optionSelected) ||
            (hasCustomDataSourcePage && !dataSourceSaved) ||
            isLoading ||
            isHostedDataLoading
          }
          onClick={onNext}
        >
          {hasCustomDataSourcePage ? 'Go to Dashboard' : 'Continue'}
        </Button>
      </div>
      {!!showAlert && (
        <Alert severity="error" title="">
          {(error as QueryError)?.data?.message ||
            'There was an error while trying to access the Prometheus Starter dashboard.'}
        </Alert>
      )}
    </div>
  );
};

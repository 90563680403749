import { BackendSrvRequest, config } from '@grafana/runtime';
import { DataSourceInstanceSettings } from '@grafana/data';
import {
  AuthMethod,
  DataSourceDTO,
  DatasourceResponse,
  DatasourceSettings,
  JsonDataTypes,
} from 'feature/common/types/Datasources';
import { QueryError } from 'feature/common/types/QueryError';
import { appCreateApi } from 'app/context';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { getDatasourceConfigData } from 'feature/datasource-config/data/DatasourceConfig.data';
import { v4 as uuidv4 } from 'uuid';
import { InfluxVersion } from 'feature/common/types/InfluxVersion';
import { isFluxVersion } from 'feature/common/utils/datasourcesUtils';
import { backendSrvBaseQuery } from 'feature/common/api/baseApi';

const dataSourcesTagType = 'datasources';

const getDataSourceBody = (action: 'create' | 'update' | 'test', dsType: Datasources, params?: DataSourceDTO) => {
  if (action === 'create') {
    const configData = getDatasourceConfigData(dsType);
    const uid = uuidv4();
    const dsName = `${configData.name}-${uid}`;
    return {
      name: dsName,
      type: configData.datasource,
      isDefault: false,
      access: 'proxy',
    };
  } else if ((action === 'update' || action === 'test') && params) {
    const {
      name,
      uid,
      id,
      type,
      url,
      user,
      database,
      version,
      password,
      organization,
      httpHeaderName1,
      httpHeaderValue1,
      authMethod,
    } = params;
    const body: DatasourceSettings = {
      id,
      uid,
      orgId: 1,
      name,
      type,
      typeLogoUrl: '',
      access: 'proxy',
      url,
      basicAuth:
        isFluxVersion(type, version as InfluxVersion) || (type === 'prometheus' && authMethod === AuthMethod.BasicAuth),
      withCredentials: type === 'prometheus' && authMethod === AuthMethod.CrossSiteCredentials,
      isDefault: false,
      jsonData: {} as JsonDataTypes,
      secureJsonFields: {},
      version: 1,
      readOnly: false,
      accessControl: {
        'alert.instances.external:read': true,
        'alert.instances.external:write': true,
        'alert.notifications.external:read': true,
        'alert.notifications.external:write': true,
        'alert.rules.external:read': true,
        'alert.rules.external:write': true,
        'datasources.caching:read': true,
        'datasources.caching:write': true,
        'datasources.id:read': true,
        'datasources.permissions:read': true,
        'datasources.permissions:write': true,
        'datasources:delete': true,
        'datasources:query': true,
        'datasources:read': true,
        'datasources:write': true,
      },
    };

    if (password) {
      body.secureJsonData = {
        [(type === 'influxdb' && version === InfluxVersion.Flux) ||
        (type === 'prometheus' && authMethod === AuthMethod.BasicAuth)
          ? 'basicAuthPassword'
          : 'password']: password,
      };
    }
    switch (type) {
      case 'influxdb':
        if (version === InfluxVersion.InfluxQLBasic) {
          body.basicAuthUser = '';
          body.user = user ?? '';
          // note: password required for basic auth is appended just above this switch statement
          body.jsonData = {
            dbName: database ?? '', // For InfluxQl `database` was deprecated for this prop `dbName` https://grafana.com/docs/grafana/latest/datasources/influxdb/#provision-the-data-source
            httpMode: 'POST',
          };
        } else if (version === InfluxVersion.InfluxQLToken) {
          body.basicAuthUser = '';
          body.jsonData = {
            dbName: database ?? '', // For InfluxQl `database` was deprecated for this prop `dbName` https://grafana.com/docs/grafana/latest/datasources/influxdb/#provision-the-data-source
            httpMode: 'POST',
            httpHeaderName1,
          };
          if (httpHeaderValue1) {
            body.secureJsonData = body.secureJsonData ?? {};
            body.secureJsonData.httpHeaderValue1 = httpHeaderValue1;
          }
        } else if (version === InfluxVersion.Flux) {
          body.basicAuthUser = user ?? '';
          body.jsonData = {
            version,
            httpHeaderName1,
            organization,
            dbName: database ?? '', // For InfluxQl `database` was deprecated for this prop `dbName` https://grafana.com/docs/grafana/latest/datasources/influxdb/#provision-the-data-source
          };
          body.orgId = body.orgId;

          if (httpHeaderValue1) {
            body.secureJsonData = body.secureJsonData ?? {};
            body.secureJsonData.httpHeaderValue1 = httpHeaderValue1;
          }
        }
        break;

      case 'graphite':
        body.jsonData.graphiteVersion = version;
        break;

      case 'postgres':
        body.jsonData.postgresVersion = Number(version);
        body.database = database;
        body.user = user;
        break;

      case 'mysql':
        body.user = user;
        body.jsonData = { database };
        break;

      case 'prometheus':
        body.jsonData = {
          oauthPassThru: authMethod === AuthMethod.OAuthForward,
          httpMethod: 'POST',
          sigV4Auth: false,
        };
        if (authMethod === AuthMethod.BasicAuth) {
          body.basicAuthUser = user;
        }
        body.typeLogoUrl = 'public/app/plugins/datasource/prometheus/img/prometheus_logo.svg';
        break;
    }
    return body;
  }
  return {};
};

function getAllDataSources(): DataSourceInstanceSettings[] {
  return Object.values(config.datasources);
}

export const DataSourcesApi = appCreateApi({
  baseQuery: backendSrvBaseQuery({ baseUrl: '/api/datasources' }),
  reducerPath: 'grafana-datasources',
  tagTypes: [dataSourcesTagType],
  endpoints: (build) => ({
    createDatasource: build.mutation<DatasourceResponse, Datasources>({
      query: (dsType) => {
        const body = getDataSourceBody('create', dsType);

        return {
          method: 'POST',
          data: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          showSuccessAlert: false,
        };
      },
      invalidatesTags: [dataSourcesTagType],
    }),
    updateDatasource: build.mutation<DatasourceResponse, DataSourceDTO>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const { uid, name, type } = args;

        const foundDupeDSName = getAllDataSources().find((elem) => elem.name === name);
        if (foundDupeDSName) {
          return { error: { data: { message: `Datasource with name ${name} already exists.` } } as QueryError };
        } else {
          const body = getDataSourceBody('update', type as Datasources, args);
          const options: Partial<BackendSrvRequest> = {
            url: `/uid/${uid}`,
            method: 'PUT',
            data: JSON.stringify(body),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          };

          const response = await baseQuery(options);
          if (!!response.error) {
            return { error: response.error as QueryError };
          }
          return { data: response.data as DatasourceResponse };
        }
      },
      invalidatesTags: [dataSourcesTagType],
    }),
  }),
});

export const { useCreateDatasourceMutation, useUpdateDatasourceMutation } = DataSourcesApi;

import { cx } from '@emotion/css';
import { Icon, IconName, IconSize, useStyles2 } from '@grafana/ui';
import { ICON_GRADIENT_END_COLOR, ICON_GRADIENT_START_COLOR } from 'feature/common/utils/styles';
import { getIconTransform } from 'feature/onboarding/utils/styles';
import React from 'react';
import { getDataConnectCardIconStyles } from 'feature/onboarding/components/PrometheusTooltip/DataConnectCardIcon.styles';

interface DataConnectIcon {
  label: string;
  icon: IconName;
  disabled: boolean;
  size?: IconSize;
}

export function DataConnectCardIcon({ icon }: { icon: DataConnectIcon }): JSX.Element {
  const styles = useStyles2(getDataConnectCardIconStyles);
  return (
    <div className={cx(styles.wrapper, icon.disabled && styles.disabled)}>
      <svg className={styles.svg} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="DataConnectCardIcon">
            <stop stopColor={ICON_GRADIENT_START_COLOR} offset="0%"></stop>
            <stop stopColor={ICON_GRADIENT_END_COLOR} offset="100%"></stop>
          </linearGradient>
        </defs>
      </svg>
      <div
        className={styles.icon}
        style={{
          transform: getIconTransform(icon.icon),
        }}
      >
        <Icon size={icon.size || 'xxl'} aria-label={icon.label} name={icon.icon} />
      </div>
      <div className={styles.label}>{icon.label}</div>
    </div>
  );
}

export const topPrioritySourceIds = [
  'hmInstancePromId',
  'datasource-influxdb',
  'mysql',
  'linux-node',
  'datasource-postgres',
  'k8s-monitoring-app',
  'datasource-marcusolsson-csv-datasource',
  'datasource-grafana-googlesheets-datasource',
  'mssql',
  'windows-exporter',
  'datasource-grafana-mongodb-datasource',
  'datasource-yesoreyeram-infinity-datasource',
  'docker',
  'datasource-loki',
  'datasource-simpod-json-datasource',
  'datasource-elasticsearch',
  'macos-node',
  'aws',
  'http-metrics',
  'raspberry-pi-node',
];

import React from 'react';

import { useStyles2 } from '@grafana/ui';
import { getStepsListStyles } from 'feature/onboarding/components/ImportDashboard/ImportDashboardStepsList.styles';
import { IndividualStep } from 'feature/onboarding/types/CardData';

const ImportDashboardStepsList = ({ data }: { data: IndividualStep[] }) => {
  const styles = useStyles2(getStepsListStyles);

  return (
    <div className={styles.contentInner}>
      <div className={styles.pairBox}>
        <div className={styles.stepsBox}>
          <ol>
            {data?.map((elem) => {
              return (
                <li key={elem.title} className={styles.stepItem(elem.isActive)}>
                  <h4>{elem.title}</h4>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ImportDashboardStepsList;

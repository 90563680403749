import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getImportDahsboardFormStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-bottom: 24px;
    max-width: 700px;
    width: 100%;
    height: 100vh;
    h2 {
      width: 100%;
      justify-content: center;
    }
    padding-top: 32px;
  `,
  headerWrapper: css`
    display: flex;
  `,
  tooltip: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    font-size: 12px;
  `,
  uidSubheadText: css`
    margin: 2px 0;
    font-size: 12px;
    color: ${theme.colors.text.secondary};
  `,
  textWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 19px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
    }
  `,
  table: css`
    tbody tr:nth-child(odd) {
      background-color: ${theme.colors.background.secondary};
    }

    tbody tr {
      line-height: 250px;
    }

    th,
    td {
      border-collapse: collapse;
      padding: 8px 15px;
    }
  `,
  header: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  `,
  preamble: css`
    font-size: ${theme.typography.body.fontSize};
    color: ${theme.colors.text.primary};
  `,
  contentContainer: css`
    padding: 24px 18px;
    background-color: ${theme.colors.background.primary};
    margin-top: 26px;
    border: 1px solid ${theme.colors.border.weak};
  `,
  alert: css`
    margin-top: ${theme.spacing(1)};
  `,
});

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getDataConnectCardIconStyles = (theme: GrafanaTheme2) => ({
  icon: css`
    svg {
      fill: url(#DataConnectCardIcon);
    }
  `,
  disabled: css`
    opacity: 0.4;
  `,
  label: css`
    padding-top: 10px;
  `,
  svg: css`
    position: absolute;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    max-width: 60px;
  `,
});

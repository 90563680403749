import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';
import { integrationKeywords } from 'feature/common/consts/integrationKeywords';
import { topPrioritySourceIds } from 'feature/common/consts/topPriorityConnections';
import { CONNECTION_GROUPS, CONNECTIONS_TO_EXCLUDE } from 'feature/common/consts/consts';

function compareSourcesByPriority(a: DataToConnect, b: DataToConnect): number {
  let aIndex;
  if (topPrioritySourceIds.includes(a.id)) {
    aIndex = topPrioritySourceIds.indexOf(a.id);
  } else {
    const aChildIndex = (a.children || []).findIndex((child) => topPrioritySourceIds.includes(child.id));
    if (aChildIndex >= 0) {
      aIndex = topPrioritySourceIds.indexOf((a.children || [])[aChildIndex].id);
    } else {
      aIndex = topPrioritySourceIds.length;
    }
  }

  let bIndex;
  if (topPrioritySourceIds.includes(b.id)) {
    bIndex = topPrioritySourceIds.indexOf(b.id);
  } else {
    const bChildIndex = (b.children || []).findIndex((child) => topPrioritySourceIds.includes(child.id));
    if (bChildIndex >= 0) {
      bIndex = topPrioritySourceIds.indexOf((b.children || [])[bChildIndex].id);
    } else {
      bIndex = topPrioritySourceIds.length;
    }
  }
  return aIndex - bIndex;
}

export function onSearchConnection(searchTerm = '', items: DataToConnect[] = []) {
  if (items && items.length > 0) {
    let result = [...items].filter((item) => !CONNECTIONS_TO_EXCLUDE.includes(item.id));
    if (searchTerm) {
      result = result.filter((integration) => {
        const keywords = (integration.search_keywords ?? integrationKeywords[integration.id]) || [];
        const integrationHasKeyword = keywords.filter((keyword: string) => keyword.includes(searchTerm));
        return integration.name?.toLowerCase().includes(searchTerm) || integrationHasKeyword?.length > 0;
      });
    }
    result = groupConnections(result);
    result = result.sort((a, b) => a.name?.localeCompare(b.name));
    result = result.sort(compareSourcesByPriority);
    return result;
  }
  return [];
}

function groupConnections(items: DataToConnect[] = []): DataToConnect[] {
  const result = [...items];
  CONNECTION_GROUPS.forEach((group) => {
    let groupObjChildren: DataToConnect[] = [];
    group.children.forEach((groupChildId) => {
      const index = result.findIndex((item) => item.id === groupChildId);
      if (index >= 0) {
        groupObjChildren.push({ ...result[index] });
        result.splice(index, 1);
      }
    });
    if (groupObjChildren.length > 0) {
      const groupItem: DataToConnect = {
        id: `${group.id}-group`,
        name: `${group.name}`,
        type: DataToConnectType.Group,
        logo_url: '',
        mostPopular: false,
        trackId: `${group.id}-options-connection`,
      };
      groupItem.logo_url = groupObjChildren[0].logo_url;
      groupItem.mostPopular = groupObjChildren.some((elem) => elem.mostPopular);
      groupItem.children = groupObjChildren;
      result.push(groupItem);
    }
  });

  const datasources = result.filter((item) => item.type === DataToConnectType.DataSource);
  const integrations = result.filter((item) => item.type === DataToConnectType.AgentIntegration);
  datasources.forEach((ds, dsIndex) => {
    const dataSourceId = ds.id.replace('datasource-', '');
    const integrationsWithDSId = integrations.find((i) => i.id === dataSourceId);
    if (integrationsWithDSId) {
      const integration = { ...integrationsWithDSId };
      const groupItem: DataToConnect = {
        id: `${integration.id}-group`,
        name: `${integration.name}`,
        type: DataToConnectType.Group,
        logo_url: integration.logo_url,
        mostPopular: integration.mostPopular || ds.mostPopular,
        children: [integration, ds],
        trackId: `${integration.id}-options-connection`,
      };
      result.splice(
        result.findIndex((i) => i.id === ds.id),
        1
      );
      result.splice(
        result.findIndex((i) => i.id === integrationsWithDSId.id),
        1
      );
      result.push(groupItem);
    }
  });
  return result;
}

import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { getUsageSectionStyles } from 'feature/home/components/refresh-design/UsageSection.styles';
import { MetricsUsage } from 'feature/usage/components/refresh-design/MetricsUsage';
import { IRMUsage } from 'feature/usage/components/refresh-design/IRMUsage';
import { LogsUsage } from 'feature/usage/components/refresh-design/LogsUsage';
import { ProfilesUsage } from 'feature/usage/components/refresh-design/ProfilesUsage';
import { TracesUsage } from 'feature/usage/components/refresh-design/TracesUsage';
import { ActiveUsersUsage } from 'feature/usage/components/refresh-design/ActiveUsersUsage';
import { K6TestsUsage } from 'feature/usage/components/refresh-design/K6TestsUsage';
import { FeO11YUsage } from 'feature/usage/components/refresh-design/FeO11YUsage';
import { AppO11YUsage } from 'feature/usage/components/refresh-design/AppO11YUsage';
import { SyntheticMonitoringUsage } from 'feature/usage/components/refresh-design/SyntheticMonitoringUsage';
import { useGetBillingDashboardQuery } from 'feature/common/api/BillingDashboardApi';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { HomeCustomLink } from 'feature/home/components/refresh-design/HomeCustomLink';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { useOrgFree } from 'feature/common/hooks/useOrgFree';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';

export const UsageSection = () => {
  const styles = useStyles2(getUsageSectionStyles);
  const { data: billingDashboard } = useGetBillingDashboardQuery();
  const { data: isFree, isLoading, isSuccess } = useOrgFree();

  let usageDescription = '';
  if (isSuccess) {
    if (isFree === true) {
      usageDescription = 'Metered monthly usage included in your Free account plan';
    } else {
      usageDescription = 'Details on your current usage';
    }
  }

  return isLoading || isSuccess ? (
    <div data-testid={DataTestId.METRICS_CONTAINER_ID}>
      <div className={styles.headerTitle}>Usage</div>
      {isLoading && <LoadingIndicator />}
      {isSuccess && isFree !== undefined && (
        <div className={styles.card}>
          <div className={styles.description}>{usageDescription}</div>
          <div className={styles.gridMetrics} data-testid={DataTestId.METRICS_USAGE_CONTAINER}>
            <MetricsUsage />
            <LogsUsage />
            <TracesUsage />
            <K6TestsUsage />
            <ProfilesUsage />
            <ActiveUsersUsage />
            <IRMUsage />
            <FeO11YUsage />
            <AppO11YUsage />
            <SyntheticMonitoringUsage />
          </div>
          {!!billingDashboard && (
            <div className={styles.dashboardButton}>
              <HomeCustomLink
                id={'billing-dashboard'}
                text="Billing dashboard"
                href={billingDashboard?.url || ''}
                trackId={ComponentKey.Accounts.BillingDashboardLink}
              />
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};

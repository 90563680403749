import React, { ReactNode, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface LinkOrSpanProps {
  to?: string;
  className?: string;
  children: ReactNode;
}

export const LinkOrSpan = (props: LinkOrSpanProps): ReactElement => {
  const { to, className, children } = props;
  if (to) {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  }
  return <span className={className}>{children}</span>;
};

import { appCreateApi } from 'app/context';
import * as baseApi from 'feature/common/api/baseApi';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { externalPaths } from 'feature/common/consts/appPaths';
import { topPrioritySourceIds } from 'feature/common/consts/topPriorityConnections';
import { INTEGRATIONS } from 'test/mocks/connectionsMock';
import { QueryError } from 'feature/common/types/QueryError';

const PLUGIN_ID = 'grafana-easystart-app';

// what the integrations api returns in body in case of success
interface SuccessfulIntegrationsResult<D> {
  status: 'success';
  data: D;
}

type IntegrationsPayload = {
  stackId: string;
  logoTheme: 'dark' | 'light';
};

const transformResponse = (response: SuccessfulIntegrationsResult<Record<string, any>>, logoTheme: string) => {
  if (!!response) {
    return Object.keys(response.data).map((key) => {
      const item = response.data[key];
      const newIntegration = {
        ...item,
        id: key,
        mostPopular: topPrioritySourceIds.includes(key),
        logo_url: item.logo[`${logoTheme}_theme_url`],
        externalUrl: `${externalPaths.integrationsAndConnections}/${key}`,
        trackId: `${key}-monitor-connection`,
      };
      delete newIntegration.logo;

      return newIntegration;
    });
  }
  return response;
};

export const IntegrationsApi = appCreateApi({
  baseQuery: baseApi.backendSrvBaseQuery({ baseUrl: `api/plugin-proxy/${PLUGIN_ID}` }),
  reducerPath: 'integrations',
  endpoints: (build) => ({
    getIntegrations: build.query<DataToConnect[], IntegrationsPayload>({
      queryFn: async ({ stackId, logoTheme }, api, extraOptions, baseQuery) => {
        let response;
        if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
          response = { data: INTEGRATIONS as unknown as SuccessfulIntegrationsResult<Record<string, any>> };
        } else {
          response = await baseQuery(`/int-api-editor/v2/stacks/${stackId}/integrations`);
        }
        if (!!response.data) {
          return {
            data: transformResponse(response.data as SuccessfulIntegrationsResult<Record<string, any>>, logoTheme),
          };
        } else if (!!response.error) {
          return { error: response.error as QueryError };
        } else {
          return {
            error: { status: -1, code: 'ERROR', data: { message: `unknown error in getIntegrations` } },
          };
        }
      },
    }),
  }),
});

export const { useGetIntegrationsQuery } = IntegrationsApi;

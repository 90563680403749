import React from 'react';

import { useStyles2 } from '@grafana/ui';
import { Icon, IconTypes } from 'feature/common/components/icons';
import { getStyles } from 'feature/help/components/HelpPanel.styles';
import { Action, hasAnyAccess } from 'feature/common/utils/UserUtils';
import { supportLink } from 'feature/common/utils/linkUtils';

import { ROLE } from 'feature/common/types/User';

export const SupportTicketLink = () => {
  const styles = useStyles2(getStyles);

  if (!hasAnyAccess(ROLE.ANY, Action.SupportTicketsRead, Action.SupportTicketsWrite)) {
    return null;
  }

  return (
    <li key={'Support ticket'} className={styles.linkIconAndText}>
      <a key={'Support ticket'} target="_self" className={styles.link} href={supportLink()}>
        <Icon className={styles.icon} logo={IconTypes.SUPPORT} />
        <span>{'Support ticket'}</span>
      </a>
    </li>
  );
};

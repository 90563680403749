import cloudIcon from 'img/cloud-icon.svg';
import cloudWatchDash from 'img/cloudwatch-dashboard.svg';
import cloudwatch from 'img/cloudwatch.png';
import customDash from 'img/custom-dashboard.svg';
import dockerDash from 'img/docker-dashboard.svg';
import docker from 'img/docker.png';
import elasticsearch from 'img/elasticsearch.svg';
import graphiteColor from 'img/graphite-color.svg';
import graphite from 'img/graphite.svg';
import influxdb from 'img/influxdb.svg';
import kafkaDash from 'img/kafka-dashboard.svg';
import kubeColor from 'img/kube-color.svg';
import kubernetesDash from 'img/kubernetes-dashboard.svg';
import kubernetes from 'img/kubernetes-logo.svg';
import kubernetesSmall from 'img/kubernetes-logo-small.svg';
import linuxDash from 'img/linux-dashboard.svg';
import linux from 'img/linux.svg';
import linuxColor from 'img/linux-color.svg';
import lokiColor from 'img/loki-color.svg';
import loki from 'img/loki.svg';
import macosDash from 'img/macos-dashboard.svg';
import macos from 'img/macos.svg';
import macosColor from 'img/macos-color.svg';
import mongodbDash from 'img/mongodb-dashboard.svg';
import mongodb from 'img/mongodb.svg';
import mysqlDash from 'img/mysql-dashboard.svg';
import mysql from 'img/mysql.png';
import node from 'img/node.svg';
import kafka from 'img/kafka.svg';
import kafkaLightMode from 'img/kafka_black.svg';
import k6Color from 'img/k6-color.svg';
import nomad from 'img/nomad.svg';
import redis from 'img/redis.svg';
import nodeDash from 'img/node-dashboard.svg';
import nomadDash from 'img/nomad-dashboard.svg';
import postgresql from 'img/postgresql.svg';
import prometheusColor from 'img/prometheus-color.svg';
import prometheusDash from 'img/prometheus-dashboard.svg';
import prometheus from 'img/prometheus.svg';
import raspberryPi from 'img/raspberry-pi.svg';
import raspberryPiColor from 'img/raspberry-pi-color.svg';
import raspberryPiDash from 'img/raspberrypi-dashboard.svg';
import redisDash from 'img/redis-dashboard.svg';
import sampleData from 'img/sample-data.svg';
import syntheticMonitoring from 'img/synthetic-monitoring.svg';
import templateDash from 'img/template-dashboard.svg';
import tempoColor from 'img/tempo-color.svg';
import tempo from 'img/tempo.svg';
import windows from 'img/windows.svg';
import windowsColor from 'img/windows-color.svg';
import citiBikesNYCDash from 'img/DashboardScreenshots/citi-bikes-nyc.png';
import lifeExpectancyDash from 'img/DashboardScreenshots/life-expectancy.png';
import prometheusMetricsOverviewDash from 'img/DashboardScreenshots/prometheus-metrics-overview.png';
import serviceHealthMonitoringDash from 'img/DashboardScreenshots/service-health-monitoring.png';
import timeSeriesVizDash from 'img/DashboardScreenshots/time-series-viz.png';
import websitePerformanceDash from 'img/DashboardScreenshots/website-performance.png';
import openAlertIncidentsViolationsDash from 'img/DashboardScreenshots/open-alert-incidents-violations.png';
import httpMetrics from 'img/http-metrics.svg';
import aws from 'img/aws.svg';
import gcp from 'img/gcp.svg';
import azure from 'img/azure.svg';
import openTelemetry from 'img/open-telemetry.png';
import faro from 'img/faro-app.svg';
import awsDark from 'img/aws-dark-icon.svg';
import awsLight from 'img/aws-light-icon.svg';
import awsAthena from 'img/aws-athena.svg';
import awsRedshift from 'img/aws-redshift.svg';
import awsTimestream from 'img/aws-timestream.svg';
import awsXRay from 'img/aws-x-ray.svg';
import awsSitewise from 'img/aws-sitewise.svg';
import gcpBigQuery from 'img/bigquery.png';
import gcpLogging from 'img/gcloud-logging.svg';
import gcpMonitoring from 'img/gcloud-monitoring.svg';
import gcpAnalytics from 'img/google-analytics.svg';
import gcpCalendar from 'img/google-calendar.svg';
import googleSheets from 'img/googlesheets.svg';
import azureMetrics from 'img/azure-metrics.png';
import azureEventHubs from 'img/azure-event-hubs.png';
import azureDataExplorer from 'img/azure-data-explorer.png';
import azureDevops from 'img/azure-devops.png';
import azureMonitor from 'img/azure-monitor.jpeg';
import gcpLogs from 'img/gcp-logs.png';
import gcpMetrics from 'img/gcp-metrics.png';
import single from 'img/DashboardScreenshots/single.png';
import multiple from 'img/DashboardScreenshots/multiple.png';
import quickpizza from '/img/DashboardScreenshots/quickpizza-sre-demodashboard.png';
import weather from '/img/DashboardScreenshots/weather-demodashboard.png';
import selfHosted from 'img/DashboardScreenshots/selfHosted.png';
import pyroscope from 'img/pyroscope.svg';
import observability from 'img/grafana-icon.svg';
import grafanaAgent from 'img/grafana-agent.svg';
import testing from 'img/testing.svg';
import demoDashPurple from 'img/demo-dash-purple-icon.svg';
import rocketOrange from 'img/rocket-orange-icon.svg';
import jsonApi from 'img/json-api-icon.svg';
import csv from 'img/csv-icon.svg';
import createDashboardsLarge from 'img/create-dashboard-large.svg';
import scaleCentralizeLarge from 'img/scale-centralize-large.svg';
import searchLarge from 'img/search-large.svg';
import kubernetesMonitoring from 'img/kubernetes-monitoring.png';
import applicationObservability from 'img/DashboardScreenshots/app-observability.png';
import o11y from 'img/o11y.svg';
import rocket from 'img/rocket.svg';
import sreOverviewDash from 'img/DashboardScreenshots/sre-overview-dash.png';
import weatherDash from 'img/DashboardScreenshots/weather-dash.png';
import k8sMonitoringDash from 'img/DashboardScreenshots/k8s-monitoring-dash.png';
import appO11yDash from 'img/DashboardScreenshots/app-o11y-dash.png';
import logsDash from 'img/DashboardScreenshots/logs-dash.png';
import profilingDash from 'img/DashboardScreenshots/profiling-dash.png';
import grafanaCloud from 'img/logsOnboarding/grafana-cloud.svg';
import stackedDashboard from 'img/logsOnboarding/stacked-dashboard.svg';
import arrowRight from 'img/logsOnboarding/arrow-right.svg';
import cloudCluster from 'img/logsOnboarding/cloud-cluster.svg';
import sourceCluster from 'img/logsOnboarding/source-cluster.svg';
import alloy from 'img/alloy.svg';
import awsFire from 'img/aws-firehose.svg';
import cloudflare from 'img/cloudflare.svg';
import datadog from 'img/datadog.svg';
import fluent from 'img/fluent.svg';
import heroku from 'img/heroku.svg';
import grafanaOrange from 'img/grafana-orange-icon.svg';
import newRelic from 'img/new-relic.svg';
import oracleCloud from 'img/oracle-cloud.svg';
import splunk from 'img/splunk.svg';
import sumoLogic from 'img/sumo-logic.svg';

export const customIcon = {
  ['prometheus']: prometheus,
  ['graphite']: graphite,
  ['loki']: loki,
  ['tempo']: tempo,
  ['prometheus-color']: prometheusColor,
  ['graphite-color']: graphiteColor,
  ['loki-color']: lokiColor,
  ['tempo-color']: tempoColor,
  ['kube-color']: kubeColor,
  ['macos']: macos,
  ['macos-color']: macosColor,
  ['windows']: windows,
  ['windows-color']: windowsColor,
  ['linux']: linux,
  ['linux-color']: linuxColor,
  ['synthetic-monitoring']: syntheticMonitoring,
  ['sample-data']: sampleData,
  ['cloud-icon']: cloudIcon,
  ['raspberry-pi']: raspberryPi,
  ['docker']: docker,
  ['mysql']: mysql,
  ['mongodb']: mongodb,
  ['influxdb']: influxdb,
  ['postgresql']: postgresql,
  ['kubernetes']: kubernetes,
  ['kubernetes-logo-small']: kubernetesSmall,
  ['elasticsearch']: elasticsearch,
  ['cloudwatch']: cloudwatch,
  ['node']: node,
  ['kafka']: kafka,
  ['kafka-black']: kafkaLightMode,
  ['nomad']: nomad,
  ['redis']: redis,
  ['raspberry-pi-color']: raspberryPiColor,
  ['k6-color']: k6Color,
  ['testing']: testing,
  ['http-metrics']: httpMetrics,
  ['open-telemetry']: openTelemetry,
  ['aws']: aws,
  ['gcp']: gcp,
  ['azure']: azure,
  ['aws-athena']: awsAthena,
  ['aws-redshift']: awsRedshift,
  ['aws-timestream']: awsTimestream,
  ['aws-x-ray']: awsXRay,
  ['aws-sitewise']: awsSitewise,
  ['big-query']: gcpBigQuery,
  ['gcp-logs']: gcpLogs,
  ['gcp-metrics']: gcpMetrics,
  ['gcp-logging']: gcpLogging,
  ['gcp-monitoring']: gcpMonitoring,
  ['gcp-analytics']: gcpAnalytics,
  ['gcp-calendar']: gcpCalendar,
  ['googlesheets']: googleSheets,
  ['azure-metrics']: azureMetrics,
  ['azure-event-hubs']: azureEventHubs,
  ['azure-data-explorer']: azureDataExplorer,
  ['azure-devops']: azureDevops,
  ['azure-monitor']: azureMonitor,
  ['pyroscope']: pyroscope,
  ['observability']: observability,
  ['faro']: faro,
  ['grafana-agent']: grafanaAgent,
  ['demo-dash-purple']: demoDashPurple,
  ['rocket-orange']: rocketOrange,
  ['json-api']: jsonApi,
  ['csv']: csv,
  ['create-dashboard-large']: createDashboardsLarge,
  ['scale-centralize-large']: scaleCentralizeLarge,
  ['search-large']: searchLarge,
  ['o11y']: o11y,
  ['rocket']: rocket,
  ['heroku']: heroku,
  ['alloy']: alloy,
  ['cloudflare']: cloudflare,
  ['datadog']: datadog,
  ['fluent']: fluent,
  ['grafana-orange']: grafanaOrange,
  ['new-relic']: newRelic,
  ['oracle-cloud']: oracleCloud,
  ['splunk']: splunk,
  ['sumo-logic']: sumoLogic,
  ['aws-firehose']: awsFire,
};

export const dashboardImg = {
  ['cloudwatch-dashboard']: cloudWatchDash,
  ['custom-dashboard']: customDash,
  ['docker-dashboard']: dockerDash,
  ['kubernetes-dashboard']: kubernetesDash,
  ['linux-dashboard']: linuxDash,
  ['mysql-dashboard']: mysqlDash,
  ['prometheus-dashboard']: prometheusDash,
  ['raspberrypi-dashboard']: raspberryPiDash,
  ['template-dashboard']: templateDash,
  ['redis-dashboard']: redisDash,
  ['nomad-dashboard']: nomadDash,
  ['macos-dashboard']: macosDash,
  ['kafka-dashboard']: kafkaDash,
  ['node-dashboard']: nodeDash,
  ['mongodb-dashboard']: mongodbDash,
  ['citi-bikes-nyc-dashboard']: citiBikesNYCDash,
  ['life-expectancy-dashboard']: lifeExpectancyDash,
  ['prometheus-metrics-overview-dashboard']: prometheusMetricsOverviewDash,
  ['service-health-monitoring-dashboard']: serviceHealthMonitoringDash,
  ['time-series-viz-dashboard']: timeSeriesVizDash,
  ['website-performance-dashboard']: websitePerformanceDash,
  ['open-alert-incidents-violations']: openAlertIncidentsViolationsDash,
  ['single-linux-node']: single,
  ['multiple-linux-node']: multiple,
  ['self-hosted-metrics']: selfHosted,
  ['quickpizza-sre-demodashboard']: quickpizza,
  ['weather-demodashboard']: weather,
  ['kubernetes-monitoring']: kubernetesMonitoring,
  ['application-observability']: applicationObservability,
  ['sre-overview-dash']: sreOverviewDash,
  ['weather-dash']: weatherDash,
  ['k8s-monitoring-dash']: k8sMonitoringDash,
  ['app-o11y-dash']: appO11yDash,
  ['logs-dash']: logsDash,
  ['profiling-dash']: profilingDash,
};

export const hostedIcons = {
  ['graphite-color']: graphiteColor,
  ['http-metrics']: httpMetrics,
  ['loki-color']: lokiColor,
  ['tempo-color']: tempoColor,
  ['prometheus-color']: prometheusColor,
  ['open-telemetry']: openTelemetry,
  ['pyroscope']: pyroscope,
};

export const cloudAppIcons = {
  ['synthetic-monitoring']: syntheticMonitoring,
  ['kubernetes']: kubernetes,
  ['k6-color']: k6Color,
  ['faro']: faro,
  ['aws-dark']: awsDark,
  ['aws-light']: awsLight,
  ['pyroscope']: pyroscope,
  ['observability']: observability,
};

export const logsOnboardingIcons = {
  ['stacked-dashboard']: stackedDashboard,
  ['grafana-cloud']: grafanaCloud,
  ['arrow-right']: arrowRight,
  ['cloud-cluster']: cloudCluster,
  ['source-cluster']: sourceCluster,
};

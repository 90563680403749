import React from 'react';
import { formatUsageValue, pluralizeWord } from '../utils/usageUtils';
import { Metric } from './Metric';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { UsageProps } from 'feature/usage/types/UsageData';

export function ProUsersUsage({ usage, isAdmin, billingDashboard }: UsageProps): JSX.Element | null {
  return (
    <div data-testid={DataTestId.PRO_USERS_USAGE_METRIC_ID}>
      {isAdmin === false ? null : (
        <Metric
          id="metric_pro_users"
          label={''}
          link={billingDashboard?.url}
          value={formatUsageValue(usage, MetricUnit.USERS, false)}
          unit={pluralizeWord(Number(usage), 'User')}
        ></Metric>
      )}
    </div>
  );
}

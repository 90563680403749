import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { LogsCard } from './LogsCard';
import { logsOnboardingData } from 'feature/onboarding/data/LogsOnboarding.data';
import { LogsCardData, LogsKeys } from 'feature/onboarding/types/LogsOnboardingTypes';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `,
  cardWrapper: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    gap: ${theme.spacing(2)};

    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    text-align: left;
  `,
});

export function LogsCardsSelection({
  selectedCard,
  setSelectedCard,
}: {
  selectedCard: LogsKeys;
  setSelectedCard: (key: LogsKeys) => void;
}) {
  const styles = useStyles2(getStyles);
  const data = logsOnboardingData;
  return (
    <div className={styles.wrapper}>
      <h3>{data.header}</h3>
      <div className={styles.cardWrapper}>
        {data.cards.map((card: LogsCardData) => (
          <LogsCard
            card={card as LogsCardData}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            key={card.key}
          />
        ))}
      </div>
    </div>
  );
}

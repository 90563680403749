import React, { useContext, useState } from 'react';

import { Button, LinkButton, useStyles2, CustomScrollbar } from '@grafana/ui';
import { Icon, IconTypes } from 'feature/common/components/icons';
import grafanaCloudLogo from 'img/grafana-cloud-logo-color.svg';
import grafanaMachineLearning from 'img/grafana-machine-learning-logo.png';
import demoDashboards from 'img/dashboards.svg';
import { getStyles } from 'feature/help/components/HelpPanel.styles';
import { SupportTicketLink } from 'feature/help/components/SupportTicketLink';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { trackAction, ActionType } from 'feature/common/utils/tracking';
import { QuickstartModal } from 'feature/help/components/modals/QuickstartModal';
import { DemoDataModal } from 'feature/help/components/modals/DemoDataModal';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { getPanelStyles } from 'feature/Panel.styles';
import { appPaths } from 'feature/common/consts/appPaths';

import { AppContext } from 'App';

export const HelpPanel = () => {
  const panelStyles = useStyles2(getPanelStyles);
  const styles = useStyles2(getStyles);
  const [shouldShowQuickstartsModal, setShouldShowQuickstartsModal] = useState(false);
  const [shouldShowDemoDashModal, setShouldShowDemoDashModal] = useState(false);
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, key, ActionType.click, orgId);
  }

  return (
    <div data-testid={DataTestId.HELP_PANEL_CONTAINER_ID} className={panelStyles.wrapper}>
      <div className={panelStyles.titleWrapper}>
        <div className={panelStyles.title}>Learn more</div>
      </div>
      <div className={panelStyles.contentWrapper}>
        <ul className={styles.links}>
          <li key={'Documentation'} className={styles.linkIconAndText}>
            <a key={'Documentation'} className={styles.link} href={'https://grafana.com/docs/grafana-cloud/'}>
              <Icon className={styles.icon} logo={IconTypes.DOCUMENTATION} />
              <span>{'Documentation'}</span>
            </a>
          </li>

          <SupportTicketLink />

          <li key={'Community'} className={styles.linkIconAndText}>
            <a key={'Community'} className={styles.link} href={'https://community.grafana.com/'}>
              <Icon className={styles.icon} logo={IconTypes.COMMUNITY} />
              <span>{'Community'}</span>
            </a>
          </li>
        </ul>
        <CustomScrollbar autoHeightMin={100} autoHeightMax={`calc(100% - 46px)`}>
          <div className={styles.cardsContainer}>
            <LinkButton
              target="_blank"
              rel="noreferrer noopener"
              fill="text"
              href={appPaths.docsPlugin}
              className={styles.card}
            >
              <img src={grafanaMachineLearning} alt="Tutorials" />
              <div className={styles.allText}>
                <div className={styles.helpText}>Tutorials</div>
                In-depth guides with step-by-step instructions
              </div>
            </LinkButton>
            <LinkButton
              href="https://grafana.com/docs/grafana-cloud/data-configuration/get-started-data/"
              onClick={() => {
                onComponentClick(ComponentKey.ConnectDataDocsFromHomeApp);
              }}
              target="_blank"
              rel="noreferrer noopener"
              fill="text"
              className={styles.card}
            >
              <img src={grafanaCloudLogo} alt="Grafana Cloud Logo" />
              <div className={styles.allText}>
                <div className={styles.helpText}>Connecting Data</div>
                Learn how to get started with Grafana Cloud by connecting data
              </div>
            </LinkButton>
            <Button
              onClick={() => {
                setShouldShowDemoDashModal(true);
                onComponentClick(ComponentKey.QuickstartFromHomeApp);
              }}
              fill="text"
              className={styles.card}
            >
              <img src={demoDashboards} alt="Demo dashboards" />
              <div className={styles.allText}>
                <div className={styles.helpText}>Demo Dashboards</div>
                Check out example dashboard & visualizations in a sandbox environment to learn what you can build with
                Grafana Cloud
              </div>
            </Button>
          </div>
        </CustomScrollbar>
        {shouldShowQuickstartsModal && (
          <QuickstartModal isOpen={shouldShowQuickstartsModal} onClose={() => setShouldShowQuickstartsModal(false)} />
        )}
        {shouldShowDemoDashModal && (
          <DemoDataModal isOpen={shouldShowDemoDashModal} onClose={() => setShouldShowDemoDashModal(false)} />
        )}
      </div>
    </div>
  );
};

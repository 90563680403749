import { useRoutes } from 'react-router-dom';
import React from 'react';
import { ControlOnboardingScreen } from '../ControlOnboarding/ControlOnboardingScreen';
import { controlOnboardingPaths } from 'feature/onboarding/data/ControlOnboarding.data';

export const RoutingScreen: React.FC = () => {
  const routes = controlOnboardingPaths.map((item) => ({
    path: item.path,
    element: <ControlOnboardingScreen step={item.step} />,
  }));

  return useRoutes(routes);
};

import { AppPlugin, AppRootProps, OrgRole } from '@grafana/data';
import { onPluginPreload } from './preload';
import { ComponentClass } from 'react';
import { App } from './App';
// @ts-ignore
import packageJson from '../package.json';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { getFaroConfig } from './faroConfig';
import { PLUGIN_URL } from 'feature/common/utils/misc';
import { ContextUser } from 'feature/common/types/ContextServType';
import { getContextUser } from 'feature/common/utils/UserUtils';

const { environment, url, name } = getFaroConfig();
const version = packageJson.version;
const FARO_GLOBAL_OBJECT_KEY = 'cloud-home-app-faro';
const user: ContextUser = getContextUser() ?? {
  id: -1,
  name: '',
  orgId: -1,
  email: '',
  orgName: '',
  orgRole: OrgRole.Viewer,
};

export const faro = Object.prototype.hasOwnProperty.call(window, FARO_GLOBAL_OBJECT_KEY)
  ? (window as any)[FARO_GLOBAL_OBJECT_KEY]
  : initializeFaro({
      url,
      app: {
        name,
        version,
        environment,
      },
      isolate: true,
      globalObjectKey: FARO_GLOBAL_OBJECT_KEY,
      beforeSend: (event) => {
        const isOnPluginPage = (event.meta.page?.url ?? '').includes(PLUGIN_URL);
        if (!isOnPluginPage) {
          return null;
        }

        return event;
      },
      user: {
        id: String(user.id),
        username: user.name,
        attributes: {
          orgId: String(user.orgId),
        },
      },
      instrumentations: [...getWebInstrumentations()],
    });

interface CloudHomeSettings {}

export const plugin = new AppPlugin<CloudHomeSettings>().setRootPage(App as unknown as ComponentClass<AppRootProps>);

onPluginPreload();

import { SelectableValue } from '@grafana/data';
import { InfluxVersion } from 'feature/common/types/InfluxVersion';

export const postgresVersions: Array<SelectableValue<number>> = [
  { label: '9.0', value: 900 },
  { label: '9.1', value: 901 },
  { label: '9.2', value: 902 },
  { label: '9.3', value: 903 },
  { label: '9.4', value: 904 },
  { label: '9.5', value: 905 },
  { label: '9.6', value: 906 },
  { label: '10', value: 1000 },
  { label: '11', value: 1100 },
  { label: '12', value: 1200 },
  { label: '13', value: 1300 },
  { label: '14', value: 1400 },
  { label: '15', value: 1500 },
];

export const influxVersions: Array<SelectableValue<InfluxVersion>> = [
  {
    label: 'InfluxQL - Basic Auth',
    value: InfluxVersion.InfluxQLBasic,
    description: 'Traditional method using username and password',
  },
  {
    label: 'InfluxQL - Token Auth',
    value: InfluxVersion.InfluxQLToken,
    description: 'Use for a more flexible authentication via generated tokens',
  },
  {
    label: 'Flux',
    value: InfluxVersion.Flux,
    description: 'Advanced data scripting and query language. Supported in InfluxDB 2.x and 1.8+',
  },
];

const GRAPHITE_VERSIONS = ['0.9', '1.0', '1.1'];

export const graphiteVersions = GRAPHITE_VERSIONS.map((version) => ({ label: `${version}.x`, value: version }));

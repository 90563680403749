import { config } from '@grafana/runtime';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { formatUsageValue, handleCompactFormat, pluralizeWord } from 'feature/usage/utils/usageUtils';
import React from 'react';
import { LIMIT_TITLE, USERS_FREE_INCLUDED } from '../consts/usage';
import { Metric } from './Metric';
import { UsageProps } from 'feature/usage/types/UsageData';

export function FreeUsersUsage({ usage, isAdmin }: UsageProps): JSX.Element | null {
  const link = config.featureToggles.topnav ? '/admin/users/' : '/org/users';

  return (
    <div data-testid={DataTestId.FREE_USERS_USAGE_METRIC_ID}>
      {isAdmin === false ? null : (
        <Metric
          id="metric_free_users"
          label={`${LIMIT_TITLE}: ${handleCompactFormat(USERS_FREE_INCLUDED)}`}
          link={link}
          value={formatUsageValue(usage, MetricUnit.USERS, true)}
          unit={pluralizeWord(Number(usage), 'User')}
        ></Metric>
      )}
    </div>
  );
}

import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { Button, useStyles2 } from '@grafana/ui';

import { AccessPolicyTokenStep } from 'feature/onboarding/components/LogsOnboarding/InstallationStep/AccessPolicyTokenStep';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { getSnippetStyles } from './snippet.styles';

export function FluentBitBody({ hostedData }: { hostedData?: HostedDataDetails }): JSX.Element {
  const styles = useStyles2(getSnippetStyles);
  const [token, setToken] = useState('"YOUR_GRAFANA_CLOUD_ACCESS_POLICY_TOKEN"');

  const snippet =
    '\n[OUTPUT]' +
    '\n   # for sending logs to cloud Loki instance' +
    '\n   Name        loki' +
    '\n   Match       *' +
    `\n   Host        ${hostedData?.hlInstanceUrl || '"YOUR_LOKI_INSTANCE_URL"'}` +
    '\n   port        443' +
    '\n   tls         on' +
    '\n   tls.verify  on' +
    `\n   http_user   ${hostedData?.hlInstanceId || '"YOUR_LOKI_INSTANCE_ID"'}` +
    `\n   http_passwd ${token}`;
  const snippetStr = '```bash' + snippet + '\n```';
  return (
    <div className={styles.container}>
      <h3>Step 2: Prepare your configuration file</h3>
      <span>
        To configure the Loki output plugin add this section to your Fluent Bit configuration.
        <a href="https://grafana.com/docs/loki/latest/send-data/fluentbit/#configuration-examples"> Here </a> is an
        example of the full configuration file.
      </span>
      <br />
      <br />
      <AccessPolicyTokenStep token={token} setToken={setToken} />
      <br />
      <Markdown>{snippetStr}</Markdown>

      <Button className={styles.button} icon="copy" onClick={() => navigator.clipboard.writeText(snippet)}>
        Copy to clipboard
      </Button>
    </div>
  );
}

import { getBackendSrv, getDataSourceSrv } from '@grafana/runtime';
import { DataSourceInstanceSettings } from '@grafana/data';

import * as baseApi from 'feature/common/api/baseApi';
import {
  DashboardSearchHit,
  DashboardSearchItemType,
  Folder,
  ImportDashboardDTO,
  isErrorResponse,
  PluginDashboardResponse,
  ImportData,
  InputType,
  DashboardInputs,
} from 'feature/onboarding/types/ImportDashboard';
import { datasourceConfigData } from 'feature/datasource-config/data/DatasourceConfig.data';

/** GETs */

export const getDefaultValuesForImportDashboard = (importData: ImportData): ImportDashboardDTO => {
  return {
    title: importData.dashboardToImport?.name,
    uid: importData.dashboardToImport?.json?.uid || undefined,
    gnetId: importData.dashboardToImport?.id,
    constants: [],
    dataSources: [importData.dataSourceSaved],
    elements: [],
    folder: { uid: '', title: 'General' },
  };
};

export const getDashboardId = () => {
  const regex = new RegExp(
    /^\/a\/cloud-home-app\/import-dashboard\/configure-([A-Za-z]+)\/([0-9]+)\/([A-Za-z]+)$/,
    'g'
  );
  if (regex.test(window.location.pathname)) {
    const id = window.location.pathname.split('/').splice(-2, 1)[0];
    if (!!Number(id) === true) {
      return id.toString();
    }
  }
  return undefined;
};

export const getUpdatedFormValues = (
  formValues: ImportDashboardDTO,
  defaultValues: ImportDashboardDTO,
  importData: ImportData
): ImportDashboardDTO => {
  return {
    title: formValues.title ?? defaultValues.title,
    uid: formValues.uid || undefined,
    gnetId: importData.dashboardToImport?.id ?? defaultValues.gnetId,
    constants: formValues.constants,
    dataSources: [importData.dataSourceSaved ?? defaultValues.dataSources],
    elements: [],
    folder: formValues.folder ?? defaultValues.folder,
  };
};

export function getFolderByUid(uid: string) {
  return baseApi.apiGet(`/api/folders/${uid}`);
}

async function searchAllFoldersForDashboard() {
  return await getBackendSrv().get('api/search?limit=1000');
}

export async function searchFolders(
  query: any,
  type: DashboardSearchItemType | string,
  permission?: any
): Promise<DashboardSearchHit[]> {
  return await getBackendSrv().get('/api/folders', {
    query,
    type,
    permission,
    limit: 1000,
  });
}

export function getFolderUid(folderName: string): string {
  return folderName.toLowerCase().replace(/ /g, '-');
}

async function getFolderId(folderName: string): Promise<number> {
  const folders = await baseApi.apiGet<Array<{ id: number; uid: string; title: string }>>('api/folders');
  const folder = folders.find((folder) => folder.title === folderName);
  if (!folder) {
    throw Error(`Folder with name ${folderName} not found`);
  }
  return folder.id;
}

/** Validation */
export const validateUid = async (uid: string) => {
  const escapedUid = encodeURIComponent(uid);
  return await baseApi
    .apiGet(`/api/dashboards/uid/${escapedUid}`, { showErrorAlert: false })
    .then((existingDashboard: any) => {
      return `Dashboard named '${existingDashboard?.dashboard.title}' in folder '${existingDashboard?.meta.folderTitle}' has the same UID`;
    })
    .catch((error) => {
      error.isHandled = true;
      return true;
    });
};

export const validateDashboardName = async (dashboardName: string, folderId: string) => {
  let name = (dashboardName || '').trim();
  const rootName = 'general';
  const nameLowerCased = name.toLowerCase();

  if (name.length === 0) {
    return 'Name is required';
  }

  if (nameLowerCased === rootName) {
    return 'This is a reserved name and cannot be used for a folder';
  }

  // GET ALL DASHBOARDS IN FOLDER
  const searchAllFolders = await searchAllFoldersForDashboard();
  if (searchAllFolders && searchAllFolders.find((fol: any) => fol.title === dashboardName)) {
    if (folderId.toLowerCase() === rootName) {
      // If a dashboard in the general folder has the same name, we will throw an error
      // Core allows duplicate naming, but here, we will block it for ease of Import execution
      return `A dashboard with the name ${dashboardName} already exists in ${folderId}. Please update dashboard name or folder`;
    } else if (
      // If you find a dashboard with the same name in the same folder, we will throw an error
      // Core allows duplicate naming, but here, we will block it for ease of Import execution
      searchAllFolders.find(
        (fol: any) =>
          (fol.folderUid && fol.folderUid === folderId && fol.title === dashboardName) ||
          (fol.folderTitle && fol.folderTitle === folderId && fol.title === dashboardName)
      )
    ) {
      return `A dashboard with the name ${dashboardName} already exists in ${folderId}. Please update dashboard name or folder`;
    }
  }

  const searchResults = await searchFolders(dashboardName, `${DashboardSearchItemType.DashDB}&folderIds=`);
  if (!searchResults) {
    return;
  } else {
    const results = searchResults.find((dashboard) => dashboard.title === dashboardName);
    if (results) {
      return 'Dashboard name already exists within in folder';
    }
    return;
  }
};

function setInputs(inputs: any) {
  return {
    dataSources: inputs.filter((p: any) => p.type === InputType.DataSource),
    constants: inputs.filter((p: any) => p.type === InputType.Constant),
    libraryPanels: [],
  };
}

export function isValidDatasourceType(inputs: { dataSources: any[]; constants: any[]; libraryPanels: any[] }): boolean {
  if (!!inputs && Object.keys(inputs).length > 0 && inputs.dataSources?.length > 0) {
    return Object.keys(datasourceConfigData).includes(inputs.dataSources[0].pluginId);
  }
  return false;
}

export function getDatasourceType(inputs: { dataSources: any[]; constants: any[]; libraryPanels: any[] }): string {
  if (!!inputs && Object.keys(inputs).length > 0 && inputs.dataSources?.length > 0) {
    return inputs.dataSources[0].pluginId;
  }
  return '';
}

export function formatErrorInvalidDatasource(isValidDataSource: boolean, dataSourceType: string): string {
  if (!isValidDataSource) {
    if (!!dataSourceType) {
      return `We cannot manage ${dataSourceType} data source configuration.`;
    } else {
      return `No data source is defined for this dashboard`;
    }
  }
  return '';
}

export function processInputs(dashboardJson: any) {
  if (dashboardJson && dashboardJson.__inputs) {
    const inputs: any[] = [];

    dashboardJson.__inputs.forEach((input: any) => {
      const inputModel: any = {
        name: input.name,
        label: input.label,
        info: input.description,
        value: input.value,
        type: input.type,
        pluginId: input.pluginId,
        options: [],
      };

      if (input.type === InputType.DataSource) {
        getDataSourceOptions(input, inputModel);
      } else if (!inputModel.info) {
        inputModel.info = 'Specify a string constant';
      }

      inputs.push(inputModel);
    });
    return setInputs(inputs);
  }
  return;
}

const getDataSourceOptions = (input: { pluginId: string; pluginName: string }, inputModel: any) => {
  const sources = getDataSourceSrv().getList({ pluginId: input.pluginId });

  if (sources.length === 0) {
    inputModel.info = 'No data sources of type ' + input.pluginName + ' found';
  } else if (!inputModel.info) {
    inputModel.info = 'Select a ' + input.pluginName + ' data source';
  }
};

/** CRUDs */
export async function importDashboard(
  importDashboardForm: ImportDashboardDTO,
  importData: ImportData,
  inputs?: DashboardInputs
) {
  let inputsToPersist = [] as any[];
  importDashboardForm.dataSources?.forEach((dataSource: DataSourceInstanceSettings, index: number) => {
    const input = inputs && inputs.dataSources[index];
    inputsToPersist.push({
      name: input?.name,
      type: input?.type,
      pluginId: input?.pluginId,
      value: dataSource.uid,
    });
  });

  inputs &&
    importDashboardForm.constants?.forEach((constant: any, index: number) => {
      const input = inputs?.constants[index];

      inputsToPersist.push({
        value: constant,
        name: input.name,
        type: input.type,
      });
    });

  const result: PluginDashboardResponse = await baseApi.apiPost(
    'api/dashboards/import',
    {
      dashboard: {
        ...importData.dashboardToImport.json,
        title: importDashboardForm.title,
        uid: importDashboardForm.uid || importData.dashboardToImport.uid || undefined,
      },
      overwrite: true,
      inputs: inputsToPersist,
      folderUid: importDashboardForm.folder.uid || '',
    },
    { showErrorAlert: true }
  );

  return result;
}

export async function createFolder(folderName: string, isFolderPicker?: boolean): Promise<number | Folder> {
  try {
    const folderUid = getFolderUid(folderName);
    const response = await baseApi.apiPost<{ id: number }>('/api/folders', { title: folderName, uid: folderUid });

    return !isFolderPicker ? (response as unknown as any).id : response;
  } catch (error) {
    if (isErrorResponse(error) && (error.status === 409 || error.status === 412)) {
      // folder already exists. Let's return its id.
      return await getFolderId(folderName);
    } else {
      throw error;
    }
  }
}

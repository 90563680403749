import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getCustomOnboardingIconStyles = (theme: GrafanaTheme2) => ({
  icon: css`
    width: 32px !important;
    height: 32px !important;
  `,
  separator: css`
    margin-bottom: ${theme.spacing(2)};
  `,
});

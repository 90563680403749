import { SetupCustomIconName, SetupImageName, SetupWizardStepData } from 'feature/onboarding/types/SetupWizard';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { SetupWizardBody } from 'feature/onboarding/components/SetupWizard/SetupWizardBody/SetupWizardBody';
import { DatasourceConfigPage } from 'feature/datasource-config/components/DatasourceConfigPage';
import { PrometheusHostedTooltip } from 'feature/onboarding/components/PrometheusTooltip/PrometheusHostedTooltip';
import { PrometheusDatasourceTooltip } from 'feature/onboarding/components/PrometheusTooltip/PrometheusDatasourceTooltip';
import React from 'react';
import { PrometheusTooltipHeader } from 'feature/onboarding/components/PrometheusTooltip/PrometheusTooltipHeader';
import { appPaths, externalPaths } from 'feature/common/consts/appPaths';
import { TestGroup } from '../types/TestGroup';

const setupData: { [key: string]: SetupWizardStepData } = {
  'custom-dashboard': {
    step: 'custom-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: true,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: false,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '1. Choose a data connection',
    preamble: '',
    cards: [
      {
        icons: [SetupCustomIconName.INFLUXDB],
        title: 'InfluxDB',
        text: 'Dashboard, query and alert on data from an InfluxDB compatible database',
        link: {
          key: 'custom-dashboard-influxdb-link',
          url: `${appPaths.setupWizard}/custom-dashboard/influxdb`,
        },
        key: 'influxdb-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.PROMETHEUS],
        title: 'Prometheus',
        text: 'Connect your Prometheus data to Grafana Cloud',
        link: {
          key: 'custom-dashboard-prometheus-link',
          url: `${appPaths.setupWizard}/custom-dashboard/prometheus`,
        },
        key: 'prometheus-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.LINUX],
        title: 'Linux Server',
        text: 'Prebuilt dashboards and alerts to monitor your Linux operating system',
        link: {
          key: 'custom-dashboard-linux-link',
          url: `${externalPaths.integrationsAndConnections}/linux-node`,
        },
        key: 'linux-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.GRAPHITE],
        title: 'Graphite',
        text: 'Dashboard, query and alert on data from a Graphite compatible database',
        link: {
          key: 'custom-dashboard-graphite-link',
          url: `${appPaths.setupWizard}/custom-dashboard/graphite`,
        },
        key: 'graphite-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.POSTGRESQL],
        title: 'PostgreSQL',
        text: 'Dashboard, query and alert on data from a PostgreSQL compatible database',
        link: {
          key: 'custom-dashboard-postgresql-link',
          url: `${appPaths.setupWizard}/custom-dashboard/postgresql`,
        },
        key: 'postgresql-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.MYSQL],
        title: 'MySQL',
        text: 'Dashboard, query and alert on data from your MySQL database',
        link: {
          key: 'custom-dashboard-mysql-custom-link',
          url: `${appPaths.setupWizard}/custom-dashboard/mysql`,
        },
        key: 'mysql-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.DOCKER],
        title: 'Docker',
        text: 'Prebuilt dashboards and alerts to monitor Docker',
        link: {
          key: 'custom-dashboard-docker-link',
          url: `${externalPaths.integrationsAndConnections}/docker`,
        },
        key: 'docker-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.MACOS],
        title: 'macOS',
        text: 'Prebuilt dashboards and alerts to monitor your Mac operating system',
        link: {
          key: 'custom-dashboard-macos-node-link',
          url: `${externalPaths.integrationsAndConnections}/macos-node`,
        },
        key: 'macos-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.LOKI],
        title: 'Loki',
        text: 'Ship logs to Grafana Cloud from a standalone host or Kubernetes cluster',
        link: {
          key: 'custom-dashboard-hosted-logs-link',
          url: `${externalPaths.integrationsAndConnections}/hlInstanceId`,
        },
        key: 'loki-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.RASPBERRY],
        title: 'Raspberry PI',
        text: 'Easy configuration and prebuilt dashboards to monitor Raspberry PI',
        link: {
          key: 'custom-dashboard-raspberry-pi-node-link',
          url: `${externalPaths.integrationsAndConnections}/raspberry-pi-node`,
        },
        key: 'raspberry-pi-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.ELASTICSEARCH],
        title: 'Elasticsearch',
        text: 'Dashboard, query and alert on data from an Elasticsearch compatible database',
        link: {
          key: 'custom-dashboard-elasticsearch-link',
          url: '/plugins/elasticsearch/',
        },
        key: 'elasticsearch-custom',
        style: 'row-flex',
        type: 'general',
      },
      {
        icons: [SetupCustomIconName.SYNTHETIC_MONITORING],
        title: 'Synthetic Monitoring',
        text: 'Check the performance of a URL from different locations across the globe',
        link: {
          key: 'custom-dashboard-synthetic-monitoring-link',
          url: externalPaths.syntheticMonitoringChecks,
        },
        key: 'synthetic-monitoring-custom',
        style: 'row-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: 'Skip',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.SkipCustomDashboard,
      },
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/create-dashboard`,
        key: ComponentKey.BackCustomDashboard,
      },
      more: {
        text: 'See other options →',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.MoreCustomDashboard,
        preamble: "Not finding what you're looking for?",
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <SetupWizardBody step="custom-dashboard" />,
  },
  'custom-influxdb-dashboard': {
    step: 'custom-influxdb-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '2. CONFIGURE DATA',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: 'back-influxdb',
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/influxdb',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <DatasourceConfigPage datasource={'influxdb'} />,
  },
  'custom-graphite-dashboard': {
    step: 'custom-graphite-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '2. CONFIGURE DATA',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: 'back-graphite',
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/graphite',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <DatasourceConfigPage datasource={'graphite'} />,
  },
  'custom-postgresql-dashboard': {
    step: 'custom-postgresql-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '2. CONFIGURE DATA',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: 'back-postgres',
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/postgresql',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <DatasourceConfigPage datasource={'postgres'} />,
  },
  'custom-mysql-dashboard': {
    step: 'custom-mysql-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '2. CONFIGURE DATA',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: 'back-mysql',
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/mysql',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <DatasourceConfigPage datasource={'mysql'} />,
  },
  'custom-prometheus-dashboard': {
    step: 'custom-prometheus-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: true,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: false,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '1. Choose a data connection',
    preamble: '',
    cards: [
      {
        icons: ['bolt'],
        title: 'Send Prometheus metrics to Grafana Cloud',
        text: 'You want a fully-managed Prometheus service that can ingest data from one small machine or scale to billions of metrics (if you need it!).',
        link: {
          key: 'custom-prometheus-send-metrics-link',
          url: `${externalPaths.integrationsAndConnections}/hmInstancePromId`,
        },
        key: 'custom-prometheus-dashboard-hosted',
        style: 'column-flex',
        badge: {
          icon: 'bolt',
          text: 'Grafana Agent',
          info: {
            header: <PrometheusTooltipHeader title={'Grafana Agent'} icon={'question-circle'} />,
            body: <PrometheusHostedTooltip />,
          },
        },
        type: 'general',
      },
      {
        icons: ['database'],
        title: 'Query your local Prometheus database',
        text: 'You already have data stored in a local Prometheus and you just want to visualize it in Grafana Cloud.',
        link: {
          key: 'custom-prometheus-plugins-link',
          url: 'plugins/prometheus',
        },
        badge: {
          icon: 'database',
          text: 'Data source',
          info: {
            header: <PrometheusTooltipHeader title={'Data source'} icon={'question-circle'} />,
            body: <PrometheusDatasourceTooltip />,
          },
        },
        key: 'custom-prometheus-dashboard-datasource',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: 'Skip',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.SkipCustomPrometheusDashboard,
      },
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: ComponentKey.BackCustomPrometheusDashboard,
      },
      more: {
        text: 'See other options →',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.MoreCustomPrometheusDashboard,
        preamble: "Not finding what you're looking for?",
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/prometheus',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <SetupWizardBody step="custom-prometheus-dashboard" />,
  },
  'custom-create-dashboard': {
    step: 'custom-create-dashboard',
    steps: [
      {
        title: 'Choose a data connection',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure data',
        stepNumber: '2.',
        isActive: false,
      },
      {
        title: 'Create a dashboard',
        stepNumber: '3.',
        isActive: true,
      },
    ],
    header: '3. Create a dashboard',
    cards: [
      {
        icons: [],
        title: 'Data Source Added',
        text: "Now it's time to build some panels for your first dashboard.",
        link: {
          key: 'custom-create-new-link',
          url: '/dashboard/new',
        },
        key: 'card-custom-create-dashboard',
        style: 'column-flex',
        eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
        buttons: [
          {
            text: 'Create your first dashboard panel →',
            link: '/dashboard/new',
            key: 'btn-custom-create-dashboard',
          },
        ],
        type: 'general',
      },
    ],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/custom-dashboard`,
        key: ComponentKey.BackCreateDashboardWizardCustom,
      },
    },
    path: 'Create a dashboard / Create your own',
    route: 'custom-dashboard/create-dashboard',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <SetupWizardBody step="custom-create-dashboard" />,
  },
  'template-dashboard': {
    step: 'template-dashboard',
    steps: [],
    header: '',
    preamble: '',
    cards: [
      {
        icons: [SetupCustomIconName.PROMETHEUS],
        image: SetupImageName.PROMETHEUS_DASHBOARD,
        title: 'Prometheus dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-prometheus-link',
          url: `${appPaths.setupWizard}/template-dashboard/prometheus`,
        },
        key: 'prometheus-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.LINUX],
        image: SetupImageName.LINUX_DASHBOARD,
        title: 'Linux dashboard',
        titleAlign: 'center',
        text: '',
        link: {
          key: 'template-dashboard-linux-link',
          url: `${externalPaths.integrationsAndConnections}/linux-node`,
        },
        key: 'linux-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.MYSQL],
        image: SetupImageName.MYSQL_DASHBOARD,
        title: 'MySQL dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-mysql-link',
          url: `${appPaths.setupWizard}/custom-dashboard/mysql`,
        },
        key: 'mysql-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.DOCKER],
        image: SetupImageName.DOCKER_DASHBOARD,
        title: 'Docker dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-docker-link',
          url: `${externalPaths.integrationsAndConnections}/docker`,
        },
        key: 'docker-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.MACOS],
        image: SetupImageName.MACOS_DASHBOARD,
        title: 'MacOS dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-macos-link',
          url: `${externalPaths.integrationsAndConnections}/macos-node`,
        },
        key: 'macos-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.KAFKA],
        image: SetupImageName.KAFKA_DASHBOARD,
        title: 'Kafka dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-kafka-link',
          url: `${externalPaths.integrationsAndConnections}/kafka`,
        },
        key: 'kafka-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.CLOUDWATCH],
        image: SetupImageName.CLOUDWATCH_DASHBOARD,
        title: 'AWS CloudWatch dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-cloudwatch-link',
          url: `${externalPaths.integrationsAndConnections}/cloudwatch`,
        },
        key: 'cloudwatch-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.RASPBERRY],
        image: SetupImageName.RASPBERRY_DASHBOARD,
        title: 'Raspberry PI dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-raspberry-pi-node-link',
          url: `${externalPaths.integrationsAndConnections}/raspberry-pi-node`,
        },
        key: 'raspberrypi-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.MONGODB],
        image: SetupImageName.MONGO_DASHBOARD,
        title: 'MongoDB dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-mongodb-link',
          url: `${externalPaths.integrationsAndConnections}/mongodb`,
        },
        key: 'mongodb-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.NOMAD],
        image: SetupImageName.NOMAD_DASHBOARD,
        title: 'Nomad dashboard',
        titleAlign: 'center',
        link: {
          key: 'template-dashboard-nomad-link',
          url: `${externalPaths.integrationsAndConnections}/nomad`,
        },
        key: 'nomad-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.REDIS],
        image: SetupImageName.REDIS_DASHBOARD,
        title: 'Redis dashboard',
        titleAlign: 'center',
        text: '',
        link: {
          key: 'template-dashboard-redis-link',
          url: `${externalPaths.integrationsAndConnections}/redis`,
        },
        key: 'redis-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
      {
        icons: [SetupCustomIconName.NODE],
        image: SetupImageName.NODE_DASHBOARD,
        title: 'Node.js dashboard',
        titleAlign: 'center',
        text: '',
        link: {
          key: 'template-dashboard-nodejs-link',
          url: `${externalPaths.integrationsAndConnections}/nodejs`,
        },
        key: 'node-dashboard-template',
        style: 'row-flex',
        type: 'image',
      },
    ],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.onboarding}/create-dashboard`,
        key: ComponentKey.BackTemplateDashboard,
      },
      more: {
        text: 'See other options →',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.MoreTemplateDashboard,
        preamble: "Not finding what you're looking for?",
      },
    },
    path: 'Create a dashboard / Browse pre-built dashboards',
    route: 'template-dashboard',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <SetupWizardBody step="template-dashboard" />,
  },
  'template-prometheus-dashboard': {
    step: 'template-prometheus-dashboard',
    steps: [],
    header: 'Connect your data',
    preamble: 'Your dashboard is going to need some data, choose a method for connecting Prometheus to Grafana Cloud.',
    cards: [
      {
        icons: ['bolt'],
        title: 'Send Prometheus metrics to Grafana Cloud',
        text: 'You want a fully-managed Prometheus service that can ingest data from one small machine or scale to billions of metrics (if you need it!).',
        link: {
          key: 'template-prometheus-dashboard-hosted-link',
          url: `${externalPaths.integrationsAndConnections}/hmInstancePromId`,
        },
        key: 'template-prometheus-dashboard-hosted',
        style: 'column-flex',
        badge: {
          icon: 'bolt',
          text: 'Grafana Agent',
          info: {
            header: <PrometheusTooltipHeader title={'Grafana Agent'} icon={'question-circle'} />,
            body: <PrometheusHostedTooltip />,
          },
        },
        type: 'general',
      },
      {
        icons: ['database'],
        title: 'Query your local Prometheus database',
        text: 'You already have data stored in a local Prometheus and you just want to visualize it in Grafana Cloud.',
        link: {
          key: 'template-prometheus-dashboard-database-link',
          url: 'plugins/prometheus',
        },
        badge: {
          icon: 'database',
          text: 'Data source',
          info: {
            header: <PrometheusTooltipHeader title={'Data source'} icon={'question-circle'} />,
            body: <PrometheusDatasourceTooltip />,
          },
        },
        key: 'template-prometheus-dashboard-datasource',
        style: 'column-flex',
        type: 'general',
      },
    ],
    buttons: {
      skip: {
        text: 'Skip',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.SkipTemplatePrometheusDashboard,
      },
      back: {
        text: 'Back',
        link: `${appPaths.setupWizard}/template-dashboard`,
        key: ComponentKey.BackTemplatePrometheusDashboard,
      },
      more: {
        text: 'See other options →',
        link: externalPaths.integrationsAndConnections,
        key: ComponentKey.MoreTemplatePrometheusDashboard,
        preamble: "Not finding what you're looking for?",
      },
    },
    path: 'Create a dashboard / Browse pre-built dashboards',
    route: 'template-dashboard/prometheus',
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <SetupWizardBody step="template-prometheus-dashboard" />,
  },
};

const stepsOnExperiments: string[] = [];

export const setupSteps = [...new Set(Object.values(setupData).map((obj) => obj.step))] as const;

export const getWizardStepData = (step: string, testGroup = TestGroup.CONTROL): SetupWizardStepData => {
  const stepId = stepsOnExperiments.includes(step) ? `${step}-${testGroup}` : step;
  return setupData[stepId];
};

import React, { useState, useEffect, useContext } from 'react';
import { useStyles2, Button, Icon } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { logsDataConnectionData } from 'feature/onboarding/data/LogsOnboarding.data';
import { LogsKeys, GrafanaCloudKeys, ExistingSourceKeys } from 'feature/onboarding/types/LogsOnboardingTypes';
import { LogsCard } from './LogsCard';
import { getTrackOriginFromPath } from 'feature/onboarding/utils/OnboardingCard';
import { trackAction, ActionType } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  cardWrapper: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing(2)};

    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    text-align: left;
  `,
  buttonWrapper: css`
    display: inline;
    margin-bottom: 30px;
  `,
  button: (selectedType: boolean) => css`
    margin: 0px 15px 10px 0px;
    border-radius: 24px;
    background-color: ${selectedType ? theme.colors.background.secondary : 'transparent'};
    padding: 10px 20px;
    height: auto;
    border: 1px solid ${selectedType ? theme.v1.palette.orange : theme.colors.border.strong};
    color: ${theme.colors.text.primary};

    &:hover {
      background-color: ${theme.colors.background.secondary};
      border: 1px solid ${selectedType ? theme.v1.palette.orange : theme.colors.border.strong};
      color: ${theme.colors.text.primary};
    }

    svg {
      margin-left: 7px;
    }
  `,
});

// eslint-disable-next-line import/no-unused-modules
export const camelToNormal = (type: string) => {
  return (
    type
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export function LogsDataConnection({ selectedCard }: { selectedCard: LogsKeys }) {
  const [selectedType, setSelectedType] = useState('viewAll');
  const [selectedPlatform, setSelectedPlatform] = useState<GrafanaCloudKeys | ExistingSourceKeys | undefined>();

  const styles = useStyles2(getStyles);

  const dataTypeSelection = Object.keys(logsDataConnectionData[selectedCard as LogsKeys]);

  useEffect(() => {
    setSelectedType('viewAll');
  }, [selectedCard]);

  const origin = getTrackOriginFromPath();
  const { orgId } = useContext(AppContext);
  return (
    <div className={styles.wrapper}>
      <h3>2. Choose your data connection</h3>
      <div className={styles.buttonWrapper}>
        {dataTypeSelection.map((type) => (
          <Button
            className={styles.button(selectedType === type)}
            key={type}
            onClick={() => {
              setSelectedType(type);
              trackAction(
                FlowEventName.LOGS_ONBOARDING_EVENT_NAME as FlowEventName,
                `${selectedCard}-{${type}`,
                ActionType.click,
                orgId,
                origin ? { origin } : undefined
              );
            }}
          >
            {camelToNormal(type)}
            {selectedType === type && <Icon size={'sm'} name="check" />}
          </Button>
        ))}
      </div>
      <div className={styles.cardWrapper}>
        {logsDataConnectionData[selectedCard][selectedType]?.map((platform) => (
          <LogsCard
            card={platform}
            selectedCard={selectedPlatform}
            setSelectedCard={setSelectedPlatform}
            key={platform.key}
          />
        ))}
      </div>
    </div>
  );
}

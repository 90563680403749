import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getOnboardingIconStyles = (theme: GrafanaTheme2) => ({
  icon: css`
    svg {
      fill: url(#OnboardingIcon);
    }
  `,
  svg: css`
    position: absolute;
    height: 30px; // to not cover the content and the button become clickable
  `,
  separator: css`
    svg {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    max-width: 60px;
  `,
});

import { externalPaths } from 'feature/common/consts/appPaths';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';

export const quickstartData = [
  {
    key: ComponentKey.LinuxFromQuickstartHome,
    title: 'Linux',
    icon: 'linux',
    style: 'row-flex',
    text: 'Prebuilt dashboards and alerts to mintor your Linux operating system',
    link: {
      key: ComponentKey.LinuxFromQuickstartHome,
      url: `${externalPaths.integrationsAndConnections}/linux-node`,
    },
  },
  {
    key: ComponentKey.MacOSFromQuickstartHome,
    title: 'macOs',
    icon: 'macos',
    style: 'row-flex',
    text: 'Prebuilt dashboards and alerts to mintor your Mac operating system',
    link: {
      key: ComponentKey.MacOSFromQuickstartHome,
      url: `${externalPaths.integrationsAndConnections}/macos-node`,
    },
  },
  {
    icon: 'windows',
    title: 'Windows',
    text: 'Prebuilt dashboards and alerts to monitor your Windows operating system',
    link: {
      key: ComponentKey.WindowsFromQuickstartHome,
      url: `${externalPaths.integrationsAndConnections}/windows-exporter`,
    },
    key: 'windows',
    style: 'row-flex',
    type: 'general',
  },
  {
    icon: 'synthetic-monitoring',
    title: 'Monitor a website',
    text: 'Check the performance of a URL from different locations across the globe',
    link: {
      key: ComponentKey.SyntheticMonitoringFromQuickstartHome,
      url: externalPaths.syntheticMonitoringChecks,
    },
    key: 'synthetic-monitoring',
    style: 'row-flex',
    type: 'general',
  },
  {
    icon: 'raspberry-pi',
    title: 'Raspberry Pi',
    text: 'Easy configuration and prebuilt dashboards to monitor Raspberry PI',
    link: {
      key: ComponentKey.RaspberryPiFromQuickstartHome,
      url: `${externalPaths.integrationsAndConnections}/raspberry-pi-node`,
    },
    key: 'raspberry-pi',
    style: 'row-flex',
    type: 'general',
  },
];

import { appCreateApi } from 'app/context';
import { formatDatasourceList, formatGrafanaDatasourceList } from 'feature/common/utils/pluginsUtils';
import { backendSrvBaseQuery } from 'feature/common/api/baseApi';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { INTERNAL_DATA_SOURCE_IDS } from 'feature/common/consts/consts';

export const PluginsApi = appCreateApi({
  baseQuery: backendSrvBaseQuery({ baseUrl: 'api' }),
  reducerPath: 'plugins',
  endpoints: (build) => ({
    getActiveDatasources: build.query<DataToConnect[], void>({
      query: () => '/plugins?enabled=1&type=datasource',
      transformResponse(datasourceList: any[]) {
        try {
          return formatDatasourceList(
            datasourceList.filter((dataSource) => !INTERNAL_DATA_SOURCE_IDS.includes(dataSource.id))
          );
        } catch (error) {
          // simply return empty array so if for some reason the datasource plugins response fails,
          // it should not break the flow of the integrations list.
          return [];
        }
      },
    }),
    getGrafanaDatasources: build.query<DataToConnect[], void>({
      query: () => '/gnet/plugins?typeCodeIn[]=datasource',
      transformResponse(response: any) {
        try {
          return formatGrafanaDatasourceList(response.items);
        } catch (error) {
          // simply return empty array so if for some reason the datasource plugins response fails,
          // it should not break the flow of the integrations list.
          return [];
        }
      },
    }),
  }),
});

export const { useGetActiveDatasourcesQuery, useGetGrafanaDatasourcesQuery } = PluginsApi;

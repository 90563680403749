import { DataSourceInstanceSettings, WithAccessControlMetadata } from '@grafana/data';
import { importDashboardSteps } from 'feature/onboarding/data/ImportDashboard.data';
import { IndividualStep } from 'feature/onboarding/types/CardData';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { OnboardingCardButton } from 'feature/onboarding/types/OnboardingCard';

export type ImportDashboardStep = (typeof importDashboardSteps)[number];

export interface ImportDashboardStepData {
  step: string;
  steps: IndividualStep[];
  header?: string;
  preamble?: string;
  buttons?: {
    skip?: OnboardingCardButton;
    back?: OnboardingCardButton;
    more?: OnboardingCardButton;
  };
  path?: string;
  route: string;
  eventName: FlowEventName;
  component?: JSX.Element | string;
}

export enum DashboardSearchItemType {
  DashDB = 'dash-db',
  DashHome = 'dash-home',
  DashFolder = 'dash-folder',
}

// /**
//  * @deprecated Use DashboardSearchItem and use UIDs instead of IDs
//  * DTO type for search API result items, but with deprecated IDs
//  * This type was previously also used heavily for views, so contains lots of
//  * extraneous properties
//  */
export interface DashboardSearchHit extends WithAccessControlMetadata {
  folderId?: number;
  folderTitle?: string;
  folderUid?: string;
  folderUrl?: string;
  id?: number;
  tags: string[];
  title: string;
  type: DashboardSearchItemType;
  uid: string;
  url: string;
  sortMeta?: number;
  sortMetaName?: string;
}

export interface ImportData {
  dashboardToImport: any;
  dataSourceSaved: DataSourceInstanceSettings;
}

export interface PluginDashboardResponse {
  dashboardId: number;
  description: string;
  folderId: number;
  imported: boolean;
  importedRevision: number;
  importedUri: string;
  importedUrl: string;
  path: string;
  pluginId: string;
  removed: boolean;
  revision: number;
  slug: string;
  title: string;
  uid: string;
}

export interface Folder {
  uid: string;
  title?: string;
}

export interface ImportDashboardDTO {
  title: string;
  uid?: string;
  gnetId: string;
  constants: string[];
  dataSources: DataSourceInstanceSettings[];
  elements: any[];
  folder: Folder;
}

export enum InputType {
  DataSource = 'datasource',
  Constant = 'constant',
  LibraryPanel = 'libraryPanel',
}

enum LibraryPanelInputState {
  New = 'new',
  Exists = 'exists',
  Different = 'different',
}

export interface DashboardInput {
  name: string;
  label: string;
  info: string;
  value: string;
  type: InputType;
}

interface DataSourceInput extends DashboardInput {
  pluginId: string;
}

interface LibraryPanelInput {
  model: any;
  state: LibraryPanelInputState;
}

export interface DashboardInputs {
  dataSources: DataSourceInput[];
  constants: DashboardInput[];
  libraryPanels: LibraryPanelInput[];
}

interface ErrorFetchResponse<E> {
  status: number;
  data: E;
}

export interface FormattedApiDashboard {
  id: number;
  status: number;
  statusCode?: string;
  orgId: number;
  orgSlug: string;
  orgName: string;
  slug: string;
  downloads?: number;
  revisionId: number;
  revision: number;
  name: string;
  description: string;
  readme: string;
  collectorType: string;
  collectorConfig: string;
  collectorPluginList: string;
  datasource: string;
  privacy?: string;
  createdAt: string;
  updatedAt?: string;
  isEditor: boolean;
  screenshots?: any;
  hasLogo: boolean;
  reviewsCount: number;
  reviewsAvgRating: number;
  links: any[];
  json: unknown;
  panelTypeSlugs?: string[];
  datasourceSlugs?: string[];
  categorySlugs?: string[];
  dependencies?: any;
  categories?: any;
  collectorPlugins?: any;
  logos?: any;
}

export function isErrorResponse<D = unknown>(thing: any): thing is ErrorFetchResponse<D> {
  return (
    typeof thing === 'object' &&
    'status' in thing &&
    !isNaN(thing.status) &&
    (thing.status < 200 || thing.status >= 300) &&
    'data' in thing
  );
}

// Find integrations by keywords
// This file is for locally defined integrations, apps and datasources.
// For infrastructure integrations you should go to the integrations folder and set the search_keywords attribute.
import { JAVA_OPEN_TELEMETRY_ID, OPEN_TELEMETRY_ID } from './consts';

export const integrationKeywords: { [key: string]: string[] } = {
  hmInstancePromId: ['prometheus'],
  hlInstanceId: ['loki', 'logs'],
  hmInstanceGraphiteId: ['graphite', 'metrics'],
  htInstanceId: ['traces, tempo'],
  'k8s-monitoring-app': ['k8s', 'container', 'deployment', 'scale', 'kubernetes'],
  'datasource-cloudwatch': ['amazon', 'aws'],
  'frontend-observability-app': ['faro', 'frontend', 'observability', 'web', 'app'],
  aws: ['amazon', 'monitoring', 'metrics', 's3', 'ec2', 'cloudfront', 'lambda', 'ebs', 'rds', 'cloudwatch'],
  [JAVA_OPEN_TELEMETRY_ID]: ['jvm', 'otel'],
  [OPEN_TELEMETRY_ID]: ['otel'],
};

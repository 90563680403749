import React, { FC } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';

import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { getStyles } from './UpgradePrompt.styles';
import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';

const portalURLs = {
  base: 'https://grafana.com/profile/org/',
  subscription: 'subscription',
};

export const UpgradePrompt: FC = () => {
  const styles = useStyles2(getStyles);
  const { isAdmin, isLoading: isCurrentUserLoading } = useCurrentUser();
  const { data: orgInfoData, isLoading: isOrgInfoDataLoading } = useOrgInfoData();
  const isTrialPlan = orgInfoData?.orgPlan === Tiers.Trial;
  const isFreeTier = orgInfoData?.orgPlan === Tiers.Free;

  const text = isFreeTier ? 'Tired of usage limits?' : 'Keep unlimited usage';

  const isLoading = isCurrentUserLoading || isOrgInfoDataLoading;

  return (
    <div className={styles.promptWrapper}>
      {!isLoading && isAdmin && (isFreeTier || isTrialPlan) && (
        <div className={styles.promptWrapper}>
          <div className={styles.upgradeButtonWrapper}>
            <LinkButton
              target="_blank"
              variant="primary"
              fill="outline"
              href={`${portalURLs.base}${portalURLs.subscription}`}
            >
              {'Upgrade plan →'}
            </LinkButton>
          </div>
          <div className={styles.limitText}>{text}</div>
        </div>
      )}
    </div>
  );
};

import React, { useContext } from 'react';
import { LinkButton, useStyles2 } from '@grafana/ui';
import { getDashboardEmptyStyles } from 'feature/home/components/refresh-design/DashboardSection/DashboardEmpty.styles';
import { isUserViewer } from 'feature/common/utils/UserUtils';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';

export const DashboardMostRecentEmpty = () => {
  const styles = useStyles2(getDashboardEmptyStyles);
  const { orgId } = useContext(AppContext);
  const isViewer = isUserViewer();

  const title = !isViewer ? 'Create your first dashboard' : 'Browse Dashboards';
  const url = !isViewer ? '/dashboard/new' : '/dashboards';
  const componentKey = !isViewer
    ? ComponentKey.Dashboards.CreateYourFirstDashboard
    : ComponentKey.Dashboards.BrowseDashboards;
  const description = !isViewer ? (
    <>
      <span>After you’ve connected data, you can use dashboards to query and visualize your data</span>
      <span>with charts, stats and tables or create lists, markdowns and other widgets.</span>
    </>
  ) : (
    <span>Search for dashboards and folders to get started.</span>
  );

  function trackEvent(): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, componentKey, ActionType.click, orgId);
  }

  return (
    <div className={styles.container}>
      <LinkButton
        data-testid={DataTestId.DASHBOARDS_MOST_RECENT_EMPTY_LINK}
        variant={'primary'}
        href={url}
        onClick={trackEvent}
      >
        {title}
      </LinkButton>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

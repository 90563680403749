import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'feature/common/consts/styles';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    panelsContainer: css`
      display: flex;
      gap: ${theme.spacing(3)};
      margin-top: ${theme.spacing(4)};
      width: 100%;
      @media (max-width: ${breakpoints.panelsContainer}px) {
        flex-direction: column;
      }
    `,
    panelItem: css`
      padding: ${theme.spacing(3)};
      width: 33%;
      flex: 1;
      height: 472px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${theme.components.panel.background};
      border: 1px solid ${theme.components.panel.borderColor};
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: ${theme.shadows.z1};
      color: ${theme.colors.text.primary};

      @media (max-width: ${breakpoints.panelsContainer}px) {
        width: 100%;
      }
    `,
  };
};

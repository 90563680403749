import React from 'react';
import { Alert } from '@grafana/ui';

interface DatasourceConfigErrorsProps {
  title: string;
  uid: string;
  onRemoveFn: () => void;
  errorMessages: string[];
}

export const DatasourceConfigErrors: React.FC<DatasourceConfigErrorsProps> = ({
  title,
  onRemoveFn,
  uid,
  errorMessages,
}) => {
  return (
    <Alert title={title} severity="error" onRemove={onRemoveFn}>
      {errorMessages.map((ms) => (
        <div key={uid}>{ms}</div>
      ))}
    </Alert>
  );
};

import React from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { DataTestId } from 'feature/common/types/test-id.enum';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    description: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
      display: flex;
      flex-direction: row;
    `,
    circle: css`
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: 10px;

      &-green {
        background-color: ${theme.colors.success.text};
      }

      &-grey {
        background-color: ${theme.colors.border.medium};
      }

      &-red {
        background-color: ${theme.colors.error.main};
      }
    `,
  };
};

type MetricTitleProps = {
  label: string;
  circleColor?: string;
};

export const MetricTitle = ({ label, circleColor }: MetricTitleProps) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={styles.description} data-testid={DataTestId.METRIC_LABEL_ID}>
        {label}
      </div>
      {!!circleColor && (
        <div data-testid={DataTestId.METRIC_CIRCLE_ID} className={`${styles.circle} ${styles.circle}-${circleColor}`} />
      )}
    </>
  );
};

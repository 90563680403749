import React from 'react';

import { Alert, Field, FormAPI, Input, SecretInput, useStyles2 } from '@grafana/ui';
import { DataSourceDTO } from 'feature/common/types/Datasources';
import { getDBConfigFormStyles } from 'feature/datasource-config/components/DBConfigForm.styles';

interface HeaderProps {
  formApi: FormAPI<DataSourceDTO>;
}

export const DBConfigHTTPHeaders = ({ formApi }: HeaderProps) => {
  const styles = useStyles2(getDBConfigFormStyles);
  return (
    <div>
      <Field label="Request headers" description="Add required token via HTTP header" required>
        <>
          <Alert severity="info" title="">
            <p>
              Pass your InfluxDB token as a Header value. For a token, the format should be{' '}
              <code>Header: &apos;Authorization&apos; </code> and <code>Value: &apos;Token MY_TOKEN_VALUE&apos;</code>.
              Only unexpired tokens will successfully authenticate. Be sure your token has not expired.
            </p>
          </Alert>
          <div className={styles.field}>
            <div className={styles.headersQueryInputs}>
              <Field
                invalid={!!formApi.errors?.httpHeaderName1?.message}
                error={!!formApi.errors?.httpHeaderName1?.message}
                required
                label="Header"
              >
                <Input
                  {...formApi.register(`httpHeaderName1`, {
                    validate: (value) => {
                      if (!value || value === '') {
                        return 'Header name required';
                      }

                      return true;
                    },
                  })}
                  type="text"
                  id={`httpHeaderName1`}
                  placeholder="Authorization"
                  onChange={(evt) =>
                    formApi.setValue('httpHeaderName1', evt.currentTarget.value, { shouldValidate: true })
                  }
                />
              </Field>
              <Field
                invalid={!!formApi.errors?.httpHeaderValue1?.message}
                error={!!formApi.errors?.httpHeaderValue1?.message}
                required
                label="Value"
              >
                <SecretInput
                  {...formApi.register(`httpHeaderValue1`, {
                    validate: (value) => {
                      if (!value || value === '') {
                        return 'Header value required';
                      }

                      return true;
                    },
                  })}
                  id={`httpHeaderValue1`}
                  placeholder={`Token my_token_value`}
                  isConfigured={!!formApi.getValues()?.httpHeaderValue1?.length}
                  onReset={() => formApi.setValue('httpHeaderValue1', '')}
                  onChange={(evt) => formApi.setValue('httpHeaderValue1', evt.currentTarget.value)}
                />
              </Field>
            </div>
          </div>
        </>
      </Field>
    </div>
  );
};

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      align-items: baseline;
      display: flex;
      flex-direction: column;
      min-width: 100px;
      padding: ${theme.spacing(1)};
    `,
    value: css`
      line-height: 32px;
      @media (max-width: 650px) {
        font-size: 16px;
        line-height: 26px;
      }
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
    label: css`
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${theme.isDark ? theme.colors.text.primary : '#67677e'};
      font-size: ${theme.typography.size.lg};
      font-weight: normal;
      line-height: ${`${theme.spacing.gridSize * theme.components.height.sm}px`};
      margin-left: ${theme.spacing(0.5)};
      @media (max-width: 1150px) {
        font-size: ${theme.typography.size.md};
        line-height: 19px;
      }
      white-space: nowrap;
    `,
    valueText: css`
      font-size: 1.8rem;
      font-weight: 500;
      color: ${theme.isDark ? '#FFB375' : theme.colors.text.primary};
      @media (max-width: 1150px) {
        font-size: ${theme.typography.size.lg};
      }
    `,
    errorIcon: css`
      margin-left: 5px;
      top: -10px;
      position: relative;
    `,
    unitText: css`
      font-size: 1rem;
      font-weight: 500;
      color: ${theme.isDark ? '#FFB375' : theme.visualization.getColorByName('gray15')};
      @media (max-width: 1366px) {
        font-size: 10px;
      }
    `,
  };
};

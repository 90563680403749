import React from 'react';
import { FreeMetricsUsage } from './FreeMetricsUsage';
import { ProMetricsUsage } from './ProMetricsUsage';
import { UsageProps } from 'feature/usage/types/UsageData';

export function MetricsUsage({ usage, isFree, billingDashboard }: UsageProps): JSX.Element {
  return isFree ? (
    <FreeMetricsUsage usage={usage} billingDashboard={billingDashboard} />
  ) : (
    <ProMetricsUsage usage={usage} billingDashboard={billingDashboard} />
  );
}

import React from 'react';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { Metric } from 'feature/usage/components/refresh-design/Metric';
import { formatUsageValue } from 'feature/usage/utils/usageUtils';
import { useUsageStatus } from 'feature/usage/hooks/useUsageStatus';

export function TracesUsage(): JSX.Element {
  const {
    usage,
    limit,
    isLoading,
    error,
    isFree,
    status: { title, circleColor, tooltip, link },
  } = useUsageStatus(MetricUnit.TRACES);

  return (
    <div data-testid={DataTestId.TRACES_USAGE_METRIC_ID}>
      <Metric
        id="metric_traces"
        label={title}
        limit={`${limit}GB`}
        tooltipText={tooltip}
        linkText={link?.title}
        isFree={isFree}
        link={link?.url}
        isLoading={isLoading}
        error={error}
        value={formatUsageValue(usage, MetricUnit.TRACES, isFree)}
        circleColor={circleColor}
      />
    </div>
  );
}

import { QueryError } from 'feature/common/types/QueryError';
import { DashboardInputs, FormattedApiDashboard } from 'feature/onboarding/types/ImportDashboard';
import { useGetGrafanaDashboardQuery } from 'feature/common/api/DashboardsApi';
import { processInputs } from 'feature/onboarding/components/ImportDashboard/importDashboardUtils';

export function useDashboards(id?: string): {
  data: FormattedApiDashboard | undefined;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: QueryError | undefined;
  inputs: DashboardInputs;
} {
  const {
    data: grafanaDashboards,
    isSuccess: isGrafanaDashboardsSuccess,
    isLoading: isGrafanaDashboardsLoading,
    isError: isGrafanaDashboardsError,
    error: grafanaDashboardsError,
  } = useGetGrafanaDashboardQuery({ id }, { skip: !id });

  const isSuccess = isGrafanaDashboardsSuccess;
  let data: FormattedApiDashboard = {} as FormattedApiDashboard;
  let processedInputs: DashboardInputs = {} as DashboardInputs;
  if (isSuccess) {
    data = grafanaDashboards || ({} as FormattedApiDashboard);
    processedInputs = processInputs(data.json) || ({} as DashboardInputs);
  }

  return {
    data,
    isLoading: isGrafanaDashboardsLoading,
    isSuccess,
    isError: isGrafanaDashboardsError,
    error: grafanaDashboardsError as QueryError,
    inputs: processedInputs,
  };
}

export const getTrialBadgeClassName = (trialDays?: number) => {
  if (trialDays === undefined) {
    return 'grey';
  }
  if (trialDays > 10) {
    return 'green';
  } else if (trialDays > 7) {
    return 'yellow';
  }
  return 'red';
};

import { Icon, IconName, useStyles2 } from '@grafana/ui';
import { ICON_GRADIENT_END_COLOR, ICON_GRADIENT_START_COLOR } from 'feature/common/utils/styles';
import { getIconTransform } from 'feature/onboarding/utils/styles';
import React from 'react';
import { getOnboardingIconStyles } from './OnboardingIcon.styles';
import { cx } from '@emotion/css';

export function OnboardingIcon({ icon, position = 'top' }: { icon: IconName; position?: 'top' | 'left' }): JSX.Element {
  const styles = useStyles2(getOnboardingIconStyles);
  return (
    <>
      <svg className={styles.svg} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="OnboardingIcon">
            <stop stopColor={ICON_GRADIENT_START_COLOR} offset="0%"></stop>
            <stop stopColor={ICON_GRADIENT_END_COLOR} offset="100%"></stop>
          </linearGradient>
        </defs>
      </svg>
      <div
        className={cx(styles.icon, { [styles.separator]: position === 'top' })}
        style={{
          transform: getIconTransform(icon),
        }}
      >
        <Icon size="xl" aria-label={icon} name={icon} />
      </div>
    </>
  );
}

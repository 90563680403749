import { Icon, useStyles2 } from '@grafana/ui';
import React from 'react';
import { getPrometheusTooltipHeaderStyles } from 'feature/onboarding/components/PrometheusTooltip/PrometheusTooltipHeader.styles';
import { IconName } from '@grafana/data';

export function PrometheusTooltipHeader({ title, icon }: { title: string; icon?: IconName }): JSX.Element {
  const styles = useStyles2(getPrometheusTooltipHeaderStyles);
  return (
    <>
      {!!icon && <Icon className={styles.infoIcon} name={icon} />}
      <span>{title}</span>
    </>
  );
}

import { appCreateApi } from 'app/context';
import { backendSrvBaseQuery } from 'feature/common/api/baseApi';
import { FormattedApiDashboard } from 'feature/onboarding/types/ImportDashboard';
import { Dashboard, DashboardFolder, DashboardResponse, InstallDashboardRequest } from 'feature/common/types/Dashboard';
import { QueryError } from 'feature/common/types/QueryError';
import { getFolderUid } from 'feature/common/utils/dashboardsUtils';

const dashboardsTagType = 'dashboards';

export const DashboardsApi = appCreateApi({
  baseQuery: backendSrvBaseQuery({ baseUrl: 'api' }),
  reducerPath: 'dashboards',
  tagTypes: [dashboardsTagType],
  endpoints: (build) => ({
    getGrafanaDashboard: build.query<FormattedApiDashboard, any>({
      query: ({ id }) => `/gnet/dashboards/${id}`,
    }),
    getStarredDashboards: build.query<DashboardResponse[], void>({
      query: () => `/search?type=dash-db&starred=true`,
      providesTags: [dashboardsTagType],
    }),
    deleteStarredDashboard: build.mutation({
      query: (uid: string) => ({
        url: `/user/stars/dashboard/uid/${uid}`,
        method: 'DELETE',
        showErrorAlert: true,
        showSuccessAlert: true,
      }),
      invalidatesTags: [dashboardsTagType],
    }),
    installDashboard: build.mutation<Dashboard, InstallDashboardRequest>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const { folderTitle, dashboard, message, slugUrl, dataSource } = args;
        const uid = getFolderUid(folderTitle);

        const getOrCreateFolder = async () => {
          const uid = getFolderUid(folderTitle);
          // search if the folder already exist or not
          const foldersResponse = await baseQuery({ url: `/folders`, method: 'GET' });
          if (!!foldersResponse.error) {
            return { error: foldersResponse.error as QueryError };
          } else {
            const folder = (foldersResponse.data as DashboardFolder[]).find((folder) => folder.title === folderTitle);
            if (!!folder) {
              return { data: folder as DashboardFolder };
            } else {
              // create new folder if It does not exist
              const folderCreated = await baseQuery({
                url: '/folders',
                method: 'POST',
                data: JSON.stringify({ uid, title: folderTitle }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                },
              });

              if (!!folderCreated.error) {
                return { error: folderCreated.error as QueryError };
              } else {
                return { data: folderCreated.data as DashboardFolder };
              }
            }
          }
        };
        const getOrCreateDashboard = async (folderId: number) => {
          const dashboardsResponse = await baseQuery({
            url: `/search?type=dash-db&folderIds=${folderId}`,
            method: 'GET',
          });
          if (!!dashboardsResponse.error) {
            return { error: dashboardsResponse.error as QueryError };
          } else {
            const dashboardFound = ((dashboardsResponse.data ?? []) as Dashboard[]).find(
              (dashDB) => dashDB.title === dashboard.title
            );
            // create the dashboard and override it if already exist
            const dashboardUpdated = JSON.parse(
              JSON.stringify(dashboard)
                .replaceAll('$slugUrl', slugUrl)
                .replaceAll('$dataSourceName', dataSource.name)
                .replaceAll('$dataSourceUID', dataSource.uid)
            );
            const response = await baseQuery({
              url: '/dashboards/db',
              method: 'POST',
              data: JSON.stringify({
                dashboard: dashboardUpdated,
                folderUid: uid,
                overwrite: Boolean(dashboardFound),
                message,
              }),
              headers: { 'Content-type': 'application/json; charset=UTF-8' },
              showSuccessAlert: false,
            });
            if (response.error) {
              return { error: response.error as QueryError };
            } else {
              return { data: response.data as Dashboard };
            }
          }
        };

        const result = await getOrCreateFolder();
        if (result.error) {
          return result;
        }
        return getOrCreateDashboard(result.data.id);
      },
      invalidatesTags: [dashboardsTagType],
    }),
  }),
});

export const {
  useGetGrafanaDashboardQuery,
  useGetStarredDashboardsQuery,
  useDeleteStarredDashboardMutation,
  useInstallDashboardMutation,
} = DashboardsApi;

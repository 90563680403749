import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getDBConfigFormStyles = (theme: GrafanaTheme2) => ({
  formFullWidth: css`
    max-width: 100%;
  `,
  lastFormGroup: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  formGroup: css`
    margin-bottom: 20px;

    h6 {
      margin-bottom: 0;
    }
  `,
  field: css`
    div [ class $= "layoutChildrenWrapper" ] {
      width: 100%;
    }

  ,
  `,
  alertSuccess: css`
    margin-top: ${theme.spacing(2)};
  `,
  removeIcon: css`
    margin-top: 18px;
    align-self: center;
  `,
  headersQueryInputs: css`
    display: flex;
    flex-direction: row;
    gap: 12px;

    input [class$='input-wrapper'] {
      min-width: 100px;
    }
  `,
  dsConfigForm: css`
    padding: ${theme.spacing(3)};
  `,
  configInfoListItems: css`
    margin-top: ${theme.spacing(2)};
    padding-left: ${theme.spacing(3)};
  `,
  authDescription: css`
    margin-bottom: ${theme.spacing(1)};
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};
  `,
});

import React, { useEffect, useState } from 'react';

import Markdown from 'markdown-to-jsx';
import { Alert, Button, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

import cloudflare1 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/cloudflare1.md';
import cloudflare2 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/cloudflare2.md';
import fluentBitIntro from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/fluentBitIntro.md';
import { FluentBitBody } from 'feature/onboarding/data/LogsOnboardingInstructions/fluentBitBody';
import fluentBitFooter from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/fluentBitFooter.md';
import fluentDIntro1 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/fluentDIntro1.md';
import fluentDIntro2 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/fluentDIntro2.md';
import { FluentDBody } from 'feature/onboarding/data/LogsOnboardingInstructions/fluentDBody';
import fluentDFooter from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/fluentDFooter.md';

import heroku1 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/heroku1.md';
import heroku2 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/heroku2.md';
import heroku3 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/heroku3.md';
import kafka1 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/kafka1.md';
import kafka2 from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/kafka2.md';
import promtailIntro from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/promtailIntro.md';
import { PromtailBody } from 'feature/onboarding/data/LogsOnboardingInstructions/promtailBody';
import promtailFooter from '!!raw-loader!feature/onboarding/data/LogsOnboardingInstructions/promtailFooter.md';

import { instructionsKey } from 'feature/onboarding/types/LogsOnboardingTypes';
import { appPaths } from 'feature/common/consts/appPaths';
import { AlloyInstallStep } from 'feature/onboarding/components/LogsOnboarding/InstallationStep/AlloyInstallStep';
import { customIcon } from 'img';
import { useInstallDashboardMutation } from 'feature/common/api/DashboardsApi';

import logsStarterDashboard from '../../../../../dashboards/logs-starter.json';
import { QueryError } from 'feature/common/types/QueryError';
import { useGetHostedDataDetailsQuery } from 'feature/common/api/GrafanaComApi';
import { kafkaLogo } from 'feature/onboarding/data/LogsOnboarding.data';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

const getStyles = (theme: GrafanaTheme2) => ({
  markdown: css`
    padding: ${theme.spacing(2)};
    margin: ${theme.spacing(2)} 0px;
    background: ${theme.colors.background.secondary};
  `,
  jsx: css`
    margin: ${theme.spacing(2)} 0px;
    padding: ${theme.spacing(2)} 0px;
    background: ${theme.colors.background.secondary};
  `,
  wrapper: css`
    background-color: ${theme.colors.background.primary};
    padding: ${theme.spacing(3)};

    a {
      color: ${theme.colors.primary.text};
    }

    h1 {
      img {
        width: 30px;
      }
    }

    img {
      width: 90%;
    }

    tr {
      border-top: 1px solid ${theme.colors.border.medium};
    }

    th,
    td {
      padding: 6px 13px;
      border: 1px solid ${theme.colors.border.medium};
    }
  `,
});

enum instructionType {
  markdown = 'markdown',
  jsx = 'jsx',
}

type InstructionsSet = {
  type: instructionType;
  snippet: JSX.Element | string;
};

type Steps = {
  [key: string]: { title: string; icon: SVGAElement; contents: InstructionsSet[] };
};

export function LogsInstructions({}: {}): JSX.Element {
  const { data: hostedData, isLoading: isHostedDataLoading } = useGetHostedDataDetailsQuery();
  const steps: Steps = {
    [instructionsKey.GRAFANA_CLOUD_CLOUDFLARE]: {
      title: 'Cloudflare',
      icon: customIcon['cloudflare'],
      contents: [
        { type: instructionType.jsx, snippet: <AlloyInstallStep /> },
        { type: instructionType.markdown, snippet: cloudflare1 },
        { type: instructionType.markdown, snippet: cloudflare2 },
      ],
    },
    [instructionsKey.GRAFANA_CLOUD_HEROKU]: {
      title: 'Heroku',
      icon: customIcon['heroku'],
      contents: [
        { type: instructionType.jsx, snippet: <AlloyInstallStep /> },
        { type: instructionType.markdown, snippet: heroku1 },
        { type: instructionType.markdown, snippet: heroku2 },
        { type: instructionType.markdown, snippet: heroku3 },
      ],
    },
    [instructionsKey.GRAFANA_CLOUD_KAFKA]: {
      title: 'Kafka',
      // icon: customIcon['kafka'],
      icon: kafkaLogo,
      contents: [
        { type: instructionType.jsx, snippet: <AlloyInstallStep /> },
        { type: instructionType.markdown, snippet: kafka1 },
        { type: instructionType.markdown, snippet: kafka2 },
      ],
    },
    [instructionsKey.DATA_SOURCE_FLUENT_BIT]: {
      title: 'FluentBit',
      icon: customIcon['fluent'],
      contents: [
        { type: instructionType.markdown, snippet: fluentBitIntro },
        { type: instructionType.jsx, snippet: <FluentBitBody hostedData={hostedData} /> },
        { type: instructionType.markdown, snippet: fluentBitFooter },
      ],
    },
    [instructionsKey.DATA_SOURCE_FLUENT_D]: {
      title: 'FluentD',
      icon: customIcon['fluent'],
      contents: [
        { type: instructionType.markdown, snippet: fluentDIntro1 },
        { type: instructionType.markdown, snippet: fluentDIntro2 },
        { type: instructionType.jsx, snippet: <FluentDBody hostedData={hostedData} /> },
        { type: instructionType.markdown, snippet: fluentDFooter },
      ],
    },
    [instructionsKey.DATA_SOURCE_PROMTAIL]: {
      title: 'Promtail',
      icon: customIcon['loki-color'],
      contents: [
        { type: instructionType.markdown, snippet: promtailIntro },
        { type: instructionType.jsx, snippet: <PromtailBody hostedData={hostedData} /> },
        { type: instructionType.markdown, snippet: promtailFooter },
      ],
    },
  };

  const styles = useStyles2(getStyles);
  const [installDashboard, { data: dashData, isLoading, error }] = useInstallDashboardMutation();
  const [showAlert, shouldShowAlert] = useState<boolean>(false);
  const selectedPlatform = window.location.pathname.replace(`${appPaths.logsOnboarding}/`, '');
  const data = steps[selectedPlatform];

  useEffect(() => {
    document.title = `${data.title} - Grafana`;
  });

  useEffect(() => {
    if (!!dashData) {
      window.location.href = dashData.url;
    }
  }, [dashData]);

  useEffect(() => {
    if (!!error) {
      shouldShowAlert(true);
    }
  }, [error]);

  return (
    <>
      <div className={styles.wrapper}>
        <h1>
          <img src={data.icon as unknown as string} alt={data.title} /> {data.title} Setup{' '}
        </h1>
        {data.contents.map((section, i) => {
          if (section.type === 'markdown') {
            return (
              <Markdown className={styles.markdown} key={`${i}-${section.type}`}>
                {section.snippet.toString()}
              </Markdown>
            );
          } else {
            return (
              <div className={styles.jsx} key={`${i}-${section.type}`}>
                {' '}
                {section.snippet}{' '}
              </div>
            );
          }
        })}
        <br />
        <Alert title="" severity="info">
          {`Please note that your Starter dashboard will not display any data until you finish connecting it to a ${data.title}
          data source.`}
        </Alert>
        <Button
          disabled={isLoading || isHostedDataLoading}
          onClick={() => {
            trackAction(
              FlowEventName.LOGS_ONBOARDING_EVENT_NAME,
              `starter-dashboard-${data.title}`,
              ActionType.click,
              hostedData?.orgId
            );
            installDashboard({
              slugUrl: hostedData?.url ?? '',
              dashboard: logsStarterDashboard,
              message: 'creating logs starter dashboard from Cloud Home plugin',
              folderTitle: 'Logs Onboarding',
              overwrite: true,
              dataSource: {
                uid: `grafanacloud-${hostedData?.hlInstanceName}`,
                name: `grafanacloud-${hostedData?.hlInstanceName}`,
              },
            });
          }}
        >
          View Starter Dashboard
        </Button>
      </div>
      {!!showAlert && (
        <Alert
          severity="error"
          title={
            (error as QueryError)?.data?.message ||
            'There was an error while trying to access the Logs Starter Dashboard.'
          }
        />
      )}
    </>
  );
}

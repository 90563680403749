import React, { useContext } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Modal, useStyles2 } from '@grafana/ui';

import { demoDashboardData } from 'feature/help/components/modals/data/DemoDashboard.data';
import { OnboardingLinksCard } from 'feature/onboarding/components/OnboardingCard/OnboardingLinksCard';
import { OnboardingImageCard } from 'feature/onboarding/components/OnboardingCard/OnboardingImageCard';
import { OnboardingLinksCardData, OnboardingImageCardData } from 'feature/onboarding/types/OnboardingCard';
import { cloudDocumentationData } from 'feature/help/components/modals/data/CloudDocumentation.data';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

export const DemoDataModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  const trackHasEnteredFromDemoDashboards = () => {
    trackAction(
      FlowEventName.DOCS_PLUGIN_EVENT,
      ComponentKey.TutorialPluginEnteredFromDemoDashboard,
      ActionType.click,
      orgId
    );
  };

  return (
    <Modal
      title=""
      isOpen={isOpen}
      closeOnBackdropClick={true}
      onClickBackdrop={onClose}
      onDismiss={onClose}
      className={styles.modal}
    >
      <div className={styles.hedSubtitle}>
        <div className={styles.header}>EXPLORE & LEARN</div>
        <div className={styles.subtitle}>
          Check out a demo dashboard or use a getting started guide to learn what you can build in Grafana Cloud
        </div>
      </div>
      <div className={styles.cards}>
        {demoDashboardData.map((item) => {
          return <OnboardingImageCard card={item as OnboardingImageCardData} key={item.key} isInModal />;
        })}
      </div>
      <div className={styles.docsSubtitle}>Check out in-depth guides with step-by-step instructions</div>
      <div className={styles.cards}>
        {cloudDocumentationData.map((item) => {
          if (item.type === 'image') {
            return <OnboardingImageCard card={item as OnboardingImageCardData} key={item.key} isInModal />;
          } else {
            return <OnboardingLinksCard card={item as OnboardingLinksCardData} key={item.key} isInModal />;
          }
        })}

        <a
          className={styles.viewMoreLink}
          href="/a/grafana-selfservetutorials-app"
          target="_blank"
          rel="noreferrer"
          onClick={trackHasEnteredFromDemoDashboards}
        >
          See more Grafana Cloud Tutorials →
        </a>
      </div>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modal: css`
      width: 1200px;
      padding: 24px;
      img {
        min-width: 200px;
        height: 87px;
        align-self: center;
      }
    `,
    hedSubtitle: css`
      display: flex;
      flex-direction: column;
    `,
    header: css`
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
    `,
    subtitle: css`
      font-size: 14px;
      margin-bottom: 35px;
    `,
    docsSubtitle: css`
      font-size: 14px;
      margin-bottom: 16px;
      margin-top: 35px;
    `,
    viewMoreLink: css`
      color: ${theme.colors.text.link};
    `,
    cards: css`
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      justify-content: flex-start;
      align-items: stretch;
      white-space: normal;
      overflow-wrap: break-word;
    `,
  };
};

import React, { useContext } from 'react';
import { LinkButton, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { AppContext } from 'App';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0;

      :hover {
        background-color: transparent;
      }
    `,
    link: css`
      color: ${theme.colors.text.link};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;

      :hover {
        opacity: 0.65;
      }
    `,
    resourcesLink: css`
      color: ${theme.colors.text.maxContrast};
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.0015em;

      :hover {
        text-decoration: underline;
      }
    `,
  };
};

type HomeCustomLinkProps = {
  id: string;
  text: string;
  href: string;
  trackId: string;
};

export const HomeCustomLink = ({ id, text, href, trackId }: HomeCustomLinkProps): JSX.Element => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  function onOpenSubscription() {
    window.open(href, '_blank');
    trackAction(FlowEventName.HOME_PAGE_EVENT, trackId, ActionType.click, orgId);
  }

  return (
    <LinkButton
      id={`${id}-link`}
      target="_self"
      className={styles.container}
      fill={'text'}
      onClick={onOpenSubscription}
    >
      <span className={styles.link}>{text}</span>
    </LinkButton>
  );
};

import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { getHomepageContentStyles } from 'feature/home/components/refresh-design/HomepageContent.styles';
import { DashboardSection } from 'feature/home/components/refresh-design/DashboardSection/DashboardSection';
import { UsageSection } from 'feature/home/components/refresh-design/UsageSection';
import { AccountOrgResources } from 'feature/home/components/refresh-design/AccountOrgResources';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { HeaderGetStarted } from 'feature/home/components/refresh-design/HeaderGetStarted';

export const HomepageContent = () => {
  const styles = useStyles2(getHomepageContentStyles);
  const { isAdmin } = useCurrentUser();

  return (
    <div className={styles.box}>
      <HeaderGetStarted />
      <div className={styles.body}>
        <DashboardSection />
        <AccountOrgResources />
        {isAdmin && (
          <div className={styles.usageContainer}>
            <UsageSection />
          </div>
        )}
      </div>
    </div>
  );
};

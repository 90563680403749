import React, { createContext } from 'react';

import { AppRootProps, PageLayoutType } from '@grafana/data';
import { appContext } from 'app/context';
import { store } from 'app/store';
import { PluginPage } from 'feature/common/components/PluginPage';
import { HomePage } from 'feature/home/components/Homepage';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SetupWizard } from 'feature/onboarding/components/SetupWizard/SetupWizard';
import { ImportDashboardPage } from 'feature/onboarding/components/ImportDashboard/ImportDashboardPage';
import { getWizardStepData, setupSteps } from 'feature/onboarding/data/SetupWizard.data';
import { SelectAndCreateDashboard } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectAndCreateDashboard';
import { getImportDashboardStepData, importDashboardSteps } from 'feature/onboarding/data/ImportDashboard.data';
import { GettingStartedScreen } from 'feature/onboarding/components/GettingStarted/GettingStartedScreen';
import { gettingStartedPaths } from 'feature/onboarding/data/GettingStartedCard.data';
import { GettingStartedCreateDashboardPage } from 'feature/onboarding/components/GettingStarted/GettingStartedCreateDashboardPage';
import { GettingStartedConfigDatasourcePage } from 'feature/onboarding/components/GettingStarted/GettingStartedConfigDatasourcePage';
import { gettingStartedDatasourcePaths } from 'feature/datasource-config/data/DatasourceConfig.data';
import { GettingStartedPath } from 'feature/onboarding/types/GettingStartedCard';
import { GettingStartedCloudServicesPage } from 'feature/onboarding/components/GettingStarted/GettingStartedCloudServicesPage';
import { PrometheusOnboardingScreen } from 'feature/onboarding/components/PrometheusOnboarding/PrometheusOnboardingScreen';
import RoutingScreenWrapper from 'feature/onboarding/components/OnboardingScreen/RoutingScreenWrapper';
import { GettingStartedPrometheusPage } from 'feature/onboarding/components/GettingStarted/GettingStartedPrometheusPage';
import { LogsOnboardingScreen } from 'feature/onboarding/components/LogsOnboarding/LogsOnboardingScreen';
import { LogsInstructions } from 'feature/onboarding/components/LogsOnboarding/LogsInstructions';

interface AppContextData {
  stackId: string;
  orgId: number;
}

export const AppContext = createContext<AppContextData>({
  stackId: '',
  orgId: 1,
});

export const App = React.memo(function App(props: AppRootProps) {
  const stackId = props.meta?.jsonData?.['stackId'] ?? '';
  const orgId = props.meta?.jsonData?.['orgId'] ?? -1;

  return (
    <AppContext.Provider value={{ stackId: `${stackId}`, orgId }}>
      <Provider store={store} context={appContext}>
        <Routes>
          {/*Base Path Route*/}
          {/* Using optional chaining here as PageLayoutType is not available in pre 9.2.0 versions */}
          <Route path="">
            <Route
              index
              element={
                <PluginPage layout={PageLayoutType?.Canvas}>
                  <HomePage />
                </PluginPage>
              }
            />

            {/* Setup Wizard Routes */}
            <Route path="setup-wizard">
              {setupSteps.map((step) => (
                <Route path={`${getWizardStepData(step).route}`} key={step} element={<SetupWizard step={step} />} />
              ))}
              <Route index element={<Navigate to={`/a/cloud-home-app/onboarding-flow/start`} replace />} />
            </Route>

            {/* Getting Started Routes */}
            <Route path="getting-started">
              <Route path={`select-and-create-dashboard`} element={<GettingStartedCreateDashboardPage />} />
              <Route path={`cloud-services`} element={<GettingStartedCloudServicesPage />} />
              <Route path={`prometheus`} element={<GettingStartedPrometheusPage />} />

              {gettingStartedPaths.map((item) => (
                <Route path={item.path} key={item.step} element={<GettingStartedScreen step={item.step} />} />
              ))}
              {gettingStartedDatasourcePaths.map((item: GettingStartedPath) => (
                <Route
                  path={item.path}
                  key={item.step}
                  element={<GettingStartedConfigDatasourcePage dataSource={item.step} />}
                />
              ))}
              <Route index element={<Navigate to={`start`} replace />} />
            </Route>

            {/* Onboarding Flow Routes */}
            <Route path="onboarding-flow">
              <Route path={`select-and-create-dashboard`} element={<SelectAndCreateDashboard />} />
              <Route path={`prometheus`} element={<PrometheusOnboardingScreen />} />
              <Route path={`logs-onboarding`}>
                {/* Logs Onboarding Experiment */}
                <Route path="" element={<LogsOnboardingScreen />} />
                <Route path=":type/:platform" element={<LogsInstructions />} />
              </Route>
              <Route index element={<Navigate to={`start`} replace />} />
              <Route path={`*`} element={<RoutingScreenWrapper />} />
            </Route>

            {/* Import Dashboard Routes */}
            <Route path="import-dashboard">
              {importDashboardSteps.map((step) => (
                <Route
                  key={step}
                  path={`${getImportDashboardStepData(step).step}/:dashboardId/:datasource`}
                  element={<ImportDashboardPage step={step} />}
                />
              ))}
              <Route index element={<Navigate to={`/a/cloud-home-app/onboarding-flow/start`} replace />} />
            </Route>

            {/* default route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </Provider>
    </AppContext.Provider>
  );
});

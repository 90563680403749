import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';

import { hostedTypes, HostedTypes } from 'feature/common/types/HostedData';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { cloudStackList } from 'feature/common/consts/localSources';

export const transformHostedDataList = (data: HostedDataDetails | undefined): DataToConnect[] => {
  if (data) {
    return cloudStackList.map((stack) => {
      const newStack = { ...stack };

      const slug: keyof HostedTypes = newStack.id.slice(0, 2);

      newStack.url = `https://grafana.com/orgs/${data.orgSlug}/${hostedTypes[slug]}/${data[newStack.id]}`;

      return newStack;
    });
  }
  return [];
};

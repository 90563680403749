import React, { useContext } from 'react';
import { IconButton, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { Dashboard } from 'feature/common/types/Dashboard';
import { QueryError } from 'feature/common/types/QueryError';
import { AlertLine } from 'feature/common/components/AlertLine/AlertLine';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid rgba(204, 204, 220, 0.12);
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1)};
    align-items: center;
    justify-content: space-between;

    &:hover {
      a {
        text-decoration: underline;
        color: ${theme.colors.text.link};
      }
    }
  `,
  title: css`
    display: flex;
    flex-direction: column;
  `,
  subTitle: css`
    color: ${theme.colors.text.secondary};
    font-size: 0.857143rem;
    line-height: 1.57143;
  `,
  link: css`
    color: ${theme.colors.text.primary};

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.text.link};
    }
  `,
  favorite: css`
    display: flex;
    justify-content: flex-end;

    svg {
      fill: rgb(235, 123, 24);
    }
  `,
  startButton: css`
    margin-right: 0;

    &:hover {
      &:before {
        background-color: transparent;
      }
    }
  `,
});

type DashboardStarredItemProps = {
  item: Dashboard;
  loading: boolean;
  onDelete: () => void;
  error?: QueryError;
};

export const DashboardStarredItem = ({ item, onDelete, loading, error }: DashboardStarredItemProps) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);

  function trackClickOnDashboardLink(id: number): void {
    trackAction(
      FlowEventName.HOME_PAGE_EVENT,
      ComponentKey.Dashboards.StarredDashboardLink(id),
      ActionType.click,
      orgId
    );
  }

  function onUnStarred(id: number) {
    trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.Dashboards.UnStarredDashboard(id), ActionType.click, orgId);
    onDelete();
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <a
          href={item.url}
          title={item.title}
          className={styles.link}
          onClick={() => trackClickOnDashboardLink(item.id)}
        >
          {item.title}
        </a>
        {Boolean(item.folderTitle) && <div className={styles.subTitle}>{item.folderTitle}</div>}
        {!!error && <AlertLine status={'error'}>{error.data.message ?? 'Error while un-starred dashboard'}</AlertLine>}
      </div>
      <div className={styles.favorite}>
        <IconButton
          aria-label="favorite"
          className={styles.startButton}
          name={loading ? 'fa fa-spinner' : 'favorite'}
          title={`Un-mark "${item.title}" as favorite`}
          disabled={loading}
          onClick={() => onUnStarred(item.id)}
        />
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { trackPage } from '@grafana/cloud-ab-testing';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { css } from '@emotion/css';
import { Icon, useStyles2 } from '@grafana/ui';
import { getOnboardingScreenStyles } from 'feature/onboarding/components/OnboardingScreen/OnboardingScreen.styles';
import { logsOnboardingData } from 'feature/onboarding/data/LogsOnboarding.data';
import { GrafanaTheme2 } from '@grafana/data';
import { LogsCardsSelection } from './LogsCardsSelection';
import { LogsDataConnection } from './LogsDataConnection';
import { LogsKeys } from 'feature/onboarding/types/LogsOnboardingTypes';

const getLogsOnboardingStyle = (theme: GrafanaTheme2) => ({
  backLink: css`
    display: flex;
    gap: ${theme.spacing(1)};
  `,
  demoButton: css`
    background: transparent;
    border: none;
    color: ${theme.colors.text.link};
    padding-left: 0px;
  `,
  buttonsWrapper: css`
    width: 90%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(3)} 0px;
    margin: auto;
  `,
  cardWrapper: css`
    padding: ${theme.spacing(4)} 0px;
    width: 90%;
    position: relative;
    margin: auto;
    max-width: 1300px;
  `,
  arrow: css`
    margin-top: -5px;
  `,
  border: css`
    width: 100%;
    height: 1px;
    border-bottom: 1px ${theme.colors.border.medium} solid;
  `,
});

export const LogsOnboardingScreen = () => {
  const styles = useStyles2((theme) => getOnboardingScreenStyles(theme, 'onboarding'));
  const logsOnboardingStyles = useStyles2(getLogsOnboardingStyle);
  const { orgId } = useContext(AppContext);
  const [selectedCard, setSelectedCard] = useState<LogsKeys>();

  const PLUGIN_API_ROOT = '/api/plugins/grafana-demodashboards-app';
  const installDatasources = async function () {
    return fetch(`${PLUGIN_API_ROOT}/resources/datasources`, { method: 'POST' });
  };
  const installDashboards = async function () {
    return fetch(`${PLUGIN_API_ROOT}/resources/dashboards`, {
      method: 'POST',
      body: JSON.stringify({ variant: 'quickpizza' }),
    });
  };

  useEffect(() => {
    document.title = 'Logs started - Grafana';
    trackPage({
      adobeTarget: {
        viewName: `onboarding-flow/logs`,
      },
    });

    return trackAction(FlowEventName.LOGS_ONBOARDING_EVENT_NAME, 'logs-onboarding-screen', ActionType.visit, orgId);
  }, [orgId]);

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.LOGS_ONBOARDING_EVENT_NAME, key, ActionType.click, orgId);
  }

  function onDemoComponentClick(key: string): void {
    trackAction(FlowEventName.LOGS_ONBOARDING_EVENT_NAME, key, ActionType.click, orgId);
    installDatasources()
      .then(() => installDashboards())
      .then(() => (window.location.href = '/d/fdkz6t5tcm7lse/logs-demo-dashboard'));
  }

  const logsRoutingData = logsOnboardingData;

  return (
    <div className={styles.container}>
      <div className={logsOnboardingStyles.buttonsWrapper}>
        <a
          className={logsOnboardingStyles.backLink}
          href={logsRoutingData.buttons.back.link}
          onClick={() => onComponentClick(logsRoutingData.buttons.back.key)}
        >
          <Icon className={logsOnboardingStyles.arrow} name={'arrow-left'} size={'xxl'} />
          <h3>{logsRoutingData.buttons.back.text}</h3>
        </a>

        <h6>
          Not ready to connect? Check out the{' '}
          <button
            className={logsOnboardingStyles.demoButton}
            onClick={() => {
              onDemoComponentClick(logsRoutingData.buttons.skip.key);
            }}
          >
            {logsRoutingData.buttons.skip.text}
          </button>
        </h6>
      </div>
      <div className={logsOnboardingStyles.border} />
      <div className={logsOnboardingStyles.cardWrapper}>
        <LogsCardsSelection selectedCard={selectedCard as LogsKeys} setSelectedCard={setSelectedCard} />
        {!!selectedCard && <LogsDataConnection selectedCard={selectedCard} />}
      </div>
    </div>
  );
};

import React, { useContext, useEffect } from 'react';

import { trackPage } from '@grafana/cloud-ab-testing';
import { PageToolbar, useStyles2 } from '@grafana/ui';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { getWizardStepData } from 'feature/onboarding/data/SetupWizard.data';
import { SetupWizardStep } from 'feature/onboarding/types/SetupWizard';
import { getSetupWizardStyles } from 'feature/onboarding/components/SetupWizard/SetupWizard.styles';
import StepsList from 'feature/onboarding/components/SetupWizard/StepsList';
import { useNavigate } from 'react-router-dom';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';

interface SetupWizardProps {
  step: SetupWizardStep;
}

export const SetupWizard: React.FC<SetupWizardProps> = ({ step }) => {
  const styles = useStyles2(getSetupWizardStyles);
  const { orgId } = useContext(AppContext);
  const navigate = useNavigate();
  const stepData = getWizardStepData(step);

  function onComponentClick(key: string): void {
    if (stepData) {
      trackAction(stepData.eventName ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME, key, ActionType.click, orgId);
    }
  }

  useEffect(() => {
    trackPage({
      adobeTarget: {
        viewName: stepData.route,
      },
    });
  }, [step, stepData]);

  return (
    <div className={styles.container}>
      {!!stepData && (
        <>
          <PageToolbar
            title={stepData.path}
            onGoBack={() => {
              onComponentClick(stepData.buttons.back.key);
              navigate(stepData.buttons.back.link);
            }}
          />
          <div className={stepData.steps.length > 0 ? styles.contentOuter : styles.wrapper(!!stepData.header)}>
            {stepData.steps.length > 0 && <StepsList data={stepData.steps} />}
            <div className={styles.wizardBody(stepData.steps.length)}>{stepData.component}</div>
          </div>
        </>
      )}
    </div>
  );
};

import React from 'react';
import { formatUsageValue } from '../utils/usageUtils';
import { Metric } from './Metric';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { UsageProps } from 'feature/usage/types/UsageData';

export function ProMetricsUsage({ usage, billingDashboard }: UsageProps): JSX.Element {
  return (
    <div data-testid={DataTestId.PRO_METRICS_USAGE_METRIC_ID}>
      <Metric
        id="metric_pro_metrics"
        label=""
        link={billingDashboard?.url}
        isLoading={false}
        value={formatUsageValue(usage, MetricUnit.METRICS, false)}
        unit={'Metrics'}
      ></Metric>
    </div>
  );
}

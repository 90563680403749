import React from 'react';
import { LIMIT_TITLE, METRICS_FREE_INCLUDED } from '../consts/usage';
import { formatUsageValue, handleCompactFormat } from '../utils/usageUtils';
import { Metric } from './Metric';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { UsageProps } from 'feature/usage/types/UsageData';

export function FreeMetricsUsage({ usage, billingDashboard }: UsageProps): JSX.Element {
  return (
    <div data-testid={DataTestId.FREE_METRICS_USAGE_METRIC_ID}>
      <Metric
        id="metric_free_metrics"
        label={`${LIMIT_TITLE}: ${handleCompactFormat(METRICS_FREE_INCLUDED)}`}
        link={billingDashboard?.url}
        isLoading={false}
        value={formatUsageValue(usage, MetricUnit.METRICS, true)}
        unit={'Metrics'}
      ></Metric>
    </div>
  );
}

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from '../consts/styles';

export const getPageStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      -webkit-font-smoothing: antialiased;
      color: ${theme.colors.text.primary};
      display: flex;
      margin: 45px auto 0 auto;
      max-width: 1675px;
      width: 80%;
      flex-direction: column;
      @media (max-width: ${breakpoints.titleText}px) {
        margin-top: 50px;
      }
      @media (max-width: ${breakpoints.wideScreen}px) {
        width: 90%;
      }
    `,
    wrapperNewDesign: css`
      -webkit-font-smoothing: antialiased;
      display: flex;
      max-width: 1675px;
      width: 100%;
      flex-direction: column;
    `,
    fullWidth: css`
      max-width: 100%;
    `,
  };
};

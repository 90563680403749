import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { getAccountStyles } from 'feature/home/components/refresh-design/Account.styles';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { AccountPlanCard } from 'feature/home/components/refresh-design/AccountPlanCard';
import { AccountDocsCard } from 'feature/home/components/refresh-design/AccountDocsCard';
import { ActiveUsersSection } from 'feature/home/components/refresh-design/ActiveUsersSection';
import { isUserAdmin } from 'feature/common/utils/UserUtils';
import { OrgSection } from 'feature/home/components/refresh-design/OrgSection';

export const AccountOrgResources = () => {
  const styles = useStyles2(getAccountStyles);

  return (
    <div data-testid={DataTestId.ACCOUNT_INFO_CONTAINER_ID}>
      <div data-testid={DataTestId.ACCOUNT_TITLE} className={styles.headerTitle}>
        Account
      </div>
      <div className={styles.wrapper}>
        {isUserAdmin() && <AccountPlanCard />}
        {!isUserAdmin() && <OrgSection />}
        <ActiveUsersSection />
        <AccountDocsCard />
      </div>
    </div>
  );
};

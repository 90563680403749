import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { Badge, useStyles2 } from '@grafana/ui';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { handleKeyDown } from 'feature/common/utils/aria';

const getStyles = (theme: GrafanaTheme2) => ({
  itemIcon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    cursor: pointer;
  `,
  itemRow: css`
    display: flex;
    text-align: left;
    list-style-type: none;
    padding-bottom: ${theme.spacing(4)};
    flex-direction: column;
  `,
  itemContainer: css`
    display: flex;
    width: 100%;
  `,
  titleContainer: css`
    display: flex;
    width: 70%;
    align-items: center;
  `,
  badgeContainer: css`
    display: flex;
    width: 30%;
    justify-content: flex-end;

    @media (max-width: 540px) {
      display: none;
    }
  `,
  badge: css`
    margin-right: ${theme.spacing(1)};
  `,
  name: css`
    margin-right: ${theme.spacing(1)};
    cursor: pointer;
  `,
  description: css`
    display: flex;
    font-size: ${theme.typography.size.sm};
    color: ${theme.colors.text};
    line-height: ${theme.spacing(2)};
    color: ${theme.colors.text};
    margin-top: ${theme.spacing(1)};
  `,
  infoIcon: css`
    position: relative;
    margin-left: -8px;
    background-color: transparent;
    border: 0;

    &:active {
      outline: none;
      border: none;
    }

    ,
  &: focus {
      outline: none;
      border: none;
    }
  `,
});

interface SelectConnectionItemProps {
  item: DataToConnect;
  onSelected: (item: DataToConnect) => void;
}

export function SelectConnectionItem({ item, onSelected }: SelectConnectionItemProps): JSX.Element {
  const styles = useStyles2(getStyles);

  function onSelectClick(e: any) {
    onSelected(item);
  }

  return (
    <li className={styles.itemRow} key={item.id}>
      <div
        className={styles.itemContainer}
        data-id={item.id}
        onClick={onSelectClick}
        onKeyDown={handleKeyDown(onSelectClick)}
        tabIndex={0}
        role="button"
      >
        <div className={styles.titleContainer}>
          <img alt={'Connection item logo'} className={styles.itemIcon} src={item.logo_url} />
          <span className={styles.name}>{item.name}</span>
        </div>
        <div className={styles.badgeContainer}>
          {item.mostPopular && <Badge className={styles.badge} text={'Most popular'} color={'green'} />}
        </div>
      </div>
    </li>
  );
}

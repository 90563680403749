export const ComponentKey = {
  Integration: 'integration',
  DataForwarding: 'data-forwarding',
  DataSource: 'data-source',
  IntegrationsFromHome: 'integrations-from-home',
  DataSourceFromHome: 'data-source-from-home',
  HostedDataFromHome: 'hosted-data-from-home',
  Skip: 'skip',
  SkipStart: 'skip-start',
  SkipCreateDashboard: 'skip-create-dashboard',
  SkipHostedData: 'skip-hosted-data',
  SkipExplore: 'skip-explore',
  SkipEasyData: 'skip-easy-data',
  BackCreateDashboard: 'back-create-dashboard',
  BackCreateDashboardWizardCustom: 'back-custom-create-dashboard',
  BackHostedData: 'back-hosted-data',
  BackExplore: 'back-explore',
  BackEasyData: 'back-easy-data',
  CreateDashboard: 'create-dashboard',
  HostedData: 'hosted-data',
  OnCall: 'oncall',
  Kubernetes: 'kubernetes',
  KubernetesFromHomeApp: 'kubernetes-from-home-app',
  OncallFromHomeApp: 'oncall-from-home-app',
  K6FromHomeApp: 'k6-from-home-app',
  SyntheticMonitoringFromQuickstartHome: 'explore-synthetic-monitoring-from-quickstart-home',
  RaspberryPiFromQuickstartHome: 'explore-raspberry-pi-link-from-quickstart-home',
  WindowsFromQuickstartHome: 'explore-windows-link-from-quickstart-home',
  MacOSFromQuickstartHome: 'macos-from-quickstart-home',
  LinuxFromQuickstartHome: 'linux-from-quickstart-home',
  ServiceHealthDashboardFromHome: 'service-health-monitoring-dashboard-from-home',
  WebsitePerformanceDashboardFromHome: 'explore-website-performance-link-from-home',
  PrometheusDashboardFromHome: 'explore-prometheus-metrics-overview-link-from-home',
  CitiBikesNYCFromHome: 'citi-bikes-nyc-from-home',
  AlertIncidentsViolationsDashboardFromHome: 'explore-open-alert-incidents-violations-link-from-home',
  PrometheusMetricsDashboardFromHome: 'explore-prometheus-metrics-overview-link-from-home',
  ExploreTimeSeriesVizDashboardFromHome: 'time-series-viz-dashboard',
  Explore: 'explore',
  Prometheus: 'prometheus',
  Graphite: 'graphite',
  Loki: 'loki',
  Tempo: 'tempo',
  Tour: 'tour',
  Demo: 'demo',
  EasyData: 'easy-data',
  MacOS: 'macos',
  Windows: 'windows',
  Linux: 'linux',
  SyntheticMonitoring: 'synthetic-monitoring',
  SampleData: 'sample-data',
  NavbarVisible: 'navbar-visible',
  PrometheusDashboardDataSource: 'prometheus-dashboard-datasource',
  PrometheusDashboardHosted: 'prometheus-dashboard-hosted',
  BackTemplateDashboard: 'back-template-dashboard',
  MoreTemplateDashboard: 'more-template-dashboard',
  SkipTemplateDashboard: 'skip-template-dashboard',
  SkipCustomDashboard: 'skip-custom-dashboard',
  BackCustomDashboard: 'back-custom-dashboard',
  MoreCustomDashboard: 'more-custom-dashboard',
  SkipTemplatePrometheusDashboard: 'skip-template-prometheus-dashboard',
  BackTemplatePrometheusDashboard: 'back-template-prometheus-dashboard',
  MoreTemplatePrometheusDashboard: 'more-template-prometheus-dashboard',
  SkipCustomPrometheusDashboard: 'skip-custom-prometheus-dashboard',
  BackCustomPrometheusDashboard: 'back-custom-prometheus-dashboard',
  MoreCustomPrometheusDashboard: 'more-custom-prometheus-dashboard',
  SAVE_CONFIG_INFLUXDB: 'save-config-influxdb',
  SKIP_CONFIG_INFLUXDB: 'skip-config-influxdb',
  SETTINGS_CONFIG_INFLUXDB: 'settings-config-influxdb',
  SAVE_CONFIG_GRAPHITE: 'save-config-graphite',
  SKIP_CONFIG_GRAPHITE: 'skip-config-graphite',
  SETTINGS_CONFIG_GRAPHITE: 'settings-config-graphite',
  SAVE_CONFIG_POSTGRESQL: 'save-config-postgresql',
  SKIP_CONFIG_POSTGRESQL: 'skip-config-postgresql',
  SETTINGS_CONFIG_POSTGRESQL: 'settings-config-postgresql',
  SAVE_CONFIG_MYSQL: 'save-config-mysql',
  SKIP_CONFIG_MYSQL: 'skip-config-mysql',
  SETTINGS_CONFIG_MYSQL: 'settings-config-mysql',
  SKIP_CONFIG_PROMETHEUS: 'skip-config-prometheus',
  SETTINGS_CONFIG_PROMETHEUS: 'settings-config-prometheus',
  SAVE_CONFIG_PROMETHEUS: 'save-config-prometheus',
  CARD_CREATE_DASHBOARD_PANEL: 'card-custom-create-dashboard',
  BUTTON_CREATE_DASHBOARD_PANEL: 'btn-custom-create-dashboard',
  Quickstart: 'quickstart',
  SkipQuickstart: 'skip-quickstart',
  BackQuickstart: 'back-quickstart',
  QuickstartFromHomeApp: 'quickstart-from-home-app',
  LOAD_AB_TESTING_ERROR: 'load-ab-testing-error',
  ConnectDataDocsFromHomeApp: 'connect-data-docs-from-home-app',
  DemoDashboardFromHomeApp: 'demo-dashboard-from-home-app',
  ConnectDataButtonFromHome: 'connect-data-button-from-home',
  AlertsAllLink: 'alerts_panel_all_link',
  DashboardsAllLink: 'dashboards_panel_all_link',
  SelectConnection: 'connection-item-selected',
  GoToConnectionPage: 'continue-to-connection-page',
  GoToCreateDashboardPage: 'continue-to-create-dashboard-page',
  BackSelectAndCreateDashboard: 'back-select-and-create-dashboard',
  SkipSelectAndCreateDashboard: 'skip-select-and-create-dashboard',
  GoToCloudPlatformPage: 'continue-to-cloud-platform',
  TutorialPluginEnteredFromDemoDashboard: 'tutorial-plugin-entered-from-demo-dashboard',
  TutorialPluginEnteredFromOnboardingFlow: 'tutorial-plugin-entered-from-onboarding-flow',
  ExploreApplicationObservabilityDashboard: 'explore-application-observability-dashboard',

  SELECT_AND_SAVE_CONFIG_INFLUXDB: 'connect-save-config-influxdb',
  SELECT_AND_SKIP_CONFIG_INFLUXDB: 'connect-skip-config-influxdb',
  SELECT_AND_SETTINGS_CONFIG_INFLUXDB: 'connect-settings-config-influxdb',
  SELECT_AND_SAVE_CONFIG_GRAPHITE: 'connect-save-config-graphite',
  SELECT_AND_SKIP_CONFIG_GRAPHITE: 'connect-skip-config-graphite',
  SELECT_AND_BACK_CONFIG_GRAPHITE: 'connect-back-config-graphite',
  SELECT_AND_SETTINGS_CONFIG_GRAPHITE: 'connect-settings-config-graphite',
  SELECT_AND_SAVE_CONFIG_POSTGRESQL: 'connect-save-config-postgresql',
  SELECT_AND_BACK_CONFIG_POSTGRESQL: 'connect-back-config-postgres',
  SELECT_AND_SKIP_CONFIG_POSTGRESQL: 'connect-skip-config-postgresql',
  SELECT_AND_SETTINGS_CONFIG_POSTGRESQL: 'connect-settings-config-postgresql',
  SELECT_AND_SAVE_CONFIG_MYSQL: 'connect-save-config-mysql',
  SELECT_AND_SKIP_CONFIG_MYSQL: 'connect-skip-config-mysql',
  SELECT_AND_SETTINGS_CONFIG_MYSQL: 'connect-settings-config-mysql',
  SELECT_AND_BACK_CONFIG_MYSQL: 'connect-back-config-mysql',
  SELECT_AND_SKIP_CONFIG_PROMETHEUS: 'connect-skip-config-prometheus',
  SELECT_AND_SAVE_CONFIG_PROMETHEUS: 'connect-save-config-prometheus',
  SELECT_AND_SETTINGS_CONFIG_PROMETHEUS: 'connect-settings-config-prometheus',
  SELECT_AND_BACK_CONFIG_PROMETHEUS: 'connect-back-config-prometheus',
  EXTERNAL_API_ERROR: 'external-api-error',

  OpenAdvancedDSSettingsImportDashboard: 'advanced-settings-config-influxdb-import-community-dashboard',
  OpenConfigureDataImportDashboard: 'open-configure-data-import-community-dashboard',
  ConfigureDataImportDashboardSuccess: 'configure-data-import-community-dashboard-success',
  ImportDashboardFlowSuccess: 'import-community-dashboard-flow-success',
  ImportDashboardFlowError: 'import-community-dashboard-flow-error',

  /** GETTING STARTED */
  OnboardingScreenV1: 'start-control-screen',
  OnboardingScreenV2: 'start-test-screen',
  GetStartedScreenV1: 'get-started-control-screen',
  GetStartedScreenV2: 'get-started-test-screen',
  SkipOnboardingScreenV1: 'skip-start',
  SkipOnboardingScreenV2: 'skip-onboarding',

  GettingStartedSaveConfigInfluxDB: 'getting-started-connect-save-config-influxdb',
  GettingStartedSaveConfigMySQL: 'getting-started-connect-save-config-mysql',
  GettingStartedSaveConfigGraphite: 'getting-started-connect-save-config-graphite',
  GettingStartedSaveConfigPostgreSQL: 'getting-started-connect-save-config-postgresql',
  GettingStartedSaveConfigPrometheus: 'getting-started-connect-save-config-prometheus',

  GettingStartedSettingsConfigInfluxDB: 'getting-started-connect-settings-config-influxdb',
  GettingStartedSettingsConfigMySQL: 'getting-started-connect-settings-config-mysql',
  GettingStartedSettingsConfigGraphite: 'getting-started-connect-settings-config-graphite',
  GettingStartedSettingsConfigPostgreSQL: 'getting-started-connect-settings-config-postgresql',
  GettingStartedSettingsConfigPrometheus: 'getting-started-connect-settings-config-prometheus',

  GettingStartedBackConfigInfluxDB: 'getting-started-connect-back-config-influxdb',
  GettingStartedBackConfigMySQL: 'getting-started-connect-back-config-mysql',
  GettingStartedBackConfigGraphite: 'getting-started-connect-back-config-graphite',
  GettingStartedBackConfigPostgreSQL: 'getting-started-connect-back-config-postgresql',
  GettingStartedBackConfigPrometheus: 'getting-started-connect-back-config-prometheus',

  OnboardingInviteTeamMember: 'invite-team-member',

  QuickPizzaDemoDashboards: 'quick-pizza-demo-dashboards',
  WeatherDemoDashboards: 'weather-demo-dashboards',
  DemoDashboardsPage: 'demo-dashboards-page',
  SandboxAccountsPage: 'sandbox-accounts-page',
  KubernetesDemoDashboardsPage: 'kubernetes-demodashboards-page',

  QuickPizzaDemoDashboardsControl: 'quick-pizza-demo-dashboards-control',
  WeatherDemoDashboardsControl: 'weather-demo-dashboards-control',
  KubernetesDemoDashboardsPageControl: 'kubernetes-demodashboards-page-control',
  ExploreApplicationObservabilityDashboardControl: 'explore-application-observability-dashboard-control',
  ExploreTimeSeriesVizDashboardFromHomeControl: 'time-series-viz-dashboard-control',
  CitiBikesNYCFromHomeControl: 'citi-bikes-nyc-from-home-control',

  QuickPizzaDemoDashboardsLink: 'quick-pizza-demo-dashboards-link-control',
  WeatherDemoDashboardsLink: 'weather-demo-dashboards-link-control',
  KubernetesDemoDashboardsPageLink: 'kubernetes-demodashboards-page-link-control',
  ExploreApplicationObservabilityDashboardLink: 'explore-application-observability-dashboard-link-control',
  ExploreTimeSeriesVizDashboardFromHomeLink: 'time-series-viz-dashboard-link-control',
  CitiBikesNYCFromHomeLink: 'citi-bikes-nyc-from-home-link-control',

  QuickPizzaDemoDashboardsSimplified: 'quick-pizza-demo-dashboards-simplified',
  WeatherDemoDashboardsSimplified: 'weather-demo-dashboards-simplified',
  KubernetesDemoDashboardsPageSimplified: 'kubernetes-demodashboards-page-simplified',
  ExploreApplicationObservabilityDashboardSimplified: 'explore-application-observability-dashboard-simplified',
  ExploreTimeSeriesVizDashboardFromHomeSimplified: 'time-series-viz-dashboard-simplified',
  CitiBikesNYCFromHomeSimplified: 'citi-bikes-nyc-from-home-simplified',

  QuickPizzaDemoDashboardsLinkSimplified: 'quick-pizza-demo-dashboards-link-simplified',
  WeatherDemoDashboardsLinkSimplified: 'weather-demo-dashboards-link-simplified',
  KubernetesDemoDashboardsPageLinkSimplified: 'kubernetes-demodashboards-page-link-simplified',
  ExploreApplicationObservabilityDashboardLinkSimplified: 'explore-application-observability-dashboard-link-simplified',
  ExploreTimeSeriesVizDashboardFromHomeLinkSimplified: 'time-series-viz-dashboard-link-simplified',
  CitiBikesNYCFromHomeLinkSimplified: 'citi-bikes-nyc-from-home-link-simplified',

  /** Home page **/
  Home: {
    /** Black version **/
    GetStartedButtonFromHome: 'get-started-button-from-home',

    /** New refresh re-design **/
    GetStartedButtonRefresh: 'home-refresh-get-started-button',
    AccountManagementMenu: (id: string) => `home-refresh-menu-${id}`,
  },
  Dashboards: {
    CreateYourFirstDashboard: 'home-refresh-create-first-dashboard-button',
    BrowseDashboards: 'home-refresh-browse-dashboards-button',
    DashboardTab: (id: string) => `home-refresh-dashboard-tab-${id}`,
    StarredDashboardLink: (id: number) => `home-refresh-starred-dashboard-link-${id}`,
    UnStarredDashboard: (id: number) => `home-refresh-uncheck-starred-dashboard-${id}`,
    ExploreDashboardCard: (id: string) => `home-refresh-explore-${id}`,
  },
  Accounts: {
    ManageSubscription: 'home-refresh-accounts-manage-subscription-link',
    AccountsCustomLink: (id: string) => `home-refresh-accounts-${id}-link`,
    BillingDashboardLink: 'home-refresh-usage-billing-dashboard-link',
  },
  Usage: {
    TooltipLink: (metricId: string, tooltipId: string) => `home-refresh-usage-${metricId}-${tooltipId}-link`,
  },
  Onboarding: {
    SelectAndCreateDashboardVisited: 'select-and-create-dashboard-visited',
    OpenStarterDashboardButton: 'open-starter-dashboard-button',
  },
};

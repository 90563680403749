import { appCreateApi } from 'app/context';
import { backendSrvBaseQuery } from 'feature/common/api/baseApi';
import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { getResultAsNumber } from 'feature/common/utils/datasourcesUtils';
import { GRAFANA_USAGE_DATASOURCE } from 'feature/common/consts/datasources';

export const UsageApi = appCreateApi({
  baseQuery: backendSrvBaseQuery({ dataSourceName: GRAFANA_USAGE_DATASOURCE }),
  reducerPath: 'usage',
  endpoints: (build) => ({
    getUsage: build.query<number | undefined, string>({
      query: (dataSourceName: string) => `/api/v1/query?query=${encodeURIComponent(dataSourceName)}`,
      transformResponse: (response: BaseQueryResult<any>) => {
        if (response?.data?.result) {
          return getResultAsNumber(response.data.result);
        }
        return 0;
      },
    }),
  }),
});

export const { useGetUsageQuery } = UsageApi;

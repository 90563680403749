import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { trackPage } from '@grafana/cloud-ab-testing';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { GettingStartedStep } from 'feature/onboarding/types/GettingStartedCard';
import { closeNavIfOpened } from 'feature/onboarding/utils/sectionNavUtils';
import { PluginPage } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import { getGettingStartedStepData } from 'feature/onboarding/data/GettingStartedCard.data';
import { GrafanaTheme2, PageLayoutType } from '@grafana/data';
import { css } from '@emotion/css';
import { GettingStarted } from './GettingStarted';

const getStyles = (theme: GrafanaTheme2) => css`
  margin-top: ${theme.spacing(3)};
`;

interface GettingStartedScreenProps {
  step: GettingStartedStep;
}

export const GettingStartedScreen: React.FC<GettingStartedScreenProps> = ({ step }) => {
  const { orgId } = useContext(AppContext);
  const styles = useStyles2(getStyles);
  const stepData = getGettingStartedStepData(step);
  useLayoutEffect(() => closeNavIfOpened());

  useEffect(() => {
    trackPage({
      adobeTarget: {
        viewName: `getting-started/${step}`,
      },
    });

    return trackAction(FlowEventName.GETTING_STARTED_EVENT_NAME, `${step}-screen`, ActionType.visit, orgId);
  }, [step, orgId]);

  return (
    <PluginPage layout={PageLayoutType?.Standard} subTitle={stepData.preamble} pageNav={stepData.breadcrumbs}>
      <div className={styles}>
        <GettingStarted step={step} version={'get-started'} />
      </div>
    </PluginPage>
  );
};

export const validateUrl = (value: string | null) => {
  if (!value) {
    return 'The URL is required';
  }
  const isValidUrl = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test(value);
  if (!isValidUrl) {
    return 'The URL is not a valid format';
  }
  return true;
};

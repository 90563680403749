import { useStyles2 } from '@grafana/ui';
import { OnboardingImageName } from 'feature/onboarding/types/OnboardingCard';
import { SetupImageName } from 'feature/onboarding/types/SetupWizard';
import { dashboardImg } from 'img';
import React, { useState } from 'react';
import { getOnboardingImageStyles } from './OnboardingImage.styles';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';

export function OnboardingImage({ image }: { image: OnboardingImageName | SetupImageName | string }): JSX.Element {
  const styles = useStyles2(getOnboardingImageStyles);
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <LoadingIndicator />}
      <img
        className={styles.image}
        src={
          dashboardImg[
            (image as unknown as keyof typeof OnboardingImageName) || (image as unknown as keyof typeof SetupImageName)
          ]
        }
        alt={image as unknown as string}
        style={{ display: loading ? 'none' : 'block' }}
        onLoad={() => setLoading(false)}
      />
    </>
  );
}

import { DataTestId } from 'feature/common/types/test-id.enum';
import { MetricUnit } from 'feature/usage/types/MetricUnit';
import { HomeCustomLink } from 'feature/home/components/refresh-design/HomeCustomLink';
import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { useOrgInfoData } from 'feature/common/hooks/useOrgInfoData';
import { Tiers } from 'feature/common/types/Tiers';
import { getAccountStyles } from 'feature/home/components/refresh-design/Account.styles';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { isUserAdmin } from 'feature/common/utils/UserUtils';
import { useGetUsersQuery } from 'feature/common/api/UsersApi';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { getMembersLink } from 'feature/home/components/refresh-design/helpers';

export const ActiveUsersSection = () => {
  const styles = useStyles2(getAccountStyles);
  const isAdmin = isUserAdmin();
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery(undefined, { skip: !isAdmin });
  const {
    data: { orgPlan, orgSlug, usage },
    isLoading: isOrgInfoLoading,
  } = useOrgInfoData();
  const isFreeTier = !isOrgInfoLoading ? orgPlan === Tiers.Free : undefined;

  const isLoading = isOrgInfoLoading || isUsersLoading;
  const totalUsers = users?.length ?? 0;
  const activeUsers = usage?.data[MetricUnit.USERS] ?? 0;

  const title = 'Users';
  const link = getMembersLink(orgSlug);
  const linkTitle = isAdmin ? 'Add users' : 'See all users';
  const total = isFreeTier === true && isAdmin ? totalUsers : activeUsers;
  const linkTrackId = isAdmin ? 'add-users' : 'see-all-users';

  return (
    <div className={styles.card} data-testid={DataTestId.ACCOUNT_USERS_SECTION}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && isFreeTier !== undefined && (
        <div className={styles.usersRow}>
          <div className={styles.userColumn}>
            <div data-testid={DataTestId.ACCOUNT_USERS_TITLE} className={styles.primaryText}>
              {title}
            </div>
            <div className={styles.orgUsersWrapper}>
              <div data-testid={DataTestId.ACCOUNT_TOTAL_ACTIVE_USERS} className={styles.standOutText}>
                {total.toLocaleString()}
              </div>
            </div>
            <HomeCustomLink
              id={'users'}
              text={linkTitle}
              href={link}
              trackId={ComponentKey.Accounts.AccountsCustomLink(linkTrackId)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { getDBConfigFooterStyles } from 'feature/datasource-config/components/DBConfigFooter/DBConfigFooter.styles';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { DatasourceConfigVersion, Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { DataTestId } from 'feature/common/types/test-id.enum';

export const DBConfigFooter: React.FC<{
  uid: string;
  orgId: number;
  datasource: Datasources;
  requiredFields?: boolean;
  version?: DatasourceConfigVersion;
}> = ({ uid, orgId, datasource, requiredFields = false, version }) => {
  const styles = useStyles2(getDBConfigFooterStyles);

  function onComponentClick(key: string): void {
    const importDashboardKey = `advanced-settings-config-${datasource}-import-community-dashboard`;
    let event;
    switch (version) {
      case 'getting-started':
        event = FlowEventName.GETTING_STARTED_EVENT_NAME;
        break;

      case 'import-dashboard':
        event = FlowEventName.IMPORT_DASHBOARD_EVENT_NAME;
        break;

      default:
        event = FlowEventName.ONBOARDING_FLOW_EVENT_NAME;
        break;
    }

    return trackAction(event, version === 'import-dashboard' ? importDashboardKey : key, ActionType.click, orgId);
  }

  function openDatasourcePage() {
    onComponentClick(`settings-config-${datasource}`);
    // TODO remove this timeout when the issue #61815 in Grafana is fixed (Routing in v9.3.2 is not triggering a proper Settings page render)
    setTimeout(() => window.location.reload());
  }

  return (
    <div>
      {requiredFields && (
        <p data-testid={DataTestId.DBCONFIG_FOOTER_REQUIRED_MSG} className={styles.textWrapper}>
          * Required fields
        </p>
      )}
      {uid && (
        <p className={styles.textWrapper} data-testid={DataTestId.DBCONFIG_FOOTER_CUSTOM_CONFIG_CONTAINER}>
          Customized configuration is available in the{' '}
          <a
            href={`/datasources/edit/${uid}`}
            onClick={openDatasourcePage}
            data-testid={DataTestId.DBCONFIG_FOOTER_CUSTOM_CONFIG_LINK}
            className={styles.advancedLink}
          >
            advanced settings page.
          </a>
          <span className={styles.note} data-testid={DataTestId.DBCONFIG_FOOTER_CUSTOM_CONFIG_NOTE}>
            Note: This action will quit the dashboard setup wizard.
          </span>
        </p>
      )}
    </div>
  );
};

import React, { useContext } from 'react';
import { useStyles2 } from '@grafana/ui';
import { OnboardingCardLink, OnboardingLinksCardData } from 'feature/onboarding/types/OnboardingCard';
import { getOnboardingCardStyles } from './OnboardingCard.styles';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { OnboardingCardIconsAndTitle } from 'feature/onboarding/components/OnboardingCard/OnboardingCardIconsAndTitle';
import { AppContext } from 'App';

export function OnboardingLinksCard({
  card,
  isInModal,
}: {
  card: OnboardingLinksCardData;
  isInModal?: boolean;
}): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  const { orgId } = useContext(AppContext);

  function onLinkClick(link: OnboardingCardLink) {
    trackAction(
      (card.eventName as FlowEventName) ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      link.key,
      ActionType.click,
      orgId
    );
    if (link.callback) {
      link.callback();
    }
  }

  return (
    <div key={card.key}>
      <div className={styles.wrapper(undefined, isInModal)}>
        <OnboardingCardIconsAndTitle card={card} isInModal />
        {!!card.links?.length && (
          <ul className={styles.linkList}>
            {card.links?.map((link) => (
              <li key={link.key}>
                <a
                  className={styles.link}
                  href={link.url}
                  key={link.key}
                  onClick={() => onLinkClick(link)}
                  target={link.target}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

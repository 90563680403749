import { appPaths } from 'feature/common/consts/appPaths';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import {
  GettingStartedPath,
  GettingStartedStep,
  GettingStartedStepData,
} from 'feature/onboarding/types/GettingStartedCard';
import { OnboardingGeneralCardData, OnboardingStepData } from 'feature/onboarding/types/OnboardingCard';
import { getControlOnboardingStepData } from 'feature/onboarding/data/ControlOnboarding.data';
import { cloneDeep } from 'lodash';
import { DEFAULT_ONBOARDING_GROUP } from 'feature/onboarding/consts/consts';
import { rollbackNavExpanded } from 'feature/onboarding/utils/sectionNavUtils';

export const gettingStartedSteps = ['start', 'hosted-data', 'explore', 'quickstart', 'performance-testing'];

export const gettingStartedPaths: GettingStartedPath[] = gettingStartedSteps.map((step) => ({
  step,
  path: `${step}`,
}));

export const getGettingStartedStepData = (
  step: GettingStartedStep,
  testGroup = DEFAULT_ONBOARDING_GROUP
): GettingStartedStepData => {
  const data: GettingStartedStepData = cloneDeep(getControlOnboardingStepData(step));
  const startData: OnboardingStepData = step !== 'start' ? getControlOnboardingStepData('start') : cloneDeep(data);

  // replace links and event names
  if (!!data.buttons.skip.link) {
    data.buttons.skip.link = data.buttons.skip.link.replace('onboarding-flow', 'getting-started');
  }
  if (!!data.buttons.back.link) {
    data.buttons.back.link = data.buttons.back.link.replace('onboarding-flow', 'getting-started');
  }
  if (!!data.buttons.more?.link) {
    data.buttons.more.link = data.buttons.more.link.replace('onboarding-flow', 'getting-started');
  }

  data.cards = data.cards.map((card) => {
    const generalCard = card as OnboardingGeneralCardData;
    if (!!generalCard.link?.url) {
      generalCard.link.url = generalCard.link.url.replace('onboarding-flow', 'getting-started');

      if (generalCard.link.url.indexOf('getting-started') < 0) {
        generalCard.link.callback = () => rollbackNavExpanded();
      }
    }
    card.eventName = FlowEventName.GETTING_STARTED_EVENT_NAME;

    return card;
  });
  data.breadcrumbs = { text: data.header, subTitle: data.preamble };
  if (step === 'start') {
    data.breadcrumbs.url = appPaths.gettingStarted;
  } else {
    data.breadcrumbs.parentItem = { text: startData.header, url: appPaths.gettingStarted };
  }

  return data;
};

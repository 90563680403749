import { ExploreDashboardCard } from 'feature/home/components/refresh-design/ExploreDashboardCard';
import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import demoDataDashboard from 'img/demoDashboards/demo-data-dashboards.svg';
import accountUsageDashboards from 'img/demoDashboards/account-usage-dashboards.svg';
import sandboxAccountDashboards from 'img/demoDashboards/sandbox-account-dashboards.svg';
import { isUserAdmin } from 'feature/common/utils/UserUtils';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      display: grid;
      grid-template-columns: repeat(${3}, 1fr);
      grid-auto-rows: auto;
      grid-gap: ${theme.spacing(2)};
      background-color: ${theme.colors.background.canvas};
      height: 200px;

      @media (min-width: 768px) and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        height: unset;
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        height: unset;
      }
    `,
  };
};

export const ExploreDashboards = () => {
  const isAdmin = isUserAdmin();
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      {isAdmin && (
        <ExploreDashboardCard
          title={'Demo Data'}
          description={'Install demo dashboards with real data to play and explore'}
          backgroundImage={demoDataDashboard}
          backgroundColor={'#73BF69'}
          url={'/a/grafana-demodashboards-app'}
        />
      )}
      <ExploreDashboardCard
        title={'Account Usage'}
        description={
          'Get insights into your account with dashboards to monitor your billing, cardinality, usage and more'
        }
        backgroundImage={accountUsageDashboards}
        backgroundColor={'#5794F2'}
        url={'/dashboards?tag=grafanacloud'}
      />
      <ExploreDashboardCard
        title={'Sandbox Account'}
        description={'Check out Grafana Play to get inspired and learn what kinds of dashboards you can build'}
        backgroundImage={sandboxAccountDashboards}
        backgroundColor={'#B877D9'}
        url={'https://play.grafana.org/dashboards'}
      />
    </div>
  );
};

import React from 'react';

import { ImportDataSourceConfig } from 'feature/onboarding/components/ImportDashboard/ImportDataSourceConfig';
import { ImportDashboardForm } from 'feature/onboarding/components/ImportDashboard/ImportDashboardForm';
import { ImportDashboardStepData } from 'feature/onboarding/types/ImportDashboard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { TestGroup } from '../types/TestGroup';

const importDashboardData: { [key: string]: ImportDashboardStepData } = {
  'configure-data': {
    step: 'configure-data',
    steps: [
      {
        title: 'Configure data',
        stepNumber: '1.',
        isActive: true,
      },
      {
        title: 'Configure dashboard',
        stepNumber: '2.',
        isActive: false,
      },
      {
        title: 'View dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '',
    preamble: '',
    path: '',
    route: `:dashboardId/:datasource`,
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <ImportDataSourceConfig />,
  },
  'configure-dashboard': {
    step: 'configure-dashboard',
    steps: [
      {
        title: 'Configure data',
        stepNumber: '1.',
        isActive: false,
      },
      {
        title: 'Configure dashboard',
        stepNumber: '2.',
        isActive: true,
      },
      {
        title: 'View dashboard',
        stepNumber: '3.',
        isActive: false,
      },
    ],
    header: '',
    preamble: '',
    path: '',
    route: `:dashboardId/:datasource`,
    eventName: FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
    component: <ImportDashboardForm />,
  },
};

const stepsOnExperiments: string[] = [];

export const importDashboardSteps = [...new Set(Object.values(importDashboardData).map((obj) => obj.step))] as const;

export const getImportDashboardStepData = (step: string, testGroup = TestGroup.CONTROL): ImportDashboardStepData => {
  const stepId = stepsOnExperiments.includes(step) ? `${step}-${testGroup}` : step;
  return importDashboardData[stepId];
};

import { AuthMethodOptions, DataSourceDTO, DatasourceType } from 'feature/common/types/Datasources';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { FormAPI } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { graphiteVersions, influxVersions, postgresVersions } from 'feature/datasource-config/consts/consts';
import { datasourceConfigData } from 'feature/datasource-config/data/DatasourceConfig.data';
import { getEventNameFromPath } from 'feature/onboarding/utils/OnboardingCard';

export const getCustomDataSourceConfigTypes = (): string[] => {
  return Object.keys(datasourceConfigData);
};

export function updateDatasourceConfig(
  ds: DataSourceDTO,
  orgId: number | undefined,
  updateFn: Function,
  componentKey: string
) {
  if (ds.type === 'prometheus') {
    const eventName = getEventNameFromPath(
      FlowEventName.GETTING_STARTED_EVENT_NAME,
      FlowEventName.ONBOARDING_FLOW_EVENT_NAME
    );
    const componentKey = `connect-save-config-prometheus`;
    trackAction(eventName, componentKey, ActionType.click, orgId);
  } else {
    trackAction(
      getEventNameFromPath(FlowEventName.GETTING_STARTED_EVENT_NAME, FlowEventName.ONBOARDING_FLOW_EVENT_NAME),
      componentKey,
      ActionType.click,
      orgId
    );
  }
  updateFn(ds);
}

export function registerOtherFields(dsType: Datasources, formApi: FormAPI<DataSourceDTO>) {
  if (dsType !== 'graphite' && dsType !== 'influxdb' && dsType !== 'prometheus') {
    formApi.register('password', { required: 'The password is required' });
  }
}

export const getDBConfigVersions = (dsType: Datasources): SelectableValue[] => {
  switch (dsType) {
    case 'influxdb':
      return influxVersions;

    case 'postgres':
      return postgresVersions;

    case 'graphite':
      return graphiteVersions;

    default:
      return [];
  }
};

export const getDefaultVersionFromValue = (value: any, versions: SelectableValue[]): SelectableValue | undefined => {
  if (value && versions && versions.length > 0) {
    return versions.find((v) => v.value === value);
  }
  return undefined;
};

const getDefaultVersion = (dsType: DatasourceType, versions: SelectableValue[]): any => {
  switch (dsType) {
    case 'influxdb':
      return influxVersions[0].value;

    case 'postgres':
      return postgresVersions[3].value;

    case 'graphite':
      return graphiteVersions[graphiteVersions.length - 1].value;

    default:
      return '';
  }
};

export const getDefaultValuesForDBConfig = (
  dsType: DatasourceType,
  id: number,
  uid: string,
  name: string
): DataSourceDTO => {
  return {
    id,
    uid,
    type: dsType,
    name: name.split('-')[0],
    url: '',
    user: '',
    database: '',
    password: '',
    version: getDefaultVersion(dsType, getDBConfigVersions(dsType)),
  };
};

export const getAuthMethodsOptions = (): SelectableValue[] => {
  return [
    {
      label: AuthMethodOptions.BasicAuth.label,
      value: AuthMethodOptions.BasicAuth.value,
      description: AuthMethodOptions.BasicAuth.description,
    },
    {
      label: AuthMethodOptions.OAuthForward.label,
      value: AuthMethodOptions.OAuthForward.value,
      description: AuthMethodOptions.OAuthForward.description,
    },
    {
      label: AuthMethodOptions.NoAuth.label,
      value: AuthMethodOptions.NoAuth.value,
      description: AuthMethodOptions.NoAuth.description,
    },
  ];
};

import { useStyles2 } from '@grafana/ui';
import { OnboardingImageCardData } from 'feature/onboarding/types/OnboardingCard';
import React, { useContext } from 'react';
import { getOnboardingCardStyles } from './OnboardingCard.styles';
import { OnboardingImage } from './OnboardingImage';
import { OnboardingCardIconsAndTitle } from 'feature/onboarding/components/OnboardingCard/OnboardingCardIconsAndTitle';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';

export function OnboardingImageCard({
  card,
  isInModal,
}: {
  card: OnboardingImageCardData;
  isInModal?: boolean;
}): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    trackAction(
      (card.eventName as FlowEventName) ?? FlowEventName.ONBOARDING_FLOW_EVENT_NAME,
      key,
      ActionType.click,
      orgId
    );
  }

  function onCardClick(): void {
    onComponentClick(card.key);
    if (card.link?.callback) {
      card.link?.callback();
    }

    if (!!card.link.url) {
      if (!!card.link.target) {
        if (
          card.key === 'demo-dashboards-link' ||
          card.key === 'sandbox-accounts-link' ||
          card.key === 'tutorials-link'
        ) {
          window.open(card.link.url, card.link.target);
          window.location.href = '/connections/add-new-connection';
        } else {
          window.open(card.link.url, card.link.target);
        }
      } else {
        window.location.href = card.link.url;
      }
    }
  }

  return (
    <div
      key={card.key}
      aria-label={card.title as string}
      onClick={onCardClick}
      onKeyDown={onCardClick}
      role="presentation"
    >
      <div className={styles.wrapper(card.image as unknown as string, isInModal)}>
        {!!card.image && <OnboardingImage image={card.image} />}
        <OnboardingCardIconsAndTitle card={card} isInModal />
      </div>
    </div>
  );
}

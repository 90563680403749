import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getDBConfigFooterStyles = (theme: GrafanaTheme2) => ({
  textWrapper: css`
    padding-bottom: ${theme.spacing(3)};
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: ${theme.colors.text.primary};
  `,
  note: css`
    margin-bottom: 0;
    width: 100%;
    display: block;
  `,
  advancedLink: css`
    color: ${theme.colors.text.link};
    text-decoration: underline;
  `,
});

import React, { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { Alert, Button, Icon, useStyles2 } from '@grafana/ui';

import { useCreateDatasourceMutation } from 'feature/common/api/DataSourcesApi';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { QueryError } from 'feature/common/types/QueryError';
import { getDatasourceConfigPageStyles } from 'feature/datasource-config/components/DatasourceConfigPage.styles';
import { getDatasourceConfigData } from 'feature/datasource-config/data/DatasourceConfig.data';
import { DatasourceResponse } from 'feature/common/types/Datasources';
import {
  DatasourceConfigVersion,
  Datasources,
  DatasourceVisualMode,
} from 'feature/datasource-config/types/DatasourceConfig';
import { DatasourceConfigForm } from 'feature/datasource-config/components/DatasourceConfigForm';
import { cx } from '@emotion/css';

interface DatasourcePageProps {
  datasource: Datasources;
  onSaved?: (dataSource: DatasourceResponse) => void;
  version?: DatasourceConfigVersion;
  visualMode?: DatasourceVisualMode;
  onBack?: () => void;
  textAlign?: 'left' | 'center';
}

export const DatasourceConfigPage: React.FC<DatasourcePageProps> = ({
  datasource,
  onSaved,
  version,
  visualMode = 'page',
  onBack,
  textAlign,
}) => {
  const styles = useStyles2((theme) => getDatasourceConfigPageStyles(theme, version, visualMode, textAlign));
  const [create, { data, isLoading, error }] = useCreateDatasourceMutation();
  const configData = getDatasourceConfigData(datasource, version);
  const [datasourceName, setDatasourceName] = useState<string>('');
  const [uid, setUid] = useState('');
  const [id, setId] = useState(0);

  const onDataSourceSaved = useCallback(
    (dataSource: DatasourceResponse) => {
      if (onSaved) {
        onSaved(dataSource);
      } else {
        window.location.href = `/dashboard/new-with-ds/${dataSource.datasource.uid} `;
      }
    },
    [onSaved]
  );

  useEffectOnce(() => {
    create(datasource);
  });

  useEffect(() => {
    if (!!data) {
      setDatasourceName(data.name);
      setId(data.id);
      setUid(data.datasource.uid);
    }
  }, [data]);

  return (
    <div className={styles.contentWrapper}>
      {visualMode === 'modal' && !!configData.buttons.back && !!onBack && (
        <Button className={styles.backButton} variant={'secondary'} fill={'text'} onClick={onBack}>
          <Icon name={'angle-left'} />
          {configData.buttons.back.text}
        </Button>
      )}
      <div className={styles.headerWrapper}>
        <h3>{configData.header}</h3>
        {configData.preamble && <p className={styles.preamble}>{configData.preamble}</p>}
      </div>

      <div className={styles.cardWrapper}>
        <div className={cx({ [styles.customBody]: visualMode === 'modal' }, 'page-body')} style={{ marginBottom: 0 }}>
          {isLoading && <LoadingIndicator />}
          {!!error && (
            <Alert title="" severity="error">
              <p>{(error as QueryError)?.data?.message || 'There was an error while creating the datasource.'}</p>
            </Alert>
          )}
          {Boolean(id) && Boolean(uid) && (
            <DatasourceConfigForm
              name={datasourceName}
              uid={uid}
              id={id}
              data={configData}
              onSaved={onDataSourceSaved}
              version={version}
            />
          )}
        </div>
      </div>
    </div>
  );
};

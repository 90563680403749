import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'feature/common/consts/styles';

export const getStyles = (theme: GrafanaTheme2) => ({
  links: css`
    display: inline-block;
    list-style: none;
    @media (max-width: ${breakpoints.menuRows}px) {
      display: flex;
      flex-direction: column;
    }

    li {
      display: inline-block;
      @media (max-width: ${breakpoints.menuRows}px) {
        display: flex;
      }
    }
  `,
  linksWrapper: css`
    display: flex;
    flex-direction: row;
    @media (max-width: ${breakpoints.menuColumn}px) {
      display: flex;
      flex-direction: column;
    }
  `,
  link: css`
    color: ${theme.isDark ? theme.visualization.getColorByName('white') : theme.visualization.getColorByName('gray15')};
    font-weight: 400;
    line-height: 28px;
    height: 28px;
  `,
  disabledLink: css`
    opacity: 0.5;
    text-decoration: none;
  `,
  linkText: css`
    margin-left: 7px;

    &:hover {
      text-decoration: underline;
    }
  `,
  menuResponsiveWrapper: css`
    display: flex;
    flex-direction: row;
  `,
});

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    titleWrapper: css`
      align-items: center;
      display: flex;
      flex-grow: 1;
      width: 100%;
      margin-bottom: 12px;

      h3 {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${theme.isDark ? theme.v1.palette.white : theme.v1.palette.gray15};
        margin-bottom: unset;
        font-weight: 500;
        line-height: 32px;

        @media (max-width: 1150px) {
          font-size: ${theme.typography.size.md};
          line-height: 19px;
        }
      }
    `,
    wrapper: css`
      align-items: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${theme.components.panel.background};
      border: 1px solid ${theme.components.panel.borderColor};
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: ${theme.shadows.z1};
      color: ${theme.colors.text.primary};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: ${theme.spacing(4)} 0;
      padding: ${theme.spacing(4)};
      width: 100%;
      min-height: 130px;
      @media (max-width: 860px) {
        flex-direction: column;
        min-height: 242px;
      }
    `,
    metrics: css`
      display: flex;
      flex-direction: row;
      flex-grow: 0.5;
      justify-content: space-around;
      @media (max-width: 1070px) {
        padding-left: ${theme.spacing(2)};
        flex-wrap: wrap;
      }
      @media (max-width: 860px) {
        padding-left: 0;
        width: 100%;
        margin: ${theme.spacing(4)} 0;
      }
    `,
    cloudPlan: css`
      display: flex;
      flex-direction: column;
      @media (max-width: 860px) {
        align-self: flex-start;
      }
    `,
    limitsText: css`
      color: ${theme.colors.text.primary};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    `,
    upgradeWrapper: css`
      display: flex;
      flex-direction: row;
    `,
  };
};

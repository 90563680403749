import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getUsageSectionStyles = (theme: GrafanaTheme2) => {
  return {
    headerTitle: css`
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      color: ${theme.colors.text.maxContrast};
    `,
    card: css`
      width: 100%;
      background-color: ${theme.colors.background.primary};
      border-radius: 4px;
      padding: 1rem;
      margin-top: 1rem;
      color: ${theme.colors.text.primary};
    `,
    tabContentWrapper: css`
      border-radius: 4px;
    `,
    description: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
      display: flex;
      flex-direction: row;
    `,
    link: css`
      color: ${theme.colors.primary.text};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    section: css`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: baseline;
    `,
    insights: css`
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 1.5rem;
    `,
    text: css`
      font-size: 22px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: ${theme.colors.text.maxContrast};
    `,
    greyCircle: css`
      background-color: ${theme.colors.border.medium};
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: 10px;
    `,
    greenCircle: css`
      background-color: #e02f44;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: 10px;
    `,
    redCircle: css`
      background-color: ${theme.colors.success.text};
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: 10px;
    `,
    gridMetrics: css`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: ${theme.spacing(2)};

      @media (1200px <= width < 1400px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (992px <= width < 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (768px <= width < 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (width < 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    `,
    includedUsageLabel: css`
      color: ${theme.colors.text.secondary};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
    `,
    dashboardButton: css`
      margin-top: ${theme.spacing(2)};
    `,
  };
};

import { config } from '@grafana/runtime';

import { appPaths, externalPaths } from 'feature/common/consts/appPaths';
import {
  LogsOnboardingData,
  DataConnectionData,
  LogsKeys,
  GrafanaCloudKeys,
  ExistingSourceKeys,
  platformKey,
  instructionsKey,
} from 'feature/onboarding/types/LogsOnboardingTypes';
import { customIcon, logsOnboardingIcons } from 'img';

export const kafkaLogo = config.theme2.isDark ? customIcon['kafka'] : customIcon['kafka-black'];
export const logsOnboardingData: LogsOnboardingData = {
  //Routing Screen
  header: '1. How do you want to work with logs in Grafana Cloud?',
  preamble: 'How do you want to work with logs in Grafana Cloud?',
  cards: [
    {
      key: LogsKeys.grafanaCloud,
      title: 'Collect, store, and manage logs in Grafana Cloud',
      text: "Logs are gathered in Grafana Cloud's backend",
      blocks: [
        { title: 'Log sources', width: '22%', frame: true, image: logsOnboardingIcons['cloud-cluster'], key: '0' },
        {
          title: 'Pull your data',
          width: '14%',
          frame: false,
          image: logsOnboardingIcons['arrow-right'],
          key: '1',
        },
        {
          title: 'Store in Grafana Cloud',
          width: '20%',
          frame: true,
          image: logsOnboardingIcons['grafana-cloud'],
          key: '2',
        },
        {
          title: 'Query from hosted Loki',
          width: '13%',
          frame: false,
          image: logsOnboardingIcons['arrow-right'],
          key: '3',
        },
        {
          title: 'Visualize on dashboards',
          width: '25%',
          frame: true,
          image: logsOnboardingIcons['stacked-dashboard'],
          key: '4',
        },
      ],
    },
    {
      key: LogsKeys.existingSource,
      title: 'Connect your existing logging stack',
      text: 'Select this if you have an existing set up for log collection and storage',
      blocks: [
        {
          title: 'Log sources',
          width: '22%x',
          frame: true,
          image: logsOnboardingIcons['source-cluster'],
          key: '0',
        },
        {
          title: 'Make a query and send data',
          width: '21%',
          frame: false,
          topImage: customIcon['grafana-orange'],
          image: logsOnboardingIcons['arrow-right'],
          key: '1',
        },
        {
          title: 'Visualize on dashboards',
          width: '37%',
          frame: true,
          image: logsOnboardingIcons['stacked-dashboard'],
          key: '2',
        },
      ],
    },
  ],
  buttons: {
    skip: {
      text: 'Logs demo dashboard',
      link: externalPaths.demoDashboards,
      key: 'demo-dashboard',
    },
    back: {
      text: 'Back',
      link: externalPaths.integrationsAndConnections,
      key: 'back-logs',
    },
  },
  columns: 2,
};

const logsDataConnectionData: DataConnectionData = {
  [LogsKeys.grafanaCloud]: {
    viewAll: [], // this gets added in for loop below
    cloudProvidersAndPlatforms: [
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.GCP}`,
        key: GrafanaCloudKeys.grafanaCloudGcp,
        title: 'GCP Logs',
        icon: customIcon['gcp-logs'],
      },
      {
        redirectUrl: `/a/${platformKey.FIREHOSE}`,
        key: GrafanaCloudKeys.grafanaCloudFireHose,
        title: 'AWS Firehose',
        icon: customIcon['aws-firehose'],
      },
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.AZURE}`,
        key: GrafanaCloudKeys.grafanaCloudAzure,
        title: 'Azure Event Hubs',
        icon: customIcon['azure-event-hubs'],
      },
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.GRAFANA_CLOUD_HEROKU}`,
        key: GrafanaCloudKeys.grafanaCloudHeroku,
        title: 'Heroku Drain',
        icon: customIcon['heroku'],
      },
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.GRAFANA_CLOUD_CLOUDFLARE}`,
        key: GrafanaCloudKeys.grafanaCloudCloudflare,
        title: 'Cloudflare',
        icon: customIcon['cloudflare'],
      },
    ],
    container: [
      {
        redirectUrl: `/a/${platformKey.KUBERNETES}/home`,
        key: GrafanaCloudKeys.grafanaCloudK8,
        title: 'Kubernetes',
        icon: customIcon['kubernetes'],
      },
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.DOCKER}`,
        key: GrafanaCloudKeys.grafanaCloudDocker,
        title: 'Docker',
        icon: customIcon['docker'],
      },
    ],
    operatingSystems: [
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.LINUX}`,
        key: GrafanaCloudKeys.grafanaCloudLinux,
        title: 'Linux',
        icon: customIcon['linux-color'],
      },

      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.WINDOWS}`,
        key: GrafanaCloudKeys.grafanaCloudWindows,
        title: 'Windows',
        icon: customIcon['windows-color'],
      },
    ],
    logsForwarding: [
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.GRAFANA_CLOUD_KAFKA}`,
        key: GrafanaCloudKeys.grafanaCloudKafka,
        title: 'Kafka',
        icon: kafkaLogo,
      },
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.LOGS_HTTP}`,
        key: GrafanaCloudKeys.grafanaCloudHttpLogs,
        title: 'Logs HTTP',
        icon: customIcon['http-metrics'],
      },
    ],
  },
  [LogsKeys.existingSource]: {
    viewAll: [], // this gets added in for loop below
    logSources: [
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.LOKI}`,
        key: ExistingSourceKeys.existingSourceLoki,
        title: 'Loki',
        icon: customIcon['loki-color'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.ELASTIC}`,
        key: ExistingSourceKeys.existingSourceElastic,
        title: 'ElasticSearch',
        icon: customIcon['elasticsearch'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.CLOUDWATCH}`,
        key: ExistingSourceKeys.existingSourceCloudwatch,
        title: 'CloudWatch',
        icon: customIcon['cloudwatch'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.ORACLE}`,
        key: ExistingSourceKeys.existingSourceOracle,
        title: 'Oracle Cloud',
        icon: customIcon['oracle-cloud'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.GCP_DATASOURCE}`,
        key: ExistingSourceKeys.existingSourceGcl,
        title: 'Google Cloud Logging',
        icon: customIcon['gcp-logs'],
      },
    ],
    logsForwarding: [
      {
        redirectUrl: `/a/grafana-collector-app/${platformKey.ALLOY}/installation`,
        key: ExistingSourceKeys.existingSourceAlloy,
        title: 'Alloy',
        icon: customIcon['alloy'],
      },
      {
        redirectUrl: `${externalPaths.integrationsAndConnections}/${platformKey.OTEL}`,
        key: ExistingSourceKeys.existingSourceOtel,
        title: 'OpenTelemetry Collector',
        icon: customIcon['open-telemetry'],
      },
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.DATA_SOURCE_FLUENT_D}`,
        key: ExistingSourceKeys.existingSourcefluentd,
        title: 'FluentD',
        icon: customIcon['fluent'],
      },
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.DATA_SOURCE_FLUENT_BIT}`,
        key: ExistingSourceKeys.existingSourcefluentbit,
        title: 'FluentBit',
        icon: customIcon['fluent'],
      },
      {
        redirectUrl: `${appPaths.logsOnboarding}/${instructionsKey.DATA_SOURCE_PROMTAIL}`,
        key: ExistingSourceKeys.existingSourcepromtail,
        title: 'Promtail',
        icon: customIcon['loki'],
      },
    ],
    infrastructureMonitoring: [
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.SUMO_LOGIC}`,
        key: ExistingSourceKeys.existingSourcesumologic,
        title: 'Sumo Logic',
        icon: customIcon['sumo-logic'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.SPLUNK}`,
        key: ExistingSourceKeys.existingSourceSplunk,
        title: 'Splunk',
        icon: customIcon['splunk'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.DATA_DOG}`,
        key: ExistingSourceKeys.existingSourceDatadog,
        title: 'Datadog',
        icon: customIcon['datadog'],
      },
      {
        redirectUrl: `${externalPaths.connectionDatasources}/${platformKey.NEW_RELIC}`,
        key: ExistingSourceKeys.existingSourceNewrelic,
        title: 'New Relic',
        icon: customIcon['new-relic'],
      },
    ],
  },
};

// concat all options in each category into `viewAll` for grafana cloud
for (const key in logsDataConnectionData[LogsKeys.grafanaCloud]) {
  logsDataConnectionData[LogsKeys.grafanaCloud].viewAll = logsDataConnectionData[LogsKeys.grafanaCloud].viewAll.concat(
    logsDataConnectionData[LogsKeys.grafanaCloud][key]
  );
}

// concat all options in each category into `viewAll` for existing source
for (const key in logsDataConnectionData[LogsKeys.existingSource]) {
  logsDataConnectionData[LogsKeys.existingSource].viewAll = logsDataConnectionData[
    LogsKeys.existingSource
  ].viewAll.concat(logsDataConnectionData[LogsKeys.existingSource][key]);
}

export { logsDataConnectionData };

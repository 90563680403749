export enum HostedData {
  Prometheus = 'Prometheus metrics',
  Loki = 'logs',
  Graphite = 'Graphite metrics',
  Tempo = 'traces',
  HTTPMetrics = 'HTTP Metrics',
  GenericOpenTelemetry = 'OpenTelemetry (OTLP)',
  JavaOpenTelemetry = 'Java OpenTelemetry',
}

export type HostedTypes = {
  [val: string]: string;
};

export const hostedTypes: HostedTypes = {
  hl: 'hosted-logs',
  hm: 'hosted-metrics',
  ht: 'hosted-traces',
};

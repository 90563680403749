export enum FlowEventName {
  CREATE_DASHBOARD_FLOW_EVENT_NAME = 'create_dashboard_flow_event',
  SETUP_WIZARD_FLOW_EVENT_NAME = 'setup_wizard_flow_event',
  DATASOURCE_CONFIG_FLOW_EVENT_NAME = 'datasource_config_flow_event',
  GETTING_STARTED_EVENT_NAME = 'getting_started_event',
  ONBOARDING_FLOW_EVENT_NAME = 'onboarding_flow_event',
  CREATE_DASHBOARD_WIZARD_VISIBILITY_EVENT_NAME = 'create_dashboard_wizard_visibility_event',
  HOME_PAGE_EVENT = 'home_page_event',
  IMPORT_DASHBOARD_EVENT_NAME = 'import-dashboard-event',
  DOCS_PLUGIN_EVENT = 'docs_plugin_event',
  SIMPLIFIED_ONBOARDING_FLOW_EVENT_NAME = 'simplified_onboarding_flow_event',
  LOGS_ONBOARDING_EVENT_NAME = 'logs_onboarding_flow_event',
}

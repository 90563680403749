import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { AlertsPanel } from 'feature/alerts/AlertsPanel/AlertsPanel';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { HelpPanel } from 'feature/help/components/HelpPanel';
import { HomeHeader } from 'feature/home/components/HomeHeader';
import { IntegrationsPanel } from 'feature/integrations/components/IntegrationsPanel';
import { MetricsPanel } from 'feature/usage/components/MetricsPanel';
import { StarredDashboardsPanel } from '../../starred-dashboards/components/StarredDashboardsPanel';
import { getStyles } from './HomePageContent.styles';

export const HomePageContent = () => {
  const styles = useStyles2(getStyles);
  const { isAdmin } = useCurrentUser();

  return (
    <>
      <HomeHeader />
      <MetricsPanel />
      <div className={styles.panelsContainer}>
        <div className={styles.panelItem}>
          <AlertsPanel />
        </div>
        <div className={styles.panelItem}>
          <StarredDashboardsPanel />
        </div>
        <div className={styles.panelItem}>
          <HelpPanel />
        </div>
      </div>
      {isAdmin && <IntegrationsPanel />}
    </>
  );
};

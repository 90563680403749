import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getOnboardingImageStyles = (theme: GrafanaTheme2) => ({
  image: css`
    width: 100%;
    margin-bottom: 16px;
    max-height: 150px;
  `,
});

import { isNotificationBannerVisible } from 'feature/notification-banners/utils/notificationBanners';
import { useGetHostedDataDetailsQuery, useGetOrgDataQuery } from 'feature/common/api/GrafanaComApi';
import { useGetUsersQuery } from 'feature/common/api/UsersApi';

export const useNotificationBannerVisible = (): { isLoading: boolean; isVisible: boolean } => {
  const { data: hostedDataDetails, isLoading: isHostedDataLoading } = useGetHostedDataDetailsQuery();
  const { data: cloudOrgData, isLoading: isOrgLoading } = useGetOrgDataQuery(hostedDataDetails?.orgSlug ?? '', {
    skip: !hostedDataDetails,
  });
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  const isLoading = isHostedDataLoading || isOrgLoading || isUsersLoading;

  if (!isLoading && !!cloudOrgData && !!users) {
    return { isLoading, isVisible: isNotificationBannerVisible(cloudOrgData, users) };
  }
  return { isLoading, isVisible: false };
};

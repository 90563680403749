import { useEffect } from 'react';
import { DatasourceResponse } from 'feature/common/types/Datasources';

type UpdateDatasourceConfigProps = {
  isSuccess: boolean;
  updatedDatasource: DatasourceResponse | undefined;
  onSaved?: (dataSource: DatasourceResponse) => void;
};

export function useUpdateDatasourceConfig({ isSuccess, updatedDatasource, onSaved }: UpdateDatasourceConfigProps) {
  useEffect(() => {
    if (isSuccess && !!updatedDatasource && onSaved) {
      onSaved(updatedDatasource);
    }
  }, [isSuccess, onSaved, updatedDatasource]);
}

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getSelectConnectionListStyles = (theme: GrafanaTheme2) => ({
  container: css`
    width: 100%;
  `,
  selectContainer: css`
    border: 1px solid ${theme.colors.border.strong};
    height: 46px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 16px;
    position: relative;
    background-color: ${theme.components.input.background};
  `,
  listContainer: css`
    border: 1px solid ${theme.colors.border.strong};
    margin-top: ${theme.spacing(2)};
    background-color: ${theme.colors.background.primary};
  `,
  angleIcon: css`
    position: absolute;
    right: 8px;
  `,
  message: css`
    color: red;
  `,
  itemListContainer: css`
    max-height: 360px;
    overflow-y: auto;
  `,
  itemList: css`
    margin-left: ${theme.spacing(3)};
    margin-right: ${theme.spacing(2)};
  `,
  logoIcon: css`
    width: 32px;
    height: 32px;
    margin-right: ${theme.spacing(1)};
  `,
});

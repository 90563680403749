import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cx } from '@emotion/css';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { selectors } from '@grafana/e2e-selectors';
import { DataSourcePicker } from '@grafana/runtime';
import { dateTimeFormat, DataSourceInstanceSettings, locationUtil } from '@grafana/data';
import { Alert, Button, Field, HorizontalGroup, Icon, Input, Legend, Tooltip, useStyles2 } from '@grafana/ui';

import { lsExists, lsRemove } from 'feature/common/utils/misc';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';
import { ImportDashboardDTO, DashboardInput, Folder } from 'feature/onboarding/types/ImportDashboard';
import { getImportDashboardStepData } from 'feature/onboarding/data/ImportDashboard.data';

import { FolderPicker } from 'feature/onboarding/components/ImportDashboard/FolderPicker';
import {
  getDefaultValuesForImportDashboard,
  importDashboard,
  getUpdatedFormValues,
  validateDashboardName,
  validateUid,
} from 'feature/onboarding/components/ImportDashboard/importDashboardUtils';
import { getImportDahsboardFormStyles } from './ImportDashboardForm.styles';
import { useDashboards } from 'feature/common/hooks/useDashboards';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { AppContext } from 'App';

export const ImportDashboardForm = ({
  dashboardId,
  selectedDataSource,
}: {
  dashboardId?: string;
  selectedDataSource?: any;
}): JSX.Element => {
  const [isSubmitted, setSubmitted] = useState(false);
  const styles = useStyles2(getImportDahsboardFormStyles);
  const { data: dashboardData, inputs, isLoading, isError, error } = useDashboards(dashboardId);
  const { orgId } = useContext(AppContext);
  const [datasource, setDatasource] = useState<DataSourceInstanceSettings>({} as DataSourceInstanceSettings);
  const defaultValues = useMemo(
    () => getDefaultValuesForImportDashboard({ dashboardToImport: dashboardData, dataSourceSaved: datasource }),
    [dashboardData, datasource]
  );
  const [folder, setFolder] = useState<Folder>(defaultValues.folder);
  const stepData = getImportDashboardStepData('configure-dashboard');
  const formMethods = useForm<ImportDashboardDTO>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = formMethods;

  const onSubmit = useCallback(
    async (formValues: ImportDashboardDTO) => {
      const updatedFormValues = getUpdatedFormValues(formValues, defaultValues, {
        dashboardToImport: dashboardData,
        dataSourceSaved: datasource,
      });
      setSubmitted(true);

      try {
        const result: any = await importDashboard(
          updatedFormValues,
          {
            dashboardToImport: dashboardData,
            dataSourceSaved: datasource,
          },
          inputs
        );

        if (result !== undefined && result.importedUrl) {
          const dashboardUrl = locationUtil.stripBaseFromUrl(result.importedUrl);
          window.location.href = `${dashboardUrl}?var-datasource=${datasource.name}`;
        }

        if (lsExists('import-dashboard-saved-data')) {
          lsRemove('import-dashboard-saved-data');
        }

        trackAction(
          FlowEventName.IMPORT_DASHBOARD_EVENT_NAME,
          ComponentKey.ImportDashboardFlowSuccess,
          ActionType.click,
          orgId
        );
      } catch (error) {
        console.error('error', error);
      }
    },
    [defaultValues, dashboardData, datasource, inputs, orgId]
  );

  useEffect(() => {
    if (
      !!dashboardData &&
      !!datasource &&
      Object.keys(dashboardData).length > 0 &&
      Object.keys(datasource).length > 0
    ) {
      trigger(); // Trigger react hook form validation on load
      return;
    }
  }, [dashboardData, datasource, trigger]);

  useEffect(() => {
    if (!!selectedDataSource && Object.keys(selectedDataSource).length > 0) {
      setDatasource(selectedDataSource);
    }
  }, [selectedDataSource]);

  useEffect(() => {
    if (!!datasource && Object.keys(datasource).length > 0) {
      setValue('dataSources', [datasource]);
    }
  }, [setValue, datasource]);

  /*
    This useEffect is needed for overwriting a dashboard. It
    submits the form even if there's validation errors on title or uid.
  */
  useEffect(() => {
    if (onSubmit && isSubmitted && (errors.title || errors.uid)) {
      onSubmit(getValues());
    }
  }, [errors, getValues, isSubmitted, onSubmit]);

  return (
    <div className={styles.container}>
      <>
        <h3 data-testid="import-dash-configure-dashboard">2. CONFIGURE DASHBOARD</h3>
        <div className={styles.headerWrapper}>
          <div className={styles.textWrapper}>
            <h3 className={styles.header}>{stepData.header}</h3>
            {stepData.preamble && <p className={styles.preamble}>{stepData.preamble}</p>}
          </div>
        </div>
        <div className={styles.contentContainer}>
          {isLoading && <LoadingIndicator />}
          {isError && (
            <Alert severity="error" title="" className={styles.alert}>
              {error?.data.message ?? 'Error while loading the dashboard data'}
            </Alert>
          )}
          {!isLoading && !isError && !!dashboardData && Object.keys(dashboardData).length > 0 && (
            <>
              <div style={{ marginBottom: '24px' }}>
                <h4 style={{ marginBottom: '24px' }}>
                  Importing dashboard from{' '}
                  <a
                    href={`https://grafana.com/dashboards/${dashboardData.id}`}
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Grafana.com
                  </a>
                </h4>

                <table className={cx('filter-table form-inline', styles.table)}>
                  <tbody>
                    <tr>
                      <td>Published by</td>
                      <td>{dashboardData.orgName}</td>
                    </tr>
                    <tr>
                      <td>Updated on</td>
                      <td>{dashboardData.updatedAt && dateTimeFormat(dashboardData.updatedAt)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Legend>Options</Legend>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)} id="importForm">
                  <Field
                    label="Name"
                    description="Add a name to your new dashboard"
                    invalid={!!errors.title}
                    error={errors.title && errors.title.message}
                    required
                  >
                    <Input
                      defaultValue={dashboardData.name}
                      type="text"
                      data-testid={selectors.components.ImportDashboardForm.name}
                      {...register('title', {
                        validate: async (v: string) =>
                          await validateDashboardName(v, getValues().folder.uid || 'General'),
                      })}
                    />
                  </Field>
                  <Field
                    label="Folder"
                    description="Select the folder to save this dashboard"
                    invalid={!!errors?.folder}
                    error={!!errors?.folder}
                    required
                  >
                    <Controller
                      render={({ field: { ref, ...field } }) => (
                        <>
                          <FolderPicker
                            {...field}
                            enableCreateNew
                            onChange={async (val) => {
                              setFolder(val);
                              if (errors.title) {
                                clearErrors('title');
                                await validateDashboardName(getValues().title, val.uid || val.title || '');
                              }
                              return folder && setValue('folder', val);
                            }}
                          />
                        </>
                      )}
                      name="folder"
                      control={control}
                    />
                  </Field>

                  <Field
                    label={
                      <>
                        <div className={styles.tooltip}>
                          Unique identifier (UID)
                          <Tooltip
                            content="The unique identifier (UID) of a dashboard can be used for uniquely identify a dashboard between multiple Grafana installs. The UID allows having consistent URLs for accessing dashboards so changing the title of a dashboard will not break any bookmarked links to that dashboard."
                            theme="info"
                            placement="top"
                          >
                            <Icon name="info-circle" />
                          </Tooltip>
                        </div>
                        <div className={styles.uidSubheadText}>
                          The UID allows having consistent URLs for accessing dashboards
                        </div>
                      </>
                    }
                    invalid={!!errors.uid}
                    error={errors.uid && errors.uid.message}
                  >
                    <>
                      <div>
                        <Input
                          defaultValue={defaultValues.uid}
                          {...register('uid', {
                            validate: async (v?: string) => v && (await validateUid(v)),
                          })}
                          type="text"
                          placeholder="e.g., 000001"
                          data-testid={'import-dashboard-uid'}
                        />
                      </div>
                      <div className={styles.uidSubheadText}>
                        <div>* Only numbers and characters such as &#45; allowed</div>
                        <div>
                          * If no UID is provided, Grafana will generate a uid hash after importing the dashboard
                        </div>
                      </div>
                    </>
                  </Field>
                  {datasource &&
                    [datasource].map((input: DataSourceInstanceSettings) => {
                      return (
                        <Field
                          label={'Data source'}
                          key={input.uid}
                          description={`Select your ${datasource.type} data source`}
                          required
                        >
                          <Controller
                            name={`dataSources`}
                            render={({ field: { onChange, ref, ...field } }) => (
                              <DataSourcePicker
                                {...field}
                                placeholder={datasource.name}
                                pluginId={input.type}
                                current={input ?? selectedDataSource}
                                onChange={setDatasource}
                              />
                            )}
                            defaultValue={selectedDataSource}
                            control={control}
                            rules={{ required: true }}
                          />
                        </Field>
                      );
                    })}

                  {inputs?.constants &&
                    inputs?.constants.map((input: DashboardInput, index) => {
                      const constantIndex = `constants[${index}]`;
                      return (
                        <Field
                          label={input.label}
                          error={errors.constants && errors.constants[index] && `${input.label} needs a value`}
                          invalid={errors.constants && !!errors.constants[index]}
                          key={constantIndex}
                        >
                          <Input {...register(constantIndex as any, { required: true })} defaultValue={input.value} />
                        </Field>
                      );
                    })}
                  <HorizontalGroup>
                    <Button
                      type="submit"
                      disabled={!!(isSubmitting || Object.keys(errors).length)}
                      data-testid={selectors.components.ImportDashboardForm.submit}
                      variant="primary"
                      form="importForm"
                    >
                      Import
                    </Button>
                  </HorizontalGroup>
                </form>
              </FormProvider>
            </>
          )}
        </div>
      </>
    </div>
  );
};

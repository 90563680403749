import React, { useContext, useLayoutEffect, useState } from 'react';

import { PluginPage } from '@grafana/runtime';
import { GrafanaTheme2, NavModelItem, PageLayoutType } from '@grafana/data';
import { appPaths } from 'feature/common/consts/appPaths';
import { DatasourceConfigPage } from 'feature/datasource-config/components/DatasourceConfigPage';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { DatasourceResponse } from 'feature/common/types/Datasources';
import { getDatasourceConfigData } from 'feature/datasource-config/data/DatasourceConfig.data';
import { Button, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { goToNewDashboard } from 'feature/onboarding/utils/selectAndCreateDashboardUtils';
import { AppContext } from 'App';
import { CREATE_DASHBOARD_HEADER, START_HEADER, TELL_US_WHAT_TO_CONNECT } from 'feature/onboarding/consts/consts';
import { closeNavIfOpened } from 'feature/onboarding/utils/sectionNavUtils';

const getStyles = (theme: GrafanaTheme2) => ({
  footer: css`
    height: 64px;
  `,
  buttonRow: css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(2)};
  `,
  separator: css`
    padding-top: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.colors.border.weak};
    margin-bottom: ${theme.spacing(2)};
  `,
});

export const GettingStartedConfigDatasourcePage: React.FC<{ dataSource: Datasources }> = ({ dataSource }) => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);
  const [dataSourceSaved, setDataSourceSaved] = useState<DatasourceResponse>();
  const data = getDatasourceConfigData(dataSource, 'getting-started');

  const pageNav: NavModelItem = {
    text: `Visualize ${data.name} data`,
    parentItem: {
      text: CREATE_DASHBOARD_HEADER,
      subTitle: TELL_US_WHAT_TO_CONNECT,
      url: `${appPaths.gettingStarted}/select-and-create-dashboard?connection=${data.connectionId}&option=datasource-${dataSource}`,
      parentItem: {
        text: START_HEADER,
        url: appPaths.gettingStarted,
      },
    },
  };

  useLayoutEffect(() => closeNavIfOpened());

  function onCreateDashboard() {
    if (!!dataSourceSaved) {
      goToNewDashboard(orgId, dataSourceSaved);
    }
  }

  return (
    <PluginPage layout={PageLayoutType?.Standard} pageNav={pageNav}>
      <DatasourceConfigPage
        datasource={dataSource}
        onSaved={setDataSourceSaved}
        version={'getting-started'}
        textAlign={'left'}
      />
      <div className={styles.footer}>
        <div className={styles.separator} />
        <div className={styles.buttonRow}>
          <Button disabled={!dataSourceSaved} onClick={onCreateDashboard}>
            Create Dashboard
          </Button>
        </div>
      </div>
    </PluginPage>
  );
};

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getDashboardSectionStyles = (theme: GrafanaTheme2) => {
  return {
    headerTitle: css`
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      color: ${theme.colors.text.maxContrast};
    `,
    headerWrapper: css`
      display: flex;
      justify-content: space-between;
    `,
    tabsContent: css`
      margin-top: ${theme.spacing(2)};
      height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: transparent;

      @media (max-width: 992px) {
        height: unset;
      }
    `,
    dashboardPanel: css`
      background-color: ${theme.colors.background.primary};
      height: 100%;
    `,
    dashTabContent: css`
      background-color: ${theme.colors.background.canvas};
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 1rem;
    `,
    card: css`
      background-color: ${theme.colors.background.primary};
      padding: 1rem;
      width: 33.3%;
      border-radius: 4px;
      margin-left: 1rem;

      :first-child {
        margin-left: 0;
      }
    `,
    badge: css`
      border-color: ${theme.colors.text.secondary} !important;
      background-color: transparent !important;
      color: ${theme.colors.text.secondary} !important;
    `,
    dashboardTitle: css`
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.0025em;
      color: ${theme.colors.text.maxContrast};
      padding: 1rem 0 1rem 0;

      :hover {
        text-decoration: underline;
      }
    `,
    emptyTabContent: css`
      text-align: center;
      vertical-align: center;
      padding: 1rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.0015em;
      color: ${theme.colors.text.secondary};
      border-radius: 4px;
    `,
    starIcon: css`
      display: inline-flex;
      vertical-align: text-top;
    `,
  };
};

import { IconName } from '@grafana/ui';
import { OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';

// the arrow-right icon is too small so we need to compensate using scale
// as setting xxxl on the icon doesn't match desired styling
const CUSTOM_ICON_SCALE: { [key: string]: number } = {
  'arrow-right': 1.5,
};

export function getIconTransform(icon: IconName | OnboardingCustomIconName): string {
  return `scale(${CUSTOM_ICON_SCALE[icon] || 1})`;
}

import React, { useContext } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';
import { DataTestId } from '../../common/types/test-id.enum';
import { AccountManagement } from './AccountManagement';
import { getStyles } from './HomeHeader.style';
import { PlanBadge } from './PlanBadge';
import { appPaths, externalPaths } from 'feature/common/consts/appPaths';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { isUserAdmin } from 'feature/common/utils/UserUtils';
import { showGetStartedButton } from 'feature/home/utils/headerUtils';
import { useOrgTrial } from 'feature/common/hooks/useOrgTrial';

export const HomeHeader = () => {
  const styles = useStyles2(getStyles);
  const { orgId } = useContext(AppContext);
  const isAdmin = isUserAdmin();
  const { data: isTrialPlan, isLoading } = useOrgTrial();

  const enabledGetStarted: boolean = showGetStartedButton(isTrialPlan);

  const onComponentClick = () => {
    let componentKey = ComponentKey.ConnectDataButtonFromHome;
    if (enabledGetStarted) {
      componentKey = ComponentKey.Home.GetStartedButtonFromHome;
    }
    trackAction(FlowEventName.HOME_PAGE_EVENT, componentKey, ActionType.click, orgId);
  };

  return (
    <div data-testid={DataTestId.HEADER_CONTAINER_ID} className={styles.headerWrapper}>
      <div data-testid={DataTestId.HEADER_WELCOME_CONTAINER_ID} className={styles.welcome}>
        <div id="headerTitleId" data-testid={DataTestId.HEADER_TITLE_ID} className={styles.header}>
          <span className={styles.welcomeText}>Welcome to</span>
          <span className={styles.cloudText}>Grafana Cloud</span>
          <div data-testid={DataTestId.PLAN_ROW_ID} className={styles.planRow}>
            <PlanBadge />
          </div>
        </div>
        {isAdmin && <AccountManagement />}
      </div>
      {isAdmin && !isLoading && (
        <div
          data-testid={
            enabledGetStarted ? DataTestId.HEADER_GET_STARTED_CONTAINER : DataTestId.HEADER_CONNECT_DATA_CONTAINER
          }
          className={styles.addDataWrapper}
        >
          <LinkButton
            data-testid={enabledGetStarted ? DataTestId.HEADER_GET_STARTED_LINK : DataTestId.HEADER_CONNECT_DATA_LINK}
            className={styles.addDataLink}
            size={'lg'}
            variant="primary"
            icon={enabledGetStarted ? 'rocket' : 'plus'}
            href={enabledGetStarted ? appPaths.gettingStarted : externalPaths.integrationsAndConnections}
            onClick={onComponentClick}
          >
            {enabledGetStarted ? 'Get started' : 'Connect data'}
          </LinkButton>
        </div>
      )}
    </div>
  );
};

import { DataTestId } from 'feature/common/types/test-id.enum';
import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { getAccountStyles } from 'feature/home/components/refresh-design/Account.styles';
import { LoadingIndicator } from 'feature/common/components/LoadingIndicator';

export const OrgSection = () => {
  const styles = useStyles2(getAccountStyles);
  const { orgRole, isLoading, isSuccess } = useCurrentUser();

  return (
    <div className={styles.card} data-testid={DataTestId.ACCOUNT_ORG_ROLE_SECTION}>
      <div className={styles.primaryText}>Access level</div>
      <div className={styles.orgUsersWrapper}>
        {isLoading && <LoadingIndicator />}
        {isSuccess && !!orgRole && (
          <div data-testid={DataTestId.ACCOUNT_ORG_ROLE_VALUE} className={styles.standOutText}>
            {orgRole}
          </div>
        )}
      </div>
    </div>
  );
};

import { appPaths } from 'feature/common/consts/appPaths';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import {
  CloudServiceCardData,
  CloudServicesStep,
  CloudServicesStepData,
} from 'feature/onboarding/types/CloudServicesCard';
import { getControlOnboardingStepData } from 'feature/onboarding/data/ControlOnboarding.data';
import { OnboardingGeneralCardData, OnboardingStepData } from 'feature/onboarding/types/OnboardingCard';
import { cloneDeep } from 'lodash';
import { rollbackNavExpanded } from 'feature/onboarding/utils/sectionNavUtils';

export const cloudServicesSteps = [
  'cloud-services',
  'custom-aws-options',
  'custom-aws-data',
  'custom-gcp-options',
  'custom-gcp-monitor',
  'custom-gcp-data',
  'custom-azure-options',
  'custom-azure-monitor',
  'custom-azure-data',
];

export const getCloudServicesStepData = (step: CloudServicesStep): CloudServicesStepData => {
  const data: OnboardingStepData = getControlOnboardingStepData(step);
  const startData: OnboardingStepData = step !== 'start' ? getControlOnboardingStepData('start') : cloneDeep(data);

  const result: CloudServicesStepData = {
    step,
    cards: data.cards.map((card) => {
      const generalCard = card as OnboardingGeneralCardData;

      const newCard: CloudServiceCardData = {
        key: card.key,
        icons: card.icons,
        title: card.title,
        text: generalCard.text,
        link: { key: generalCard.link.key },
        style: card.style,
        eventName: FlowEventName.GETTING_STARTED_EVENT_NAME,
      };

      if (generalCard.link.url.startsWith(appPaths.onboarding)) {
        newCard.link.goToOption = generalCard.link.url.replace(`${appPaths.onboarding}/`, '');
      } else {
        newCard.link.url = generalCard.link.url.replace('onboarding-flow', 'getting-started');
        if (newCard.link.url.indexOf('getting-started') < 0) {
          newCard.link.callback = () => rollbackNavExpanded();
        }
      }
      return newCard;
    }),
    breadcrumbs: {
      text: data.header,
      subTitle: data.preamble,
      parentItem: { text: startData.header, url: appPaths.gettingStarted },
    },
  };
  if (step !== 'cloud-services') {
    result.buttons = {
      back: {
        text: 'Go back',
        goToOption: data.buttons.back.link.replace(`${appPaths.onboarding}/`, ''),
        key: data.buttons.back.key,
        icon: 'arrow-left',
      },
    };
  }

  return result;
};

import { IconTypes, OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

export const isOnboardingCustomIconName = (icon: IconTypes): icon is OnboardingCustomIconName =>
  Object.values(OnboardingCustomIconName).includes(icon);

export const getEventNameFromPath = (
  gettingStartedEvent = FlowEventName.GETTING_STARTED_EVENT_NAME,
  onboardingEvent = FlowEventName.ONBOARDING_FLOW_EVENT_NAME
): FlowEventName => {
  return window.location.pathname.indexOf('/a/cloud-home-app/getting-started') >= 0
    ? gettingStartedEvent
    : onboardingEvent;
};

export const getTrackOriginFromPath = (): string => {
  const path = window.location.pathname;
  if (path.indexOf('/select-and-create-dashboard') >= 0) {
    return 'create-dashboard';
  }
  if (path.indexOf('/prometheus') >= 0) {
    return 'prometheus';
  }
  return '';
};

import React from 'react';
import { css, cx } from '@emotion/css';
import { useStyles2, Icon } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

const getStyles = (theme: GrafanaTheme2) => ({
  error: css`
    color: ${theme.colors.error.text};
  `,
  alert: css`
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    font-size: ${theme.typography.size.sm};
    align-items: center;
  `,
  success: css`
    color: ${theme.colors.success.text};
  `,
  icon: css`
    margin-right: 6px;
  `,
  alertContent: css`
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  `,
  base: css`
    font-size: ${theme.typography.size.base};
  `,
});

type Props = {
  status: 'error' | 'success';
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'sm';
  ariaLabel?: string;
};

export function AlertLine(props: Props) {
  const styles = useStyles2(getStyles);
  const isSizeBase = props.size === 'base';

  const icon =
    props.status === 'error' ? (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="exclamation-triangle" className={styles.icon} />
    ) : (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="check" className={styles.icon} />
    );

  return (
    <>
      <p
        className={cx(
          styles.alert,
          props.className,
          props.status === 'error' ? styles.error : styles.success,
          isSizeBase && styles.base
        )}
      >
        {icon}
        <p role="alert" aria-label={props.ariaLabel || `${props.status} message`} className={styles.alertContent}>
          {props.children}
        </p>
      </p>
    </>
  );
}

import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { Button, useStyles2 } from '@grafana/ui';

import { AccessPolicyTokenStep } from 'feature/onboarding/components/LogsOnboarding/InstallationStep/AccessPolicyTokenStep';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { getSnippetStyles } from './snippet.styles';

export function PromtailBody({ hostedData }: { hostedData?: HostedDataDetails }): JSX.Element {
  const styles = useStyles2(getSnippetStyles);
  const [token, setToken] = useState('"YOUR_GRAFANA_CLOUD_ACCESS_POLICY_TOKEN"');

  const snippet =
    '\nclients:' +
    '\n  - url: https://' +
    `${hostedData?.hlInstanceId}:` +
    token +
    `@${hostedData?.hlInstanceUrl?.substring(7)}`;

  const snippetStr = '```bash' + snippet + '\n```';
  return (
    <div className={styles.container}>
      <h3>Step 2: Prepare your configuration file</h3>
      <span>
        To configure Promtail to forward logs to Grafana Cloud Loki, you need to update the `client.url` in your
        configuration file.
        <a href="https://grafana.com/docs/loki/latest/send-data/promtail/configuration/#example-static-config">Here </a>
        is an example of the full configuration file. Here is an example snippet on what it should look like:
      </span>
      <br />
      <Markdown>
        {'```bash\nclients:\n - url: https://123456:glc_xxxxx@$logs-endpoint.grafana.net/loki/api/v1/push \n```'}
      </Markdown>
      <br />
      <AccessPolicyTokenStep token={token} setToken={setToken} />
      <br />
      <h5>Here&apos;s your template:</h5>
      <Markdown>{snippetStr}</Markdown>
      <Button className={styles.button} icon="copy" onClick={() => navigator.clipboard.writeText(snippet)}>
        Copy to clipboard
      </Button>
    </div>
  );
}

import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, ReactReduxContextValue } from 'react-redux';

export const appContext = React.createContext({} as ReactReduxContextValue<any, any>);

export const appCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    useDispatch: createDispatchHook(appContext),
    useSelector: createSelectorHook(appContext),
    useStore: createStoreHook(appContext),
  })
);

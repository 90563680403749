import React, { useContext } from 'react';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { PanelRenderer } from '@grafana/runtime';
import { DataTestId } from '../../common/types/test-id.enum';
import { getPanelStyles } from 'feature/Panel.styles';
import { EMPTY_PANEL_DATA } from '../../common/consts/panels';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

export const StarredDashboardsPanel = () => {
  const styles = useStyles2(getPanelStyles);
  const { orgId } = useContext(AppContext);

  return (
    <div data-testid={DataTestId.STARRED_DASHBOARDS_PANEL} className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Dashboards</div>
        <LinkButton
          variant="primary"
          fill="text"
          href={'/dashboards'}
          onClick={() =>
            trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.DashboardsAllLink, ActionType.click, orgId)
          }
        >
          {'All dashboards →'}
        </LinkButton>
      </div>
      <div className={styles.contentWrapper}>
        <PanelRenderer
          title=""
          width={1000}
          height={1000}
          pluginId="dashlist"
          data={EMPTY_PANEL_DATA}
          options={{
            maxItems: 30,
            showHeadings: true,
            showRecentlyViewed: true,
            showSearch: false,
            showStarred: true,
          }}
        />
      </div>
    </div>
  );
};

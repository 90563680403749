import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  icons: css`
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: -5px;
    position: relative;
  `,
  ct: css`
    color: ${theme.colors.text.secondary};
    /*padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)};*/
    ul {
      padding: 0px 0px 0px 20px;
    }
    b {
      color: ${theme.colors.text.primary};
    }
  `,
  "mb0": css`
    margin-bottom: 0;
  `,
});

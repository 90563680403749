import React, { useContext } from 'react';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { quickstartData } from './data/QuickstartModal.data';
import { Card, Modal, useStyles2 } from '@grafana/ui';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { CustomOnboardingIcon } from 'feature/onboarding/components/OnboardingCard/CustomOnboardingIcon';
import { OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';
import { trackAction, ActionType } from 'feature/common/utils/tracking';
import { AppContext } from 'App';

export const QuickstartModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();
  const { orgId } = useContext(AppContext);
  function onComponentClick(key: string): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, key, ActionType.click, orgId);
  }

  return (
    <Modal
      title=""
      isOpen={isOpen}
      closeOnBackdropClick={true}
      onClickBackdrop={onClose}
      onDismiss={onClose}
      className={styles.modal}
    >
      <div className={styles.hedSubtitle}>
        <div className={styles.header}>QUICKSTART OPTIONS</div>
        <div className={styles.subtitle}>
          Try out a simple integration with your laptop OS or monitor any URL in just a couple of steps
        </div>
      </div>
      <div className={styles.cards}>
        {quickstartData.map((item) => {
          return (
            <Card
              key={item.key}
              className={styles.card}
              onClick={() => {
                onComponentClick(item.link.key);
                navigate(item.link.url);
              }}
            >
              <div className={styles.iconTitle}>
                <CustomOnboardingIcon icon={item.icon as unknown as OnboardingCustomIconName} />
                <div key={item.key}>{item.title}</div>
              </div>
              <div className={styles.description}>{item.text}</div>
            </Card>
          );
        })}
      </div>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modal: css`
      width: 1000px;
      padding-left: 35px;
      img {
        width: 26px;
        height: 26px;
      }
    `,
    hedSubtitle: css`
      display: flex;
      flex-direction: column;
    `,
    header: css`
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
    `,
    subtitle: css`
      font-size: 14px;
      margin-bottom: 35px;
    `,
    cards: css`
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
    `,
    card: css`
      display: flex;
      width: 278px;
      height: 156px;
      flex-direction: column;
      padding: ${theme.spacing(1)} ${theme.spacing(4)};
      border: 1px solid ${theme.colors.border.medium};
      font-size: 16px;
    `,
    iconTitle: css`
      display: flex;
      flex-flow: row wrap;
      margin-top: 5px;
      font-weight: 400;
      gap: ${theme.spacing(1)};
      align-items: center;
      img {
        margin-top: 16px;
      }
    `,
    description: css`
      align-self: flex-start;
      font-size: 13px;
    `,
  };
};

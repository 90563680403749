import { DatasourcePlugin, GrafanaDatasource } from 'feature/common/types/Datasources';
import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';
import { isConnectionsSectionPresent } from 'feature/common/utils/misc';
import { topPrioritySourceIds } from 'feature/common/consts/topPriorityConnections';
import { externalPaths } from 'feature/common/consts/appPaths';

export const getDatasourcePluginUrl = (datasourceId: string): string => {
  if (datasourceId) {
    return isConnectionsSectionPresent()
      ? `${externalPaths.connectionDatasources}/${datasourceId}/`
      : `plugins/${datasourceId}/`;
  }
  return '';
};

export const formatDatasourceList = (datasourceList: DatasourcePlugin[]): DataToConnect[] => {
  return datasourceList.map((datasource: DatasourcePlugin) => {
    return {
      id: `datasource-${datasource.id}`,
      name: datasource.name.trim(),
      overview: datasource.info.description?.trim(),
      logo_url: datasource.info.logos.small,
      type: DataToConnectType.DataSource,
      externalUrl: getDatasourcePluginUrl(datasource.id),
      mostPopular: topPrioritySourceIds.includes(`datasource-${datasource.id}`),
      trackId: `${datasource.id.endsWith('-datasource') ? datasource.id : `${datasource.id}-datasource`}-connection`,
    };
  });
};

export const formatGrafanaDatasourceList = (datasourceList: GrafanaDatasource[]): DataToConnect[] => {
  return datasourceList.map((datasource: GrafanaDatasource) => {
    return {
      id: `datasource-${datasource.downloadSlug}`,
      name: datasource.name.trim(),
      overview: datasource.description.trim(),
      logo_url: `https://grafana.com/api/plugins/${datasource.downloadSlug}/logos/small`,
      type: DataToConnectType.DataSource,
      plan: datasource.status,
      externalUrl: getDatasourcePluginUrl(datasource.downloadSlug),
      mostPopular: topPrioritySourceIds.includes(`datasource-${datasource.downloadSlug}`),
      trackId: `${
        datasource.downloadSlug.endsWith('-datasource')
          ? datasource.downloadSlug
          : `${datasource.downloadSlug}-datasource`
      }-connection`,
    };
  });
};

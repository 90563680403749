import React from 'react';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { useStyles2 } from '@grafana/ui';
import { HomeCustomLink } from 'feature/home/components/refresh-design/HomeCustomLink';
import { getAccountStyles } from 'feature/home/components/refresh-design/Account.styles';
import { appPaths } from 'feature/common/consts/appPaths';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { isUserAdmin, hasAnyAccess, Action } from 'feature/common/utils/UserUtils';
import { getGrafanaDomain } from 'feature/home/components/refresh-design/helpers';
import { ROLE } from 'feature/common/types/User';
import { supportLink } from 'feature/common/utils/linkUtils';

export const AccountDocsCard = () => {
  const styles = useStyles2(getAccountStyles);
  const isAdmin = isUserAdmin();
  const domain = getGrafanaDomain();

  const hasSupportTicketsAccess = hasAnyAccess(ROLE.ADMIN, Action.SupportTicketsRead, Action.SupportTicketsWrite);

  return (
    <div className={`${styles.card}`} data-testid={DataTestId.ACCOUNT_DOCS_SECTION}>
      <div className={styles.docsRows}>
        <HomeCustomLink
          id={'docs'}
          href={'https://grafana.com/docs/grafana-cloud/'}
          text={'Docs'}
          trackId={ComponentKey.Accounts.AccountsCustomLink('docs')}
        />
        <HomeCustomLink
          id={'support'}
          href={hasSupportTicketsAccess ? supportLink() : 'https://community.grafana.com/'}
          text={'Support'}
          trackId={ComponentKey.Accounts.AccountsCustomLink('docs-support')}
        />
        <HomeCustomLink
          id={'what-is-new'}
          href={'https://grafana.com/docs/grafana-cloud/whatsnew/'}
          text={"What's new"}
          trackId={ComponentKey.Accounts.AccountsCustomLink('docs-what-is-new')}
        />
        <HomeCustomLink
          id={'tutorials'}
          href={appPaths.docsPlugin}
          text={'Tutorials'}
          trackId={ComponentKey.Accounts.AccountsCustomLink('docs-tutorials')}
        />
        {isAdmin && (
          <HomeCustomLink
            id={'stacks'}
            href={`${domain}/profile/org?src=hg_home`}
            text={'Stacks'}
            trackId={ComponentKey.Accounts.AccountsCustomLink('stacks')}
          />
        )}
        {isAdmin && (
          <HomeCustomLink
            id={'access-policies'}
            href={`${domain}/profile/org/access-policies`}
            text={'Access policies'}
            trackId={ComponentKey.Accounts.AccountsCustomLink('access-policies')}
          />
        )}
      </div>
    </div>
  );
};

import React from 'react';
import { Icon, useStyles2 } from '@grafana/ui';
import { getDashboardEmptyStyles } from 'feature/home/components/refresh-design/DashboardSection/DashboardEmpty.styles';

export const DashboardStarredEmpty = () => {
  const styles = useStyles2(getDashboardEmptyStyles);

  return (
    <div className={styles.container}>
      <Icon name="graph-bar" />
      <div className={styles.description}>
        <span>Your starred dashboards will appear here</span>
        <span>
          You can star your favorite dashboards by clicking the <Icon name="star" className={styles.icon} /> from the
          dashboard page
        </span>
      </div>
    </div>
  );
};

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getDashboardEmptyStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: ${theme.colors.text.secondary};
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background.primary};
  `,
  icon: css`
    display: inline-flex;
    vertical-align: text-top;
  `,
  description: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: ${theme.spacing(2)};
    span {
      text-align: center;
    }
  `,
});

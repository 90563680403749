import { config } from '@grafana/runtime';

enum FARO_ENV {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

function getFaroEnv() {
  const appUrl = new URL(config.appUrl).hostname;
  switch (true) {
    case appUrl.endsWith('grafana-ops.net') || appUrl.endsWith('grafana-ops.com'):
      return FARO_ENV.STAGING;

    case appUrl.endsWith('grafana.net') || appUrl.endsWith('grafana.com'):
      return FARO_ENV.PROD;

    case appUrl.endsWith('grafana-dev.net') || appUrl.endsWith('grafana-dev.com'):
    case appUrl.endsWith('localhost'):
    default:
      return FARO_ENV.DEV;
  }
}

// The three apps were created at https://appo11y.grafana.net/a/grafana-kowalski-app/apps
// and the collector URLs were generated there.
export function getFaroConfig() {
  const environment = getFaroEnv();
  switch (environment) {
    case FARO_ENV.DEV:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/892ef9c04ecbfc1c77eb340a4fc77a64',
        name: 'cloud-home-app-dev',
        environment,
      };
    case FARO_ENV.STAGING:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/2a11fceb08fcbba65a8345abd0ffbfb8',
        name: 'cloud-home-app-ops',
        environment,
      };
    case FARO_ENV.PROD:
    default:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/91627726a505f418db95d8b4f31433b7',
        name: 'cloud-home-app-prod',
        environment,
      };
  }
}

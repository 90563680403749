import { FlowEventName } from 'feature/onboarding/types/FlowEventName';

export enum ActionType {
  click = 'click',
  visit = 'visit',
  load = 'load',
}

export const trackAction = (
  eventName: FlowEventName,
  componentKey: string,
  action: ActionType,
  orgId: number | undefined,
  params?: object
) => {
  if (validTrackData(eventName, componentKey, action, orgId)) {
    // console.log(`event: ${eventName} component_key: ${componentKey} action: ${action} org_id: ${orgId} origin: ${(params as any)?.origin}`);
    let trackParams = {
      component_key: componentKey,
      action,
      org_id: orgId,
    };
    if (params) {
      trackParams = { ...trackParams, ...params };
    }
    (window as any).rudderanalytics?.track(eventName, trackParams);
  }
};

const validTrackData = (
  eventName: FlowEventName,
  componentKey: string,
  action: ActionType,
  orgId: number | undefined
): boolean => {
  const missing = [];
  if (!eventName) {
    missing.push('eventName');
  }
  if (!componentKey) {
    missing.push('component_key');
  }
  if (!action) {
    missing.push('action');
  }
  if (!orgId) {
    missing.push('org_id');
  }

  if (!!missing.length) {
    const message = `Attention! ${missing.join(',')} ${
      missing.length > 1 ? 'are' : 'is'
    } not defined. The action will not be tracked`;
    console.error(message);
    return false;
  }
  return true;
};

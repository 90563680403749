import { FlowEventName } from '../types/FlowEventName';
import { TestGroup } from '../types/TestGroup';

export const getTestGroupBasedOnFlowEvent = (eventFlow: FlowEventName) => {
  switch (eventFlow) {
    case FlowEventName.ONBOARDING_FLOW_EVENT_NAME:
      return TestGroup.CONTROL;
    default:
      return TestGroup.CONTROL;
  }
};

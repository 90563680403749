import { useStyles2 } from '@grafana/ui';
import { OnboardingCardData } from 'feature/onboarding/types/OnboardingCard';
import React, { useContext } from 'react';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { OnboardingCardIconsAndTitle } from 'feature/onboarding/components/OnboardingCard/OnboardingCardIconsAndTitle';
import { handleKeyDown } from 'feature/common/utils/aria';
import { AppContext } from 'App';
import { CloudServiceCardData } from 'feature/onboarding/types/CloudServicesCard';
import { getOnboardingCardStyles } from 'feature/onboarding/components/OnboardingCard/OnboardingCard.styles';

function CloudServiceCardBody({ card }: { card: CloudServiceCardData }): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));

  return (
    <div className={styles.wrapper(undefined)}>
      <OnboardingCardIconsAndTitle card={card as OnboardingCardData} />
      {card.text && typeof card.text === 'string' && (
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: card.text }} />
      )}
      {card.text && typeof card.text !== 'string' && <p className={styles.text}>{card.text}</p>}
    </div>
  );
}

export function CloudServiceCard({
  card,
  onCardSelected,
}: {
  card: CloudServiceCardData;
  onCardSelected: () => void;
}): JSX.Element {
  const styles = useStyles2((theme) => getOnboardingCardStyles(theme, card.style));
  const { orgId } = useContext(AppContext);

  function onComponentClick(key: string): void {
    trackAction(
      (card.eventName as FlowEventName) ?? FlowEventName.GETTING_STARTED_EVENT_NAME,
      key,
      ActionType.click,
      orgId
    );
  }

  function onCardClick(): void {
    onComponentClick(card.key);
    if (!!onCardSelected) {
      onCardSelected();
    }
    if (!!card.link.callback) {
      card.link.callback();
    }
    if (!!card.link.url) {
      window.location.href = card.link.url;
    }
  }

  return (
    <div
      className={card.selected ? styles.cardSelected : ''}
      onClick={onCardClick}
      onKeyDown={handleKeyDown(onCardClick)}
      tabIndex={0}
      role="button"
    >
      <CloudServiceCardBody card={card} />
    </div>
  );
}

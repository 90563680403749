import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { DatasourceConfigVersion, DatasourceVisualMode } from 'feature/datasource-config/types/DatasourceConfig';

export const getDatasourceConfigPageStyles = (
  theme: GrafanaTheme2,
  version?: DatasourceConfigVersion,
  visualMode?: DatasourceVisualMode,
  textAlign = 'center'
) => ({
  headerWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: ${textAlign};
    align-items: ${textAlign};

    h3 {
      margin-bottom: 0;
    }
  `,
  textWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: ${textAlign};
  `,
  header: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  `,
  preamble: css`
    font-size: ${theme.typography.body.fontSize};
    color: ${theme.colors.text.primary};
  `,
  cardWrapper: css`
    display: grid;
    position: relative;
    width: 100%;
    margin-top: ${theme.spacing(3)};
    gap: ${theme.spacing(2)};
    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
  `,
  contentWrapper: css`
    padding: 0;
    width: 100%;
  `,
  customBody: css`
    border: 0 !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
  `,
  backButton: css`
    padding: 0 0 ${theme.spacing(1.5)} 0;

    &:hover {
      background: transparent;
    }
  `,
});

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { OnboardingVersion } from 'feature/onboarding/types/OnboardingVersion';

export const getSelectAndCreateDashboardStyles = (theme: GrafanaTheme2, type: OnboardingVersion) => ({
  container: css`
    position: fixed;
    left: 0;
    top: 0;
    background: ${theme.colors.background.canvas};
    z-index: 1025;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  `,
  wrapper: css`
    max-width: 950px;
    width: 100%;
    text-align: center;

    ${type === 'onboarding' ? 'display: flex;flex-direction: column;margin: auto;' : ''}

    h3 {
      line-height: 2.5rem;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${type !== 'get-started' ? '0 auto' : undefined};
  `,
  selectContent: css`
    margin-top: ${theme.spacing(3)};
  `,
  topLinksWrapper: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 50px 0;
  `,
  topLink: css`
    color: ${theme.visualization.getColorByName('white')};

    :hover {
      opacity: 0.65;
    }
  `,
  headerWrapper: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 60px;
  `,
  cardWrapper: (columns?: number) => css`
    display: grid;
    grid-template-columns: repeat(${columns && columns > 1 ? columns : 1}, 1fr);
    position: relative;
    margin-top: ${columns === 1 ? theme.spacing(6) : theme.spacing(3)};
    gap: ${theme.spacing(2)};
    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 640px) and (max-width: 950px) {
      grid-template-columns: repeat(${(columns && columns > 2) || !columns ? 2 : columns}, 1fr);
    }
    @media (min-width: 950px) {
      grid-template-columns: repeat(${(columns && columns > 3) || !columns ? 3 : columns}, 1fr);
    }

    text-align: left;
  `,
  buttonsWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(4)};
  `,
  continueButton: css`
    width: 338px;
    height: 48px;
    display: flex;
    justify-content: center;
  `,
  dbConfigContainer: css`
    margin-top: ${theme.spacing()};
    display: ${type !== 'get-started' ? 'block' : 'flex'};
    justify-content: ${type !== 'get-started' ? 'center' : 'flex-start'};
    text-align: left;
    margin-top: ${theme.spacing(3)};
  `,
  cloudPlatformSubtitle: css`
    margin-top: 60px;
  `,
});

import React, { useContext, useEffect, useLayoutEffect } from 'react';

import { PluginPage } from '@grafana/runtime';
import { NavModelItem, PageLayoutType } from '@grafana/data';
import { appPaths } from 'feature/common/consts/appPaths';
import { START_HEADER } from 'feature/onboarding/consts/consts';
import { closeNavIfOpened } from 'feature/onboarding/utils/sectionNavUtils';
import { trackPage } from '@grafana/cloud-ab-testing';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { AppContext } from 'App';
import { PrometheusOnboardingControl } from '../PrometheusOnboarding/PrometheusOnboardingControl';

export const GettingStartedPrometheusPage: React.FC = () => {
  const { orgId } = useContext(AppContext);
  const pageNav: NavModelItem = {
    text: 'Prometheus',
    subTitle: 'Tell us how do you want to connect your Prometheus data to Grafana Cloud',
    parentItem: {
      text: START_HEADER,
      url: appPaths.gettingStarted,
    },
  };

  useEffect(() => {
    trackPage({
      adobeTarget: {
        viewName: `getting-started/prometheus`,
      },
    });

    return trackAction(FlowEventName.GETTING_STARTED_EVENT_NAME, `prometheus-screen`, ActionType.visit, orgId);
  }, [orgId]);

  useLayoutEffect(() => closeNavIfOpened());

  return (
    <PluginPage layout={PageLayoutType?.Standard} pageNav={pageNav}>
      <div style={{ marginTop: '24px' }}>
        <PrometheusOnboardingControl />
      </div>
    </PluginPage>
  );
};

import React, { useEffect } from 'react';
import { useStyles2 } from '@grafana/ui';

import { getHomepageBackgroundStyles } from 'feature/home/components/refresh-design/HomepageBackground.styles';
import { HomepageContent } from 'feature/home/components/refresh-design/HomepageContent';
import { getGreetingBasedOnTime } from 'feature/home/components/refresh-design/helpers';
import { DataTestId } from 'feature/common/types/test-id.enum';

export const HomepageBackground = () => {
  const styles = useStyles2(getHomepageBackgroundStyles);
  const greeting = getGreetingBasedOnTime();

  useEffect(() => {
    const canvasContent: HTMLDivElement | null = document.querySelector('div [class$="canvas-content"]');
    if (canvasContent) {
      canvasContent.style.padding = '0';
    }
  }, []);

  return (
    <div className={styles.backgroundImg}>
      <div className={styles.centeredLayout}>
        <div className={styles.headerTitle} data-testid={DataTestId.HEADER_CONTAINER_ID}>
          {greeting}
        </div>
        <HomepageContent />
      </div>
    </div>
  );
};

import React, { ChangeEvent, useEffect } from 'react';
import { Icon, Input, useStyles2 } from '@grafana/ui';
import { getSelectConnectionInputStyles } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectConnectionInput.styles';

interface SelectConnectionItemProps {
  disabled?: boolean;
  onSearchTerm: (term: string) => void;
  clearSearch: boolean;
}

export function SelectConnectionInput({
  disabled = false,
  onSearchTerm,
  clearSearch,
}: SelectConnectionItemProps): JSX.Element {
  const styles = useStyles2(getSelectConnectionInputStyles);

  useEffect(() => {
    if (clearSearch) {
      onSearchTerm('');
    }
  }, [clearSearch, onSearchTerm]);

  function onSearchInputChanged(e: ChangeEvent<HTMLInputElement>) {
    const searchTerm = e.currentTarget.value;
    onSearchTerm(searchTerm?.toLowerCase());
  }

  return (
    <div className={styles.searchContainer}>
      <Input
        className={styles.input}
        disabled={disabled}
        prefix={<Icon name={'search'} />}
        type={'search'}
        placeholder={'Search'}
        onChange={onSearchInputChanged}
        aria-label="Search connections by name"
      />
    </div>
  );
}

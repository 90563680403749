import { SubscriptionCodeType } from 'feature/common/types/Subscriptions';

export const ENTERPRISE_INSTANCE = {
  id: 660254,
  orgId: 276038,
  orgSlug: 'sedemo',
  orgName: 'SE Demo',
  type: 'grafana',
  name: 'devsedemo.grafana.net',
  url: 'https://devsedemo.grafana.net',
  slug: 'devsedemo',
  version: 'instant',
  description: '',
  status: 'active',
  gateway: 'istio',
  createdAt: '2023-06-05T09:14:33.000Z',
  createdBy: 'aengusrooneygrafana',
  updatedAt: '2023-09-18T14:40:23.000Z',
  updatedBy: 'hg-instance-status-sync',
  trial: 0,
  trialExpiresAt: null,
  clusterId: 81,
  clusterSlug: 'prod-us-east-0',
  clusterName: 'prod-us-east-0',
  plan: 'gcloud',
  planName: 'Grafana Cloud',
  billingStartDate: '2023-06-05T09:14:33.000Z',
  billingEndDate: null,
  billingActiveUsers: 0,
  billingGrafanaActiveUsers: 0,
  billingOnCallActiveUsers: 0,
  currentActiveUsers: 0,
  currentActiveAdminUsers: 0,
  currentActiveEditorUsers: 0,
  currentActiveViewerUsers: 0,
  dailyUserCnt: 0,
  dailyAdminCnt: 0,
  dailyEditorCnt: 0,
  dailyViewerCnt: 0,
  dashboardCnt: 21,
  datasourceCnts: {},
  userQuota: 10,
  dashboardQuota: -1,
  alertQuota: -1,
  alertCnt: 0,
  ssl: true,
  customAuth: true,
  customDomain: true,
  support: true,
  runningVersion:
    '10.4.0-65230 (commit: 0d1462cbbbe1e4712170d946818c2299bbb0e8b1, branch: HEAD, enterprise-commit: 844a09c99820f17617435007beca54fb2ffeb912)',
  machineLearning: 0,
  incident: 0,
  k6OrgId: 3311310,
  hmInstancePromId: 1021387,
  hmInstancePromUrl: 'https://prometheus-prod-13-prod-us-east-0.grafana.net',
  hmInstancePromName: 'devsedemo-prom',
  hmInstancePromStatus: 'active',
  hmInstancePromCurrentUsage: 2,
  hmInstancePromCurrentActiveSeries: 2,
  hmInstancePromClusterId: 117,
  hmInstanceGraphiteId: 1021388,
  hmInstanceGraphiteUrl: 'https://graphite-prod-13-prod-us-east-0.grafana.net',
  hmInstanceGraphiteName: 'devsedemo-graphite',
  hmInstanceGraphiteType: 'graphite-v5',
  hmInstanceGraphiteStatus: 'active',
  hmInstanceGraphiteCurrentUsage: 0,
  hlInstanceId: 613299,
  hlInstanceUrl: 'https://logs-prod-006.grafana.net',
  hlInstanceName: 'devsedemo-logs',
  hlInstanceStatus: 'active',
  hlInstanceCurrentUsage: 0,
  hlInstanceClusterId: 124,
  amInstanceId: 508643,
  amInstanceName: 'devsedemo-alerts',
  amInstanceUrl: 'https://alertmanager-prod-us-east-0.grafana.net',
  amInstanceStatus: 'active',
  amInstanceGeneratorUrl: 'https://devsedemo.grafana.net',
  amInstanceGeneratorUrlDatasource: '',
  htInstanceId: 609811,
  htInstanceUrl: 'https://tempo-prod-04-prod-us-east-0.grafana.net',
  htInstanceName: 'devsedemo-traces',
  htInstanceStatus: 'active',
  htInstanceClusterId: 128,
  hpInstanceId: 660254,
  hpInstanceUrl: 'https://profiles-prod-001.grafana.net',
  hpInstanceName: 'devsedemo-profiles',
  hpInstanceStatus: 'active',
  agentManagementInstanceId: 0,
  agentManagementInstanceUrl: '',
  agentManagementInstanceName: '',
  agentManagementInstanceStatus: 'unknown',
  regionId: 9,
  regionSlug: 'prod-us-east-0',
  regionPublicName: 'US East',
  provider: 'aws',
  providerRegion: 'us-east-2',
  links: [
    {
      rel: 'self',
      href: '/instances/devsedemo',
    },
    {
      rel: 'private-connectivity-info',
      href: '/instances/devsedemo/private-connectivity-info',
    },
    {
      rel: 'org',
      href: '/orgs/sedemo',
    },
    {
      rel: 'plugins',
      href: '/instances/devsedemo/plugins',
    },
  ],
};

export const PRO_INSTANCE = {
  id: 399906,
  orgId: 677328,
  orgSlug: 'yahimaperez',
  orgName: 'yahimaperez',
  type: 'grafana',
  name: 'yahimaperez.grafana.net',
  url: 'https://yahimaperez.grafana.net',
  slug: 'yahimaperez',
  version: 'stable',
  description: '',
  status: 'active',
  gateway: 'istio',
  createdAt: '2022-07-13T12:07:24.000Z',
  createdBy: 'yahimaperez',
  updatedAt: '2023-06-09T22:04:29.000Z',
  updatedBy: 'raintank',
  trial: 0,
  trialExpiresAt: null,
  clusterId: 66,
  clusterSlug: 'prod-eu-west-0',
  clusterName: 'prod-eu-west-0',
  plan: 'gcloud',
  planName: 'Grafana Cloud',
  billingStartDate: '2022-07-13T12:07:23.000Z',
  billingEndDate: null,
  billingActiveUsers: 0,
  billingGrafanaActiveUsers: 0,
  billingOnCallActiveUsers: 0,
  currentActiveUsers: 0,
  currentActiveAdminUsers: 0,
  currentActiveEditorUsers: 0,
  currentActiveViewerUsers: 0,
  dailyUserCnt: 0,
  dailyAdminCnt: 0,
  dailyEditorCnt: 0,
  dailyViewerCnt: 0,
  dashboardCnt: 22,
  datasourceCnts: {
    graphite: 1,
    influxdb: 1,
    mysql: 1,
  },
  userQuota: 10,
  dashboardQuota: -1,
  alertQuota: -1,
  alertCnt: 0,
  ssl: true,
  customAuth: true,
  customDomain: true,
  support: true,
  runningVersion: '10.0.3-cloud.4.aed62623 (commit: d9eea1cb64, branch: HEAD)',
  machineLearning: 0,
  incident: 0,
  k6OrgId: null,
  hmInstancePromId: 500486,
  hmInstancePromUrl: 'https://prometheus-prod-01-eu-west-0.grafana.net',
  hmInstancePromName: 'yahimaperez-prom',
  hmInstancePromStatus: 'active',
  hmInstancePromCurrentUsage: 40,
  hmInstancePromCurrentActiveSeries: 40,
  hmInstanceGraphiteId: 500487,
  hmInstanceGraphiteUrl: 'https://graphite-prod-01-eu-west-0.grafana.net',
  hmInstanceGraphiteName: 'yahimaperez-graphite',
  hmInstanceGraphiteType: 'graphite-v5',
  hmInstanceGraphiteStatus: 'active',
  hmInstanceGraphiteCurrentUsage: 0,
  hlInstanceId: 249213,
  hlInstanceUrl: 'https://logs-prod-eu-west-0.grafana.net',
  hlInstanceName: 'yahimaperez-logs',
  hlInstanceStatus: 'active',
  hlInstanceCurrentUsage: 0,
  amInstanceId: 248197,
  amInstanceName: 'yahimaperez-alerts',
  amInstanceUrl: 'https://alertmanager-eu-west-0.grafana.net',
  amInstanceStatus: 'active',
  amInstanceGeneratorUrl: 'https://yahimaperez.grafana.net',
  amInstanceGeneratorUrlDatasource: '',
  htInstanceId: 245725,
  htInstanceUrl: 'https://tempo-eu-west-0.grafana.net',
  htInstanceName: 'yahimaperez-traces',
  htInstanceStatus: 'active',
  hpInstanceId: 399906,
  hpInstanceUrl: 'https://profiles-prod-010.grafana.net',
  hpInstanceName: 'yahimaperez-profiles',
  hpInstanceStatus: 'active',
  regionId: 3,
  regionSlug: 'eu',
  links: [
    {
      rel: 'self',
      href: '/instances/yahimaperez',
    },
    {
      rel: 'org',
      href: '/orgs/yahimaperez',
    },
    {
      rel: 'plugins',
      href: '/instances/yahimaperez/plugins',
    },
  ],
};

export const FREE_INSTANCE = {
  id: 404365,
  orgId: 682339,
  orgSlug: 'layahi',
  orgName: 'layahi',
  type: 'grafana',
  name: 'layahi.grafana.net',
  url: 'https://layahi.grafana.net',
  slug: 'layahi',
  version: 'beta',
  description: '',
  status: 'active',
  gateway: 'istio',
  createdAt: '2022-07-21T11:54:26.000Z',
  createdBy: 'layahi',
  updatedAt: '2023-06-09T21:42:52.000Z',
  updatedBy: 'raintank',
  trial: 0,
  trialExpiresAt: '2022-08-04T11:54:24.000Z',
  clusterId: 66,
  clusterSlug: 'prod-eu-west-0',
  clusterName: 'prod-eu-west-0',
  plan: 'free',
  planName: 'Free',
  billingStartDate: '2022-08-04T11:54:24.000Z',
  billingEndDate: null,
  billingActiveUsers: 1,
  billingGrafanaActiveUsers: 1,
  billingOnCallActiveUsers: 0,
  currentActiveUsers: 1,
  currentActiveAdminUsers: 1,
  currentActiveEditorUsers: 0,
  currentActiveViewerUsers: 0,
  dailyUserCnt: 1,
  dailyAdminCnt: 1,
  dailyEditorCnt: 0,
  dailyViewerCnt: 0,
  dashboardCnt: 11,
  datasourceCnts: {
    postgres: 1,
  },
  userQuota: 3,
  dashboardQuota: 10,
  alertQuota: -1,
  alertCnt: 0,
  ssl: true,
  customAuth: false,
  customDomain: false,
  support: false,
  runningVersion: '10.0.3-cloud.4.aed62623 (commit: d9eea1cb64, branch: HEAD)',
  machineLearning: 0,
  incident: 0,
  k6OrgId: null,
  hmInstancePromId: 509460,
  hmInstancePromUrl: 'https://prometheus-prod-01-eu-west-0.grafana.net',
  hmInstancePromName: 'layahi-prom',
  hmInstancePromStatus: 'active',
  hmInstancePromCurrentUsage: 0,
  hmInstancePromCurrentActiveSeries: 0,
  hmInstanceGraphiteId: 509461,
  hmInstanceGraphiteUrl: 'https://graphite-prod-01-eu-west-0.grafana.net',
  hmInstanceGraphiteName: 'layahi-graphite',
  hmInstanceGraphiteType: 'graphite-v5',
  hmInstanceGraphiteStatus: 'active',
  hmInstanceGraphiteCurrentUsage: 0,
  hlInstanceId: 253700,
  hlInstanceUrl: 'https://logs-prod-eu-west-0.grafana.net',
  hlInstanceName: 'layahi-logs',
  hlInstanceStatus: 'active',
  hlInstanceCurrentUsage: 0,
  amInstanceId: 252684,
  amInstanceName: 'layahi-alerts',
  amInstanceUrl: 'https://alertmanager-eu-west-0.grafana.net',
  amInstanceStatus: 'active',
  amInstanceGeneratorUrl: 'https://layahi.grafana.net',
  amInstanceGeneratorUrlDatasource: '',
  htInstanceId: 250214,
  htInstanceUrl: 'https://tempo-eu-west-0.grafana.net',
  htInstanceName: 'layahi-traces',
  htInstanceStatus: 'active',
  hpInstanceId: 404365,
  hpInstanceUrl: 'https://profiles-prod-010.grafana.net',
  hpInstanceName: 'layahi-profiles',
  hpInstanceStatus: 'active',
  regionId: 3,
  regionSlug: 'eu',
  links: [
    {
      rel: 'self',
      href: '/instances/layahi',
    },
    {
      rel: 'org',
      href: '/orgs/layahi',
    },
    {
      rel: 'plugins',
      href: '/instances/layahi/plugins',
    },
  ],
};

const result = new Date();
result.setDate(result.getDate() + 10);

export const TRIAL_INSTANCE = {
  id: 827568,
  orgId: 1025948,
  orgSlug: 'growthtrialhome',
  orgName: 'growthtrialhome',
  type: 'grafana',
  name: 'growthtrialhome.grafana.net',
  url: 'https://growthtrialhome.grafana.net',
  slug: 'growthtrialhome',
  version: 'steady',
  description: '',
  status: 'active',
  gateway: 'istio',
  createdAt: '2024-01-09T09:38:39.000Z',
  createdBy: 'yahimaperez29',
  updatedAt: '2024-01-11T14:50:18.000Z',
  updatedBy: 'hg-instance-status-sync',
  trial: 1,
  trialExpiresAt: '2024-01-23T09:38:30.000Z',
  clusterId: 81,
  clusterSlug: 'prod-us-east-0',
  clusterName: 'prod-us-east-0',
  plan: 'free-trial',
  planName: 'Free Trial',
  billingStartDate: '2024-01-09T09:38:39.000Z',
  billingEndDate: null,
  billingActiveUsers: 2,
  billingGrafanaActiveUsers: 2,
  billingOnCallActiveUsers: 0,
  currentActiveUsers: 2,
  currentActiveAdminUsers: 1,
  currentActiveEditorUsers: 0,
  currentActiveViewerUsers: 1,
  dailyUserCnt: 1,
  dailyAdminCnt: 0,
  dailyEditorCnt: 0,
  dailyViewerCnt: 1,
  dashboardCnt: 10,
  datasourceCnts: {},
  userQuota: 3,
  dashboardQuota: -1,
  alertQuota: -1,
  alertCnt: 0,
  ssl: true,
  customAuth: true,
  customDomain: true,
  support: true,
  runningVersion:
    '10.4.0-65039 (commit: da894994d42ee2bde00f2366fe6d30ad00b0e907, branch: HEAD, enterprise-commit: 650bb190e01e93c323952920a45064d56e493dc6)',
  machineLearning: 0,
  incident: 0,
  k6OrgId: null,
  hmInstancePromId: 1364733,
  hmInstancePromUrl: 'https://prometheus-prod-13-prod-us-east-0.grafana.net',
  hmInstancePromName: 'growthtrialhome-prom',
  hmInstancePromStatus: 'active',
  hmInstancePromCurrentUsage: 0,
  hmInstancePromCurrentActiveSeries: 0,
  hmInstancePromClusterId: 117,
  hmInstanceGraphiteId: 1364734,
  hmInstanceGraphiteUrl: 'https://graphite-prod-13-prod-us-east-0.grafana.net',
  hmInstanceGraphiteName: 'growthtrialhome-graphite',
  hmInstanceGraphiteType: 'graphite-v5',
  hmInstanceGraphiteStatus: 'active',
  hmInstanceGraphiteCurrentUsage: 0,
  hlInstanceId: 781391,
  hlInstanceUrl: 'https://logs-prod-006.grafana.net',
  hlInstanceName: 'growthtrialhome-logs',
  hlInstanceStatus: 'active',
  hlInstanceCurrentUsage: 0,
  hlInstanceClusterId: 124,
  amInstanceId: 680313,
  amInstanceName: 'growthtrialhome-alerts',
  amInstanceUrl: 'https://alertmanager-prod-us-east-0.grafana.net',
  amInstanceStatus: 'active',
  amInstanceGeneratorUrl: 'https://growthtrialhome.grafana.net',
  amInstanceGeneratorUrlDatasource: '',
  htInstanceId: 779667,
  htInstanceUrl: 'https://tempo-prod-04-prod-us-east-0.grafana.net',
  htInstanceName: 'growthtrialhome-traces',
  htInstanceStatus: 'active',
  htInstanceClusterId: 128,
  hpInstanceId: 827568,
  hpInstanceUrl: 'https://profiles-prod-001.grafana.net',
  hpInstanceName: 'growthtrialhome-profiles',
  hpInstanceStatus: 'active',
  agentManagementInstanceId: 0,
  agentManagementInstanceUrl: '',
  agentManagementInstanceName: '',
  agentManagementInstanceStatus: 'unknown',
  regionId: 9,
  regionSlug: 'prod-us-east-0',
  regionPublicName: 'US East',
  provider: 'aws',
  providerRegion: 'us-east-2',
  links: [
    {
      rel: 'self',
      href: '/instances/growthtrialhome',
    },
    {
      rel: 'private-connectivity-info',
      href: '/instances/growthtrialhome/private-connectivity-info',
    },
    {
      rel: 'org',
      href: '/orgs/growthtrialhome',
    },
    {
      rel: 'plugins',
      href: '/instances/growthtrialhome/plugins',
    },
  ],
};

export const ADVANCED_INSTANCE = {
  id: 288726,
  orgId: 576536,
  orgSlug: 'cloudadminteam',
  orgName: 'Cloud Admin Team',
  type: 'grafana',
  name: 'cloudadminteam.grafana.net',
  url: 'https://cloudadminteam.grafana.net',
  slug: 'cloudadminteam',
  version: 'stable',
  description: '',
  status: 'active',
  gateway: 'istio',
  createdAt: '2021-12-13T12:53:39.000Z',
  createdBy: 'raintank',
  updatedAt: '2023-06-07T23:48:28.000Z',
  updatedBy: 'raintank',
  trial: 0,
  trialExpiresAt: null,
  clusterId: 69,
  clusterSlug: 'prod-us-central-0',
  clusterName: 'prod-us-central-0',
  plan: 'advanced',
  planName: 'Grafana Cloud Advanced',
  billingStartDate: '2021-12-13T12:53:38.000Z',
  billingEndDate: null,
  billingActiveUsers: 0,
  billingGrafanaActiveUsers: 0,
  billingOnCallActiveUsers: 0,
  currentActiveUsers: 0,
  currentActiveAdminUsers: 0,
  currentActiveEditorUsers: 0,
  currentActiveViewerUsers: 0,
  dailyUserCnt: 0,
  dailyAdminCnt: 0,
  dailyEditorCnt: 0,
  dailyViewerCnt: 0,
  dashboardCnt: 54,
  datasourceCnts: {
    cloudwatch: 5,
    elasticsearch: 1,
    'fetzerch-sunandmoon-datasource': 1,
    graphite: 9,
    influxdb: 36,
    mysql: 5,
    postgres: 16,
    stackdriver: 1,
    'synthetic-monitoring-datasource': 1,
  },
  userQuota: 10,
  dashboardQuota: -1,
  alertQuota: -1,
  alertCnt: 0,
  ssl: true,
  customAuth: true,
  customDomain: true,
  support: true,
  runningVersion: '10.0.3-cloud.4.aed62623 (commit: d9eea1cb64, branch: HEAD)',
  machineLearning: 0,
  incident: 0,
  k6OrgId: null,
  hmInstancePromId: 277348,
  hmInstancePromUrl: 'https://prometheus-prod-10-prod-us-central-0.grafana.net',
  hmInstancePromName: 'cloudadminteam-prom',
  hmInstancePromStatus: 'active',
  hmInstancePromCurrentUsage: 0,
  hmInstancePromCurrentActiveSeries: 0,
  hmInstanceGraphiteId: 277349,
  hmInstanceGraphiteUrl: 'https://graphite-prod-10-prod-us-central-0.grafana.net',
  hmInstanceGraphiteName: 'cloudadminteam-graphite',
  hmInstanceGraphiteType: 'graphite-v5',
  hmInstanceGraphiteStatus: 'active',
  hmInstanceGraphiteCurrentUsage: 0,
  hlInstanceId: 137645,
  hlInstanceUrl: 'https://logs-prod-us-central1.grafana.net',
  hlInstanceName: 'cloudadminteam-logs',
  hlInstanceStatus: 'active',
  hlInstanceCurrentUsage: 0,
  amInstanceId: 136630,
  amInstanceName: 'cloudadminteam-alerts',
  amInstanceUrl: 'https://alertmanager-us-central1.grafana.net',
  amInstanceStatus: 'active',
  amInstanceGeneratorUrl: 'https://cloudadminteam.grafana.net',
  amInstanceGeneratorUrlDatasource: '',
  htInstanceId: 134155,
  htInstanceUrl: 'https://tempo-us-central1.grafana.net',
  htInstanceName: 'cloudadminteam-traces',
  htInstanceStatus: 'active',
  hpInstanceId: 288726,
  hpInstanceUrl: 'https://profiles-prod-003.grafana.net',
  hpInstanceName: 'cloudadminteam-profiles',
  hpInstanceStatus: 'active',
  regionId: 1,
  regionSlug: 'us',
  links: [
    {
      rel: 'self',
      href: '/instances/cloudadminteam',
    },
    {
      rel: 'org',
      href: '/orgs/cloudadminteam',
    },
    {
      rel: 'plugins',
      href: '/instances/cloudadminteam/plugins',
    },
  ],
};

export const TRIAL_SUBSCRIPTION = {
  id: 1025948,
  slug: 'growthtrialhome',
  name: 'growthtrialhome',
  url: '',
  createdAt: '2024-01-09T09:38:27.000Z',
  createdBy: 'yahimaperez29',
  updatedAt: '2024-01-09T09:38:41.000Z',
  updatedBy: '',
  avatar: 'default',
  links: [
    {
      rel: 'self',
      href: '/orgs/growthtrialhome',
    },
    {
      rel: 'api-keys',
      href: '/orgs/growthtrialhome/api-keys',
    },
    {
      rel: 'members',
      href: '/orgs/growthtrialhome/members',
    },
  ],
  checksPerMonth: 0,
  wpPlan: '',
  hgInstanceLimit: 1,
  hmInstanceLimit: 1,
  hlInstanceLimit: 1,
  userQuota: -1,
  supportPlan: '',
  creditApproved: 0,
  msaSignedAt: '2024-01-09T09:38:28.000Z',
  msaSignedBy: 'yahimaperez29',
  enterprisePlugins: 1,
  grafanaCloud: 4,
  privacy: 'private',
  reseller: '',
  resellerId: null,
  resellerName: null,
  emergencySupport: false,
  htUsage: 45.62079705006048,
  hlUsage: 45.88696982955,
  hlRetentionUsage: 0,
  hmUsage: 5035138,
  hmGraphiteUsage: 0,
  hgUsage: 0,
  geUsersUsage: 0,
  geInstancesUsage: 0,
  k6VuhUsage: 150,
  k6IPUsage: 0,
  irmUsage: 3,
  hpUsage: 45.115710545464914,
  feO11YUsage: 50,
  appO11YUsage: 1,
  hgGrafanaUsage: 2,
  hgIncludedUsers: 3,
  hgPluginUsersOverageRate: 0,
  hgPluginUsersIncludedUsage: null,
  hgOnCallUsage: 0,
  hmCurrentUsage: 0,
  hmIncludedSeries: 10000,
  hlIncludedUsage: 50,
  hlQueryToIngestRatio: 100,
  gcloudMonthlyCost: 0,
  awsCustomerId: '',
  awsMarketplaceSupport: 0,
  trialStartDate: '2024-01-09T09:38:30.000Z',
  trialEndDate: '2024-01-23T09:38:30.000Z',
  trialLengthDays: 14,
  trialNoticeDate: null,
  cancellationDate: null,
  retainedStackId: 0,
  allowGCloudTrial: 0,
  pluginSignatureType: 'private',
  contractType: 'self_serve',
  contractTypeId: 1,
  hgCurrentActiveUsers: 2,
  subscriptions: {
    current: {
      product: SubscriptionCodeType.FREE,
      isTrial: true,
      endDate: `${result}`,
      payload: {
        userId: 1373705,
        trialId: '6b4b1f97-87a4-4ce2-aa42-6002fd53ade2',
        username: 'yahimaperez29',
      },
      plan: 'trial-v2',
      publicName: 'Trial',
      enterprisePluginsAdded: false,
    },
    nextProduct: 'grafana-cloud-free',
    next: {
      product: SubscriptionCodeType.FREE,
      payload: {
        actionType: 'scheduled-downgrade',
        retainedStackId: null,
        cancellationDetails: {
          cancellationClientNotes: 'Trial subscription automatically downgraded',
        },
      },
      plan: 'free',
      publicName: 'Free',
    },
  },
  hmAverageDpm: 1,
  hmTier1Rate: 8,
  hmTier2Min: 0,
  hmTier2Rate: 0,
  hmTier3Min: 0,
  hmTier3Rate: 0,
  hlTier1Rate: 0.5,
  hlTier2Min: 0,
  hlTier2Rate: 0,
  hlTier3Min: 0,
  hlTier3Rate: 0,
  hgTier1Rate: 8,
  hgTier2Min: 0,
  hgTier2Rate: 0,
  hgTier3Min: 0,
  hgTier3Rate: 0,
  htIncludedUsage: 100,
  htTier1Rate: 0.5,
  htTier2Min: 0,
  htTier2Rate: 0,
  htTier3Min: 0,
  htTier3Rate: 0,
  hlRetentionIncludedUsage: 0,
  hlRetentionTier1Rate: 0,
  hlRetentionTier2Min: 0,
  hlRetentionTier2Rate: 0,
  hlRetentionTier3Min: 0,
  hlRetentionTier3Rate: 0,
  hmGraphiteIncludedUsage: 0,
  hmGraphiteTier1Rate: 0,
  hmGraphiteTier2Min: 0,
  hmGraphiteTier2Rate: 0,
  hmGraphiteTier3Min: 0,
  hmGraphiteTier3Rate: 0,
  geUsersIncludedUsage: 0,
  geUsersTier1Rate: 0,
  geUsersTier2Min: 0,
  geUsersTier2Rate: 0,
  geUsersTier3Min: 0,
  geUsersTier3Rate: 0,
  geInstancesIncludedUsage: 0,
  geInstancesTier1Rate: 0,
  geInstancesTier2Min: 0,
  geInstancesTier2Rate: 0,
  geInstancesTier3Min: 0,
  geInstancesTier3Rate: 0,
  k6VuhIncludedUsage: 1000,
  k6VuhTier1Rate: 0.15,
  k6VuhTier2Min: 0,
  k6VuhTier2Rate: 0,
  k6VuhTier3Min: 0,
  k6VuhTier3Rate: 0,
  k6VuhUnits: 1,
  k6IPIncludedUsage: 0,
  k6IPTier1Rate: 30,
  k6IPTier2Min: 0,
  k6IPTier2Rate: 0,
  k6IPTier3Min: 0,
  k6IPTier3Rate: 0,
  irmStatus: 1,
  irmIncludedUsage: 3,
  irmTier1Rate: 20,
  irmTier2Min: 0,
  irmTier2Rate: 0,
  irmTier3Min: 0,
  irmTier3Rate: 0,
  hpIncludedUsage: 100,
  hpTier1Rate: 0.5,
  hpTier2Min: 0,
  hpTier2Rate: 0,
  hpTier3Min: 0,
  hpTier3Rate: 0,
  feO11YIncludedUsage: 50000,
  feO11YTier1Rate: 0,
  feO11YTier2Min: 0,
  feO11YTier2Rate: 0,
  feO11YTier3Min: 0,
  feO11YTier3Rate: 0,
  feO11YUnits: 1000,
  appO11YIncludedUsage: 2232,
  appO11YTier1Rate: 0,
  appO11YTier2Min: 0,
  appO11YTier2Rate: 0,
  appO11YTier3Min: 0,
  appO11YTier3Rate: 0,
  appO11YUnits: 1,
  smUsage: 101000,
  smIncludedUsage: 100000,
  smTier1Rate: 0,
  smTier2Min: 0,
  smTier2Rate: 0,
  smTier3Min: 0,
  smTier3Rate: 0,
  smUnits: 10000,
};

export const ADVANCED_SUBSCRIPTION = {
  id: 576536,
  slug: 'cloudadminteam',
  name: 'Cloud Admin Team',
  url: '',
  createdAt: '2021-12-13T12:48:39.000Z',
  createdBy: '',
  updatedAt: '2023-07-21T15:28:17.000Z',
  updatedBy: '',
  avatar: 'default',
  links: [
    {
      rel: 'self',
      href: '/orgs/cloudadminteam',
    },
    {
      rel: 'api-keys',
      href: '/orgs/cloudadminteam/api-keys',
    },
    {
      rel: 'members',
      href: '/orgs/cloudadminteam/members',
    },
  ],
  checksPerMonth: 0,
  wpPlan: '',
  hgInstanceLimit: 3,
  hmInstanceLimit: 0,
  hlInstanceLimit: 0,
  userQuota: -1,
  supportPlan: '',
  creditApproved: 0,
  msaSignedAt: null,
  msaSignedBy: null,
  enterprisePlugins: 0,
  grafanaCloud: 1,
  privacy: 'private',
  reseller: '',
  emergencySupport: true,
  htUsage: 0,
  hlUsage: 0,
  hlRetentionUsage: 0,
  hmUsage: 0,
  hmGraphiteUsage: 0,
  hgUsage: 0,
  geUsersUsage: 0,
  geInstancesUsage: 0,
  k6VuhUsage: 0,
  irmUsage: 0,
  hpUsage: 0,
  hgGrafanaUsage: 0,
  hgIncludedUsers: 0,
  hgOnCallUsage: 0,
  hmCurrentUsage: 5,
  hmIncludedSeries: 0,
  hlIncludedUsage: 0,
  hlQueryToIngestRatio: 0,
  gcloudMonthlyCost: 0,
  awsCustomerId: '',
  awsMarketplaceSupport: 0,
  trialStartDate: null,
  trialEndDate: null,
  trialLengthDays: 0,
  trialNoticeDate: null,
  cancellationDate: null,
  retainedStackId: 0,
  allowGCloudTrial: 1,
  pluginSignatureType: 'private',
  contractType: 'contracted',
  contractTypeId: 2,
  hgCurrentActiveUsers: 0,
  subscriptions: {
    current: {
      product: SubscriptionCodeType.ADVANCED,
      isTrial: false,
      endDate: null,
      payload: {},
      plan: 'advanced',
      publicName: 'Advanced',
    },
    nextProduct: null,
    next: null,
  },
  hmAverageDpm: 0,
  hmTier1Rate: 0,
  hmTier2Min: 0,
  hmTier2Rate: 0,
  hmTier3Min: 0,
  hmTier3Rate: 0,
  hlTier1Rate: 0,
  hlTier2Min: 0,
  hlTier2Rate: 0,
  hlTier3Min: 0,
  hlTier3Rate: 0,
  hgTier1Rate: 0,
  hgTier2Min: 0,
  hgTier2Rate: 0,
  hgTier3Min: 0,
  hgTier3Rate: 0,
  htIncludedUsage: 0,
  htTier1Rate: 0,
  htTier2Min: 0,
  htTier2Rate: 0,
  htTier3Min: 0,
  htTier3Rate: 0,
  hlRetentionIncludedUsage: 0,
  hlRetentionTier1Rate: 0,
  hlRetentionTier2Min: 0,
  hlRetentionTier2Rate: 0,
  hlRetentionTier3Min: 0,
  hlRetentionTier3Rate: 0,
  hmGraphiteIncludedUsage: 0,
  hmGraphiteTier1Rate: 0,
  hmGraphiteTier2Min: 0,
  hmGraphiteTier2Rate: 0,
  hmGraphiteTier3Min: 0,
  hmGraphiteTier3Rate: 0,
  geUsersIncludedUsage: 0,
  geUsersTier1Rate: 0,
  geUsersTier2Min: 0,
  geUsersTier2Rate: 0,
  geUsersTier3Min: 0,
  geUsersTier3Rate: 0,
  geInstancesIncludedUsage: 0,
  geInstancesTier1Rate: 0,
  geInstancesTier2Min: 0,
  geInstancesTier2Rate: 0,
  geInstancesTier3Min: 0,
  geInstancesTier3Rate: 0,
  k6VuhIncludedUsage: 0,
  k6VuhTier1Rate: 0,
  k6VuhTier2Min: 0,
  k6VuhTier2Rate: 0,
  k6VuhTier3Min: 0,
  k6VuhTier3Rate: 0,
  k6VuhUnits: 100,
  irmStatus: 2,
  irmIncludedUsage: 0,
  irmTier1Rate: 0,
  irmTier2Min: 0,
  irmTier2Rate: 0,
  irmTier3Min: 0,
  irmTier3Rate: 0,
  hpIncludedUsage: 0,
  hpTier1Rate: 0.5,
  hpTier2Min: 0,
  hpTier2Rate: 0,
  hpTier3Min: 0,
  hpTier3Rate: 0,
  tags: ['staff'],
  accountManagerId: 0,
  accountManagerUsername: null,
  accountManagerName: null,
  accountOwnerId: 0,
  accountOwnerUsername: null,
  accountOwnerName: null,
  hmBillingStartDate: null,
  hmBillingEndDate: null,
  hmBilledToDate: null,
  hmOverageWarnDate: null,
  hmOverageAmount: 0,
  hmCurrentPrometheusUsage: 5,
  hmCurrentGraphiteUsage: 0,
  hlBillingStartDate: null,
  hlBillingEndDate: null,
  hlBilledToDate: null,
  hlOverageWarnDate: null,
  hlOverageAmount: 0,
  hlCurrentUsage: 0,
  hgBillingStartDate: null,
  hgBillingEndDate: null,
  hgBilledToDate: null,
  hgOverageWarnDate: null,
  hgOverageAmount: 0,
  hgActiveUsers: 0,
  hgDatasourceCnts: {
    cloudwatch: 5,
    elasticsearch: 1,
    'fetzerch-sunandmoon-datasource': 1,
    graphite: 9,
    influxdb: 65,
    mysql: 5,
    postgres: 16,
    stackdriver: 1,
    'synthetic-monitoring-datasource': 2,
  },
  hgIntegrationCnts: {
    'apache-http': 1,
    'apollo-server': 1,
    asterisk: 1,
    'cloudwatch-logs': 1,
    github: 1,
    'grafana-agent': 2,
    kubernetes: 1,
    'linux-node': 2,
    'macos-node': 1,
    mysql: 1,
    postgres: 1,
    'raspberry-pi-node': 1,
    spark: 1,
    'windows-exporter': 1,
  },
  htOverageWarnDate: null,
  htFirstUsageDate: null,
  htCurrentUsage: 0,
  htOverageAmount: 0,
  htBillingStartDate: null,
  htBillingEndDate: null,
  htBilledToDate: null,
  hlRetentionOverageWarnDate: null,
  hlRetentionFirstUsageDate: null,
  hlRetentionCurrentUsage: 0,
  hlRetentionOverageAmount: 0,
  hlRetentionBillingStartDate: null,
  hlRetentionBillingEndDate: null,
  hlRetentionBilledToDate: null,
  hmGraphiteOverageWarnDate: null,
  hmGraphiteFirstUsageDate: null,
  hmGraphiteCurrentUsage: 0,
  hmGraphiteOverageAmount: 0,
  hmGraphiteBillingStartDate: null,
  hmGraphiteBillingEndDate: null,
  hmGraphiteBilledToDate: null,
  geUsersOverageWarnDate: null,
  geUsersFirstUsageDate: null,
  geUsersCurrentUsage: 0,
  geUsersOverageAmount: 0,
  geUsersBillingStartDate: null,
  geUsersBillingEndDate: null,
  geUsersBilledToDate: null,
  geInstancesOverageWarnDate: null,
  geInstancesFirstUsageDate: null,
  geInstancesCurrentUsage: 0,
  geInstancesOverageAmount: 0,
  geInstancesBillingStartDate: null,
  geInstancesBillingEndDate: null,
  geInstancesBilledToDate: null,
  k6VuhOverageWarnDate: null,
  k6VuhFirstUsageDate: null,
  k6VuhCurrentUsage: 0,
  k6VuhOverageAmount: 0,
  k6VuhBillingStartDate: null,
  k6VuhBillingEndDate: null,
  k6VuhBilledToDate: null,
  irmOverageWarnDate: null,
  irmFirstUsageDate: null,
  irmCurrentUsage: 0,
  irmOverageAmount: 0,
  irmBillingStartDate: null,
  irmBillingEndDate: null,
  irmBilledToDate: null,
  hpOverageWarnDate: null,
  hpFirstUsageDate: null,
  hpCurrentUsage: 0,
  hpOverageAmount: 0,
  hpBillingStartDate: '2023-05-01T00:00:00.000Z',
  hpBillingEndDate: null,
  hpBilledToDate: null,
  totalOverageAmount: 0,
  memberCnt: 46,
  licenseCnt: 0,
  licenseConfiguredCnt: 0,
  licenseUnconfiguredCnt: 0,
  hmInstanceCnt: 4,
  hmGraphiteInstanceCnt: 2,
  hmPrometheusInstanceCnt: 2,
  hgInstanceCnt: 2,
  hlInstanceCnt: 2,
  htInstanceCnt: 2,
  ubersmithClientId: 0,
  intacctCustomerId: 0,
  intacctCustomerUrl: '',
  committedArr: 0,
  nacvCredit: 0,
  effectiveMrrHigh: 0,
  zendeskId: 1900083158473,
  salesforceAccountId: '',
  salesforceLeadId: '00Q1I00000IFkzlUAD',
  salesforceCustomOrgId: 'aAN8W0000013CZlWAM',
  slackSupport: 0,
  slackSupportChannel: '',
  happinessRating: null,
  happinessNote: null,
  happinessCreatedAt: null,
  happinessExpiredAt: null,
  happinessChangedAt: null,
  happinessUserName: null,
  cancellationClientNotes: null,
  cancellationNotes: null,
  cancellationReason: '',
  netPromoterScore: null,
  hmFirstOverageDate: null,
  hmFirstApproachingLimitDate: null,
  spendCommitCreditBalance: 0,
  spendCommitCreditTotal: 0,
  projectedOverageAmount: 0,
  estimatedArr: 0,
  referredBy: null,
  k6OrgId: null,
};

export const PRO_SUBSCRIPTION = {
  id: 677328,
  slug: 'yahimaperez',
  name: 'yahimaperez',
  url: '',
  createdAt: '2022-07-11T11:44:13.000Z',
  createdBy: '',
  updatedAt: '2022-07-28T11:48:19.000Z',
  updatedBy: '',
  avatar: 'default',
  links: [
    {
      rel: 'self',
      href: '/orgs/yahimaperez',
    },
    {
      rel: 'api-keys',
      href: '/orgs/yahimaperez/api-keys',
    },
    {
      rel: 'members',
      href: '/orgs/yahimaperez/members',
    },
  ],
  checksPerMonth: 0,
  wpPlan: '',
  hgInstanceLimit: 10,
  hmInstanceLimit: 3,
  hlInstanceLimit: 3,
  userQuota: 10,
  supportPlan: '',
  creditApproved: 0,
  msaSignedAt: '2022-07-11T11:44:14.000Z',
  msaSignedBy: 'yahimaperez',
  enterprisePlugins: 0,
  grafanaCloud: 1,
  privacy: 'private',
  reseller: '',
  emergencySupport: false,
  htUsage: 0,
  hlUsage: 0,
  hlRetentionUsage: 0,
  hmUsage: 40,
  hmGraphiteUsage: 0,
  hgUsage: 1,
  geUsersUsage: 0,
  geInstancesUsage: 0,
  k6VuhUsage: 0,
  irmUsage: 0,
  hpUsage: 0,
  hgGrafanaUsage: 1,
  hgIncludedUsers: 2,
  hgOnCallUsage: 0,
  hmCurrentUsage: 40,
  hmIncludedSeries: 20000,
  hlIncludedUsage: 100,
  hlQueryToIngestRatio: 0,
  gcloudMonthlyCost: 0,
  awsCustomerId: '',
  awsMarketplaceSupport: 0,
  trialStartDate: null,
  trialEndDate: null,
  trialLengthDays: 0,
  trialNoticeDate: null,
  cancellationDate: null,
  retainedStackId: 0,
  allowGCloudTrial: 1,
  pluginSignatureType: 'private',
  contractType: 'none',
  contractTypeId: 0,
  hgCurrentActiveUsers: 1,
  subscriptions: {
    current: {
      product: SubscriptionCodeType.PRO,
      isTrial: false,
      endDate: null,
      payload: {},
      plan: 'pro',
      publicName: 'Pro',
    },
    nextProduct: null,
    next: null,
  },
  hmAverageDpm: 1,
  hmTier1Rate: 0,
  hmTier2Min: 0,
  hmTier2Rate: 0,
  hmTier3Min: 0,
  hmTier3Rate: 0,
  hlTier1Rate: 0,
  hlTier2Min: 0,
  hlTier2Rate: 0,
  hlTier3Min: 0,
  hlTier3Rate: 0,
  hgTier1Rate: 0,
  hgTier2Min: 0,
  hgTier2Rate: 0,
  hgTier3Min: 0,
  hgTier3Rate: 0,
  htIncludedUsage: 0,
  htTier1Rate: 0,
  htTier2Min: 0,
  htTier2Rate: 0,
  htTier3Min: 0,
  htTier3Rate: 0,
  hlRetentionIncludedUsage: 0,
  hlRetentionTier1Rate: 0,
  hlRetentionTier2Min: 0,
  hlRetentionTier2Rate: 0,
  hlRetentionTier3Min: 0,
  hlRetentionTier3Rate: 0,
  hmGraphiteIncludedUsage: 0,
  hmGraphiteTier1Rate: 0,
  hmGraphiteTier2Min: 0,
  hmGraphiteTier2Rate: 0,
  hmGraphiteTier3Min: 0,
  hmGraphiteTier3Rate: 0,
  geUsersIncludedUsage: 0,
  geUsersTier1Rate: 0,
  geUsersTier2Min: 0,
  geUsersTier2Rate: 0,
  geUsersTier3Min: 0,
  geUsersTier3Rate: 0,
  geInstancesIncludedUsage: 0,
  geInstancesTier1Rate: 0,
  geInstancesTier2Min: 0,
  geInstancesTier2Rate: 0,
  geInstancesTier3Min: 0,
  geInstancesTier3Rate: 0,
  k6VuhIncludedUsage: 0,
  k6VuhTier1Rate: 0,
  k6VuhTier2Min: 0,
  k6VuhTier2Rate: 0,
  k6VuhTier3Min: 0,
  k6VuhTier3Rate: 0,
  k6VuhUnits: 100,
  irmStatus: 1,
  irmIncludedUsage: 2,
  irmTier1Rate: 20,
  irmTier2Min: 0,
  irmTier2Rate: 0,
  irmTier3Min: 0,
  irmTier3Rate: 0,
  hpIncludedUsage: 100,
  hpTier1Rate: 0.5,
  hpTier2Min: 0,
  hpTier2Rate: 0,
  hpTier3Min: 0,
  hpTier3Rate: 0,
  tags: ['staff'],
  accountManagerId: 0,
  accountManagerUsername: null,
  accountManagerName: null,
  accountOwnerId: 0,
  accountOwnerUsername: null,
  accountOwnerName: null,
  hmBillingStartDate: '2022-07-11T11:44:14.000Z',
  hmBillingEndDate: null,
  hmBilledToDate: null,
  hmOverageWarnDate: null,
  hmOverageAmount: 0,
  hmCurrentPrometheusUsage: 40,
  hmCurrentGraphiteUsage: 0,
  hlBillingStartDate: '2022-07-11T11:44:14.000Z',
  hlBillingEndDate: null,
  hlBilledToDate: null,
  hlOverageWarnDate: null,
  hlOverageAmount: 0,
  hlCurrentUsage: 0,
  hgBillingStartDate: '2022-07-11T11:44:14.000Z',
  hgBillingEndDate: null,
  hgBilledToDate: null,
  hgOverageWarnDate: null,
  hgOverageAmount: 0,
  hgActiveUsers: 0,
  hgDatasourceCnts: {
    graphite: 1,
    influxdb: 1,
    mysql: 1,
  },
  hgIntegrationCnts: {
    'grafana-agent': 1,
    'macos-node': 1,
    mysql: 1,
  },
  htOverageWarnDate: null,
  htFirstUsageDate: null,
  htCurrentUsage: 0,
  htOverageAmount: 0,
  htBillingStartDate: '2022-07-11T11:44:14.000Z',
  htBillingEndDate: null,
  htBilledToDate: null,
  hlRetentionOverageWarnDate: null,
  hlRetentionFirstUsageDate: '2022-07-13T13:02:39.000Z',
  hlRetentionCurrentUsage: 0,
  hlRetentionOverageAmount: 0,
  hlRetentionBillingStartDate: null,
  hlRetentionBillingEndDate: null,
  hlRetentionBilledToDate: null,
  hmGraphiteOverageWarnDate: null,
  hmGraphiteFirstUsageDate: null,
  hmGraphiteCurrentUsage: 0,
  hmGraphiteOverageAmount: 0,
  hmGraphiteBillingStartDate: null,
  hmGraphiteBillingEndDate: null,
  hmGraphiteBilledToDate: null,
  geUsersOverageWarnDate: null,
  geUsersFirstUsageDate: null,
  geUsersCurrentUsage: 0,
  geUsersOverageAmount: 0,
  geUsersBillingStartDate: null,
  geUsersBillingEndDate: null,
  geUsersBilledToDate: null,
  geInstancesOverageWarnDate: null,
  geInstancesFirstUsageDate: null,
  geInstancesCurrentUsage: 0,
  geInstancesOverageAmount: 0,
  geInstancesBillingStartDate: null,
  geInstancesBillingEndDate: null,
  geInstancesBilledToDate: null,
  k6VuhOverageWarnDate: null,
  k6VuhFirstUsageDate: null,
  k6VuhCurrentUsage: 0,
  k6VuhOverageAmount: 0,
  k6VuhBillingStartDate: null,
  k6VuhBillingEndDate: null,
  k6VuhBilledToDate: null,
  irmOverageWarnDate: null,
  irmFirstUsageDate: null,
  irmCurrentUsage: 0,
  irmOverageAmount: 0,
  irmBillingStartDate: null,
  irmBillingEndDate: null,
  irmBilledToDate: null,
  hpOverageWarnDate: null,
  hpFirstUsageDate: null,
  hpCurrentUsage: 0,
  hpOverageAmount: 0,
  hpBillingStartDate: '2023-08-01T00:00:00.000Z',
  hpBillingEndDate: null,
  hpBilledToDate: null,
  totalOverageAmount: 0,
  memberCnt: 2,
  licenseCnt: 0,
  licenseConfiguredCnt: 0,
  licenseUnconfiguredCnt: 0,
  hmInstanceCnt: 2,
  hmGraphiteInstanceCnt: 1,
  hmPrometheusInstanceCnt: 1,
  hgInstanceCnt: 1,
  hlInstanceCnt: 1,
  htInstanceCnt: 1,
  ubersmithClientId: 0,
  intacctCustomerId: 0,
  intacctCustomerUrl: '',
  committedArr: 0,
  nacvCredit: 0,
  effectiveMrrHigh: 0,
  zendeskId: 5868755880861,
  salesforceAccountId: '',
  salesforceLeadId: '00Q8W00000ezsMTUAY',
  salesforceCustomOrgId: 'aAN8W0000013N8eWAE',
  slackSupport: 0,
  slackSupportChannel: '',
  happinessRating: null,
  happinessNote: null,
  happinessCreatedAt: null,
  happinessExpiredAt: null,
  happinessChangedAt: null,
  happinessUserName: null,
  cancellationClientNotes: null,
  cancellationNotes: null,
  cancellationReason: '',
  netPromoterScore: null,
  hmFirstOverageDate: null,
  hmFirstApproachingLimitDate: null,
  spendCommitCreditBalance: 0,
  spendCommitCreditTotal: 0,
  projectedOverageAmount: 0,
  estimatedArr: 0,
  referredBy: null,
  k6OrgId: null,
};

export const SS_ADVANCED_SUBSCRIPTION = {
  id: 677328,
  slug: 'yahimaperez',
  name: 'yahimaperez',
  url: '',
  createdAt: '2022-07-11T11:44:13.000Z',
  createdBy: '',
  updatedAt: '2022-07-28T11:48:19.000Z',
  updatedBy: '',
  avatar: 'default',
  links: [
    {
      rel: 'self',
      href: '/orgs/yahimaperez',
    },
    {
      rel: 'api-keys',
      href: '/orgs/yahimaperez/api-keys',
    },
    {
      rel: 'members',
      href: '/orgs/yahimaperez/members',
    },
  ],
  checksPerMonth: 0,
  wpPlan: '',
  hgInstanceLimit: 10,
  hmInstanceLimit: 3,
  hlInstanceLimit: 3,
  userQuota: 10,
  supportPlan: '',
  creditApproved: 0,
  msaSignedAt: '2022-07-11T11:44:14.000Z',
  msaSignedBy: 'yahimaperez',
  enterprisePlugins: 0,
  grafanaCloud: 1,
  privacy: 'private',
  reseller: '',
  emergencySupport: false,
  htUsage: 0,
  hlUsage: 0,
  hlRetentionUsage: 0,
  hmUsage: 40,
  hmGraphiteUsage: 0,
  hgUsage: 1,
  geUsersUsage: 0,
  geInstancesUsage: 0,
  k6VuhUsage: 0,
  irmUsage: 0,
  hpUsage: 0,
  hgGrafanaUsage: 1,
  hgIncludedUsers: 2,
  hgOnCallUsage: 0,
  hmCurrentUsage: 40,
  hmIncludedSeries: 20000,
  hlIncludedUsage: 100,
  hlQueryToIngestRatio: 0,
  gcloudMonthlyCost: 0,
  awsCustomerId: '',
  awsMarketplaceSupport: 0,
  trialStartDate: null,
  trialEndDate: null,
  trialLengthDays: 0,
  trialNoticeDate: null,
  cancellationDate: null,
  retainedStackId: 0,
  allowGCloudTrial: 1,
  pluginSignatureType: 'private',
  contractType: 'self_serve',
  contractTypeId: 0,
  hgCurrentActiveUsers: 1,
  subscriptions: {
    current: {
      product: SubscriptionCodeType.SS_ADVANCED,
      isTrial: false,
      endDate: null,
      payload: {},
      plan: 'advanced',
      publicName: 'Advanced',
    },
    nextProduct: null,
    next: null,
  },
  hmAverageDpm: 1,
  hmTier1Rate: 0,
  hmTier2Min: 0,
  hmTier2Rate: 0,
  hmTier3Min: 0,
  hmTier3Rate: 0,
  hlTier1Rate: 0,
  hlTier2Min: 0,
  hlTier2Rate: 0,
  hlTier3Min: 0,
  hlTier3Rate: 0,
  hgTier1Rate: 0,
  hgTier2Min: 0,
  hgTier2Rate: 0,
  hgTier3Min: 0,
  hgTier3Rate: 0,
  htIncludedUsage: 0,
  htTier1Rate: 0,
  htTier2Min: 0,
  htTier2Rate: 0,
  htTier3Min: 0,
  htTier3Rate: 0,
  hlRetentionIncludedUsage: 0,
  hlRetentionTier1Rate: 0,
  hlRetentionTier2Min: 0,
  hlRetentionTier2Rate: 0,
  hlRetentionTier3Min: 0,
  hlRetentionTier3Rate: 0,
  hmGraphiteIncludedUsage: 0,
  hmGraphiteTier1Rate: 0,
  hmGraphiteTier2Min: 0,
  hmGraphiteTier2Rate: 0,
  hmGraphiteTier3Min: 0,
  hmGraphiteTier3Rate: 0,
  geUsersIncludedUsage: 0,
  geUsersTier1Rate: 0,
  geUsersTier2Min: 0,
  geUsersTier2Rate: 0,
  geUsersTier3Min: 0,
  geUsersTier3Rate: 0,
  geInstancesIncludedUsage: 0,
  geInstancesTier1Rate: 0,
  geInstancesTier2Min: 0,
  geInstancesTier2Rate: 0,
  geInstancesTier3Min: 0,
  geInstancesTier3Rate: 0,
  k6VuhIncludedUsage: 0,
  k6VuhTier1Rate: 0,
  k6VuhTier2Min: 0,
  k6VuhTier2Rate: 0,
  k6VuhTier3Min: 0,
  k6VuhTier3Rate: 0,
  k6VuhUnits: 100,
  irmStatus: 1,
  irmIncludedUsage: 2,
  irmTier1Rate: 20,
  irmTier2Min: 0,
  irmTier2Rate: 0,
  irmTier3Min: 0,
  irmTier3Rate: 0,
  hpIncludedUsage: 100,
  hpTier1Rate: 0.5,
  hpTier2Min: 0,
  hpTier2Rate: 0,
  hpTier3Min: 0,
  hpTier3Rate: 0,
  tags: ['staff'],
  accountManagerId: 0,
  accountManagerUsername: null,
  accountManagerName: null,
  accountOwnerId: 0,
  accountOwnerUsername: null,
  accountOwnerName: null,
  hmBillingStartDate: '2022-07-11T11:44:14.000Z',
  hmBillingEndDate: null,
  hmBilledToDate: null,
  hmOverageWarnDate: null,
  hmOverageAmount: 0,
  hmCurrentPrometheusUsage: 40,
  hmCurrentGraphiteUsage: 0,
  hlBillingStartDate: '2022-07-11T11:44:14.000Z',
  hlBillingEndDate: null,
  hlBilledToDate: null,
  hlOverageWarnDate: null,
  hlOverageAmount: 0,
  hlCurrentUsage: 0,
  hgBillingStartDate: '2022-07-11T11:44:14.000Z',
  hgBillingEndDate: null,
  hgBilledToDate: null,
  hgOverageWarnDate: null,
  hgOverageAmount: 0,
  hgActiveUsers: 0,
  hgDatasourceCnts: {
    graphite: 1,
    influxdb: 1,
    mysql: 1,
  },
  hgIntegrationCnts: {
    'grafana-agent': 1,
    'macos-node': 1,
    mysql: 1,
  },
  htOverageWarnDate: null,
  htFirstUsageDate: null,
  htCurrentUsage: 0,
  htOverageAmount: 0,
  htBillingStartDate: '2022-07-11T11:44:14.000Z',
  htBillingEndDate: null,
  htBilledToDate: null,
  hlRetentionOverageWarnDate: null,
  hlRetentionFirstUsageDate: '2022-07-13T13:02:39.000Z',
  hlRetentionCurrentUsage: 0,
  hlRetentionOverageAmount: 0,
  hlRetentionBillingStartDate: null,
  hlRetentionBillingEndDate: null,
  hlRetentionBilledToDate: null,
  hmGraphiteOverageWarnDate: null,
  hmGraphiteFirstUsageDate: null,
  hmGraphiteCurrentUsage: 0,
  hmGraphiteOverageAmount: 0,
  hmGraphiteBillingStartDate: null,
  hmGraphiteBillingEndDate: null,
  hmGraphiteBilledToDate: null,
  geUsersOverageWarnDate: null,
  geUsersFirstUsageDate: null,
  geUsersCurrentUsage: 0,
  geUsersOverageAmount: 0,
  geUsersBillingStartDate: null,
  geUsersBillingEndDate: null,
  geUsersBilledToDate: null,
  geInstancesOverageWarnDate: null,
  geInstancesFirstUsageDate: null,
  geInstancesCurrentUsage: 0,
  geInstancesOverageAmount: 0,
  geInstancesBillingStartDate: null,
  geInstancesBillingEndDate: null,
  geInstancesBilledToDate: null,
  k6VuhOverageWarnDate: null,
  k6VuhFirstUsageDate: null,
  k6VuhCurrentUsage: 0,
  k6VuhOverageAmount: 0,
  k6VuhBillingStartDate: null,
  k6VuhBillingEndDate: null,
  k6VuhBilledToDate: null,
  irmOverageWarnDate: null,
  irmFirstUsageDate: null,
  irmCurrentUsage: 0,
  irmOverageAmount: 0,
  irmBillingStartDate: null,
  irmBillingEndDate: null,
  irmBilledToDate: null,
  hpOverageWarnDate: null,
  hpFirstUsageDate: null,
  hpCurrentUsage: 0,
  hpOverageAmount: 0,
  hpBillingStartDate: '2023-08-01T00:00:00.000Z',
  hpBillingEndDate: null,
  hpBilledToDate: null,
  totalOverageAmount: 0,
  memberCnt: 2,
  licenseCnt: 0,
  licenseConfiguredCnt: 0,
  licenseUnconfiguredCnt: 0,
  hmInstanceCnt: 2,
  hmGraphiteInstanceCnt: 1,
  hmPrometheusInstanceCnt: 1,
  hgInstanceCnt: 1,
  hlInstanceCnt: 1,
  htInstanceCnt: 1,
  ubersmithClientId: 0,
  intacctCustomerId: 0,
  intacctCustomerUrl: '',
  committedArr: 0,
  nacvCredit: 0,
  effectiveMrrHigh: 0,
  zendeskId: 5868755880861,
  salesforceAccountId: '',
  salesforceLeadId: '00Q8W00000ezsMTUAY',
  salesforceCustomOrgId: 'aAN8W0000013N8eWAE',
  slackSupport: 0,
  slackSupportChannel: '',
  happinessRating: null,
  happinessNote: null,
  happinessCreatedAt: null,
  happinessExpiredAt: null,
  happinessChangedAt: null,
  happinessUserName: null,
  cancellationClientNotes: null,
  cancellationNotes: null,
  cancellationReason: '',
  netPromoterScore: null,
  hmFirstOverageDate: null,
  hmFirstApproachingLimitDate: null,
  spendCommitCreditBalance: 0,
  spendCommitCreditTotal: 0,
  projectedOverageAmount: 0,
  estimatedArr: 0,
  referredBy: null,
  k6OrgId: null,
};

export const FREE_SUBSCRIPTION = {
  id: 1025948,
  slug: 'growthtrialhome',
  name: 'growthtrialhome',
  url: '',
  createdAt: '2024-01-09T09:38:27.000Z',
  createdBy: 'yahimaperez29',
  updatedAt: '2024-01-09T09:38:41.000Z',
  updatedBy: '',
  avatar: 'default',
  links: [
    { rel: 'self', href: '/orgs/growthtrialhome' },
    { rel: 'api-keys', href: '/orgs/growthtrialhome/api-keys' },
    { rel: 'members', href: '/orgs/growthtrialhome/members' },
  ],
  checksPerMonth: 0,
  wpPlan: '',
  hgInstanceLimit: 1,
  hmInstanceLimit: 0,
  hlInstanceLimit: 0,
  userQuota: 3,
  supportPlan: '',
  creditApproved: 0,
  msaSignedAt: '2024-01-09T09:38:28.000Z',
  msaSignedBy: 'yahimaperez29',
  enterprisePlugins: 1,
  grafanaCloud: 9,
  privacy: 'private',
  reseller: '',
  resellerId: null,
  resellerName: null,
  emergencySupport: false,
  htUsage: 45.62079705006048,
  hlUsage: 48.88696982955,
  hlRetentionUsage: 0,
  hmUsage: 3250,
  hmGraphiteUsage: 0,
  hgUsage: 3,
  geUsersUsage: 0,
  geInstancesUsage: 0,
  k6VuhUsage: 225,
  irmUsage: 0,
  hpUsage: 0,
  hgGrafanaUsage: 1,
  hgIncludedUsers: 3,
  hgPluginUsersOverageRate: 0,
  hgPluginUsersIncludedUsage: null,
  hgOnCallUsage: 0,
  hmCurrentUsage: 0,
  hmIncludedSeries: 10000,
  hlIncludedUsage: 50,
  hlQueryToIngestRatio: 0,
  gcloudMonthlyCost: 0,
  awsCustomerId: '',
  awsMarketplaceSupport: 0,
  trialStartDate: '2022-07-21T11:54:24.000Z',
  trialEndDate: '2022-08-04T11:54:24.000Z',
  trialLengthDays: 14,
  trialNoticeDate: null,
  cancellationDate: '2022-08-04T11:54:24.000Z',
  retainedStackId: 0,
  allowGCloudTrial: 0,
  pluginSignatureType: 'private',
  contractType: 'none',
  contractTypeId: 0,
  hgCurrentActiveUsers: 1,
  subscriptions: {
    current: {
      product: SubscriptionCodeType.FREE,
      isTrial: false,
      endDate: null,
      payload: {},
      plan: 'free',
      publicName: 'Free',
    },
  },
  hmAverageDpm: 1,
  hmTier1Rate: 8,
  hmTier2Min: 0,
  hmTier2Rate: 0,
  hmTier3Min: 0,
  hmTier3Rate: 0,
  hlTier1Rate: 0.5,
  hlTier2Min: 0,
  hlTier2Rate: 0,
  hlTier3Min: 0,
  hlTier3Rate: 0,
  hgTier1Rate: 8,
  hgTier2Min: 0,
  hgTier2Rate: 0,
  hgTier3Min: 0,
  hgTier3Rate: 0,
  htIncludedUsage: 100,
  htTier1Rate: 0.5,
  htTier2Min: 0,
  htTier2Rate: 0,
  htTier3Min: 0,
  htTier3Rate: 0,
  hlRetentionIncludedUsage: 0,
  hlRetentionTier1Rate: 0,
  hlRetentionTier2Min: 0,
  hlRetentionTier2Rate: 0,
  hlRetentionTier3Min: 0,
  hlRetentionTier3Rate: 0,
  hmGraphiteIncludedUsage: 0,
  hmGraphiteTier1Rate: 0,
  hmGraphiteTier2Min: 0,
  hmGraphiteTier2Rate: 0,
  hmGraphiteTier3Min: 0,
  hmGraphiteTier3Rate: 0,
  geUsersIncludedUsage: 0,
  geUsersTier1Rate: 0,
  geUsersTier2Min: 0,
  geUsersTier2Rate: 0,
  geUsersTier3Min: 0,
  geUsersTier3Rate: 0,
  geInstancesIncludedUsage: 0,
  geInstancesTier1Rate: 0,
  geInstancesTier2Min: 0,
  geInstancesTier2Rate: 0,
  geInstancesTier3Min: 0,
  geInstancesTier3Rate: 0,
  k6VuhIncludedUsage: 1000,
  k6VuhTier1Rate: 0.15,
  k6VuhTier2Min: 0,
  k6VuhTier2Rate: 0,
  k6VuhTier3Min: 0,
  k6VuhTier3Rate: 0,
  k6VuhUnits: 1,
  k6IPIncludedUsage: 0,
  k6IPTier1Rate: 30,
  k6IPTier2Min: 0,
  k6IPTier2Rate: 0,
  k6IPTier3Min: 0,
  k6IPTier3Rate: 0,
  irmStatus: 1,
  irmIncludedUsage: 3,
  irmTier1Rate: 20,
  irmTier2Min: 0,
  irmTier2Rate: 0,
  irmTier3Min: 0,
  irmTier3Rate: 0,
  hpIncludedUsage: 100,
  hpTier1Rate: 0.5,
  hpTier2Min: 0,
  hpTier2Rate: 0,
  hpTier3Min: 0,
  hpTier3Rate: 0,
  feO11YUsage: 53001,
  feO11YIncludedUsage: 50000,
  feO11YTier1Rate: 0,
  feO11YTier2Min: 0,
  feO11YTier2Rate: 0,
  feO11YTier3Min: 0,
  feO11YTier3Rate: 0,
  feO11YUnits: 1000,
  appO11YUsage: 2244,
  appO11YIncludedUsage: 2232,
  appO11YTier1Rate: 0,
  appO11YTier2Min: 0,
  appO11YTier2Rate: 0,
  appO11YTier3Min: 0,
  appO11YTier3Rate: 0,
  appO11YUnits: 1,
  smUsage: 101000,
  smIncludedUsage: 100000,
  smTier1Rate: 0,
  smTier2Min: 0,
  smTier2Rate: 0,
  smTier3Min: 0,
  smTier3Rate: 0,
  smUnits: 10000,
};

export const NO_MARKETPLACE = {
  orgId: 576536,
  orgSlug: 'raintank',
  activeSubscription: false,
};

export const GCP_MARKETPLACE_SUBSCRIPTION = {
  orgId: 967208,
  orgSlug: 'devotedstudios',
  activeSubscription: true,
  details: {
    isLegacy: false,
    provider: 'gcp',
  },
};

export const AZURE_MARKETPLACE_SUBSCRIPTION = {
  orgId: 967208,
  orgSlug: 'hello',
  activeSubscription: true,
  details: {
    isLegacy: false,
    provider: 'azure',
  },
};

export const AWS_MARKETPLACE_SUBSCRIPTION = {
  orgId: 967208,
  orgSlug: 'testaws',
  activeSubscription: true,
  details: {
    isLegacy: false,
    provider: 'aws',
  },
};

import React from 'react';
import { FreeUsersUsage } from 'feature/usage/components/FreeUsersUsage';
import { ProUsersUsage } from 'feature/usage/components/ProUsersUsage';
import { UsageProps } from 'feature/usage/types/UsageData';

export function UsersUsage({ usage, isAdmin, isFree, billingDashboard }: UsageProps): JSX.Element | null {
  if (isAdmin === true) {
    return isFree ? (
      <FreeUsersUsage usage={usage} isAdmin={isAdmin} />
    ) : (
      <ProUsersUsage usage={usage} isAdmin={isAdmin} billingDashboard={billingDashboard} />
    );
  }
  return null;
}

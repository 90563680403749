import { useStyles2 } from '@grafana/ui';
import React from 'react';
import { DataConnectCardIcon } from 'feature/onboarding/components/PrometheusTooltip/DataConnectCardIcon';
import { getStyles } from 'feature/onboarding/components/PrometheusTooltip/PrometheusTooltip.styles';

export function PrometheusHostedTooltip(): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.ct}>
      <b>What you can do</b>
      <div className={styles.icons}>
        <DataConnectCardIcon
          icon={{
            label: 'Collect',
            icon: 'arrow-right',
            size: 'xl',
            disabled: false,
          }}
        />
        <DataConnectCardIcon
          icon={{
            label: 'Store',
            icon: 'database',
            size: 'xl',
            disabled: false,
          }}
        />
        <DataConnectCardIcon
          icon={{
            label: 'Visualize',
            icon: 'apps',
            size: 'xl',
            disabled: false,
          }}
        />
      </div>
      <p>
        <ul>
          <li>Easy-to-use agent-integrations that include pre-built dashboards and alerts.</li>
          <li>Long term, scalable storage for your metrics directly in Grafana Cloud.</li>
          <li>No need to manage any Prometheus servers yourself.</li>
        </ul>
      </p>
      <p>
        <b>How to explore your data</b>
      </p>
      <p className={styles.mb0}>
        After configuring the agent-integration, you will receive pre-built dashboards and alerts for your Prometheus
        data. You can also use Grafana Explore to write queries and build customized panels and dashboards.
      </p>
    </div>
  );
}

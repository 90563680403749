import React, { useEffect } from 'react';
import { cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

import { getPageStyles } from 'feature/common/components/Page.styles';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';

import { LoadManager } from '../../common/components/LoadManager';
import { lsGet, lsKeys, lsRemove } from 'feature/common/utils/misc';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'feature/common/consts/appPaths';
import { HomepageBackground } from 'feature/home/components/refresh-design/HomepageBackground';
import { useNotificationBannerVisible } from 'feature/notification-banners/hooks/useNotificationBannerVisible';
import { HomePageContent } from 'feature/home/components/HomePageContent';
import { isNewCloudHomeRedesignEnabled } from 'feature/common/utils/featureToggles';

export const HomePage = () => {
  const pageStyles = useStyles2(getPageStyles);
  const navigate = useNavigate();
  const { isLoading, error } = useCurrentUser();

  const { isLoading: isNotificationLoading, isVisible } = useNotificationBannerVisible();
  const enableNewRedesign = isNewCloudHomeRedesignEnabled();

  if (lsGet(lsKeys.onboardingVisited) === false) {
    navigate(appPaths.onboarding);
  }

  useEffect(() => {
    const checkNotificationBanner = () => {
      if (!isNotificationLoading && enableNewRedesign) {
        const divWrapper: HTMLDivElement | null = document.querySelector('div[class$="-page-wrapper"]');
        if (divWrapper) {
          const currentHeight = divWrapper.style.height;
          const newHeight = isVisible ? 'calc(100% - 60px)' : '';
          if (currentHeight !== newHeight) {
            divWrapper.style.height = newHeight;
          }
        }
      }
    };

    checkNotificationBanner();
    window.addEventListener('storage', checkNotificationBanner);
    return () => window.removeEventListener('storage', checkNotificationBanner);
  }, [isNotificationLoading, isVisible, enableNewRedesign]);

  useEffect(() => {
    // remove flag on instances that still have the variable in storage
    if (lsGet(lsKeys.isNewUserTestVariation)) {
      lsRemove(lsKeys.isNewUserTestVariation);
    }
  }, []);

  return (
    <div
      className={cx([
        { [pageStyles.wrapper]: !enableNewRedesign },
        { [pageStyles.wrapperNewDesign]: enableNewRedesign },
        { [pageStyles.fullWidth]: enableNewRedesign },
      ])}
    >
      <LoadManager loading={isLoading && !error}>
        {enableNewRedesign ? <HomepageBackground /> : <HomePageContent />}
      </LoadManager>
    </div>
  );
};

import { cx } from '@emotion/css';
import React from 'react';

import { useStyles2 } from '@grafana/ui';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { IntegrationCard } from './IntegrationCard';
import { getStyles } from './IntegrationsPanel.styles';
import { externalPaths } from 'feature/common/consts/appPaths';

/* cSpell:disable */
const integrations = [
  {
    description:
      'Fully managed service for monitoring your clusters, pods, and all of your applications and services on top of it.',
    id: 'kubernetes',
    img: 'kubernetesLogo',
    title: 'Kubernetes',
    btnText: 'Start sending data',
    href: externalPaths.monitorKubernetes,
  },
  {
    description:
      'Collect and analyze alerts, escalate based on schedules and deliver them to Slack, Phone Calls, SMS, and others.',
    id: 'oncall',
    img: 'oncallLogo',
    title: 'On Call',
    btnText: 'Set up an on-call rotation',
    href: 'a/grafana-oncall-app/integrations/CKQSCBG3QN6Q9',
  },
  {
    description:
      'Collaborative performance testing, seamless CLI integration, worldwide test execution, and premium result analysis.',
    id: 'k6-app',
    img: 'k6Logo',
    title: 'k6',
    btnText: 'Start testing',
    href: externalPaths.k6App,
  },
];
/* cSpell:enable */

export const IntegrationsPanel = () => {
  const styles = useStyles2(getStyles);

  return (
    <div data-testid={DataTestId.INTEGRATIONS_CONTAINER_ID} className={cx('integrations-panel', styles.wrapper)}>
      <div className={styles.title}>Explore more Grafana Cloud features to level up your Observability</div>
      <ul data-testid={DataTestId.INTEGRATIONS_LIST_ID} className={styles.integrationsList}>
        {integrations.map(({ title, id, img, description, href, btnText }) => (
          <li data-title={title} className={styles.item} key={id}>
            <IntegrationCard description={description} href={href} logo={img} title={title} btnText={btnText} id={id} />
          </li>
        ))}
      </ul>
    </div>
  );
};

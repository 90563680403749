import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';
import { Button, useStyles2 } from '@grafana/ui';
import { getPageStyles } from 'feature/common/components/Page.styles';
import { getSelectAndCreateDashboardStyles } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectAndCreateDashboard.styles';
import { SelectConnectionList } from 'feature/onboarding/components/SelectAndCreateDashboard/SelectConnectionList';
import { useConnectToData } from 'feature/common/hooks/useConnectToData';
import { appPaths } from 'feature/common/consts/appPaths';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { DataToConnect } from 'feature/common/types/DataToConnect';
import { OnboardingGeneralCardData } from 'feature/onboarding/types/OnboardingCard';
import { OnboardingCard } from 'feature/onboarding/components/OnboardingCard';
import { onSearchConnection } from 'feature/onboarding/utils/SearchConnection';
import { DatasourceResponse } from 'feature/common/types/Datasources';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { DatasourceConfigPage } from 'feature/datasource-config/components/DatasourceConfigPage';
import { CUSTOM_CONNECTION_REDIRECT } from 'feature/common/consts/consts';
import {
  getConnectionCards,
  getCustomDatasourceFromConnectionSelected,
  isDatasourceWithCustomPage,
  onContinue,
} from 'feature/onboarding/utils/selectAndCreateDashboardUtils';
import {
  getCloudPlatformCards,
  getCloudPlatformName,
  isCloudPlatformOption,
} from 'feature/onboarding/utils/cloudPlatformUtils';
import { waitForElm } from 'feature/common/utils/misc';
import { AppContext } from 'App';
import { useNavigate } from 'react-router-dom';
import { OnboardingVersion } from 'feature/onboarding/types/OnboardingVersion';
import { useQueryParam } from 'feature/common/hooks/useQueryParam';
import { getEventNameFromPath } from 'feature/onboarding/utils/OnboardingCard';
import {
  CREATE_DASHBOARD_HEADER,
  getSelectAndCreateDashboardTitle,
  TELL_US_WHAT_TO_CONNECT,
} from 'feature/onboarding/consts/consts';
import { redirectToHref } from 'feature/onboarding/utils/sectionNavUtils';

interface SelectAndCreateDashboardProps {
  version?: OnboardingVersion;
}

const TRACK_ORIGIN = 'create-dashboard';

export const SelectAndCreateDashboard: React.FC<SelectAndCreateDashboardProps> = ({ version = 'onboarding' }) => {
  const pageStyles = useStyles2(getPageStyles);
  const styles = useStyles2((theme) => getSelectAndCreateDashboardStyles(theme, version));
  const navigate = useNavigate();
  const { orgId } = useContext(AppContext);
  const { data, isLoading, error } = useConnectToData();
  const [connectionSelected, setConnectionSelected] = useState<DataToConnect>();
  const [optionSelected, setOptionSelected] = useState<string>('');
  const [cloudPlatformOptionSelected, setCloudPlatformOptionSelected] = useState<string>('');
  const [dataSourceSaved, setDataSourceSaved] = useState<DatasourceResponse>();
  const customDatasourceContainerRef = useRef<HTMLDivElement>(null);
  const cloudPlatformContainerRef = useRef<HTMLDivElement>(null);
  const [connectionParam, setConnectionParam] = useState<DataToConnect>();
  const connection = useQueryParam('connection');
  const option = useQueryParam('option');

  const connections = onSearchConnection('', data);

  const getConnectionById = useCallback(
    (id: string): DataToConnect | undefined => {
      return connections?.find((c) => c.id === id);
    },
    [connections]
  );

  const onConnectionSelected = useCallback(
    (connection: DataToConnect) => {
      // remove all possible url parameters
      navigate({ pathname: window.location.pathname, search: '' }, { replace: true });
      if (!!connectionParam) {
        setConnectionParam(undefined);
      }

      if (CUSTOM_CONNECTION_REDIRECT[connection.id]) {
        redirectToHref(CUSTOM_CONNECTION_REDIRECT[connection.id]);
      } else {
        setConnectionSelected(connection);
        setOptionSelected(!connection.children || connection.children.length === 0 ? connection.id : '');

        trackAction(getEventNameFromPath(), connection.trackId, ActionType.click, orgId, {
          origin: TRACK_ORIGIN,
        });
      }
    },
    [orgId, connectionParam, navigate]
  );

  useEffect(() => {
    if (version === 'onboarding') {
      document.title = 'Create your first dashboard - Grafana';
    }
  }, [version]);

  useEffect(() => {
    if (!!connection && !connectionParam) {
      const connectionFound = getConnectionById(connection);
      if (!!connectionFound) {
        setConnectionParam(connectionFound);

        if (CUSTOM_CONNECTION_REDIRECT[connectionFound.id]) {
          redirectToHref(CUSTOM_CONNECTION_REDIRECT[connectionFound.id]);
        } else {
          setConnectionSelected(connectionFound);
          trackAction(getEventNameFromPath(), ComponentKey.SelectConnection, ActionType.click, orgId, {
            origin: TRACK_ORIGIN,
          });
        }

        if (!!option) {
          setOptionSelected(option);
        }
        navigate({ pathname: window.location.pathname, search: '' }, { replace: true });
      }
    }
  }, [connection, option, getConnectionById, connectionParam, navigate, orgId, version]);

  useEffect(() => {
    if (!!error) {
      trackAction(getEventNameFromPath(), ComponentKey.EXTERNAL_API_ERROR, ActionType.click, orgId, {
        message: error.data.message ?? 'Error while retrieving the integrations data',
        origin: TRACK_ORIGIN,
      });
    }
  }, [error, orgId, version]);

  useEffect(() => {
    if (isDatasourceWithCustomPage(connectionSelected, optionSelected)) {
      waitForElm('[aria-label="Datasource settings page basic settings"]').then(() => {
        customDatasourceContainerRef?.current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      });
    }

    if (isCloudPlatformOption(optionSelected) && cloudPlatformContainerRef?.current) {
      cloudPlatformContainerRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, [optionSelected, connectionSelected]);

  function onBackClick(key: string): void {
    trackAction(getEventNameFromPath(), key, ActionType.click, orgId, { origin: TRACK_ORIGIN });
  }

  function onCardSelected(option: string) {
    setOptionSelected(option);
  }

  function onCloudPlatformCardSelected(option: string) {
    setCloudPlatformOptionSelected(option);
  }

  function renderSecondLevelOptions() {
    if (isDatasourceWithCustomPage(connectionSelected, optionSelected)) {
      const customDatasourceId = getCustomDatasourceFromConnectionSelected(optionSelected) as Datasources;
      return (
        <div ref={customDatasourceContainerRef} className={styles.dbConfigContainer}>
          <DatasourceConfigPage
            datasource={customDatasourceId}
            onSaved={setDataSourceSaved}
            version={version === 'onboarding' ? 'select-and-create-dashboard' : 'getting-started'}
          />
        </div>
      );
    } else if (isCloudPlatformOption(optionSelected)) {
      const cloudPlatformCards = getCloudPlatformCards(
        optionSelected,
        cloudPlatformOptionSelected,
        onCloudPlatformCardSelected,
        'page'
      );

      if (Boolean(cloudPlatformCards?.length)) {
        const cloudPlatformSection = (
          <div className={styles.cardWrapper(cloudPlatformCards.length > 3 ? 3 : cloudPlatformCards.length)}>
            {cloudPlatformCards.map((card: OnboardingGeneralCardData) => (
              <OnboardingCard card={card} key={card.key} />
            ))}
          </div>
        );

        return (
          <div ref={cloudPlatformContainerRef}>
            <h3 className={styles.cloudPlatformSubtitle}>
              Select an option to get started with {getCloudPlatformName(optionSelected)}
            </h3>
            {cloudPlatformSection}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function renderOptions() {
    const header = !!connectionSelected?.name ? (
      <div className={styles.headerWrapper}>
        <h3>{getSelectAndCreateDashboardTitle(connectionSelected?.name)}</h3>
      </div>
    ) : null;
    const cardsComponent = (
      <div className={styles.cardWrapper(connectionSelected?.children?.length ?? 1)}>
        {cards.map((card) => (
          <OnboardingCard card={card as OnboardingGeneralCardData} key={card.key} />
        ))}
      </div>
    );

    if (connectionSelected) {
      const hasChildren = connectionSelected.children && connectionSelected.children.length > 0;
      return (
        <>
          {hasChildren && header}
          {cardsComponent}
          {renderSecondLevelOptions()}
        </>
      );
    }
    return null;
  }

  const cards = Boolean(connectionSelected)
    ? getConnectionCards(connectionSelected, optionSelected, onCardSelected)
    : [];
  const hasCustomDataSourcePage = isDatasourceWithCustomPage(connectionSelected, optionSelected);

  return (
    <div className={version !== 'get-started' ? styles.container : undefined}>
      <div className={cx({ [pageStyles.wrapper]: version !== 'get-started' }, styles.wrapper)}>
        {version !== 'get-started' && (
          <div className={styles.topLinksWrapper}>
            <a
              className={styles.topLink}
              href={appPaths.onboarding}
              onClick={() => onBackClick(ComponentKey.BackSelectAndCreateDashboard)}
            >
              Back
            </a>
          </div>
        )}
        <div className={styles.content}>
          {version !== 'get-started' && (
            <div className="header">
              <h1>{CREATE_DASHBOARD_HEADER}</h1>
              <span>{TELL_US_WHAT_TO_CONNECT}</span>
            </div>
          )}
          <div className={styles.selectContent}>
            <SelectConnectionList
              title={'Select'}
              opened={true}
              items={connections}
              loading={isLoading}
              message={error?.data?.message ?? ''}
              itemSelected={connectionParam}
              onSelected={onConnectionSelected}
            />
          </div>
          {Boolean(connectionSelected) && (
            <>
              {renderOptions()}

              <div className={styles.buttonsWrapper}>
                <Button
                  className={styles.continueButton}
                  onClick={() =>
                    onContinue(connectionSelected, optionSelected, cloudPlatformOptionSelected, orgId, dataSourceSaved)
                  }
                  disabled={!Boolean(optionSelected) || (hasCustomDataSourcePage && !dataSourceSaved)}
                  variant={'primary'}
                >
                  {hasCustomDataSourcePage ? 'Create a dashboard' : 'Continue'}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

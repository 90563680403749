export enum IconTypes {
  BRIEFCASE = 'briefcase',
  SHIELD = 'shield',
  USERS = 'users',
  BILL = 'bill',
  BILLING = 'billing',
  DOCUMENTATION = 'document-info',
  SUPPORT = 'support',
  COMMUNITY = 'users-alt',
  QUICKSTARTS = 'quickstarts',
  STAR = 'star',
  HOME = 'home',
}

import React from 'react';

import { useStyles2, LinkButton } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { usePluginComponents } from '@grafana-cloud/ui';

interface CollectorInstallationInstructionsExtensionProps {
  installMode: string;
  showTestConnection: boolean;
  showOSSelection: boolean;
  showTitle: boolean;
}

const colors = {
  blue12: 'rgba(50, 116, 217, .15)',
  dark03: '#424345',
};
const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    padding: ${theme.spacing(3)};
  `,
  blueBackground: css`
    backgroundcolor: ${colors.blue12};
  `,

  box: css`
    marginbottom: ${theme.spacing(2)};

    h5: {
      color: ${theme.isDark ? theme.colors.primary.contrastText : colors.dark03};
      marginbottom: ${theme.spacing(2)};
    }
  `,

  marginTop: css`
    margintop: ${theme.spacing(2)};
  `,

  container: css`
    marginbottom: ${theme.spacing(2)};
    fontsize: ${theme.typography.body.fontSize};
  `,

  link: css`
    color: ${theme.colors.text.link};
  `,
});

export function AlloyInstallStep({}: {}): JSX.Element {
  const styles = useStyles2(getStyles);
  const CollectorInstallationInstructionsExtension =
    usePluginComponents<CollectorInstallationInstructionsExtensionProps>({
      extensionPointId: 'grafana/grafana-collector-app/collector-installation-instructions/v1',
    })?.components?.[0];

  return (
    <div className={styles.wrapper}>
      <h2>Step 1: Install Grafana Alloy</h2>
      <div className={cx(styles.box, styles.blueBackground, styles.marginTop)}>
        <h5>Before you configure this integration, you must set up Grafana Alloy.</h5>

        <div className={styles.container}>
          <p>
            Grafana Alloy is a component-based telemetry collector. It focuses on ease of use, adaptability to your
            needs, and easy debugging. You configure Grafana Alloy with{' '}
            <a
              href="https://grafana.com/docs/alloy/latest/concepts/configuration-syntax/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              Alloy Configuration Syntax
            </a>{' '}
            an HCL-inspired expression language that uses small reusable blocks that work together to form programmable
            pipelines.
          </p>

          <LinkButton
            icon="question-circle"
            href="https://grafana.com/docs/alloy/latest/"
            target="_blank"
            rel="noreferrer"
            fill="text"
          >
            Read more about Grafana Alloy
          </LinkButton>
        </div>
        {CollectorInstallationInstructionsExtension && (
          <CollectorInstallationInstructionsExtension
            showTestConnection={true}
            showOSSelection={true}
            showTitle={true}
            installMode={'alloy'}
          />
        )}
      </div>
    </div>
  );
}

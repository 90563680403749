import { InfluxVersion } from 'feature/common/types/InfluxVersion';
import { UsageResult } from 'feature/usage/types/UsageResult';
import { DatasourceType } from 'feature/common/types/Datasources';

export function isFluxVersion (type: DatasourceType, version: InfluxVersion){
  return type === 'influxdb' && version === InfluxVersion.Flux;
}

export const getResultAsNumber = (result: UsageResult[] | undefined) => {
  if (!result || !result.length) {
    return undefined;
  }
  // traces results comeback as a string from the server
  const value: number =
    typeof result[0]?.value[1] === 'string' ? Number(result[0]?.value[1] as any) : result[0]?.value[1];
  return value;
};

export type DataToConnect = {
  id: string;
  name: string;
  nameHtml?: JSX.Element;
  logo_url: string;
  type: DataToConnectType;
  overview?: string;
  url?: string;
  externalUrl?: string;
  slug?: string;
  version?: string;
  dashboard_folder?: string;
  search_keywords?: string[];
  has_update?: boolean;
  mostPopular: boolean;
  children?: DataToConnect[];
  trackId: string;
};

export enum DataToConnectType {
  SaasIntegration = 'saas',
  HostedData = 'hosted-data',
  AgentIntegration = 'agent',
  CloudApp = 'cloud-app',
  DataSource = 'data-source',
  Alpha = 'alpha',
  Group = 'group',
}

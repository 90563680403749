import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { OnboardingCustomIconName } from 'feature/onboarding/types/OnboardingCard';
import { SetupCustomIconName } from 'feature/onboarding/types/SetupWizard';
import { customIcon } from 'img';
import { getCustomOnboardingIconStyles } from './CustomOnboardingIcon.styles';
import { cx } from '@emotion/css';

export function CustomOnboardingIcon({
  icon,
  position = 'top',
}: {
  icon: OnboardingCustomIconName | SetupCustomIconName;
  position?: 'top' | 'left';
}): JSX.Element {
  const styles = useStyles2(getCustomOnboardingIconStyles);
  return (
    <>
      <img
        className={cx(styles.icon, { [styles.separator]: position === 'top' })}
        src={
          customIcon[
            (icon as unknown as keyof typeof OnboardingCustomIconName) ||
              (icon as unknown as keyof typeof SetupCustomIconName)
          ]
        }
        alt={icon as unknown as string}
      />
    </>
  );
}

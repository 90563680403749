import { DataToConnect, DataToConnectType } from 'feature/common/types/DataToConnect';

export const PROMETHEUS_CONNECTION: DataToConnect = {
  id: 'prometheus-group',
  name: 'Prometheus',
  type: DataToConnectType.Group,
  logo_url: 'public/plugins/cloud-home-app/img/prometheus-color.svg',
  mostPopular: true,
  trackId: 'prometheus-options-connection',
  children: [
    {
      id: 'hmInstancePromId',
      name: 'Prometheus metrics',
      logo_url: 'public/plugins/cloud-home-app/img/prometheus-color.svg',
      overview:
        'Your Grafana Cloud stack includes a massively scalable, high-performance, and highly available Prometheus endpoint. Bring together the raw, unsampled metrics for all your applications and infrastructure, spread around the globe, in one place - with 13-months retention (Pro).',
      type: DataToConnectType.HostedData,
      mostPopular: true,
      externalUrl: '/connections/add-new-connection/hmInstancePromId?from=onboarding',
      trackId: 'hosted-prometheus-connection',
      url: 'https://grafana.com/orgs/layahi/hosted-metrics/509460',
    },
    {
      id: 'datasource-prometheus',
      name: 'Prometheus',
      overview: 'Open source time series database & alerting',
      logo_url: 'public/app/plugins/datasource/prometheus/img/prometheus_logo.svg',
      type: DataToConnectType.DataSource,
      externalUrl: '/connections/datasources/prometheus/',
      mostPopular: false,
      trackId: 'prometheus-datasource-connection',
    },
  ],
};

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'feature/common/consts/styles';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    icon: css`
      height: 16px !important;
      width: 16px !important;
      margin-right: 3px;
      vertical-align: text-bottom;
      @media (min-width: ${breakpoints.panelsContainer}px) and (max-width: 1360px) {
        display: none;
      }
    `,
    links: css`
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(2)};

      @media (max-width: 1050px) {
        justify-content: center;
        gap: ${theme.spacing(2)};
      }
    `,
    link: css`
      min-width: 0;
      color: ${theme.colors.primary.text};
      flex-flow: row nowrap;
      li {
        padding: ${theme.spacing(2)} ${theme.spacing(1)};
        max-width: 100px;
        > span {
          width: 100%;
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${theme.colors.primary.text};
          }
        }
      }

      @media (max-width: 1900px) {
        a {
          padding: ${theme.spacing(2)} 2px;
        }
      }
    `,
    linkIconAndText: css`
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.colors.primary.text};

      @media (max-width: 1900px) {
        a {
          padding: ${theme.spacing(2)} 2px;
          white-space: nowrap;
        }
      }
    `,
    allText: css`
      display: flex;
      flex-direction: column;
      font-size: 12px;
      a {
        ${theme.spacing(0.5)}
      }
    `,
    cardsContainer: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
    `,
    card: css`
      background-color: ${theme.colors.background.secondary};
      border-radius: ${theme.shape.borderRadius(1)};
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: ${theme.spacing(2)};
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: inherit;
      @media (max-width: 1200px) {
        padding: ${theme.spacing(2)};
      }
      div:hover {
        cursor: pointer;
      }
      span {
        overflow-wrap: break-word;
        hyphens: manual;
        white-space: normal;
        line-height: 1.5;
      }
      text-align: left;
      img {
        margin-right: 12px;
        align-self: baseline;
      }
      a {
        text-decoration: none;
      }
    `,
    helpText: css`
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    `,
  };
};

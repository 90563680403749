export interface ABTestingData {
  experimentId: string;
  group?: string;
  version: string;
  experimentData?: any;
}

export interface ABTestingPayload {
  mbox: string;
  activity: string;
  orgId?: number;
  params?: {
    [param: string]: string | number | boolean;
  };
}

export enum ABTestingActivity {
  HOME = 'home',
  GET_STARTED = 'getting-started',
  SELECT_AND_CREATE_DASHBOARD = 'select-create-dashboard',
}

export const OG_ONBOARDING_MBOX = 'og-onboarding-mbox';

import { DataToConnectType } from 'feature/common/types/DataToConnect';
import { topPrioritySourceIds } from 'feature/common/consts/topPriorityConnections';
import { HostedData } from 'feature/common/types/HostedData';
import { cloudAppIcons, hostedIcons } from 'img';
import { externalPaths } from 'feature/common/consts/appPaths';

export const k8Data = {
  id: 'k8s-monitoring-app',
  name: 'Kubernetes Monitoring',
  logo_url: cloudAppIcons['kubernetes'],
  overview: ``,
  type: DataToConnectType.CloudApp,
  externalUrl: '/a/grafana-k8s-app/home',
  mostPopular: topPrioritySourceIds.includes('k8s-monitoring-app'),
  trackId: 'kubernetes-app-connection',
};

export const airflowIntegration = {
  name: 'Apache Airflow',
  slug: 'apache-airflow',
  version: '0.0.1',
  overview:
    'Apache Airflow is an open-source platform for programmatically authoring, scheduling, and monitoring workflows, enabling the orchestration of complex data pipelines and task dependencies. This integration for Grafana Cloud allows users to collect metrics, scheduler logs, and task logs for monitoring an Apache Airflow system.\\n\\nMetrics include DAG (Directed Acyclic Graph) failures, DAG durations, task failures, task durations, scheduler details, executor tasks, and pool task slots for an Apache Airflow system.\\n\\nThis integration supports Apache Airflow versions 2.5.0+.\\n\\n**Links**  \\n[Apache Airflow mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-airflow-mixin)  \\n[Apache Airflow docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-airflow/)  \\n',
  type: DataToConnectType.AgentIntegration,
  search_keywords: ['workflow', 'orchestration', 'task', 'scheduling', 'pipelines', 'apache-airflow'],
  dashboard_folder: 'Integration - Apache Airflow',
  has_update: false,
  id: 'apache-airflow',
  mostPopular: false,
  logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_airflow.png',
  externalUrl: '/connections/connect-data/apache-airflow',
  trackId: 'apache-airflow-connection',
};

export const phlareDatasource = {
  externalUrl: '/connections/datasources/phlare/',
  id: 'datasource-phlare',
  logo_url: 'public/app/plugins/datasource/phlare/img/phlare_icon_color.svg',
  mostPopular: false,
  name: 'Phlare',
  overview:
    'Horizontally-scalable, highly-available, multi-tenant continuous profiling aggregation system. OSS profiling solution from Grafana Labs.',
  type: DataToConnectType.DataSource,
  trackId: 'phlare-datasource-connection',
};

export const awsFirehose = {
  id: 'aws-logs-firehose',
  slug: 'aws-logs-firehose',
  name: 'CloudWatch logs via AWS Firehose',
  logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws-firehose.svg',
  overview: 'THis is an overview',
  type: DataToConnectType.Alpha,
  externalUrl: '/connections/datasources/aws-logs-firehose/',
  mostPopular: false,
  trackId: 'aws-logs-firehose-connection',
};

export const influxDatasource = {
  externalUrl: '/connections/datasources/influxdb/',
  id: 'datasource-influxdb',
  logo_url: 'public/app/plugins/datasource/influxdb/img/influxdb_logo.svg',
  mostPopular: true,
  name: 'InfluxDB',
  overview: 'Open source time series database',
  type: DataToConnectType.DataSource,
  trackId: 'influxdb-datasource-connection',
};

export const hostedPrometheus = {
  id: 'hmInstancePromId',
  name: HostedData.Prometheus,
  logo_url: hostedIcons['prometheus-color'],
  overview: `Your Grafana Cloud stack includes a massively scalable, high-performance, and highly available Prometheus endpoint. Bring together the raw, unsampled metrics for all your applications and infrastructure, spread around the globe, in one place - with 13-months retention (Pro).`,
  type: DataToConnectType.HostedData,
  mostPopular: topPrioritySourceIds.includes('hmInstancePromId'),
  externalUrl: `${externalPaths.integrationsAndConnections}/hmInstancePromId`,
  trackId: 'prometheus-connection',
};

export const INTEGRATIONS = {
  status: 'success',
  data: {
    aerospike: {
      name: 'Aerospike',
      slug: 'aerospike',
      version: '0.0.1',
      overview:
        'Aerospike is a high-performance, NoSQL, key-value database designed for ultra-low-latency and high-throughput applications. It offers fast data storage and retrieval, scalability, and ACID compliance, making it suitable for real-time, mission-critical data processing.\n\nThis integration supports Aerospike versions 6.2.0+\n\n**Links**  \n[Aerospike mixin](https://github.com/grafana/jsonnet-libs/tree/master/aerospike-mixin)  \n[Aerospike docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-aerospike/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aerospike.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aerospike.png',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'aerospike'],
      dashboard_folder: 'Integration - Aerospike',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-aerospike',
    },
    'apache-activemq': {
      name: 'Apache ActiveMQ',
      slug: 'apache-activemq',
      version: '1.0.0',
      overview:
        'Apache ActiveMQ is an open-source message broker software that facilitates the communication between different applications or components using messaging patterns. Message brokers like ActiveMQ play a crucial role in distributed systems by enabling asynchronous communication and decoupling producers and consumers of messages. They serve as intermediaries that receive, store, and route messages between different components, applications, or services. The integration with Grafana Cloud enables users to oversee an ActiveMQ environment using five distinct dashboards. These dashboards display metrics and logs for ActiveMQ clusters, instances, topics, and queue components.\n\nThis integration supports Apache ActiveMQ version 5.x+  \nThis integration supports the JMX exporter 0.17.0+\n\n**Links**  \n[Apache ActiveMQ mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-activemq-mixin)  \n[Apache ActiveMQ docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-activemq/)  \n[JMX Exporter](https://github.com/prometheus/jmx_exporter)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_activemq.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_activemq.png',
      },
      type: 'agent',
      search_keywords: ['MQ', 'Queue', 'Message queue', 'Topic', 'Pubsub', 'apache-activemq'],
      dashboard_folder: 'Integration - Apache ActiveMQ',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-activemq',
    },
    'apache-airflow': {
      name: 'Apache Airflow',
      slug: 'apache-airflow',
      version: '0.0.4',
      overview:
        'Apache Airflow is an open-source platform for programmatically authoring, scheduling, and monitoring workflows, enabling the orchestration of complex data pipelines and task dependencies. This integration for Grafana Cloud allows users to collect metrics, scheduler logs, and task logs for monitoring an Apache Airflow system.\n\nMetrics include DAG (Directed Acyclic Graph) failures, DAG durations, task failures, task durations, scheduler details, executor tasks, and pool task slots for an Apache Airflow system.\n\nThis integration supports Apache Airflow versions 2.5.0+.\n\n**Links**  \n[Apache Airflow mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-airflow-mixin)  \n[Apache Airflow docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-airflow/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_airflow.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_airflow.png',
      },
      type: 'agent',
      search_keywords: ['workflow', 'orchestration', 'task', 'scheduling', 'pipelines', 'apache-airflow'],
      dashboard_folder: 'Integration - Apache Airflow',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-airflow',
    },
    'apache-cassandra': {
      name: 'Apache Cassandra',
      slug: 'apache-cassandra',
      version: '1.0.0',
      overview:
        'Apache Cassandra is an open source NoSQL distributed database. This integration for Grafana Cloud allows users to collect metrics and system logs for monitoring an Apache Cassandra instance or clustered deployment.\n\nMetrics include number of nodes in a cluster, virtual memory and cpu usage, read/write latencies, compaction tasks, and garbage collections. It includes useful visualizations for cluster, node, and keyspace metrics.\n\n**Links**  \n[Apache Cassandra mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-cassandra-mixin)  \n[Apache Cassandra docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-cassandra/)  \n[JMX Exporter](https://github.com/prometheus/jmx_exporter)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cassandra.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cassandra.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'apache-cassandra'],
      dashboard_folder: 'Integration - Apache Cassandra',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-cassandra',
    },
    'apache-couchdb': {
      name: 'Apache CouchDB',
      slug: 'apache-couchdb',
      version: '0.0.3',
      overview:
        'Apache CouchDB is a NoSQL document-oriented database system known for its scalability, availability, and easy replication of data across multiple servers. This integration for Grafana Cloud allows users to collect metrics and system logs for monitoring an Apache CouchDB instance or clustered deployment. This integration also includes useful visualizations for both cluster and node metrics such as open databases, database writes/reads, request latency, request rates, response statuses, and replicator failure info.\n\nThis integration supports Apache CouchDB versions 3.2.x+.\n\n**Links**  \n[Apache CouchDB mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-couchdb-mixin)  \n[Apache CouchDB docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-couchdb)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_couchdb.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_couchdb.png',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'apache-couchdb'],
      dashboard_folder: 'Integration - Apache CouchDB',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-couchdb',
    },
    'apache-hadoop': {
      name: 'Apache Hadoop',
      slug: 'apache-hadoop',
      version: '0.0.3',
      overview:
        'Apache Hadoop is an open-source framework designed to process and store large amounts of data across distributed clusters of computers. It provides a scalable and fault-tolerant solution for handling big data by utilizing a distributed file system (Hadoop Distributed File System) and a parallel processing model (MapReduce). This integration for Grafana Cloud allows users to monitor a Hadoop cluster with 4 separate dashboards for visualizing metrics and logs for a Hadoop NameNode, DataNode, NodeManager, and ResourceManager component.\n\nThis integration supports Apache Hadoop version 3.3.1+  \nApache Hadoop 3.3 and upper supports Java 8 and Java 11 (runtime only)  \nThis integration supports the JMX exporter 0.17.0+  \n\n**Links**  \n[Apache Hadoop mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-hadoop-mixin)  \n[Apache Hadoop docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-hadoop/)  \n[JMX Exporter](https://github.com/prometheus/jmx_exporter)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/hadoop-logo.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/hadoop-logo.png',
      },
      type: 'agent',
      search_keywords: [
        'hadoop',
        'hdfs',
        'namenode',
        'datanode',
        'nodemanager',
        'resourcemanager',
        'yarn',
        'apache-hadoop',
      ],
      dashboard_folder: 'Integration - Apache Hadoop',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-hadoop',
    },
    'apache-hbase': {
      name: 'Apache HBase',
      slug: 'apache-hbase',
      version: '1.0.0',
      overview:
        "Apache HBase is an open-source non-relational distributed database modeled after Google's Bigtable and written in Java. It is developed as part of Apache Software Foundation's Apache Hadoop project and runs on top of HDFS or Alluxio, providing Bigtable-like capabilities for Hadoop.\n\nThis integration supports Apache HBase versions 3.0.0+\n\n**Links**  \n[Apache HBase mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-hbase-mixin)  \n[Apache HBase docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-apache-hbase/)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_hbase.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache_hbase.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'bigdata', 'apache-hbase'],
      dashboard_folder: 'Integration - Apache HBase',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-hbase',
    },
    'apache-http': {
      name: 'Apache HTTP server',
      slug: 'apache-http',
      version: '0.0.3',
      overview:
        'Apache HTTP server integration is an observability package of dashboards and alerts filled with metrics and logs.\n\n**Links**  \n[Apache HTTP server mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-http-mixin)  \n[Apache HTTP server docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-http/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache.svg',
      },
      type: 'agent',
      search_keywords: ['server', 'web server', 'http', 'apache-http'],
      dashboard_folder: 'Integration - Apache HTTP',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-http',
    },
    'apache-mesos': {
      name: 'Apache Mesos',
      slug: 'apache-mesos',
      version: '0.0.3',
      overview:
        'Apache Mesos is an open-source distributed systems kernel that acts as a platform for managing and orchestrating computing resources across clusters of machines. Apache Mesos integration for Grafana cloud allows users to monitor masters events queue, registrar state, allocator usage as well as disk, memory and logs for both the master and agent targets in an overview dashboard.\n\nThis integration supports Apache Mesos 1.4.x+  \nThis integration supports the [Prometheus Mesos Exporter](https://github.com/mesos/mesos_exporter) v1.1.2\n\n**Links**  \n[Apache Mesos mixin](https://github.com/grafana/jsonnet-libs/tree/master/apache-mesos-mixin)  \n[Apache Mesos docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apache-mesos/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache-mesos-dark.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache-mesos-light.png',
      },
      type: 'agent',
      search_keywords: ['distributed systems', 'cluster computing', 'scheduler', 'resource management', 'apache-mesos'],
      dashboard_folder: 'Integration - Apache Mesos',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apache-mesos',
    },
    'apollo-server': {
      name: 'Apollo Server',
      slug: 'apollo-server',
      version: '0.0.5',
      overview:
        "Apollo Server is an open-source, spec-compliant GraphQL server that's compatible with any GraphQL client, including Apollo Client. It's the best way to build a production-ready, self-documenting GraphQL API that can use data from any source. This integration enables the agent to send metrics to Grafana Cloud along with a useful default dashboard for visualization.\n\n**Links**  \n[Apollo Server mixin](https://github.com/bfmatei/apollo-prometheus-exporter/tree/main/apollo-server-mixin)  \n[Apollo Server docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-apollo-server/)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apollo.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apollo.svg',
      },
      type: 'agent',
      search_keywords: ['graphql', 'node', 'react', 'javascript', 'apollo-server'],
      dashboard_folder: 'Integration - Apollo Server',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-apollo-server',
    },
    asterisk: {
      name: 'Asterisk',
      slug: 'asterisk',
      version: '0.0.5',
      overview:
        'Asterisk is a free and open source framework for building communications applications and is sponsored by Sangoma. Asterisk enables the creation of communication applications such as PBX (private branch exchange), VoIP (Voice over Internet Protocol), and conference servers. The Asterisk integration can monitor useful metrics and logs of an asterisk instance.\n\n**Links**  \n[Asterisk mixin](https://github.com/grafana/jsonnet-libs/tree/master/asterisk-mixin)  \n[Asterisk docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-asterisk/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/asterisk.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/asterisk.png',
      },
      type: 'agent',
      search_keywords: ['voip', 'pbx', 'communication', 'asterisk'],
      dashboard_folder: 'Integration - Asterisk',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-asterisk',
    },
    awx: {
      name: 'AWX',
      slug: 'awx',
      version: '0.0.3',
      overview:
        'AWX provides a web-based user interface, REST API, and task engine built on top of Ansible. It is one of the upstream projects for Red Hat Ansible Automation Platform.\n\n**Links**  \n[AWX mixin](https://github.com/grafana/jsonnet-libs/tree/master/awx-mixin)  \n[AWX docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-awx/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/awx.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/awx.svg',
      },
      type: 'agent',
      search_keywords: ['automation', 'redhat', 'ansible', 'awx'],
      dashboard_folder: 'Integration - AWX',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'azure-event-hubs': {
      name: 'Azure Event Hubs',
      slug: 'azure-event-hubs',
      version: '0.0.2',
      overview:
        'Move your messages from the Azure Event Hubs to Grafana Cloud.\n\n**Links**  \n[Azure Event Hubs Promtail Reference](https://grafana.com/docs/loki/latest/clients/promtail/configuration/#azure-event-hubs)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/azure-event-hubs-logo.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/azure-event-hubs-logo.png',
      },
      type: 'agent',
      search_keywords: ['logs', 'event', 'azure', 'microsoft', 'azure-event-hubs'],
      dashboard_folder: 'Integration - Azure Event Hubs',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'azure-metrics': {
      name: 'Azure Metrics',
      slug: 'azure-metrics',
      version: '0.0.1',
      overview:
        "[Azure](https://azure.microsoft.com/en-us) is Microsoft's cloud computing services offering.\n\nAlongside with their compute offering, Azure provides services such as databases, with it's well known [Cosmos DB](https://learn.microsoft.com/en-us/azure/cosmos-db/), storage with [Blob Storage](https://learn.microsoft.com/en-us/azure/storage/blobs/), serverless compute with [Azure Functions](https://learn.microsoft.com/en-us/azure/azure-functions/), identity with [Azure Active Directory](https://learn.microsoft.com/en-us/azure/active-directory/) and many others.\n\nThe Azure Metrics integration enabled you to send metrics from your Azure projects to Grafana Cloud.\n\n**Links**  \n[Azure Monitor](https://azure.microsoft.com/en-us/products/monitor)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/azure-metrics.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/azure-metrics.png',
      },
      type: 'agent',
      search_keywords: ['ad', 'aks', 'azure', 'cosmos', 'microsoft', 'windows', 'azure-metrics'],
      dashboard_folder: 'Integration - Azure Metrics',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    caddy: {
      name: 'Caddy',
      slug: 'caddy',
      version: '0.0.4',
      overview:
        'The Caddy web server is an open-source web server written in Go. \nIt uses the Go standard library for its HTTP functionality and supports HTTPS natively.\n\n**Links**  \n[Caddy mixin](https://github.com/grafana/jsonnet-libs/tree/master/caddy-mixin)  \n[Caddy docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-caddy/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/caddy.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/caddy.svg',
      },
      type: 'agent',
      search_keywords: ['server', 'web server', 'http', 'go', 'go lang', 'caddy'],
      dashboard_folder: 'Integration - Caddy',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    ceph: {
      name: 'Ceph',
      slug: 'ceph',
      version: '0.0.6',
      overview:
        'Ceph uniquely delivers object, block, and file storage in one unified system.\n\n**Links**  \n[Ceph mixin](https://github.com/ceph/ceph-mixins)  \n[Ceph docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-ceph/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ceph.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ceph.svg',
      },
      type: 'agent',
      search_keywords: ['storage', 'database', 'ceph'],
      dashboard_folder: 'Integration - Ceph',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-ceph',
    },
    'cert-manager': {
      name: 'Cert Manager',
      slug: 'cert-manager',
      version: '0.0.5',
      overview:
        'cert-manager is a native [Kubernetes](https://kubernetes.io/) certificate management controller. It can help with issuing certificates from a variety of sources, such as Let’s Encrypt, HashiCorp Vault, Venafi, a simple signing key pair, or self signed.\n\n**Links**  \n[Cert Manager mixin](https://github.com/grafana/jsonnet-libs/tree/master/cert-manager)  \n[Cert Manager docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-cert-manager/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cert-manager.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cert-manager.png',
      },
      type: 'agent',
      search_keywords: ['certificate', 'encrypt', 'hashicorp', 'venafi', 'dns', 'cert-manager'],
      dashboard_folder: 'Integration - Cert Manager',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-cert-manager',
    },
    'cilium-enterprise': {
      name: 'Cilium Enterprise',
      slug: 'cilium-enterprise',
      version: '0.0.3',
      overview:
        'The Cilium Enterprise integration uses the Grafana Agent to collect metrics exposed by the Cilium Operator, Cilium Agent and its components, as well as Hubble. \nA series of dashboards have been provided, both for overviews and per-component basis.\n**Links**  \n[Cilium Enterprise mixin](https://github.com/grafana/jsonnet-libs/tree/master/cilium-enterprise-mixin)  \n[Cilium Enterprise docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-cilium-enterprise)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cilium-solo.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cilium-solo.svg',
      },
      type: 'agent',
      search_keywords: [
        'service mesh',
        'discovery',
        'load balancer',
        'cluster',
        'BGP',
        'hubble',
        'timescape',
        'cilium-enterprise',
      ],
      dashboard_folder: 'Integration - Cilium Enterprise',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-cilium-enterprise',
    },
    clickhouse: {
      name: 'ClickHouse',
      slug: 'clickhouse',
      version: '1.0.0',
      overview:
        'ClickHouse is an open-source column-oriented database management system that allows generating analytical data reports in real-time. The ClickHouse integration uses the Grafana agent to collect metrics to monitor a ClickHouse cluster that can be used to easily select metrics via cluster instance. The integration also supports the ClickHouse error logs being scraped by the agent using Promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\nThis integration supports ClickHouse 22.6.8.35+.\n\n**Links**  \n[ClickHouse mixin](https://github.com/grafana/jsonnet-libs/tree/master/clickhouse-mixin)  \n[ClickHouse docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-clickhouse)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/clickhouse-logo.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/clickhouse-logo.png',
      },
      type: 'agent',
      search_keywords: ['database management', 'ETL', 'sql', 'clickhouse'],
      dashboard_folder: 'Integration - ClickHouse',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-clickhouse',
    },
    cloudflare: {
      name: 'Cloudflare',
      slug: 'cloudflare',
      version: '1.0.0',
      overview:
        'Cloudflare is a globally distributed network infrastructure and cybersecurity company that provides content delivery, DDoS mitigation, internet security, and other related services to enhance the performance and security of websites and internet applications. This integration for Grafana Cloud allows users to collect and analyze metrics for `Cloudflare Analytics - account and zone analytics`, a description of which is found [here](https://developers.cloudflare.com/analytics/account-and-zone-analytics/).\n\nMetrics include requests, bandwidth, CPU utilization, HTTP response codes, pool health, and colocation information. These are used to provide valuable visualizations for zones, workers, and pools.\n\nThis integration requires `Pro Plan` or better Cloudflare subscriptions.\n\n**Links**  \n[Cloudflare mixin](https://github.com/grafana/jsonnet-libs/tree/master/cloudflare-mixin)  \n[Cloudflare docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-cloudflare/) \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cloudflare-logo.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cloudflare-logo.png',
      },
      type: 'agent',
      search_keywords: ['cloudflare', 'cloud', 'cloudflare'],
      dashboard_folder: 'Integration - Cloudflare',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-cloudflare',
    },
    cockroachdb: {
      name: 'CockroachDB',
      slug: 'cockroachdb',
      version: '0.0.6',
      overview:
        'CockroachDB is a cloud-native SQL database for building global, scalable cloud services that survive disasters.\n\n**Links**   \n[CockroachDB mixin](https://github.com/metalmatze/kube-cockroachdb/tree/main/monitoring)  \n[CockroachDB docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-cockroachdb)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cockroachdb.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/cockroachdb.png',
      },
      type: 'agent',
      search_keywords: ['sql', 'database', 'cockroachdb'],
      dashboard_folder: 'Integration - CockroachDB',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-cockroachdb',
    },
    'confluent-cloud': {
      name: 'Confluent Cloud',
      slug: 'confluent-cloud',
      version: '0.0.5',
      overview:
        'The Confluent Cloud integration allows you to scrape Confluent metrics without installing the Grafana agent. You can create and configure multiple scrape jobs to organize your resources. For more information, see the [Confluent Cloud docs](https://docs.confluent.io/cloud/current/overview.html).\n\n**Links**  \n[Confluent Cloud mixin](https://github.com/grafana/jsonnet-libs/tree/master/confluent-kafka-mixin)  \n[Confluent Cloud docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-confluent-cloud/)  \n\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/confluent-cloud.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/confluent-cloud.png',
      },
      type: 'saas',
      search_keywords: ['kafka', 'apache', 'ksqldb', 's3', 'confluent-cloud'],
      dashboard_folder: 'Integration - Confluent Cloud',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    consul: {
      name: 'Consul',
      slug: 'consul',
      version: '0.0.5',
      overview:
        '[Consul](https://developer.hashicorp.com/consul/docs/intro) is a service mesh solution providing a full featured control plane with service discovery, configuration, and segmentation functionality.\n\nConsul is split into a [client-server architecture](https://developer.hashicorp.com/consul/docs/architecture) by which servers provide a control plane into the cluster of clients, and clients form a mostly stateless data plane alongside the sidecar proxy on each node running your applications and services.\n\nSince [Consul provides first-class support for Envoy](https://developer.hashicorp.com/consul/docs/connect/proxies/envoy) as a sidecar proxy, it is strongly recommended that you also install the [Grafana integration for Envoy](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-envoy/), assuming that you are using it.\n\n\n**Links**  \n[Consul mixin](https://github.com/grafana/jsonnet-libs/tree/master/consul-mixin)  \n[Consul docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-consul/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/consul.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/consul.png',
      },
      type: 'agent',
      search_keywords: ['hashicorp', 'networking', 'cloud', 'service', 'registry', 'consul'],
      dashboard_folder: 'Integration - Consul',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-consul',
    },
    coredns: {
      name: 'CoreDNS',
      slug: 'coredns',
      version: '0.0.7',
      overview:
        'CoreDNS is a fast and efficient DNS server written in Go, that chains plugins, where each plugin performs a (DNS) function.\n\n**Links**  \n[CoreDNS mixin](https://github.com/povilasv/coredns-mixin)  \n[CoreDNS docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-coredns)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/coredns.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/coredns.svg',
      },
      type: 'agent',
      search_keywords: ['dns', 'coredns'],
      dashboard_folder: 'Integration - CoreDNS',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-coredns',
    },
    couchbase: {
      name: 'Couchbase',
      slug: 'couchbase',
      version: '0.0.3',
      overview:
        'Couchbase is a distributed NoSQL database which provides high availability, scalability, and performance for large sets of data. This integration for Grafana Cloud allows users to collect metrics and logs from the cluster, node, and bucket levels of a Couchbase clustered deployment.\n\nThis integration supports Couchbase 7.0+\n\n**Links**  \n[Couchbase mixin](https://github.com/grafana/jsonnet-libs/tree/master/couchbase-mixin)  \n[Couchbase docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-couchbase/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/couchbase.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/couchbase.png',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'couchbase'],
      dashboard_folder: 'Integration - Couchbase',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-couchbase',
    },
    discourse: {
      name: 'Discourse',
      slug: 'discourse',
      version: '0.0.2',
      overview:
        'Discourse is an application that is used to host a discussion platform. The Discourse integration uses the Grafana Agent to collect metrics from a Discourse instance like HTTP traffic, request rates, latency, sidekiq jobs, and browser heap usage.\n\nThis integration supports Discourse 2.8+\n\n**Links**  \n[Discourse mixin](https://github.com/grafana/jsonnet-libs/tree/master/discourse-mixin)  \n[Discourse docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-discourse/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/discourse.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/discourse.svg',
      },
      type: 'agent',
      search_keywords: ['forum', 'social', 'community', 'open-source', 'discourse'],
      dashboard_folder: 'Integration - Discourse',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-discourse',
    },
    dnsmasq: {
      name: 'dnsmasq',
      slug: 'dnsmasq',
      version: '0.0.4',
      overview:
        'dnsmasq is a network infrastructure provider for small networks. It provides DNS, DHCP, router advertisement, and more. This integration enables the agent to send metrics to Grafana Cloud along with a useful default dashboard for visualization.\n\n**Links**  \n[dnsmasq mixin](https://github.com/google/dnsmasq_exporter/tree/main/dnsmasq-mixin)  \n[dnsmasq docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-dnsmasq/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/dnsmasq.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/dnsmasq.png',
      },
      type: 'agent',
      search_keywords: ['dns', 'network', 'dhcp', 'router', 'dnsmasq'],
      dashboard_folder: 'Integration - dnsmasq',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    docker: {
      name: 'Docker',
      slug: 'docker',
      version: '0.1.0',
      overview:
        'Docker is a popular open-source platform that enables developers to create, deploy, and run applications in a virtualized environment called a container. It allows developers to package an application with all its dependencies, libraries, and other components required to run it, into a single container image. The Docker integration collects metrics and logs from a Docker instance and provides useful pre-built dashboards to monitor them. \n\n**Links**  \n[Docker mixin](https://github.com/grafana/jsonnet-libs/tree/master/docker-mixin)  \n[Docker docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-docker/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/docker.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/docker.png',
      },
      type: 'agent',
      search_keywords: ['container', 'deployment', 'docker'],
      dashboard_folder: 'Integration - Docker',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'docker-desktop': {
      name: 'Docker Desktop',
      slug: 'docker-desktop',
      version: '1.0.0',
      overview:
        'Docker Desktop is an easy-to-install application for your Mac, Windows or Linux environment that enables you to build and share containerized applications and microservices.\nThis integration allows you to monitor your local Docker Desktop instance using the Grafana Cloud extension in Docker Desktop.The integration includes dashboard to monitor Docker metrics and logs as well as the Linux host metrics and logs.\n\n**Links**  \n[Docker Desktop mixin](https://github.com/grafana/jsonnet-libs/tree/master/docker-mixin)  \n[Docker Desktop docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-docker-desktop/)  \n[Linux Server mixin](https://github.com/prometheus/node_exporter/tree/master/docs/node-mixin)  \n[Docker Desktop extension marketplace](https://hub.docker.com/search?q=\u0026type=extension)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/docker-desktop.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/docker-desktop.png',
      },
      type: 'agent',
      search_keywords: ['container', 'deployment', 'docker-desktop'],
      dashboard_folder: 'Integration - Docker Desktop',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    elasticsearch: {
      name: 'ElasticSearch',
      slug: 'elasticsearch',
      version: '0.0.3',
      overview:
        'Elasticsearch is a search engine based on the Lucene library.\nIt provides a distributed, multitenant-capable full-text search engine with an HTTP web interface and schema-free JSON documents.\n\nThis integration enables the agent to send metrics to Grafana Cloud along with a useful pre-built dashboard for visualization.\n\n**Links**  \n[ElasticSearch mixin](https://github.com/grafana/jsonnet-libs/tree/master/elasticsearch-mixin)  \n[ElasticSearch docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-elasticsearch/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/elasticsearch.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/elasticsearch.svg',
      },
      type: 'agent',
      search_keywords: ['lucene', 'elk', 'logstash', 'kibana', 'elasticsearch'],
      dashboard_folder: 'Integration - ElasticSearch',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    envoy: {
      name: 'Envoy',
      slug: 'envoy',
      version: '0.0.5',
      overview:
        'Envoy is an L7 proxy and communication bus designed for large modern service oriented architectures.\n\n**Links**  \n[Envoy mixin](https://github.com/grafana/jsonnet-libs/tree/master/envoy-mixin)  \n[Envoy docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-envoy/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/envoy.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/envoy.png',
      },
      type: 'agent',
      search_keywords: ['proxy', 'sidecar', 'load balancer', 'service mesh', 'envoy'],
      dashboard_folder: 'Integration - Envoy',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    etcd: {
      name: 'etcd',
      slug: 'etcd',
      version: '0.0.6',
      overview:
        'etcd is a distributed key-value store that is designed for distributed systems. This integration enables the agent to send metrics to Grafana Cloud along with a useful pre-built dashboard for visualization.\n\n**Links**  \n[etcd mixin](https://github.com/etcd-io/etcd/tree/main/contrib/mixin)  \n[etcd docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-etcd/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/etcd.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/etcd.png',
      },
      type: 'agent',
      search_keywords: ['key-value', 'kubernetes', 'etcd'],
      dashboard_folder: 'Integration - etcd',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-etcd',
    },
    'f5-bigip': {
      name: 'F5 BIG-IP',
      slug: 'f5-bigip',
      version: '1.0.0',
      overview:
        'F5 BIG-IP is a multifaceted application delivery controller (ADC) that offers load balancing, traffic management, security, and performance optimization for business-critical applications. Deployed as hardware, virtual, or cloud instances, it provides essential scalability and resilience, ensuring uninterrupted and secure access to applications across diverse environments. This integration for Grafana cloud allows users to monitor a BIG-IP cluster with 5 separate dashboards for visualizing metrics and logs for a BIG-IP cluster, node, pool, virtual server, and logs overview dashboards.\n\nThis integration supports BIG-IP version 16.1.2+\n\n**Links**  \n[F5 BIG-IP mixin](https://github.com/grafana/jsonnet-libs/tree/master/f5-bigip-mixin)  \n[F5 BIG-IP docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-f5-bigip/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/f5-bigip-logo-dark.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/f5-bigip-logo-light.png',
      },
      type: 'agent',
      search_keywords: ['F5', 'BIG-IP', 'load balancing', 'traffic management', 'security', 'f5-bigip'],
      dashboard_folder: 'Integration - F5 BIG-IP',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-f5-bigip',
    },
    'gcp-logs': {
      name: 'GCP Logs',
      slug: 'gcp-logs',
      version: '0.0.2',
      overview:
        'Move your logs from GCP to Grafana Cloud using GCP Pub/Sub and the Grafana Agent\n\nAre you interested in a version of this integration which allows you to skip setting up the agent? Consider filling out our form to be contacted in the future for [Beta Access](https://forms.gle/KTqe9JeR33bKNdiH8).\n\n**Links**  \n[GCP Logs Promtail Reference](https://grafana.com/docs/loki/latest/clients/promtail/configuration/#gcp-log)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gcp-logs-logo.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gcp-logs-logo.png',
      },
      type: 'agent',
      search_keywords: ['google', 'cloud', 'observability', 'monitoring', 'gcp-logs'],
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'gcp-metrics': {
      name: 'GCP Metrics',
      slug: 'gcp-metrics',
      version: '0.0.1',
      overview:
        'Google Cloud Platform (GCP), offered by Google, is a suite of cloud computing services that runs on the same\ninfrastructure that Google uses internally for its end-user products, such as Google Search, Gmail, Google Drive, and\nYouTube. Alongside a set of management tools, it provides a series of modular cloud services including computing, data\nstorage, data analytics and machine learning.\n\nThe GCP Metrics integration enables you to send metrics from your GCP projects to Grafana Cloud.\n\n**Links**  \n[GCP](https://cloud.google.com/)  \n[GCP Metrics](https://cloud.google.com/products/operations)\n[Stackdriver Exporter](https://github.com/prometheus-community/stackdriver_exporter)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gcp-metrics.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gcp-metrics.png',
      },
      type: 'agent',
      search_keywords: ['GCP', 'google', 'stackdriver', 'gcp-metrics'],
      dashboard_folder: 'Integration - GCP Metrics',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    gitea: {
      name: 'Gitea',
      slug: 'gitea',
      version: '0.0.4',
      overview:
        "Gitea is an open-source forge software package for hosting software development version control using Git as well as other collaborative features like bug tracking, wikis and code review.\n\nThis integration provides the dashboard with metrics exposed by Gitea, including application's key stats as well as CPU, memory, file descriptors utilization.\n\n**Links**  \n[Gitea mixin](https://github.com/v-zhuravlev/gitea/tree/main/contrib/gitea-monitoring-mixin)  \n[Gitea docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-gitea/)\n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gitea.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gitea.svg',
      },
      type: 'agent',
      search_keywords: ['git', 'collaborative', 'wiki', 'gitea'],
      dashboard_folder: 'Integration - Gitea',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    github: {
      name: 'GitHub',
      slug: 'github',
      version: '0.0.5',
      overview:
        'GitHub is a provider of Internet hosting for software development and version control using Git.\n\n**Links**  \n[GitHub mixin](https://github.com/infinityworks/github-exporter/pull/70)  \n[GitHub docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-github/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/github.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/github.png',
      },
      type: 'agent',
      search_keywords: ['git', 'repo', 'version control', 'github'],
      dashboard_folder: 'Integration - Github',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    gitlab: {
      name: 'GitLab',
      slug: 'gitlab',
      version: '1.0.0',
      overview:
        'GitLab is an open-source DevOps platform for software development. The GitLab integration uses the Grafana agent to collect metrics for monitoring a GitLab EE (Enterprise Edition) instance, including aspects such as HTTP request rates, HTTP request latencies, and CI pipeline creation rates.\nThe integration also supports the rails error logs being scraped by the agent using Promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\nThis integration supports GitLab EE 15.3.3+.\n\n**Links**  \n[GitLab mixin](https://github.com/grafana/jsonnet-libs/tree/master/gitlab-mixin)  \n[GitLab docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-gitlab/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gitlab.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/gitlab.svg',
      },
      type: 'agent',
      search_keywords: ['vcs', 'git', 'gitlab'],
      dashboard_folder: 'Integration - GitLab',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-gitlab',
    },
    golang: {
      name: 'Go',
      slug: 'golang',
      version: '0.0.6',
      overview:
        'The Go programming language (golang) is a programming language designed at Google to make programmers more productive in applications with strong networking concurrency needs. This integration enables the agent to send metrics to Grafana Cloud along with a useful default dashboard for visualization.\n\n**Links**  \n[Go mixin](https://github.com/grafana/jsonnet-libs/tree/master/go-runtime-mixin)  \n[Go docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-golang/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/golang.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/golang.png',
      },
      type: 'agent',
      search_keywords: ['google', 'backend', 'golang'],
      dashboard_folder: 'Integration - Go',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'grafana-agent': {
      name: 'Grafana Agent health',
      slug: 'grafana-agent',
      version: '0.0.6',
      overview:
        'The Grafana agent integration lets you monitor metrics and logs of a Grafana agent instance\n\n**Links**  \n[Grafana Agent health mixin](https://github.com/grafana/jsonnet-libs/tree/master/grafana-agent-mixin)  \n[Grafana Agent health docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-grafana-agent/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/grafana-agent.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/grafana-agent.png',
      },
      type: 'agent',
      installation: {
        version: '0.0.1',
        installed_on: '2023-04-05T10:34:56Z',
      },
      search_keywords: ['agent', 'grafana-agent'],
      dashboard_folder: 'Integration - Grafana Agent',
      has_update: true,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-grafana-agent',
    },
    haproxy: {
      name: 'HAProxy',
      slug: 'haproxy',
      version: '0.0.5',
      overview:
        "HAProxy is a free, very fast and reliable solution offering high availability, load balancing, and proxying for TCP and HTTP-based applications. It is particularly suited for very high traffic web sites and powers quite a number of the world's most visited ones. Over the years it has become the de-facto standard opensource load balancer, is now shipped with most mainstream Linux distributions, and is often deployed by default in cloud platforms. This integration allows you to collect metrics from the HAProxy instance and visualize them through pre-built dashboard in Grafana cloud.\n\n**Links**  \n[HAProxy mixin](https://github.com/jdbaldry/haproxy-mixin)  \n[HAProxy docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-haproxy/)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/haproxy.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/haproxy.png',
      },
      type: 'agent',
      search_keywords: ['load balancer', 'proxy', 'tcp', 'http', 'haproxy'],
      dashboard_folder: 'Integration - HAProxy',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-haproxy',
    },
    hass: {
      name: 'Home Assistant',
      slug: 'hass',
      version: '0.0.4',
      overview:
        'Home Assistant is an open-source software platform designed for home automation, which enables users to control and monitor various devices and services in their home through a single, user-friendly interface. Home Assistant can run on a variety of devices, including Raspberry Pi, and can integrate with other popular home automation platforms like Amazon Alexa and Google Assistant. This integration connects your Home Assistant instance to Grafana cloud to visualize metrics through a pre-built dashboard.\n\n**Links**  \n[Home Assistant mixin](https://github.com/grafana/jsonnet-libs/tree/master/hass-mixin)  \n[Home Assistant docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure//integrations/integration-reference/integration-hass/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/hass.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/hass.svg',
      },
      type: 'agent',
      search_keywords: ['temperatures', 'humidty', 'automation', 'iot', 'hass'],
      dashboard_folder: 'Integration - Home Assistant',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'hcp-consul': {
      name: 'HCP Consul',
      slug: 'hcp-consul',
      version: '0.0.2',
      overview:
        '[Consul](https://developer.hashicorp.com/consul/docs/intro) is a service mesh solution providing a full featured control plane with service discovery, configuration, and segmentation functionality.\n\nConsul is split into a [client-server architecture](https://developer.hashicorp.com/consul/docs/architecture) by which servers provide a control plane into the cluster of clients, and clients form a mostly stateless data plane alongside the sidecar proxy on each node running your applications and services.\n\nHCP Consul specifically is a service provided by Hashicorp, by which they run and provide management software for your Consul cluster servers and related infrastructure. On your own infrastructure, you are required to only deploy and manage network peering, and the client and sidecar instances.\n\nSince [Consul provides first-class support for Envoy](https://developer.hashicorp.com/consul/docs/connect/proxies/envoy) as a sidecar proxy, it is strongly recommended that you also install the [Grafana integration for Envoy](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-envoy/), assuming that you are using it.\n\n\n**Links**  \n[HCP Consul mixin](https://github.com/grafana/jsonnet-libs/tree/master/consul-mixin)  \n[HCP Consul docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-hcp-consul/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/consul.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/consul.png',
      },
      type: 'agent',
      search_keywords: ['hashicorp', 'networking', 'cloud', 'service', 'registry', 'hcp', 'hcp-consul'],
      dashboard_folder: 'Integration - HCP Consul',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-hcp-consul',
    },
    'hcp-vault': {
      name: 'HCP Vault',
      slug: 'hcp-vault',
      version: '0.0.2',
      overview:
        'HashiCorp Vault helps organizations reduce the risk of breaches and data exposure with identity-based security automation and encryption as a service. Vault secures, stores, and tightly controls access to tokens, passwords, certificates, API keys, and other secrets in modern computing. This integration connects your Vault instance running in HashiCorp Cloud Platform to your Grafana Cloud instance to visualize metrics with pre-built dashboards.\n\n[HCP Vault mixin](https://github.com/grafana/jsonnet-libs/tree/master/vault-mixin)  \n[HCP Vault docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-hcp-vault/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/vault.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/vault.png',
      },
      type: 'saas',
      search_keywords: ['hashicorp', 'secret', 'credentials', 'managed', 'hcp-vault'],
      dashboard_folder: 'Integration - HCP Vault',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'ibm-db2': {
      name: 'IBM DB2',
      slug: 'ibm-db2',
      version: '0.0.3',
      overview:
        'IBM DB2 is a relational database management system designed to store, manage, and retrieve data efficiently. This integration for Grafana Cloud allows users to collect metrics and logs for monitoring an IBM DB2 database.\n\nMetrics include active connections, row operations, bufferpool hit ratio, tablespace usage, average lock wait time, deadlocks, and active locks. \n\nThis integration supports IBM DB2 9.7 and onwards.\n\n**Links**  \n[IBM DB2 mixin](https://github.com/grafana/ibm-db2-prometheus-exporter/tree/master/mixin/)  \n[IBM DB2 docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-ibm-db2/)  \n[IBM DB2 Exporter](https://github.com/grafana/ibm-db2-prometheus-exporter/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ibm-db2-dark.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ibm-db2-light.svg',
      },
      type: 'agent',
      search_keywords: ['ibm', 'db2', 'database', 'ibm-db2'],
      dashboard_folder: 'Integration - IBM DB2',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-ibm-db2',
    },
    'ibm-mq': {
      name: 'IBM MQ',
      slug: 'ibm-mq',
      version: '0.0.3',
      overview:
        'IBM MQ is a message queue software that can be deployed across a range of different environments. MQ sends and receives messages via message queues to support the exchange of information between applications, systems, and services.\n\nThis integration includes useful visualizations for cluster, queue manager, queue, and topic metrics. Metrics such as the number of messages sent and received by queue managers and queues, the number of different queue operations, disk usage, CPU usage, active connections, average queue time, and expired messages.\n\nThis integration supports IBM MQ 9.1+\n\n**Links**  \n[IBM MQ mixin](https://github.com/grafana/jsonnet-libs/tree/master/ibm-mq-mixin)  \n[IBM MQ docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-ibm-mq/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ibm-mq.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ibm-mq.svg',
      },
      type: 'agent',
      search_keywords: ['queue', 'messaging', 'distributed', 'message', 'ibm-mq'],
      dashboard_folder: 'Integration - IBM MQ',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-ibm-mq',
    },
    jenkins: {
      name: 'Jenkins',
      slug: 'jenkins',
      version: '0.0.4',
      overview:
        'Jenkins is a free and open source automation server. It helps automate the parts of software development related to building, testing, and deploying, facilitating continuous integration and continuous delivery. It is a server-based system that runs in servlet containers such as Apache Tomcat.\n\n**Links**  \n[Jenkins mixin](https://github.com/grafana/jsonnet-libs/tree/master/jenkins-mixin)  \n[Jenkins docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-jenkins/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/jenkins.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/jenkins.png',
      },
      type: 'agent',
      search_keywords: ['automation', 'automate', 'ci', 'cd', 'deploy', 'continuous', 'jenkins'],
      dashboard_folder: 'Integration - Jenkins',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    jira: {
      name: 'Jira',
      slug: 'jira',
      version: '0.0.4',
      overview:
        'Jira is a tool for planning, tracking, and releasing software. A Jira instance can be hosted by Jira on its own cloud or you can host it on AWS, Azure, or an on-premise server. This integration enables the agent to send metrics from a Jira instance you host to Grafana Cloud along with a useful pre-built dashboard for visualization.\n\n**Links**  \n[Jira mixin](https://github.com/grafana/jsonnet-libs/tree/master/jira-mixin)  \n[Jira docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-jira/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/jira.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/jira.png',
      },
      type: 'agent',
      search_keywords: ['plan', 'track', 'project management', 'jira'],
      dashboard_folder: 'Integration - Jira',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-jira',
    },
    kafka: {
      name: 'Kafka',
      slug: 'kafka',
      version: '1.0.0',
      overview:
        'Apache Kafka is an open-source distributed event streaming platform used by thousands of companies for high-performance data pipelines, streaming analytics, data integration, and mission-critical applications.\n\n**Links**  \n[Kafka mixin](https://github.com/grafana/jsonnet-libs/tree/master/kafka-mixin)  \n[Kafka docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-kafka/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/kafka.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/kafka_black.svg',
      },
      type: 'agent',
      search_keywords: ['zookeeper', 'ksqldb', 'schema', 'connect', 'kafka'],
      dashboard_folder: 'Integration - Kafka',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-kafka',
    },
    'linux-node': {
      name: 'Linux Server',
      slug: 'linux-node',
      version: '1.1.2',
      overview:
        'Linux is a family of open-source Unix-like operating systems based on the Linux kernel. Linux is the leading operating system on servers, and is one of the most prominent examples of free and open-source software collaboration.\n\nLinux Server integration for Grafana Cloud enables you to collect metrics related to the operating system running on a node, including aspects like CPU usage, load average, memory usage, and disk and networking I/O using node_exporter integration. It also allows you to use the agent to scrape logs.\n\n**Links**  \n[Linux Server mixin](https://github.com/prometheus/node_exporter/tree/master/docs/node-mixin)  \n[Linux Server docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-linux-node/)   \n[Multiple nodes monitoring documentation](https://grafana.com/docs/grafana-cloud/infrastructure-as-code/ansible/ansible-multiple-agents/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/linux.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/linux.png',
      },
      type: 'agent',
      search_keywords: ['operating system', 'server', 'linux-node'],
      dashboard_folder: 'Integration - Linux Node',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-linux-node',
    },
    loki: {
      name: 'Self-hosted Grafana Loki',
      slug: 'loki',
      version: '0.0.2',
      overview:
        'Send self-monitoring metrics and logs from Grafana Loki or GEL running in your Kubernetes cluster to Grafana Cloud.\nThe integration comes with dashboards to help monitor the health of your Loki or GEL cluster as well as\nunderstand per-tenant usage and behavior.\n\n**Links**  \n[Self-hosted Grafana Loki](https://grafana.com/loki/)  \n[Self-hosted Grafana Loki mixin](https://github.com/grafana/loki/tree/main/production/loki-mixin)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/loki.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/loki.svg',
      },
      type: 'agent',
      search_keywords: ['log', 'grafana', 'loki'],
      dashboard_folder: 'Integration - Self-hosted Grafana Loki',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-loki',
    },
    'macos-node': {
      name: 'macOS',
      slug: 'macos-node',
      version: '0.0.10',
      overview:
        'The macOS integration uses the agent to collect metrics related to the operating system, including aspects like CPU usage, load average, memory usage, and disk and networking I/O. \nIt also supports system logs being scraped by the agent using promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\n**Links**  \n[macOS mixin](https://github.com/prometheus/node_exporter/tree/master/docs/node-mixin)  \n[macOS docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-macos-node/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apple.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apple.svg',
      },
      type: 'agent',
      installation: {
        version: '0.0.7',
        installed_on: '2023-05-09T08:09:03Z',
      },
      search_keywords: ['operating system', 'macos-node'],
      dashboard_folder: 'Integration - MacOS Node',
      has_update: true,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-macos-node',
    },
    memcached: {
      name: 'Memcached',
      slug: 'memcached',
      version: '0.0.5',
      overview:
        'Memcached is a distributed, in-memory key-value store used to speed up dynamic database-driven websites by caching information in memory. \n\n**Links**  \n[Memcached mixin](https://github.com/grafana/jsonnet-libs/tree/master/memcached-mixin)  \n[Memcached docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-memcached/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/memcached.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/memcached.png',
      },
      type: 'agent',
      search_keywords: ['key-value', 'memcached'],
      dashboard_folder: 'Integration - Memcached',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-memcached',
    },
    'metrics-endpoint': {
      name: 'Metrics Endpoint',
      slug: 'metrics-endpoint',
      version: '0.0.1',
      overview:
        'With the Metrics Endpoint integration, you can automate the scraping of any Prometheus-compatible publicly accessible URL. The scraped metrics are then stored in Grafana Cloud, without requiring any additional infrastructure. You can set up multiple configurations, known as "scrape jobs", to simultaneously monitor multiple URLs.',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/metrics-endpoint-prometheus.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/metrics-endpoint-prometheus.svg',
      },
      type: 'saas',
      search_keywords: ['custom', 'http', 'scrape', 'url', 'prometheus', 'agentless', 'metrics-endpoint'],
      dashboard_folder: 'Integration - Metrics Endpoint',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'microsoft-iis': {
      name: 'Microsoft IIS',
      slug: 'microsoft-iis',
      version: '0.0.3',
      overview:
        'Microsoft IIS is a web server that can host and manage web apps on Windows servers. This integration for Grafana Cloud allows users to collect metrics and access logs for monitoring a Microsoft IIS instance.\n\nThis integration supports Microsoft IIS 10.0 onwards.\n\n**Links**  \n[Microsoft IIS mixin](https://github.com/grafana/jsonnet-libs/tree/master/microsoft-iis-mixin)  \n[Microsoft IIS docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-microsoft-iis/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/windows.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/windows.png',
      },
      type: 'agent',
      search_keywords: [
        'web server',
        'windows',
        'IIS',
        'Internet Information Services',
        'Windows server',
        'microsoft-iis',
      ],
      dashboard_folder: 'Integration - Microsoft IIS',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-microsoft-iis',
    },
    mimir: {
      name: 'Self-hosted Grafana Mimir',
      slug: 'mimir',
      version: '1.0.2',
      overview:
        '[Grafana Mimir](https://github.com/grafana/mimir) is an open source software project that provides a scalable long-term storage for Prometheus. [Grafana Enterprise Metrics (or GEM)](https://grafana.com/products/enterprise/metrics/) is the enterprise version of Grafana Mimir. You can install both of these products via the [Grafana Mimir Helm chart](https://github.com/grafana/mimir/tree/main/operations/helm/charts/mimir-distributed).\n\nThis integration comes with dashboards, recording and alerting rules to help monitor the health of your Mimir or GEM cluster as well as understand per-tenant usage and behavior.\n\n\u003e**Note:** an updated version of this integration is available under the Kubernetes App, which makes use of the Grafana Agent Operator, a more automated and easily maintained solution.\n\n**Links**  \n[Self-hosted Grafana Mimir mixin](https://github.com/grafana/mimir/tree/main/operations/mimir-mixin)  \n[Self-hosted Grafana Mimir docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-mimir/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mimir.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mimir.png',
      },
      type: 'agent',
      search_keywords: ['metrics', 'grafana', 'cortex', 'prometheus', 'mimir'],
      dashboard_folder: 'Integration - Self-hosted Grafana Mimir',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-mimir',
    },
    minio: {
      name: 'MinIO',
      slug: 'minio',
      version: '0.0.6',
      overview:
        'MinIO is a Kubernetes-native high-performance object storage server which is designed for large-scale private cloud infrastructure and compatible with Amazon S3.\n\n**Links**  \n[MinIO mixin](https://github.com/grafana/jsonnet-libs/tree/master/minio-mixin)  \n[MinIO docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-minio)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/minio.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/minio.png',
      },
      type: 'agent',
      search_keywords: ['storage', 'minio'],
      dashboard_folder: 'Integration - MinIO',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-minio',
    },
    mongodb: {
      name: 'MongoDB',
      slug: 'mongodb',
      version: '0.0.6',
      overview:
        'MongoDB is a general purpose, document-based, distributed database built for modern application developers and for the cloud era.\n\n**Links**  \n[MongoDB mixin](https://github.com/grafana/jsonnet-libs/tree/master/mongodb-mixin)  \n[MongoDB docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-mongodb/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mongodb.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mongodb.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'mongodb'],
      dashboard_folder: 'Integration - MongoDB',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-mongodb',
    },
    'mongodb-atlas': {
      name: 'MongoDB Atlas',
      slug: 'mongodb-atlas',
      version: '0.0.1',
      overview:
        'MongoDB Atlas is a fully managed cloud database service that provides a scalable, secure, and flexible platform for hosting and managing MongoDB databases. This integration for Grafana Cloud allows users to collect metrics for monitoring a MongoDB Atlas project containing any number of clustered database deployments.\n\nMetrics include connections, operations, deadlocks, memory usage, election counts, sharding statistics, and network metrics. These are used to provide valuable visualizations for clusters, replica sets, and individual nodes.\n\nThis integration supports MongoDB 4.4+ and MongoDB Atlas cluster levels M10+.\n\n**Links**  \n[MongoDB Atlas mixin](https://github.com/grafana/jsonnet-libs/tree/master/mongodb-atlas-mixin)  \n[MongoDB Atlas docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-mongdb-atlas/)   \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mongodb-atlas.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mongodb-atlas.png',
      },
      type: 'agent',
      search_keywords: ['database', 'nosql', 'cloud', 'mongodb-atlas'],
      dashboard_folder: 'Integration - MongoDB Atlas',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-mongodb-atlas',
    },
    mssql: {
      name: 'Microsoft SQL Server',
      slug: 'mssql',
      version: '0.0.4',
      overview:
        'Microsoft SQL Server is a relational database that can be used to store and retrieve data. The Microsoft SQL Server integration uses the Grafana Agent to collect metrics for monitoring a MSSQL instance, including aspects such as active connections, read/write latency, buffer cache hit rate, and page faults. Accompanying dashboards are provided to visualize these metrics, as well as recommended alerting policies.\n\nThis integration supports SQL Server 2017, SQL Server 2019, and SQL Server 2022.\n\n**Links**  \n[Microsoft SQL Server mixin](https://github.com/grafana/jsonnet-libs/tree/master/mssql-mixin)   \n[Microsoft SQL Server docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-mssql/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mssql-logo.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mssql-logo.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'sql', 'rdbms', 'mssql'],
      dashboard_folder: 'Integration - Microsoft SQL Server',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-mssql',
    },
    mysql: {
      name: 'MySQL',
      slug: 'mysql',
      version: '1.0.1',
      overview:
        'MySQL is a managed, open source relational database that is a widely used for both small and large applications.\n\n**Links**  \n[MySQL mixin](https://github.com/prometheus/mysqld_exporter/tree/main/mysqld-mixin)  \n[MySQL docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-mysql/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mysql.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/mysql.png',
      },
      type: 'agent',
      installation: {
        version: '0.0.5',
        installed_on: '2023-06-27T11:55:32Z',
      },
      search_keywords: ['database', 'mysql'],
      dashboard_folder: 'Integration - MySQL',
      has_update: true,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-mysql',
    },
    nginx: {
      name: 'Nginx',
      slug: 'nginx',
      version: '0.0.5',
      overview:
        'NGINX is open source software for web serving, reverse proxying, caching, load balancing, media streaming, and more. It started out as a web server designed for maximum performance and stability. In addition to its HTTP server capabilities, NGINX can also function as a proxy server for email (IMAP, POP3, and SMTP) and a reverse proxy and load balancer for HTTP, TCP, and UDP servers.\n\n**Links**  \n[Nginx mixin](https://github.com/grafana/jsonnet-libs/tree/master/nginx-mixin)  \n[Nginx docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-nginx/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nginx.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nginx.svg',
      },
      type: 'agent',
      search_keywords: ['proxy', 'cache', 'balancing', 'server', 'web server', 'http', 'nginx'],
      dashboard_folder: 'Integration - Nginx',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    nodejs: {
      name: 'Node.js',
      slug: 'nodejs',
      version: '0.0.6',
      overview:
        'Node.js is a JavaScript runtime environment that allows execution of JavaScript code outside a web browser.\n\n**Links**  \n[Node.js mixin](https://github.com/grafana/jsonnet-libs/tree/master/nodejs-mixin)  \n[Node.js docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-nodejs/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nodejs.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nodejs.svg',
      },
      type: 'agent',
      search_keywords: ['javascript', 'js', 'backend', 'nodejs'],
      dashboard_folder: 'Integration - NodeJS',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-nodejs',
    },
    nomad: {
      name: 'Nomad',
      slug: 'nomad',
      version: '0.0.5',
      overview:
        'Nomad is a simple and flexible workload orchestrator to deploy and manage containers and non-containerized applications across on-prem and clouds at scale.\n\n**Links**  \n[Nomad mixin](https://github.com/grafana/jsonnet-libs/tree/master/nomad-mixin)  \n[Nomad docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-nomad)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nomad.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nomad.png',
      },
      type: 'agent',
      search_keywords: ['workload', 'orchestrator', 'container', 'nomad'],
      dashboard_folder: 'Integration - Nomad',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    nsq: {
      name: 'NSQ',
      slug: 'nsq',
      version: '0.0.2',
      overview:
        '[NSQ](https://nsq.io/) is a realtime distributed messaging platform designed to operate at scale, handling billions of messages per day.\n\nThis integration uses [NSQ statsd integration](https://nsq.io/components/nsqd.html#statsd--graphite-integration) to gather metrics.\n\n**Links**  \n[NSQ mixin](https://github.com/grafana/jsonnet-libs/tree/master/nsq-mixin)  \n[NSQ docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-nsq)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nsq.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/nsq.png',
      },
      type: 'agent',
      search_keywords: ['realtime', 'message', 'nsq'],
      dashboard_folder: 'Integration - NSQ',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-nsq',
    },
    openai: {
      name: 'OpenAI',
      slug: 'openai',
      version: '0.0.1',
      overview:
        'The OpenAI Integration allows to you monitor OpenAI API usage using the [grafana-openai-monitoring](https://github.com/grafana/grafana-openai-monitoring/tree/main) python library. By configuring the OpenAI Integration, users can gain valuable insights into token usage rates, response times, and overall costs. This integration empowers users to make data-driven decisions while ensuring optimal utilization of OpenAI APIs.\n\n**Links**  \n[OpenAI](https://openai.com/)  \n[OpenAI mixin](https://github.com/grafana/grafana-openai-monitoring/tree/main/openai-mixin)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/openai-dark.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/openai-light.png',
      },
      type: 'saas',
      search_keywords: ['openai', 'ML', 'Machine Learning', 'ChatGPT', 'gpt', 'openai'],
      dashboard_folder: 'Integration - OpenAI',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-openai',
    },
    opensearch: {
      name: 'OpenSearch',
      slug: 'opensearch',
      version: '0.0.2',
      overview:
        'OpenSearch is an open-source project and a fork of Elasticsearch that provides a fully-featured and compatible alternative. It uses the same core technologies as Elasticsearch, including the Lucene search engine, and offers similar features such as distributed search, indexing, and analytics. This integration for Grafana Cloud allows users to monitor an OpenSearch clustered deployment with 3 separate dashboards for visualizing metric details at the cluster, node, and index levels.\n\nThis integration supports OpenSearch version 2.5.0+  \nThis integration supports Prometheus exporter plugin for OpenSearch version 2.5.0.0+\n\n**Links**  \n[OpenSearch mixin](https://github.com/grafana/jsonnet-libs/tree/master/opensearch-mixin)  \n[OpenSearch docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-opensearch/)  \n[OpenSearch Prometheus Exporter Plugin](https://github.com/aiven/prometheus-exporter-plugin-for-opensearch)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/opensearch_dark.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/opensearch_light.png',
      },
      type: 'agent',
      search_keywords: ['elasticsearch', 'index', 'lucene', 'distributed search', 'opensearch'],
      dashboard_folder: 'Integration - OpenSearch',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-opensearch',
    },
    oracledb: {
      name: 'OracleDB',
      slug: 'oracledb',
      version: '0.0.3',
      overview:
        'OracleDB is an enterprise SQL Database system produced and marketed by Oracle Corporation. The OracleDB integration uses the Grafana Agent to collect metrics and alert logs for monitoring an OracleDB instance. Including metrics such as virtual memory usage, open file descriptors, sessions, processes, database wait times, and tablespace usage.\nIt also includes monitoring the alert log of an OracleDB instance which contains valuable information for usage and operations of the instance.\n\nThis integration supports metrics provided by v0.0.1 of a third party OracleDB exporter, which is integrated into the Grafana Agent.\n\n**Links**  \n[OracleDB mixin](https://github.com/grafana/jsonnet-libs/tree/master/oracledb-mixin)  \n[OracleDB docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-oracledb/)  \n[Third party OracleDB exporter](https://github.com/iamseth/oracledb_exporter/)  \n',
      logo: {
        dark_theme_url: 'https://grafana.com/api/plugins/oci-logs-datasource/logos/small',
        light_theme_url: 'https://grafana.com/api/plugins/oci-logs-datasource/logos/small',
      },
      type: 'agent',
      search_keywords: ['database', 'sql', 'rdbms', 'oracledb'],
      dashboard_folder: 'Integration - OracleDB',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-oracledb',
    },
    postgres: {
      name: 'PostgreSQL',
      slug: 'postgres',
      version: '1.0.3',
      overview:
        'PostgreSQL is a free and open-source relational database management system (RDBMS) emphasizing extensibility and SQL compliance.\n\n**Links**  \n[PostgreSQL mixin](https://github.com/grafana/postgres_exporter/tree/master/postgres_mixin)  \n[PostgreSQL docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-postgres/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/postgres.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/postgres.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'sql', 'postgres'],
      dashboard_folder: 'Integration - PostgreSQL',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'postgres-integration',
    },
    presto: {
      name: 'Presto',
      slug: 'presto',
      version: '1.0.0',
      overview:
        "Presto is an open-source distributed SQL query engine designed for running interactive analytic queries against data sources of all sizes, ranging from gigabytes to petabytes. It allows users to query data where it lives, whether it's in Hadoop, AWS S3, Cassandra, MySQL, and many other data sources without the need for complex data migration or transformation. The integration with Grafana Cloud enables users to oversee a Presto environment using distinct dashboards. These dashboards display metrics and logs for Presto clusters, workers, coordinators, and logs.\n\nThis integration supports Presto 0.28+ running alongisde a JMX exporter 0.19.0+.\n\n**Links**  \n[Presto mixin](https://github.com/grafana/jsonnet-libs/tree/master/presto-mixin)  \n[Presto docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-presto)  \n[JMX Exporter](https://github.com/prometheus/jmx_exporter)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/presto.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/presto.png',
      },
      type: 'agent',
      search_keywords: ['SQL', 'Engine', 'Database', 'Query', 'presto'],
      dashboard_folder: 'Integration - Presto',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-presto',
    },
    rabbitmq: {
      name: 'RabbitMQ',
      slug: 'rabbitmq',
      version: '0.0.5',
      overview:
        'RabbitMQ is the most widely deployed open source message broker. It supports multiple messaging protocols, and can be deployed in distributed and federated configurations to meet high-scale, high-availability requirements.\n\n**Links**  \n[RabbitMQ mixin](https://github.com/grafana/jsonnet-libs/tree/master/rabbitmq-mixin)  \n[RabbitMQ docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-rabbitmq/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/rabbitmq.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/rabbitmq.svg',
      },
      type: 'agent',
      search_keywords: ['message', 'queue', 'rabbitmq'],
      dashboard_folder: 'Integration - RabbitMQ',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-rabbitmq',
    },
    'raspberry-pi-node': {
      name: 'Raspberry Pi',
      slug: 'raspberry-pi-node',
      version: '0.0.4',
      overview:
        'The Raspberry Pi integration uses the agent to collect metrics related to the operating system (Linux-based), including aspects like CPU usage, load average, memory usage, and disk and networking I/O. \nIt also supports system logs being scraped by the agent using promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\n**Links**  \n[Raspberry Pi mixin](https://github.com/grafana/node_exporter/tree/master/docs/node-mixin)  \n[Raspberry Pi docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-raspberry-pi-node/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/raspberrypi.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/raspberrypi.svg',
      },
      type: 'agent',
      search_keywords: ['board', 'embedded', 'sensor', 'raspberry-pi-node'],
      dashboard_folder: 'Integration - Raspberry Pi Node',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-raspberrypi-node',
    },
    rclone: {
      name: 'rclone',
      slug: 'rclone',
      version: '0.0.3',
      overview:
        "Rclone is a command line program to manage files on Cloud storage. It is a feature rich alternative to Cloud vendors' web storage interfaces. Over 40 Cloud storage products support rclone including S3 object stores, business and consumer file storage services, and standard transfer protocols.\n\n**Links**  \n[rclone mixin](https://github.com/grafana/jsonnet-libs/tree/master/rclone-mixin)  \n[rclone docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-rclone)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/rclone.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/rclone.png',
      },
      type: 'agent',
      search_keywords: ['file', 'transfer', 'rclone'],
      dashboard_folder: 'Integration - rclone',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    redis: {
      name: 'Redis',
      slug: 'redis',
      version: '0.0.5',
      overview:
        'Redis is an in-memory data structure store frequently used as a key-value data store, cache, and message broker.\n\n**Links**  \n[Redis mixin](https://github.com/oliver006/redis_exporter/tree/master/contrib/redis-mixin)  \n[Redis docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-redis/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/redis.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/redis.png',
      },
      type: 'agent',
      search_keywords: ['memory', 'database', 'cache', 'redis'],
      dashboard_folder: 'Integration - Redis',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-redis',
    },
    'redis-enterprise': {
      name: 'Redis Enterprise',
      slug: 'redis-enterprise',
      version: '0.0.3',
      overview:
        'Redis Enterprise is an enterprise-grade deployment strategy for Redis, the popular and efficient database used for caching, message brokering, and data streaming. Redis Enterprise provides deployment solutions and tools for creating and maintaining both on-prem and cloud solutions.\n\nThis integration includes useful metrics to monitor Redis Enterprise deployments. Metrics like memory usage, CPU usage, replication lag, networking and log data can help teams discern, identify and visualize their Redis Enterprise environments, allowing you to monitor your environment down to the clusters, node and database level.\n\nThis integration supports Redis Enterprise software versions v6.X+.\n\n**Links**  \n[Redis Enterprise mixin](https://github.com/grafana/jsonnet-libs/tree/master/redis-enterprise-mixin)  \n[Redis Enterprise docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-redis-enterprise/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/redis-enterprise-cube-rgb.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/redis-enterprise-cube-rgb.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'enterprise', 'memorystore', 'redis-enterprise'],
      dashboard_folder: 'Integration - Redis Enterprise',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-redis-enterprise',
    },
    'ruby-rack': {
      name: 'Ruby Rack',
      slug: 'ruby-rack',
      version: '0.0.4',
      overview:
        'The Rack webserver interface for Ruby allows easily building web applications. This integration enables the agent to send metrics to Grafana Cloud along with a useful default dashboard for visualization.\n\n**Links**  \n[Ruby Rack mixin](https://github.com/grafana/jsonnet-libs/tree/master/ruby-mixin)  \n[Ruby Rack docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-ruby-rack/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ruby-rack.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ruby-rack.png',
      },
      type: 'agent',
      search_keywords: ['web', 'backend', 'ruby', 'ruby-rack'],
      dashboard_folder: 'Integration - Ruby Rack',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'sap-hana': {
      name: 'SAP HANA®',
      slug: 'sap-hana',
      version: '0.0.3',
      overview:
        'SAP HANA is an enterprise in-memory database designed to process large volumes of data in real time. This integration for Grafana Cloud allows users to collect metrics and trace logs for monitoring an SAP HANA system. \n\nMetrics include CPU usage by core, disk and memory usage, network and disk throughput, average query execution time, connections, alerts, replica status and ship delay. Visualizations for the system and for individual hosts are included. \n\nThis integration supports SAP HANA 2.0 SPS06+  \nThis integration supports the [SUSE/hanadb_exporter](https://github.com/SUSE/hanadb_exporter) 0.8.0+  \n\nSAP HANA is the trademark or registered trademark of SAP SE or its affiliates in Germany and in other countries.\n\n**Links**  \n[SAP HANA® mixin](https://github.com/grafana/jsonnet-libs/tree/master/sap-hana-mixin)  \n[SAP HANA® docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-sap-hana/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/sap-hana-logo.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/sap-hana-logo.svg',
      },
      type: 'agent',
      search_keywords: ['database', 'sql', 'rdbms', 'in-memory', 'analytic', 'enterprise', 'sap-hana'],
      dashboard_folder: 'Integration - SAP HANA',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-sap-hana',
    },
    snmp: {
      name: 'SNMP',
      slug: 'snmp',
      version: '0.0.3',
      overview:
        'The SNMP integration lets you monitor any generic SNMP (Simple Network Management Protocol) device.\n\n**Links**  \n[SNMP mixin](https://github.com/grafana/jsonnet-libs/tree/master/snmp-mixin)  \n[SNMP docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-snmp/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/snmp.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/snmp.png',
      },
      type: 'agent',
      search_keywords: ['snmp', 'network', 'snmp'],
      dashboard_folder: 'Integration - SNMP',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-snmp',
    },
    snowflake: {
      name: 'Snowflake',
      slug: 'snowflake',
      version: '0.0.2',
      overview:
        'Snowflake is a cloud data platform that is designed to connect businesses globally, across any type or scale of data and many different workloads, and unlock seamless data collaboration.\nThe Snowflake integration uses the Grafana Agent to collect metrics for monitoring a Snowflake account, including aspects such as credit usage, storage usage, and login success rates. Accompanying dashboards are provided to visualize these metrics.\n\nThis integration supports metrics provided by v0.0.1 of the Snowflake exporter, which is integrated into the Grafana Agent.\n\n**Links**  \n[Snowflake mixin](https://github.com/grafana/snowflake-prometheus-exporter/tree/main/mixin)  \n[Snowflake docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-snowflake/)  \n[Snowflake exporter](https://github.com/grafana/snowflake-prometheus-exporter/tree/main)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/snowflake-logo-light.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/snowflake-logo-light.png',
      },
      type: 'agent',
      search_keywords: ['data warehouse', 'pipeline', 'cybersecurity', 'snowflake'],
      dashboard_folder: 'Integration - Snowflake',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-snowflake',
    },
    spark: {
      name: 'Apache Spark',
      slug: 'spark',
      version: '0.0.4',
      overview:
        'Apache Spark™ is a multi-language engine for executing data engineering, data science, and machine learning on single-node machines or clusters.\n\n**Links**  \n[Apache Spark mixin](https://github.com/grafana/jsonnet-libs/tree/master/spark-mixin)  \n[Apache Spark docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-spark/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache-spark.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/apache-spark.svg',
      },
      type: 'agent',
      search_keywords: ['data science', 'science', 'machine learning', 'spark'],
      dashboard_folder: 'Integration - Apache Spark',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    squid: {
      name: 'Squid',
      slug: 'squid',
      version: '0.0.3',
      overview:
        'Squid is a popular open-source proxy server that is used to improve web performance and provide content caching for web clients. The Squid integration uses the Grafana agent to collect metrics for monitoring a Squid instance, including aspects such as cache hits \u0026 miss service time, cache hit ratios, client and server requests, server object swaps, and DNS lookup service time.\nThe integration also supports Squid logs being scraped by the agent using Promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\nThis integration supports Squid 5+.\n\n**Links**  \n[Squid mixin](https://github.com/grafana/jsonnet-libs/tree/master/squid-mixin)  \n[Squid docs](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-squid/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/squid.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/squid.png',
      },
      type: 'agent',
      search_keywords: ['squid', 'proxy', 'server', 'squid'],
      dashboard_folder: 'Integration - Squid',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-squid',
    },
    tensorflow: {
      name: 'TensorFlow Serving',
      slug: 'tensorflow',
      version: '0.0.4',
      overview:
        'TensorFlow Serving is an end-to-end open source platform for machine learning. The TensorFlow Serving integration uses the Grafana agent to collect metrics for monitoring a TensorFlow Serving instance, including aspects such as model request latency, model runtime latency, batch queuing latency, graph build time, and graph run time.\nThe integration also supports the TensorFlow Serving Docker container logs being scraped by the agent using Promtail. An accompanying dashboard is provided to visualize these metrics and logs.\n\nThis integration supports TensorFlow Serving 2.10.0+.\n\n**Links**  \n[TensorFlow Serving mixin](https://github.com/grafana/jsonnet-libs/tree/master/tensorflow-mixin)  \n[TensorFlow Serving docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-tensorflow/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/tensorflow.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/tensorflow.png',
      },
      type: 'agent',
      search_keywords: ['machine learning', 'ml', 'model', 'tensorflow'],
      dashboard_folder: 'Integration - TensorFlow',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-tensorflow',
    },
    traefik: {
      name: 'Traefik',
      slug: 'traefik',
      version: '0.0.6',
      overview:
        "Traefik is a dynamic load balancer designed for ease of configuration, especially in dynamic environments. It supports automatic discovery of services, metrics, tracing, and has Let's Encrypt support out of the box. Traefik provides a “ready to go” system for serving production traffic with these additions.\n\n**Links**  \n[Traefik mixin](https://github.com/grafana/jsonnet-libs/tree/master/traefik-mixin)  \n[Traefik docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-traefik/)\n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/traefik.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/traefik.png',
      },
      type: 'agent',
      search_keywords: ['load', 'balancer', 'networking', 'traefik'],
      dashboard_folder: 'Integration - Traefik',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'ubiquiti-edgerouter': {
      name: 'Ubiquiti EdgeRouter',
      slug: 'ubiquiti-edgerouter',
      version: '0.0.3',
      overview:
        'Ubiquiti EdgeRouter dashboard lets you see vital metrics on throughput, errors, and traffic distribution.\n\n**Links**  \n[Ubiquiti EdgeRouter mixin](https://github.com/grafana/jsonnet-libs/tree/master/ubnt-edgerouter-mixin)  \n[Ubiquiti EdgeRouter docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-ubiquiti-edgerouter/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ubnt-er.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/ubnt-er.png',
      },
      type: 'agent',
      search_keywords: ['network', 'router', 'snmp', 'ubiquiti-edgerouter'],
      dashboard_folder: 'Integration - Ubiquiti EdgeRouter',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'varnish-cache': {
      name: 'Varnish Cache',
      slug: 'varnish-cache',
      version: '0.0.3',
      overview:
        'Varnish Cache is an open source web application accelerator, also called a caching HTTP reverse proxy. This integration for Grafana Cloud allows users to collect metrics and logs for their Varnish system.\n\nVarnish Cache integration for Grafana cloud provides a streamlined way for users to easily monitor their Varnish system with little overhead while getting the crucial information they need.\n\nThis integration supports Varnish Cache 6.0 LTS.\n\n**Links**  \n[Varnish Cache mixin](https://github.com/grafana/jsonnet-libs/tree/master/varnish-mixin)  \n[Varnish Cache docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-varnish-cache/)  \n[Varnish Cache exporter](https://github.com/jonnenauha/prometheus_varnish_exporter)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/varnish.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/varnish.png',
      },
      type: 'agent',
      search_keywords: ['varnish', 'cache', 'proxy', 'web', 'varnish-cache'],
      dashboard_folder: 'Integration - Varnish Cache',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-varnish-cache',
    },
    vault: {
      name: 'Vault',
      slug: 'vault',
      version: '0.0.4',
      overview:
        'HashiCorp Vault secures, stores, and controls access to tokens, passwords, certificates, API keys, and other secrets in modern computing. This integration allows you to monitor metrics from Vault.\n\n**Links**  \n[Vault mixin](https://github.com/grafana/jsonnet-libs/tree/master/vault-mixin)  \n[Vault docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-vault/)  \n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/vault.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/vault.png',
      },
      type: 'agent',
      search_keywords: ['secret', 'secure', 'hashicorp', 'vault'],
      dashboard_folder: 'Integration - Vault',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'windows-exporter': {
      name: 'Windows',
      slug: 'windows-exporter',
      version: '1.0.0',
      overview:
        "Monitor Windows instances using Grafana Agent installed. The integration comes with pre installed dashboards, which give an overview of your Windows' fleet at once, a single host overview, as well as additional dashboards that provide more metrics for further system performance analysis.\nThe integration also provides dashboard showing Windows event logs.\n\n**Links**  \n[Windows mixin](https://github.com/grafana/jsonnet-libs/tree/master/windows-mixin)  \n[Windows docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-windows-exporter/)  \n",
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/windows.png',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/windows.png',
      },
      type: 'agent',
      search_keywords: ['operating system', 'windows', 'microsoft', 'windows-exporter'],
      dashboard_folder: 'Integration - Windows Exporter',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: 'integrations-windows-exporter',
    },
    'wso2-enterprise-integrator': {
      name: 'WSO2 Enterprise Integrator',
      slug: 'wso2-enterprise-integrator',
      version: '0.0.6',
      overview:
        'WSO2 Enterprise Integrator 7.1.0 is a powerful configuration-driven approach to integration, which allows developers to build integration solutions graphically.\n\nThis is a hybrid platform that enables API-centric integration and supports various integration architecture styles: microservices architecture, cloud-native architecture, or a centralized ESB architecture. This integration platform offers a graphical/configuration-driven approach to developing integrations for any of the architectural styles.\n\n**Links**  \n[WSO2 Enterprise Integrator mixin](https://github.com/grafana/jsonnet-libs/tree/master/wso2-enterprise-integrator-mixin)  \n[WSO2 Enterprise Integrator docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-wso2-enterprise-integrator/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/wso2-ei.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/wso2-ei.svg',
      },
      type: 'agent',
      search_keywords: ['esb', 'microservice', 'wso2-enterprise-integrator'],
      dashboard_folder: 'Integration - WSO2 Enterprise Integrator',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
    'wso2-streaming-integrator': {
      name: 'WSO2 Streaming Integrator',
      slug: 'wso2-streaming-integrator',
      version: '0.0.3',
      overview:
        'WSO2 Streaming Integrator allows you to implement streaming ETL, change data capture (CDC), and process large files and real-time APIs. You can connect and realize event-driven architectures with distributed streaming systems such as Kafka, Amazon SQS, and more.\n\nThis integration was designed based on the dashboards publicly available on [WSO2 Github](https://github.com/wso2/streaming-integrator/tree/master/modules/distribution/carbon-home/resources/dashboards). It is updated to use the most recent panel versions and enables both a course and fine grained evaluation of your Streaming Integrator instances and Siddhi server and applications.\n\n**Links**  \n[WSO2 Streaming Integrator mixin](https://github.com/grafana/jsonnet-libs/tree/master/wso2-streaming-integrator-mixin)  \n[WSO2 Streaming Integrator docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-wso2-streaming-integrator/)\n',
      logo: {
        dark_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/wso2-si.svg',
        light_theme_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/wso2-si.svg',
      },
      type: 'agent',
      search_keywords: ['processing engine', 'data pipeline', 'wso2-streaming-integrator'],
      dashboard_folder: 'Integration - WSO2 Streaming Integrator',
      has_update: false,
      metrics_check_query: '',
      logs_check_query: '',
      rule_namespace: '',
    },
  },
};

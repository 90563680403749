import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getSetupWizardBodyStyles = (theme: GrafanaTheme2) => ({
  headerWrapper: css`
    display: flex;
  `,
  textWrapper: css`
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 19px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  `,
  header: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  `,
  preamble: css`
    font-size: ${theme.typography.body.fontSize};
    color: ${theme.colors.text.primary};
  `,
  cardWrapper: css`
    display: grid;
    position: relative;
    margin-top: ${theme.spacing(3)};
    gap: ${theme.spacing(2)};
    @media (max-width: 1495px) {
      flex-flow: wrap;
    }
  `,
  moreBtnWrapper: css`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0 50px 0;
  `,
  moreText: css`
    color: ${theme.colors.text.primary};
    padding-right: 5px;
  `,
  moreLink: css`
    color: ${theme.colors.text.link};
    :hover {
      opacity: 1;
    }
  `,
  contentWrapper: css`
    padding: 2rem 3rem 0 0;
  `,
});

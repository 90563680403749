import { dateTime, LoadingState, PanelData } from '@grafana/data';

export const EMPTY_PANEL_DATA: PanelData = {
  state: LoadingState.Done,
  series: [],
  timeRange: {
    from: dateTime(),
    to: dateTime(),
    raw: {
      from: 'now',
      to: 'now',
    },
  },
};

export enum DataTestId {
  DASHBOARDS_CONTAINER_ID = 'home-dashboards',
  ALERTS_CONTAINER_ID = 'home-alerts',
  STARRED_DASHBOARDS_PANEL = 'starred-dashboards-panel',
  HEADER_CONTAINER_ID = 'home-header',
  HEADER_TITLE_ID = 'header-title',
  HEADER_WELCOME_CONTAINER_ID = 'home-welcome',
  HEADER_CONNECT_DATA_CONTAINER = 'connect-data',
  HEADER_GET_STARTED_CONTAINER = 'get-started-container',
  HEADER_CONNECT_DATA_LINK = 'connect-data-link',
  HEADER_GET_STARTED_LINK = 'get-started-link',
  HEADER_GET_STARTED_DESCRIPTION = 'get-started-description',
  INTEGRATIONS_CONTAINER_ID = 'home-integrations',
  INTEGRATIONS_LIST_ID = 'home-integration-list',
  HELP_PANEL_CONTAINER_ID = 'home-help-container',
  METRICS_CONTAINER_ID = 'home-metrics',
  METRICS_USAGE_CONTAINER = 'home-metrics-usage-container',
  METRICS_BODY = 'home-metrics-body',
  ACCOUNT_MANAGEMENT_CONTAINER_ID = 'account-management-container',
  ACCOUNT_MANAGEMENT_LIST_ID = 'account-management-list',
  PLAN_CONTAINER_ID = 'plan-container',
  PLAN_ROW_ID = 'plan-row',
  PLAN_BADGE_ID = 'plan-badge',
  PLAN_TRIAL_ID = 'plan-trial',
  PLAN_TRIAL_TOTAL_DAYS = 'plan-trial-total-days',
  PLAN_TRIAL_DAYS_LEFT = 'plan-trial-days-left',
  METRIC_LABEL_ID = 'metric-label',
  METRIC_CIRCLE_ID = 'metric-circle',
  METRIC_VALUE_ID = 'metric-value',
  METRIC_UNIT_ID = 'metric-unit',
  METRIC_ERROR_ID = 'metric-error',
  METRIC_LIMIT_ID = 'metric-limit',
  METRIC_LOADING_ID = 'metric-loading',
  USERS_USAGE_METRIC_ID = 'users-usage-metric',
  METRICS_USAGE_METRIC_ID = 'metric-usage-metric',
  FREE_USERS_USAGE_METRIC_ID = 'free-users-usage-metric',
  PRO_USERS_USAGE_METRIC_ID = 'pro_users-usage-metric',
  FREE_METRICS_USAGE_METRIC_ID = 'free-metric-usage-metric',
  PRO_METRICS_USAGE_METRIC_ID = 'pro-metric-usage-metric',
  LOGS_USAGE_METRIC_ID = 'logs-usage-metric',
  TRACES_USAGE_METRIC_ID = 'traces-usage-metric',
  DBCONFIG_FOOTER_REQUIRED_MSG = 'dbconfig-footer-required-message',
  DBCONFIG_FOOTER_CUSTOM_CONFIG_CONTAINER = 'dbconfig-footer-custom-config-container',
  DBCONFIG_FOOTER_CUSTOM_CONFIG_LINK = 'dbconfig-footer-custom-config-link',
  DBCONFIG_FOOTER_CUSTOM_CONFIG_NOTE = 'dbconfig-footer-custom-config-note',
  K6_USAGE_METRIC_ID = 'k6-usage-metric',
  FEO11Y_USAGE_METRIC_ID = 'feO11Y-usage-metric',
  APPO11Y_USAGE_METRIC_ID = 'appO11Y-usage-metric',
  PROFILES_USAGE_METRIC_ID = 'profiles-usage-metric',
  IRM_USAGE_METRIC_ID = 'irm-usage-metric',
  SYNTHETIC_MONITORING_USAGE_METRIC_ID = 'sm-usage-metric',
  ACCOUNT_INFO_CONTAINER_ID = 'home-account',
  ACCOUNT_TITLE = 'home-account-title',
  ACCOUNT_PLAN_SECTION = 'home-account-plan-section',
  ACCOUNT_PLAN_TITLE = 'home-account-plan-title',
  ACCOUNT_PLAN_VALUE = 'home-account-plan-value',
  ACCOUNT_PLAN_TRIAL_BADGE = 'home-account-plan-trial-badge',
  ACCOUNT_PLAN_TRIAL_CIRCLE = 'home-account-plan-trial-circle',
  ACCOUNT_DOCS_SECTION = 'home-account-docs-section',
  ACCOUNT_USERS_SECTION = 'home-account-users-section',
  ACCOUNT_USERS_TITLE = 'home-account-users-title',
  ACCOUNT_TOTAL_ACTIVE_USERS = 'home-account-total-active-users',
  ACCOUNT_ORG_ROLE_SECTION = 'home-account-org-role-section',
  ACCOUNT_ORG_ROLE_VALUE = 'home-account-org-role-value',
  DASHBOARD_CARD = 'dashboard-card',
  DASHBOARD_CARD_HEADER = 'dashboard-card-header',
  DASHBOARDS_MOST_RECENT_EMPTY_LINK = 'dashboard-most-recent-empty-link',
}

import { appCreateApi } from 'app/context';
import { CloudOrgData } from 'feature/common/types/CloudOrgData';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { backendSrvBaseQuery } from 'feature/common/api/baseApi';
import { QueryError } from 'feature/common/types/QueryError';
import { MarketplaceSubscriptionDetails } from 'feature/common/types/MarketplaceSubscriptionDetails';
import { FREE_INSTANCE, FREE_SUBSCRIPTION, NO_MARKETPLACE } from 'test/mocks/gcomMocks';

const PLUGIN_ID = 'cloud-home-app';

const grafanaComApiTagType = 'grafanaComAPi';

export const GrafanaComApi = appCreateApi({
  baseQuery: backendSrvBaseQuery({ baseUrl: `api/plugin-proxy/${PLUGIN_ID}/grafanacom-api` }),
  reducerPath: 'grafanaCom',
  tagTypes: [grafanaComApiTagType],
  endpoints: (build) => ({
    getOrgData: build.query<CloudOrgData, string>({
      queryFn: async (slug, api, extraOptions, baseQuery) => {
        if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
          return { data: FREE_SUBSCRIPTION as unknown as CloudOrgData };
        }
        const response = await baseQuery(`/orgs/${slug}`);

        if (!!response.data) {
          return { data: response.data as CloudOrgData };
        } else if (!!response.error) {
          return { error: response.error as QueryError };
        } else {
          return {
            error: { status: -1, code: 'ERROR', data: { message: `unknown error in getOrgData` } },
          };
        }
      },
    }),
    getHostedDataDetails: build.query<HostedDataDetails, void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
          return { data: FREE_INSTANCE as unknown as HostedDataDetails };
        }
        const response = await baseQuery('/instances');

        if (!!response.data) {
          return { data: response.data as HostedDataDetails };
        } else if (!!response.error) {
          return { error: response.error as QueryError };
        } else {
          return {
            error: { status: -1, code: 'ERROR', data: { message: `unknown error in getHostedDataDetails` } },
          };
        }
      },
    }),
    getMarketplaceSubscription: build.query<MarketplaceSubscriptionDetails, string>({
      queryFn: async (slug, api, extraOptions, baseQuery) => {
        if (process.env.NODE_ENV === 'development' || (window as any).Cypress) {
          return { data: NO_MARKETPLACE as unknown as MarketplaceSubscriptionDetails };
        }
        const response = await baseQuery(`/orgs/${slug}/marketplace-subscription-details`);

        if (!!response.data) {
          return { data: response.data as MarketplaceSubscriptionDetails };
        } else if (!!response.error) {
          return { error: response.error as QueryError };
        } else {
          return {
            error: { status: -1, code: 'ERROR', data: { message: `unknown error in getMarketplaceSubscription` } },
          };
        }
      },
    }),
  }),
});

export const { useGetOrgDataQuery, useGetHostedDataDetailsQuery, useGetMarketplaceSubscriptionQuery } = GrafanaComApi;

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getSnippetStyles = (theme: GrafanaTheme2) => ({
  container: css`
    max-width: 1300px;
    padding: 0px ${theme.spacing(2)};
  `,
  button: css`
    background: transparent;
    color: ${theme.colors.primary.border};

    :hover {
      background: ${theme.colors.background.secondary};
      color: ${theme.colors.primary.border};
    }
  `,
});

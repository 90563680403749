import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getPanelStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      height: 100%;
    `,
    title: css`
      font-size: 19px;
      font-weight: 500;
      line-height: ${theme.spacing(4)};
    `,
    titleWrapper: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.spacing(1)};
    `,
    contentWrapper: css`
      height: calc(100% - 40px);
    `,
  };
};

import React, { useContext } from 'react';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { handleKeyDown } from 'feature/common/utils/aria';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

type ExploreDashboardCardProps = {
  title: string;
  description: string;
  backgroundImage: string;
  backgroundColor: string;
  url: string;
};

const getStyles = (theme: GrafanaTheme2, backgroundImage: string, backgroundColor: string) => ({
  container: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background.primary};
    color: ${theme.colors.text.primary};
    border-radius: 4px;
    cursor: pointer;

    h3 {
      margin-top: ${theme.spacing(2)};
      color: ${theme.colors.text.maxContrast};
    }

    p {
      margin-bottom: ${theme.spacing(1)};
    }
  `,
  header: css`
    display: flex;
    background-color: ${backgroundColor};
    width: 100%;
    height: 65px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: ${theme.spacing(0.5)};
    border-top-right-radius: ${theme.spacing(0.5)};

    img {
      width: auto;
      height: 41px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  `,
  text: css`
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  `,
});

export const ExploreDashboardCard = ({
  title,
  description,
  backgroundImage,
  backgroundColor,
  url,
}: ExploreDashboardCardProps) => {
  const styles = useStyles2((theme) => getStyles(theme, backgroundImage, backgroundColor));
  const { orgId } = useContext(AppContext);

  function handleClick() {
    const id = title.toLowerCase().replaceAll(' ', '-');
    trackAction(
      FlowEventName.HOME_PAGE_EVENT,
      ComponentKey.Dashboards.ExploreDashboardCard(id),
      ActionType.click,
      orgId
    );

    window.open(url, '_blank');
  }

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      onKeyDown={handleKeyDown(handleClick)}
      tabIndex={0}
      role="button"
      data-testid={DataTestId.DASHBOARD_CARD}
    >
      <div className={styles.header} data-testid={DataTestId.DASHBOARD_CARD_HEADER}>
        <img src={backgroundImage} alt={title} />
      </div>
      <h3 className={styles.text}>{title}</h3>
      <p className={styles.text}>{description}</p>
    </div>
  );
};

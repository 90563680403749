import React, { useContext } from 'react';

import { LinkButton, useStyles2 } from '@grafana/ui';

import { getHeaderGetStartedStyles } from 'feature/home/components/refresh-design/HeaderGetStarted.styles';
import { appPaths } from 'feature/common/consts/appPaths';
import { useCurrentUser } from 'feature/common/hooks/useCurrentUser';
import { DataTestId } from 'feature/common/types/test-id.enum';
import { useOrgTrial } from 'feature/common/hooks/useOrgTrial';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { FlowEventName } from 'feature/onboarding/types/FlowEventName';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { AppContext } from 'App';

export const HeaderGetStarted = () => {
  const styles = useStyles2(getHeaderGetStartedStyles);
  const { orgId } = useContext(AppContext);
  const { isAdmin } = useCurrentUser();
  const { data: isTrialPlan } = useOrgTrial();

  function trackEvent(): void {
    trackAction(FlowEventName.HOME_PAGE_EVENT, ComponentKey.Home.GetStartedButtonRefresh, ActionType.click, orgId);
  }

  if (isAdmin && isTrialPlan) {
    return (
      <div className={styles.headerWrapper} data-testid={DataTestId.HEADER_GET_STARTED_CONTAINER}>
        <div data-testid={DataTestId.HEADER_GET_STARTED_DESCRIPTION} className={styles.getStartedText}>
          Set up your Grafana Cloud Stack in a few easy steps
        </div>
        <LinkButton
          data-testid={DataTestId.HEADER_GET_STARTED_LINK}
          size="md"
          className={styles.getStartedBtn}
          href={appPaths.gettingStarted}
          onClick={trackEvent}
        >
          Get started
        </LinkButton>
      </div>
    );
  }
  return null;
};

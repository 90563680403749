import { uniqueId } from 'lodash';
import React, { useState } from 'react';

import { Field, FormAPI, Input, SecretInput, Select, useStyles2, InlineField } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { AuthMethod, DataSourceDTO } from 'feature/common/types/Datasources';
import { InfluxVersion } from 'feature/common/types/InfluxVersion';
import { validateUrl } from 'feature/onboarding/utils/validate';
import { Datasources } from 'feature/datasource-config/types/DatasourceConfig';
import { getDBConfigFormStyles } from 'feature/datasource-config/components/DBConfigForm.styles';
import {
  getAuthMethodsOptions,
  getDBConfigVersions,
  getDefaultVersionFromValue,
} from 'feature/datasource-config/utils/datasourceConfigUtils';
import { DBConfigHTTPHeaders } from 'feature/datasource-config/components/DBConfigHTTPHeaders/DBConfigHTTPHeaders';

const renderPostgresFormGroups = (
  formApi: FormAPI<DataSourceDTO>,
  styles: any,
  version: SelectableValue,
  setVersion: Function,
  versions: SelectableValue[]
) => {
  return (
    <>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <Field
          className={styles.field}
          label="Data Source Name"
          required
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Input
            placeholder="My first PostgreSQL data source"
            {...formApi.register('name', { required: 'The name is required' })}
          />
        </Field>
      </div>
      <div className={styles.formGroup}>
        <h3>Connection</h3>
        <Field
          className={styles.field}
          label="Host"
          description="The IP address/hostname and optional port of your PostgreSQL instance."
          required
          invalid={!!formApi.errors?.url?.message}
          error={formApi.errors?.url?.message}
        >
          <Input
            placeholder="e.g. http://localhost:5432"
            {...formApi.register('url', { required: 'The host is required' })}
          />
        </Field>
        <Field
          className={styles.field}
          label="Database"
          description="Enter the name of your PostgreSQL database."
          required
          invalid={!!formApi.errors?.database?.message}
          error={formApi.errors?.database?.message}
        >
          <Input {...formApi.register('database', { required: 'The database is required' })} />
        </Field>
        <Field
          className={styles.field}
          label="User"
          description="Enter the login/username associated with your PostgreSQL database."
          required
          invalid={!!formApi.errors?.user?.message}
          error={formApi.errors?.user?.message}
        >
          <Input {...formApi.register('user', { required: 'The user is required' })} />
        </Field>
        <Field
          className={styles.field}
          label="Password"
          description="Enter the password associated with your PostgreSQL database."
          required
          invalid={!!formApi.errors?.password?.message}
          error={formApi.errors?.password?.message}
        >
          <SecretInput
            isConfigured={false}
            onReset={() => formApi.setValue('password', '')}
            onChange={(evt) => formApi.setValue('password', evt.currentTarget.value, { shouldValidate: true })}
          />
        </Field>
      </div>
      <div className={styles.lastFormGroup}>
        <h3>Details</h3>
        <Field
          className={styles.field}
          label="Version"
          description="Select your version of PostgreSQL."
          required
          invalid={!!formApi.errors?.version?.message}
          error={formApi.errors?.version?.message}
        >
          <Select
            aria-label="PostgreSQL version"
            value={version}
            options={versions}
            onChange={(e) => {
              formApi.setValue('version', `${e.value}`);
              setVersion(e);
            }}
          />
        </Field>
      </div>
    </>
  );
};

const renderMySQLFormGroups = (formApi: FormAPI<DataSourceDTO>, styles: any) => {
  return (
    <>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <Field
          className={styles.field}
          label="Data Source Name"
          required
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Input
            placeholder="My first MySQL data source"
            {...formApi.register('name', { required: 'The name is required' })}
          />
        </Field>
      </div>
      <div className={styles.formGroup}>
        <h3>Connection</h3>
        <Field
          className={styles.field}
          label="Host"
          description="The IP address/hostname and optional port of your MySQL instance."
          required
          invalid={!!formApi.errors?.url?.message}
          error={formApi.errors?.url?.message}
        >
          <Input
            placeholder="e.g. http://localhost:5432"
            {...formApi.register('url', { required: 'The host is required' })}
          />
        </Field>
        <Field
          className={styles.field}
          label="Database"
          description="Enter the name of your MySQL database."
          required
          invalid={!!formApi.errors?.database?.message}
          error={formApi.errors?.database?.message}
        >
          <Input {...formApi.register('database', { required: 'The database is required' })} />
        </Field>
        <Field
          className={styles.field}
          label="User"
          description="Enter the login/username associated with your MySQL database."
          required
          invalid={!!formApi.errors?.user?.message}
          error={formApi.errors?.user?.message}
        >
          <Input {...formApi.register('user', { required: 'The user is required' })} />
        </Field>
        <Field
          className={styles.field}
          label="Password"
          description="Enter the password associated with your MySQL database."
          required
          invalid={!!formApi.errors?.password?.message}
          error={formApi.errors?.password?.message}
        >
          <SecretInput
            isConfigured={false}
            onReset={() => formApi.setValue('password', '')}
            onChange={(evt) => formApi.setValue('password', evt.currentTarget.value, { shouldValidate: true })}
          />
        </Field>
      </div>
    </>
  );
};

const renderInfluxDBFormGroups = (
  formApi: FormAPI<DataSourceDTO>,
  styles: any,
  version: SelectableValue,
  setVersion: Function,
  versions: SelectableValue[]
) => {
  const htmlPrefix = uniqueId('influxdb-config');

  return (
    <div className={styles.influxFormContainer}>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <Field
          label="Data Source Name"
          required
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Input
            placeholder="My first InfluxDB data source"
            data-testid="datasource-name"
            {...formApi.register('name', { required: 'The name is required' })}
          />
        </Field>
      </div>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <h3>Auth</h3>
        <Field
          label="Authentication methods"
          required
          description="Choose an authentication method to access the data source"
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Select
            aria-label="Authentication methods"
            value={version}
            options={versions}
            id="select-authentication-method"
            data-testid="select-authentication-method"
            onChange={(evt: SelectableValue<InfluxVersion>) => {
              setVersion(evt);
              formApi.setValue('version', evt.value, { shouldValidate: true });

              if (evt.value === InfluxVersion.Flux) {
                formApi.unregister(['user', 'password']);
                formApi.register('httpHeaderName1', { required: 'The header is required' });
                formApi.register('httpHeaderValue1', { required: 'The token value is required' });
                formApi.register('database', { required: 'The database/bucket name is required' });
              } else if (evt.value === InfluxVersion.InfluxQLToken) {
                formApi.register('database', { required: 'The database/bucket name is required' });
                formApi.register('httpHeaderName1', { required: 'The header is required' });
                formApi.register('httpHeaderValue1', { required: 'The token value is required' });
              } else {
                formApi.register('password');
                formApi.register('database', { required: 'The database/bucket name is required' });
              }
            }}
          />
        </Field>
      </div>
      <div className={styles.formGroup}>
        <h3>Connection</h3>
        <Field
          label="URL"
          description="The HTTP protocol, IP address, and port of your InfluxDB API"
          required
          invalid={!!formApi.errors?.url?.message}
          error={formApi.errors?.url?.message}
        >
          <Input
            placeholder="e.g. http://localhost:8086"
            data-testid="url"
            {...formApi.register('url', { validate: validateUrl })}
          />
        </Field>
      </div>
      <div className={styles.lastFormGroup}>
        {version.value === InfluxVersion.InfluxQLBasic && (
          <>
            <h3>Details</h3>
            <Field
              className={styles.field}
              label="Database/Bucket"
              description={
                <>
                  Enter the name of your InfluxDB database, mapped to a bucket. Note, your bucket must be
                  <a href="https://docs.influxdata.com/influxdb/cloud-serverless/query-data/influxql/dbrp/">
                    {' '}
                    {/* cspell:disable-next-line */}
                    DBRP mapped{' '}
                  </a>
                  in order for querying to work.
                </>
              }
              required
              invalid={!!formApi.errors?.database?.message}
              error={formApi.errors?.database?.message}
            >
              <Input
                id={`${htmlPrefix}-db`}
                data-testid="database-bucket-name"
                {...formApi.register('database', { required: 'The database/bucket is required' })}
              />
            </Field>
            <Field
              className={styles.field}
              label="User"
              description="Enter the username associated with your InfluxDB database."
              invalid={!!formApi.errors?.user?.message}
              error={formApi.errors?.user?.message}
            >
              <Input id={`${htmlPrefix}-user`} data-testid="username" {...formApi.register('user')} />
            </Field>
            <Field
              className={styles.field}
              label="Token"
              description="Enter the token associated with your InfluxDB database."
              invalid={!!formApi.errors?.password?.message}
              error={formApi.errors?.password?.message}
            >
              <SecretInput
                id={`${htmlPrefix}-pwd`}
                isConfigured={false}
                data-testid="token"
                onReset={() => formApi.setValue('password', '')}
                onChange={(evt) => formApi.setValue('password', evt.currentTarget.value, { shouldValidate: true })}
              />
            </Field>
          </>
        )}
        {version.value === InfluxVersion.InfluxQLToken && (
          <>
            <h3>Details</h3>
            <Field
              className={styles.field}
              label="Database/Bucket"
              description="Enter the name of your InfluxDB database, mapped to a bucket."
              required
              invalid={!!formApi.errors?.database?.message}
              error={formApi.errors?.database?.message}
            >
              <Input
                id={`${htmlPrefix}-db`}
                data-testid="database-bucket-name"
                {...formApi.register('database', { required: 'The database/bucket is required' })}
              />
            </Field>
            <DBConfigHTTPHeaders formApi={formApi} />
          </>
        )}
        {version.value === InfluxVersion.Flux && (
          <>
            <h3>Details</h3>
            <Field
              className={styles.field}
              label="Database/Bucket"
              description="Enter the name of your InfluxDB database, mapped to a bucket."
              required
              invalid={!!formApi.errors?.database?.message}
              error={formApi.errors?.database?.message}
            >
              <Input
                id={`${htmlPrefix}-db`}
                data-testid="database-bucket-name"
                {...formApi.register('database', { required: 'The database/bucket is required' })}
              />
            </Field>
            <DBConfigHTTPHeaders formApi={formApi} />
          </>
        )}
      </div>
    </div>
  );
};

const renderGraphiteFormGroups = (
  formApi: FormAPI<DataSourceDTO>,
  styles: any,
  version: SelectableValue,
  setVersion: Function,
  versions: SelectableValue[]
) => {
  return (
    <>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <Field
          className={styles.field}
          label="Data Source Name"
          required
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Input
            type="text"
            placeholder="My first Graphite data source"
            {...formApi.register('name', { required: 'The name is required' })}
          />
        </Field>
      </div>
      <div className={styles.formGroup}>
        <h3>Connection</h3>
        <Field
          className={styles.field}
          label="URL"
          description="HTTP protocol, IP, and port of your graphite-web or graphite-api installation."
          required
          invalid={!!formApi.errors?.url?.message}
          error={formApi.errors?.url?.message}
        >
          <Input
            type="text"
            placeholder="e.g. http://localhost:8080"
            {...formApi.register('url', { validate: validateUrl })}
          />
        </Field>
      </div>
      <div className={styles.lastFormGroup}>
        <h3>Details</h3>
        <Field
          className={styles.field}
          label="Version"
          description="Select your version of Graphite."
          required
          invalid={!!formApi.errors?.version?.message}
          error={formApi.errors?.version?.message}
        >
          <Select
            aria-label="Graphite version"
            value={version}
            options={versions}
            onChange={(e) => {
              formApi.setValue('version', e.value);
              setVersion(e);
            }}
          />
        </Field>
      </div>
    </>
  );
};

const renderPrometheusFormGroups = (
  formApi: FormAPI<DataSourceDTO>,
  styles: any,
  authMethod: SelectableValue,
  setAuthMethod: Function,
  authMethodOptions: SelectableValue[]
) => {
  return (
    <>
      <div className={styles.formGroup} aria-label="Datasource settings page basic settings">
        <InlineField
          label="Name"
          labelWidth={24}
          tooltip="The name is used when you select the data source in panels. The default data source is preselected in new panels."
          grow
          required
          invalid={!!formApi.errors?.name?.message}
          error={formApi.errors?.name?.message}
        >
          <Input
            id="name"
            type="text"
            placeholder="My first Prometheus data source"
            {...formApi.register('name', { required: 'The name is required' })}
          />
        </InlineField>
      </div>
      <div className={styles.formGroup}>
        <h3>Connection</h3>
        <InlineField
          htmlFor="connection-url"
          label={'Prometheus server URL'}
          labelWidth={24}
          tooltip={
            <>
              Your access method is <em>Server</em>, this means the URL needs to be accessible from the grafana
              backend/server.
              <a
                href={'https://grafana.com/docs/grafana/latest/datasources/prometheus/#configure-the-data-source'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit docs for more details here.
              </a>
            </>
          }
          grow
          required
          invalid={!!formApi.errors?.url?.message}
          error={formApi.errors?.url?.message}
          interactive
        >
          <Input
            id="connection-url"
            aria-label="Data source connection URL"
            placeholder="e.g. http://localhost:9090"
            {...formApi.register('url', { validate: validateUrl })}
          />
        </InlineField>
      </div>
      <>
        <div className={styles.formGroup}>
          <h3>Authentication</h3>
          <h6>Authentication methods</h6>
          <p className={styles.authDescription}>Choose an authentication method to access the data source</p>
          <Field required invalid={!!formApi.errors?.authMethod?.message} error={formApi.errors?.authMethod?.message}>
            <Select
              aria-label="Auth method"
              value={authMethod}
              options={authMethodOptions}
              onChange={(e) => {
                formApi.setValue('authMethod', e.value);
                setAuthMethod(e);
                if (e.value === AuthMethod.BasicAuth) {
                  formApi.register('password', { required: 'The password is required' });
                } else {
                  formApi.unregister('password');
                }
              }}
            />
          </Field>
        </div>
        {authMethod.value === AuthMethod.BasicAuth && (
          <div className={styles.formGroup}>
            <InlineField
              htmlFor="user"
              label={'User'}
              labelWidth={24}
              tooltip={'The username of the data source account'}
              required
              invalid={!!formApi.errors?.user?.message}
              error={formApi.errors?.user?.message}
              interactive
              grow
            >
              <Input id="user" {...formApi.register('user', { required: 'The user is required' })} />
            </InlineField>
            <InlineField
              className={styles.field}
              htmlFor="password"
              label={'Password'}
              labelWidth={24}
              tooltip={'The password of the data source account'}
              required
              invalid={!!formApi.errors?.password?.message}
              error={formApi.errors?.password?.message}
              interactive
              grow
            >
              <SecretInput
                isConfigured={false}
                onReset={() => formApi.setValue('password', '')}
                onChange={(evt) => formApi.setValue('password', evt.currentTarget.value, { shouldValidate: true })}
              />
            </InlineField>
          </div>
        )}
      </>
    </>
  );
};

type DatasourceFormGroupsProps = {
  datasource: Datasources;
  defaultVersion: any;
  formApi: FormAPI<DataSourceDTO>;
};
const DatasourceFormGroups = ({ datasource, defaultVersion, formApi }: DatasourceFormGroupsProps) => {
  const styles = useStyles2(getDBConfigFormStyles);
  const versions = getDBConfigVersions(datasource);
  const selectableVersion = getDefaultVersionFromValue(defaultVersion, versions);
  const [version, setVersion] = useState<SelectableValue>(selectableVersion ?? {});

  const authMethodOptions = getAuthMethodsOptions();
  const [authMethod, setAuthMethod] = useState<SelectableValue>(authMethodOptions[2]);

  switch (datasource) {
    case 'postgres':
      return renderPostgresFormGroups(formApi, styles, version, setVersion, versions);

    case 'mysql':
      return renderMySQLFormGroups(formApi, styles);

    case 'influxdb':
      return renderInfluxDBFormGroups(formApi, styles, version, setVersion, versions);

    case 'graphite':
      return renderGraphiteFormGroups(formApi, styles, version, setVersion, versions);

    case 'prometheus':
      return renderPrometheusFormGroups(formApi, styles, authMethod, setAuthMethod, authMethodOptions);
  }
  return <></>;
};

export default DatasourceFormGroups;

import { DataToConnectType } from 'feature/common/types/DataToConnect';
import { ActionType, trackAction } from 'feature/common/utils/tracking';
import { ComponentKey } from 'feature/onboarding/types/ComponentKey';
import { externalPaths } from 'feature/common/consts/appPaths';
import { OnboardingCardData, OnboardingGeneralCardData } from 'feature/onboarding/types/OnboardingCard';
import { getControlOnboardingStepData } from 'feature/onboarding/data/ControlOnboarding.data';
import { cloneDeep } from 'lodash';
import { CLOUD_PLATFORM } from 'feature/common/types/CloudPlatform.enum';
import { CLOUD_PLATFORM_TITLES } from 'feature/onboarding/consts/consts';
import { getEventNameFromPath } from 'feature/onboarding/utils/OnboardingCard';
import { redirectToHref } from 'feature/onboarding/utils/sectionNavUtils';

export const isCloudPlatformOption = (optionSelected: string): boolean => {
  const platforms: string[] = Object.values(CLOUD_PLATFORM).filter((x) => typeof x === 'string');

  const platFormFound = platforms.find((p) => optionSelected.indexOf(p) >= 0);
  if (platFormFound) {
    const normalizedId: string = optionSelected.replace('datasource-', '');
    return normalizedId === platFormFound;
  }
  return false;
};

export const getCloudPlatformId = (optionSelected: string): string => {
  if (isCloudPlatformOption(optionSelected)) {
    return optionSelected.replace('datasource-', '');
  }
  return '';
};

export const getCloudPlatformType = (optionSelected: string): DataToConnectType | undefined => {
  if (isCloudPlatformOption(optionSelected)) {
    return optionSelected.indexOf('datasource-') < 0
      ? DataToConnectType.AgentIntegration
      : DataToConnectType.DataSource;
  }
  return undefined;
};

export const getCloudPlatformName = (optionSelected: string): string => {
  const platformId = getCloudPlatformId(optionSelected);
  if (platformId) {
    return CLOUD_PLATFORM_TITLES[platformId];
  }
  return optionSelected;
};

export const getCloudPlatformUrl = (optionSelected: string, cloudPlatformOptionSelected: string): string => {
  const optionType: DataToConnectType | undefined = getCloudPlatformType(optionSelected);
  const connectionId = cloudPlatformOptionSelected ? cloudPlatformOptionSelected : getCloudPlatformId(optionSelected);

  if (optionType === DataToConnectType.AgentIntegration) {
    return `${externalPaths.integrationsAndConnections}/${connectionId}`;
  } else if (optionType === DataToConnectType.DataSource) {
    return `${externalPaths.connectionDatasources}/${connectionId}`;
  }
  return '';
};

export function goToCloudPlatformConnection(
  orgId: number | undefined,
  optionSelected: string,
  cloudPlatformOptionSelected: string
) {
  if (optionSelected) {
    trackAction(getEventNameFromPath(), ComponentKey.GoToCloudPlatformPage, ActionType.click, orgId);
    redirectToHref(getCloudPlatformUrl(optionSelected, cloudPlatformOptionSelected));
  }
}

export function getCloudPlatformCards(
  optionSelected: string,
  cloudPlatformOptionSelected: string,
  onCloudPlatformCardSelected: Function,
  visualMode: 'modal' | 'page' = 'page'
): OnboardingGeneralCardData[] {
  const cloudPlatformId = getCloudPlatformId(optionSelected);
  const optionType = getCloudPlatformType(optionSelected);
  const suffix =
    optionType === DataToConnectType.AgentIntegration
      ? 'monitor'
      : optionType === DataToConnectType.DataSource
        ? 'data'
        : '';
  const cards: OnboardingCardData[] = getControlOnboardingStepData(`custom-${cloudPlatformId}-${suffix}`)?.cards ?? [];
  return (
    cards.map((card: OnboardingCardData) => {
      const newCard = cloneDeep(card) as OnboardingGeneralCardData;
      const urlParts = newCard.link.url.split('/');
      const connectionId: string = urlParts[urlParts.length - 2];
      newCard.link.url = '';
      newCard.link.callback = () => onCloudPlatformCardSelected(connectionId);
      if (cloudPlatformOptionSelected === connectionId) {
        newCard.selected = true;
      }
      if (visualMode === 'modal') {
        newCard.design = 'card';
      }
      return newCard;
    }) ?? []
  );
}

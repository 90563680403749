import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import darkGradient from 'img/backgrounds/background-dark.svg';
import lightGradient from 'img/backgrounds/background-light.svg';

export const getHomepageBackgroundStyles = (theme: GrafanaTheme2) => {
  return {
    backgroundImg: css`
      background-image: url(${theme.isDark ? darkGradient : lightGradient});
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-y: hidden;
    `,
    centeredLayout: css`
      width: 70%;
      overflow: hidden;
      margin: ${theme.spacing(3)} auto;
      max-width: 1400px;

      @media (max-width: 576px) {
        width: 94%;
      }
    `,
    headerTitle: css`
      color: ${theme.colors.text.primary};
      margin-bottom: ${theme.spacing(3)};
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.07px;
    `,
  };
};
